.account-plan-change {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__overlay {
    background: rgba(16, 22, 26, 0.6);
  }

  &__form {
    position: relative;
    width: 100%;
    max-width: 516px;
    padding: 40px 0;
    background: #ffffff;
  }

  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 18px;
    height: 18px;
    cursor: pointer;

    & > {
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 24px;
    color: #10161a;
    text-align: center;
  }

  &__change-list-wrapper {
    width: 100%;
    max-width: 372px;
    margin: 0 auto;
    padding: 40px 0 18px 0;
    border-radius: 4px;
    background-color: #f6f8fc;
  }

  &__price {
    color: #206acf;
    text-align: center;
  }

  &__time {
    margin-top: 10px;
    color: #10161a;
    text-align: center;
  }

  &__change-item {
    display: flex;
    align-items: center;
    min-height: 48px;
    margin: 0 24px;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e7ec;
    }
  }

  &__change-item-icon-wrapper {
    font-size: 0;
  }

  &__change-item-name {
    margin-left: 13px;
    color: #10161a;
  }

  &__change-item-status {
    margin-left: auto;
  }

  &__submit {
    width: 216px;
    height: 48px;
    margin: 24px auto 0;
    border-radius: 24px;
    background-color: #206acf;
  }
}
