.overlay {
  background-color: transparent;
}

.overlay > div {
  box-shadow: 0px 3px 6px 0px #05295626;
}

.overlay > div > div {
  background-color: var(--tooltipContent);
  color: var(--textColor);
  padding: 0;
  border: none;
  border-radius: 8px;
}