.account-plan-container {
  background: #f6f8fc;
  display: flex;
  flex-direction: column;

  .popups-head {
    background: #ffffff;
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 70px;
  }
}
