@import 'static/assets/styles/global/global-varibles';

.container {
  font-family: 'Nunito';
  display: flex;
  flex-direction: column;
}

.biosCircle {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 24px;
}

.biosCircle > svg {
  max-width: 50%;
  max-height: 50%;
  height: initial;
  color: var(--textColor);
}

.biosTalent {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 11px 13px 11px 16px;
  border: 0.5px dashed #5E5E5E;
  height: 62px;
  border-radius: 20px;
  cursor: pointer;
}

.description {
  width: calc(100% - 63px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.biosTalentTitle {
  margin: 0;
  height: 19px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--textColor);
}

.biosTalentText {
  margin: 0;
  height: 16px;
  font-size: 12px;
  font-weight: 200;
  line-height: 16px;
  letter-spacing: 0em;
  word-break: break-all;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--textColorSecond);
}

.mainSideBarButtonBack {
  color: var(--textColor);
  cursor: pointer;
}

.mainBlock {
  border-color: var(--textColor);
}

.mainBlock:hover {
  border-color: var(--primeTextColor);
  border-width: 1px;
}

.titleSep {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: var(--textColorSecond);
  margin-bottom: 0;
  margin-top: 24px;
}

.activateBiosForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.myInput {
  height: 62px !important;
  border-radius: 20px 3px 20px 20px !important;
  border-color: #5e5e5e !important;
  color: var(--textColor);

  &::before {
    content: '' !important;
    display: none;
  }

  input {
    caret-color: initial
  }
}

.myInput:hover {
  border-color: var(--primeTextColor) !important;
  border-width: 1px !important;
}