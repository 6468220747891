@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  justify-content: space-between;
}
.buttonWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7e8a9f;
  color: #ffffff;
  font-family: $sf_pro;
  font-weight: 400;
  font-size: 12px;
  line-height: 118%;
  padding: 6px 14px;
  width: 82px;
  height: 27px;
  margin: 0;
  &:hover {
    background-color: #000000;
  }
  &__selected {
    background-color: #000000;
  }
}

@media (max-width: 500px) {
  .buttonWrapper {
    width: 52px;
  }
}