.account-plan-usage-item {
  text-align: center;

  &_used & {
    &__usage-ico {
      use {
        stroke: #206acf;
      }
    }
  }

  &_shared & {
    &__usage-ico {
      use {
        stroke: #5da5ff;
      }
    }
  }

  &_free & {
    &__usage-ico {
      use {
        stroke: #ccceda;
      }
    }
  }

  &_over {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    flex-direction: row;
    text-align: left;

    .account-plan-usage-item__usage-desc {
      margin-top: 0;
      margin-left: 8px;
    }

    .account-plan-usage-item__usage-ico {
      use {
        stroke: #ed5463;
      }
    }
  }

  &__usage-desc {
    margin-top: 7px;
  }

  &__usage-text {
    margin: 0 0 7px;
    line-height: 1;
    color: #7c7e88;
  }

  &__usage-item-place {
    line-height: 1;
    color: #10161a;
  }
}
