.headerActionsBtnWrapper {
  width: 100%;
  display: flex;
  gap: 8px;
}

.uploadBtn {
  width: 33px;
  height: 33px;
  min-width: 33px;
  border-radius: 5px;
  gap: 4px;
  background-color: var(--actionButtonsColor);
  cursor: pointer;
  color: #ffff;
  border: 0.6px solid rgba(0, 0, 0, 0.4);
  & svg {
    color: var(--textColor);
  }
}

.uploadBtn:hover {
  background-color: var(--actionButtonsHover);
  border: 0.6px solid #fff;
  & svg {
    color: var(--baseColor);
  }
}

.uploadBtn:disabled {
  background-color: var(--switchBgInactive);

  & svg {
    color: #ffff;
  }
}

.uploadBtnDisabled {
  color: var(--textColorGray) !important;
}

.extraBtn {
  width: 33px;
  height: 33px;
  min-width: 33px;
  border-radius: 5px;
  gap: 4px;
  background-color: var(--actionButtonsColor);
  border: 0.6px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: var(--textColor);
}

.extraBtn:hover {
  background-color: var(--actionButtonsHover);
  border: 0.6px solid #fff;

  & svg {
    color: var(--baseColor);
  }
}

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  min-width: 33px;
  padding: 5px, 10px, 5px, 10px;
  border-radius: 5px;
  border: 0.6px;
  background-color: var(--actionButtonsColor);
  border: 0.6px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.actionBtnDelete {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 33px;
  padding: 5px, 10px, 5px, 10px;
  border-radius: 5px;
  border: 0.6px;
  background-color: var(--actionButtonsColor);
  border: 0.6px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: var(--textColor);
  text-transform: uppercase;
}

.actionBtn svg {
  color: var(--buttonIconColor);
}

.actionBtn:hover {
  background-color: var(--actionButtonsHover);
  border: 0.6px solid #fff;
  & svg {
    color: var(--baseColor);
  }
}

.mainHeader {
  &__action__btns {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    width: 180px;

    & svg {
      position: relative;
    }
  }

  &__btn {
    position: relative;
    cursor: pointer;
    margin: 0 8px;
    padding: 0;
    justify-content: center;
    transition: transform 0.5s linear;
    &__upload {
      margin: 0 8px;
      cursor: pointer;
    }
    &__member {
      margin: 0 8px;
      cursor: pointer;
    }
  }
}

.circleHover {
  position: relative;

  &:before {
    display: none;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--hover);
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}

.circleRotate {
  transform: rotate(45deg);
}

@media (max-width: 1024px) {
  .mainHeader {
    &__action__btns {
      justify-content: center;
      width: fit-content;
    }
  }

  .hide_is_tablet_selected_entity {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .mainHeader {
    &__action__btns {
      width: 144px;
      button:nth-child(5) {
        display: none;
      }
    }
  }

  .mainHeader__btn.mainHeader__hidden {
    display: none;
  }
}
