.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.settingsTab {
  width: 346px;
  background-color: #111111;
  border-radius: 20px;
  border: 0.5px solid #5E5E5E4D;
  padding: 16px;
}

.label {
  color: white;
  margin: 0 0 16px 0;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.switchItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.list:not(:last-child) {
  margin-bottom: 24px;
}

.switchLeft {
  display: flex;
  justify-content: center;
}

.switchLeft > svg {
  color: #fff;
}

.switchName {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
  margin-left: 13px;
}

.switchComponent > span {
  background-color: transparent;
  border: 0.5px solid #FFFFFF50;
}

.switchComponent > span > span {
  top: 2px !important;
  background-color: #FFFFFF50;
}

.switchComponent input:checked ~ span {
  background-color: transparent !important;
  border: 0.5px solid #FFFFFF !important;
}

.switchComponent input:checked ~ span > span {
  background-color:#FFFFFF !important;
}

.tooltipContent {
  padding: 6px;
  text-align: center;
}

.tooltipContent > span {
  cursor: pointer;
  color: blue;
}
