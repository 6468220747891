.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 40px;

  & svg {
    width: 30px;
    height: 40px;
    max-width: 30px;
    max-height: 40px;
  }
}

.uploadItem {
  min-height: 65px;
}
