@import 'static/assets/styles/global/global-varibles';

.label {
  display: block;
  font-family: $sf_pro, sans-serif;
  font-size: 12px;
  line-height: 24px;
  color: var(--textColor);
  margin-bottom: 5px;
}
