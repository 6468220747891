@import 'static/assets/styles/global/global-varibles';

.container {
  margin-top: 8px;
}

.fileLibery {
  gap: 4px;
}

.titleBackCollapsed {
  display: flex;
  transform: rotate(180deg);
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}

.menu {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.collapsed {
  width: 76px;
}

.collapsed .menu {
  padding-left: 0;
  padding-right: 0;
}

.menuItem {
  height: 38px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menuName {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 24px;
  color: #4b576c;
  margin-left: 16px;
}

.collapsed .subTitle {
  margin-left: 4px;
  text-align: center;
}

.collapsed .mainTitle {
  margin: 0;
  display: inline;
}

.collapsed .mainTitle button {
  margin: 0;
  display: flex;
  align-items: center;
}

.credits {
  font-size: 10px;
  color: $primary_black;
}

.collapsed .hr {
  margin: 8px;
}

.hr {
  margin: 8px 6px 8px 6px;
  border: 0.2px solid var(--hrColor);
}

@media screen and (max-width: 480px) {
  .title > div {
    display: block;
    margin-right: 20px;
  }
}
