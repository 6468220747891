@import '../../../static/assets/styles/global/global-varibles.scss';

.overlay {
  z-index: 1001;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.wrapper {
  background-color: $colWhite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 504px;
}

.modal {
  padding: 50px 48px 40px;
  max-width: 504px;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.primary {
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.4px;
  margin: 0 10px;
  padding: 14px 15%;
  height: 48px;
  min-width: 130px;
  max-width: 230px;
  background: #0f73ef;
  color: #ffffff;
  text-transform: uppercase;
  align-self: center;
  cursor: pointer;
  width: 100%;
  margin: auto;
}
