.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-item {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 24px;
    color: var(--textColorGray);
    display: flex;

    a {
      font-family: $sf_pro;
      display: flex;
      font-size: 12px;
      line-height: 24px;
      color: $medium_grey;
      white-space: nowrap;
    }

    &.active {
      font-family: $sf_pro;
      color: var(--textColor);

      a {
        font-family: $sf_pro;
        color: var(--textColor);
      }
    }

    &-additional {
      &-clear {
        &-label {
          margin-left: 36px;

          font-family: $sf_pro;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.02em;
          color: #9ca2ad;
        }
      }

      &-label {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 6px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $primary_light;
        background: $color_blue;
        border-radius: 8px;
        padding: 3px 6px;
        margin: 0 0 0 7px;
        height: 20px;
      }
    }

    &:hover {
      .breadcrumb-tooltip {
        transform: none;
        display: flex;
      }
    }
  }

  &-title {
    font-family: $sf_pro;
    font-size: 16px;
    line-height: 24px;
    color: var(--textColor);
    display: flex;
    text-transform: uppercase;

    a {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: var(--textColor);
      font-weight: 400;
    }

    &.active {
      font-family: $sf_pro;
      color: var(--textColor);

      a {
        font-family: $sf_pro;
        color: var(--textColor);
      }
    }

    &-additional {
      &-label {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 6px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $primary_light;
        background: $color_blue;
        border-radius: 8px;
        padding: 3px 6px;
        margin: 0 0 0 7px;
        height: 20px;
      }
    }

    &:hover {
      .breadcrumb-tooltip {
        transform: none;
        display: flex;
      }
    }
  }

  &-search {
    font-size: 16px;
    line-height: 24px;
    color: var(--textColor);

    &-count {
      margin-left: 5px;
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 16px;
      color: #7e8a9f;
    }
  }

  &-divider {
    display: flex;
    margin: 0 11px;
    align-items: center;
    transition: rotate 200ms ease-in-out;
  }

  &-tooltip {
    background-color: white;
    color: black;
    font-size: 11px;
    font-family: $sf_pro;
    line-height: 16px;
    padding: 15px 16px 15px 16px;
    z-index: 10001;
  }

  &-context {
    &-menu {
      min-width: 300px;
    }
  }
}
@media (max-width: 480px) {
  .breadcrumb {
    &-title {
      & > a {
        font-size: 14px;
      }
    }
  }
}
