.metaContainer {
  display: flex;
  flex-direction: column;
  width: 346px;
  gap: 8px;
}


// progressWidget

.progress {
  position: relative;
  height: 270px;
  width: 100%;
  background-color: #111111;
  border: 0.5px solid #5E5E5E66;
  border-radius: 20px;
  padding: 16px 16px 13px 16px;
  overflow: hidden;
}

.progressInitial .progressContent > div > div {
  width: 96px;
  height: 66px;
}

.progressHeader {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.progressHeaderTitle {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  color: #FFFFFF;
  margin-left: 10px;
}

.infoTitle {
  margin-left: 0;
}

.progressHeaderButoon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 0.2px solid #FFFFFF;
  cursor: pointer;
  border-radius: 50%;
}

.progressContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 63px);
  padding: 3px 0 9px 0;
}

.progressContent img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.progressFooter {
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 1;
}

.progressFooterItem {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

.progressFooterItemTitle {
  font-family: 'Nunito';
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
}

.progressFooterItemText {
  font-family: 'Nunito';
  font-size: 10px;
  font-weight: 200;
  line-height: 14px;
}

.remixForm {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 16px;
  top: 60px;
  left: 0;
  width: calc(100% - 32px);
  margin: 0 16px 16px 16px;
  height: calc(100% - 72px);
  border: 0.5px solid #5E5E5E4D;
  background: #111111;
  border-radius: 20px;
  z-index: 1;
}

.remixForm label {
  font-family: 'Nunito';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
  text-align: left;
  color: #FFFFFF;
}

.remixFormRow {
  display: flex;
  justify-content: space-between;
}

.remixFormRow p {
  font-family: 'Nunito';
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  margin: 0;
  color: #FFFFFF99;
  align-self: center;
}

.remixForm input {
  border: 0.5px solid #FFFFFF66;
  padding: 10px 16px 10px 16px;
  height: 39px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: transparent;
}


.remixForm input[type=number] {
  -moz-appearance: textfield;
}

.remixForm input[type=number]::-webkit-outer-spin-button,
.remixForm input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remixForm button {
  width: 40px;
  display: block;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #FFFFFF;
  margin-right: 10px;
  margin-left: auto;
  margin-top: auto;
  cursor: pointer;
}

// value

.value {
  height: 147px;
  width: 100%;
  background-color: #111111;
  border: 0.5px solid #5E5E5E66;
  border-radius: 20px;
  padding: 16px 16px 14px 16px;
}

.valueLogo {
  display: flex;
  align-items: center;
}

.valueHeader {
  display: flex;
  justify-content: space-between;
}

.valueHeaderText {
  margin: 0 0 0 7px;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #FFFFFF;
}

.valueChange {
  display: flex;
  align-items: center;
}

.valueChange span {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin-left: 4px;
}

.valueChangeProfit {
  color: #1BBF66;
}

.valueChangeLosses {
  color: #EC1C2D;
}

.valueContent {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  margin-bottom: 9px;
}

.valueContentText {
  font-family: 'Nunito';
  font-size: 30px;
  font-weight: 500;
  line-height: 41px;
  color: #FFFFFF;
}

.valueContentFooter {
  font-family: 'Nunito';
  font-size: 10px;
  font-weight: 200;
  line-height: 14px;
  opacity: 0,6;
  color: #FFFFFF;
  margin: 0;
}

.valueContentGraphic {
  display: flex;
  align-items: center;
  height: 40px;
  width: 190px;
}

.grafChart {
  height: .6px;
  width: 100%;
  background-color: #fff;
} 

/// meta

.meta {
  width: 100%;
  position: relative;
  background-color: #111111;
  border: 0.5px solid #5E5E5E66;
  border-radius: 20px;
  padding: 16px 16px 14px 16px;
}

.metaBtn {
  cursor: pointer;
}

.metaContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 16px;
}

.metaItem {
  display: flex;
  justify-content: space-between;
}

.metaItemTitle {
  font-family: 'Nunito';
  align-self: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.7;
  margin: 0;
}

.metaItemText {
  font-family: 'Nunito';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
}

.hashContainer {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hashContainer > p {
  width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hashContainer svg {
  color: white;
}