@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}
