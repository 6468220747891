.connectWrapper {
  font-family: 'Nunito';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px 72px 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header svg {
  width: 40px;
  height: 27px;
}

.title {
  font-size: 24px;
  font-weight: 200;
  line-height: 33px;
  letter-spacing: 0em;
}

.uploadText {
  position: absolute;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 200;
  line-height: 22px;
  width: 100px;
  text-align: center;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.saveBtn {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.saveBtnDisabled {
  color: #FFFFFF80;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.avatarLogo {
  position: relative;
  width: 50%;
}

.logo {
  width: 100%;
  height: initial;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 20px;
  width: 100%;
  gap: 14px;
}

.fileInput {
  display: none;
}

.logoImage {
  position: absolute;
  top: 12%;
  left: 14%;
  width: 72%;
  height: 70%;
  border-radius: 50%;
  object-fit: cover;
}

////////////   INPUT 

.inputContainer {
  width: 100%;
  display: flex;
  height: 48px;
  border: 0.8px solid #FFFFFF;
  border-radius: 14px;
  position: relative;
  padding: 13px 16px;
}

.inputContainer::before {
  position: absolute;
  content: '';
  top: 20px;
  left: -4px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  background-color: #FFFFFF;
}

.input {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  width: 100% !important;
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  caret-color: #F5E12D;
  color: white;
}

.inputContainer:focus-within {
  border-color: #F5E12D;
}

.inputContainer:focus-within::before {
  background-color: #F5E12D;
}

.input::placeholder {
  color: #FFFFFF80;
}

.selectContainer {
  width: 100%;
  display: flex;
  height: 48px;
  border: 0.8px solid #FFFFFF;
  border-radius: 14px;
  position: relative;
}

.selectContainerActive {
  border-color: #F5E12D;
}

.selectContainerActive::before {
  background-color: #F5E12D !important;
}

.selectContainer::before {
  position: absolute;
  content: '';
  top: 20px;
  left: -4px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  background-color: #FFFFFF;
}

.textareaContainer {
  width: 100%;
  display: flex;
  height: 108px;
  border: 0.8px solid #FFFFFF;
  border-radius: 14px;
  position: relative;
  padding: 13px 16px;
}

.textareaContainer:focus-within {
  border-color: #F5E12D;
}

.textareaContainer:focus-within::before {
  background-color: #F5E12D;
}

.textareaContainer::before {
  position: absolute;
  content: '';
  top: 20px;
  left: -4px;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  background-color: #FFFFFF;
}

.textareaInput {
  font-family: 'Nunito';
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #fff;
  caret-color: #F5E12D;
}