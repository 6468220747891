@import 'static/assets/styles/global/global-varibles';

.errorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1001;
}

.errorModal {
  display: block;

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}

.errorContainer {
  font-family: $sf_pro;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 504px;
  padding: 40px;
  text-align: center;
  background: #fff;
  transform: translate(-50%, -50%);
}

.errorTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #10161a;
  margin: 15px 0;
}

.errorText {
  font-size: 14px;
  line-height: 24px;
  color: #7e8a9f;
  margin-bottom: 24px;
}
