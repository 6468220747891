.locationTab {
  width: 346px;
  border-radius: 20px;
  background-color: #111111;
  padding: 16px;
  border: 0.5px solid #5E5E5E66;
}

.label {
  font-family: 'Nunito';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  margin: 0 0 16px 0;
}

.countrySelector label {
  margin: 0;
}

.countrySelector {
  
}

.list {
  margin-top: 10px;
}

.locationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  border: .5px solid #6f6f6f;
}

.locationInfo {
  display: flex;
  align-items: center;
}

.locationItem span {
  margin-left: 8px;
  color: #fff;
}

.deleteBtn {
  cursor: pointer;
}