.multy-upload-details {
  margin: auto;
  position: fixed;
  margin-left: 140px;
  left: 0;
  right: 0;
  bottom: 76px;
  z-index: 1008;
  & .download {
    position: relative;
    max-width: 450px;
    width: 100%;
    min-height: 62px;
    background-color: var(--baseColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 13px;
    box-shadow: 0px 2px 8px var(--boxShadow);

    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      padding: 0 14px;
    }
    &__file {
      color: var(--textColor);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: rem(16px);
      font-family: $sf_pro;
      &_dot {
        margin-right: 5px;
        width: 3px;
        height: 3px;
        background-color: rgba(171, 173, 185, 0.6);
        border-radius: 50%;
      }
      &_size {
        color: var(--textColor);
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        font-weight: 300;
      }
    }
    &__details {
      font-family: $sf_pro;
      line-height: rem(16px);
      cursor: pointer;
    }
    &__status {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 10px;
      border-radius: 100px;
      background-color: rgba(240, 242, 247, 0.15);
      padding: 0 16px;
      margin-bottom: 10px;
      transition: width 0.5s ease;
      &__complete {
        color: #3ab36c;
        margin-left: 14px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
      &__incompete {
        color: var(--textColor);
      }

      &__time {
        margin-left: 5px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        color: var(--textColor);
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      &__button {
        margin-left: 10px;
      }
      &__icons {
        display: flex;
        justify-content: space-between;
      }
      & > button:hover {
        cursor: pointer;
      }
    }
    &__item {
      width: 450px;
      height: 65px;
      border-bottom: 1px solid rgba(124, 136, 158, 0.31);
      display: flex;
      justify-content: center;
      text-align: left;
      &:hover {
        background-color: var(--hover);
      }

      &:hover .tooltip {
        display: flex;
        position: absolute !important;
        background-color: var(--tooltipContentSecond);
        color: var(--textColor);
        border-radius: 8px;
        border: 1px solid var(--inputBg);
        box-shadow: 0px 2px 8px var(--boxShadow);
        padding: 15px 23px;
      }

      &__completed {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        color: #3ab36c;
      }

      &__canceled {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        color: #ed1d2d;
      }

      &__status {
        align-items: flex-end;
        display: flex;
        color: var(--textColor);
        font-size: 12px;
        &__time {
          margin-right: 18px;
          padding-bottom: 9px;
        }
        &__right {
          display: flex;
        }
        &_percent {
          padding-bottom: 9px;
          margin-right: 12px;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            right: -8px;
            bottom: 15px;
            width: 3px;
            height: 3px;
            background: var(--textColor);
            border-radius: 50%;
          }
        }
        &__dot {
          margin-left: 5px;
          margin-right: 5px;
          padding-bottom: 15px;
        }
      }
      &__name {
        height: 24px;
        margin: 15px auto auto 12px;
        max-width: 270px;
        display: flex;
        flex-direction: column;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--textColor);
        text-overflow: ellipsis;
      }
      &__icon {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 25px;
      }
      &__cancel {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        color: var(--textColor);

        margin: 25px 18px 24px auto;
        &:hover {
          cursor: pointer;
        }
      }
      &__button {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        color: var(--textColor);

        margin: 25px 18px 24px auto;
        &:hover {
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.1px;

          color: #0f73ef;
        }
      }
      &__upload {
        height: 1px;
        width: 100px;
        background-color: #0f73ef;
        display: flex;
        justify-content: flex-start;
        min-height: 1px;
      }
    }
    &__upload {
      margin-top: 13px;
      height: 1px;
      background-color: #0f73ef;
      display: flex;
      justify-content: flex-start;
      &__bar {
        min-width: 450px;
        border-bottom: 1px solid rgba(124, 136, 158, 0.31);
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-height: 390px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .isClosed {
    display: none;
  }
}

.modal {
  .download {
    &__modal {
      &__header {
        margin-top: 16px;
        font-size: 24px;
        line-height: 32px;
        color: var(--textColor);
        font-weight: 400;
      }
      &__warning {
        color: var(--textColor);
        margin-top: 16px;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
      &__button {
        :hover {
          cursor: pointer;
        }
        display: flex;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 16px;
        height: 48px;
        &__text {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 24px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          letter-spacing: 1.4px;
          text-transform: uppercase;
        }
        &__cancel {
          width: 199px;
          border: 1px solid #ccceda;
          box-sizing: border-box;
          margin-right: 10px;
          color: #0f73ef;
        }
        &__continue {
          width: 199px;
          background: #0f73ef;
          color: var(--textColor);
        }
      }
      &__icon {
        width: 16px;
        :hover {
          cursor: pointer;
        }
        position: relative;
        left: 416px;
        right: 16px;
        bottom: 24px;
      }
    }
  }

  &-upload {
    height: 280px;
  }
}

@media screen and (max-width: 480px) {
  .multy-upload-details {
    width: 100vw;
    right: 0;
    left: auto;
  }
  .download__buttons__button:first-child {
    display: none;
  }
}
