@import 'static/assets/styles/global/global-varibles.scss';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

.avatar {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}

.avatar :global(.avatar-initials) {
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  svg {
    width: 20px;
    height: 20px;
  }
}

.name {
  font-family: $sf_pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: $colBlackText;
  margin-left: 8px;
  text-align: left;
  padding-right: 10px;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.sidebarIsClosed .name {
  display: none;
}

.arrowIcon {
  margin-left: auto;
  margin-right: 10px;
  transform: rotate(0deg);
  flex-shrink: 0;
}

.noUserName .arrowIcon {
  margin-left: 10px;
}

.sidebarIsClosed .arrowIcon {
  display: none;
}

.menuIsOpen .arrowIcon {
  transform: rotate(180deg);
}
