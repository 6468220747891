@import 'static/assets/styles/global/global-varibles';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: relative;
}

.circle::before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.circle:nth-child(1),
.circle:nth-child(2) {
  opacity: 1;
}

.circle:nth-child(3) {
  animation: opacityAnimation3 4s infinite;
}

.circle:nth-child(4) {
  animation: opacityAnimation4 4s infinite;
}

.circle:nth-child(1)::before {
  opacity: 1;
}

.circle:nth-child(2)::before {
  animation: appear2 4s infinite;
}

.circle:nth-child(3)::before {
  animation: appear3 4s infinite;
}

@keyframes opacityAnimation3 {
  0% {
    opacity: 0.4;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityAnimation4 {
  0% {
    opacity: 0.1;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}


@keyframes appear2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear3 {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}