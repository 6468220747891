@import 'static/assets/styles/global/global-varibles';

.container {
  background-color: #000;
  width: 100vw;
  height: 100%;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  gap: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;
  overflow-y: auto;
  overflow-x: hidden;

  &__title {
    color: #fff;
    text-align: center;
    font-family: $sf_pro;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.18px;
    text-transform: uppercase;
    display: block;
    margin: 16px 0 0;
  }

  &__subtitle {
    color: #fff;
    text-align: center;
    font-family: $sf_pro;
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.18px;
    text-transform: lowercase;
    opacity: 0.7;
    margin: 0 0 56px;
  }

  &__entityName {
    color: #fff;
    font-family: $sf_pro;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & button {
      cursor: pointer;
      border-radius: 8px;
      width: 100%;
      max-width: 340px;
      padding: 10px 20px;

      color: #fff;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.14px;
      text-align: center;

      transition: all 250ms ease-in-out;
    }

    & button:first-child {
      background: #0a84ff;
      font-weight: 500;

      &:hover {
        background: #0f75db;
        color: #fff;
      }
    }

    & button:last-child {
      background: #1c1c1e;
      margin-top: 11px;
      font-weight: 300;

      &:hover {
        background: #202022;
        color: #fff;
      }
    }
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
