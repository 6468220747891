.files-container {
  width: 100%;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dots-action {
      width: auto;
      height: auto;
    }

    & > .file-action {
      flex-shrink: 0;
    }
  }

  &__content {
    position: relative;
    .files-container__content {
      &::-webkit-scrollbar {
        display: none;
      }
      &-square {
        position: relative;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-wrap: wrap;
    align-content: flex-start;
    &.ios {
      min-height: auto;
      max-height: auto;
      height: calc(100vh - 200px);
    }
    &-square {
      position: relative;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      width: 100%;
      align-content: flex-start;

      .square__item {
        display: flex;
        max-height: 250px;
        &:hover {
          & > section > div > svg {
            transform: translate(-50%, -50%) rotate(90deg);
          }
          & > section > div > div > div > svg {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }

    &-time {
      width: 100%;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $text_grey;
      margin: 0 0 8px 0;
    }
  }

  .uploader {
    flex-basis: 16.16666%;
    min-height: 242px;
    margin-bottom: 8px;
    margin-right: 0.5%;
  }

  &__empty {
    margin-top: 57px;
    height: 100%
  }
}

@media screen and (max-width: 1700px) {
  .files-container {
    .uploader {
      flex-basis: 19.5%;
    }
  }
}

@media screen and (max-width: 1350px) {
  .files-container {
    .uploader {
      flex-basis: 24.5%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .files-container__content-square {
    position: relative;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    & .square__item {
      width: calc(50% - 7px);
      max-height: 242px;
      min-width: 200px;
    }
  }
  .isSelectedOnLaptop {
    justify-content: center;
  }
  .files-container {
    .uploader {
      flex-basis: 32.834%;
    }
  }
}

@media screen and (max-width: 800px) {
  .files-container {
    .uploader {
      flex-basis: 49.5%;
    }
  }
}

@media screen and (max-width: 520px) {
  .files-container {
    .uploader {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .files-container {
    &__content {
      &-square {
        display: grid;
        grid-template-columns: repeat(2, minmax(160px, 1fr));
      }
    }
  }

  .square__item {
    width: initial !important;
    min-width: inherit !important;
  }
}
@media screen and (max-width: 330px) {
  .files-container__content-square {
    justify-content: center;
  }
}
