.wallet-balance {
  padding: 40px 20px;
  text-align: center;
  background: #ffffff;

  &__title {
    margin-bottom: 15px;
    color: #206acf;
    letter-spacing: 1.4px;
    line-height: 1;
  }

  &__current-balance {
    margin-bottom: 20px;

    color: #10161a;
    line-height: 1;
  }

  &__recharge {
    width: 172px;
    height: 36px;
    margin: 0 auto;
  }
}
