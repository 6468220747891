.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Nunito';
  width: 100%;
  height: 100%;
  padding: 21px 16px 45px 16px;
  background-color: #000;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: #C5BEBE;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
  margin-top: 60px;
  overflow-y: auto;
}

.subtitle {
  font-size: 13px;
  font-weight: 200;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF99;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.neyraButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 32px;
  font-weight: 200;
  line-height: 44px;
  letter-spacing: 0em;  
  border: 1px solid #FFFFFF80;
  height: 70px;
  border-radius: 14px;
}

.buttonText {
  color: #fff;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.plusIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.7px solid #fff;
  color: #fff;
  font-family: Nunito;
  font-size: 26px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-align: center;
}

.loginButton {
  padding: 12px 16px;
  border-radius: 14px;
  border: 1px dashed #727272;
  color: #727272;
  text-align: center;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 38px;
}