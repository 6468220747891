.upload-details {
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 8px;
  z-index: 101;
  & .download {
    max-width: 740px;
    width: 100%;
    height: 62px;
    margin: 0 auto;
    background-color: rgba(36, 45, 52, 0.96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
    }
    &__file {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $primary_light;
      line-height: rem(16px);
      font-family: $sf_pro;
      &_name {
        margin: 0 5px;
      }
      &_dot {
        margin-right: 5px;
        width: 3px;
        height: 3px;
        background-color: rgba(171, 173, 185, 0.6);
        border-radius: 50%;
      }
    }
    &__details {
      color: rgba(255, 255, 255, 0.6);
      font-family: $sf_pro;
      line-height: rem(16px);
      cursor: pointer;
    }
    &__status {
      width: 100%;
      height: 10px;
      border-radius: 100px;
      transition: width 0.5s ease;
      &_progress {
        height: 100%;
        border-radius: 100px;
        &-uploading {
          background-color: #206acf;
        }
        &-encrypting {
          background-color: #b453fd;
        }
        &-sharding {
          background-color: #ed5463;
        }
      }
    }
  }
}
