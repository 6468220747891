@import 'static/assets/styles/global/global-varibles';

.popover > div:first-child {
  background-color: var(--tooltipContent);
}

.settingsWrapper {
  display: flex;
}

.optionItem {
  display: flex;
  cursor: pointer;
  color: #494951;
}

.optionItem:hover {
  color: #ffffff;
}

.viewOption {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  margin: 0 4px;
  color: #ffffff;
}

.accountButtonWrapper {
  border-radius: 4px;
  width: 29px;
  height: 27px;
  border: var(--buttonBorder);
  background-color: var(--buttonBg);
  color: var(--buttonIconColor);
  cursor: pointer;
}

.accountButtonWrapper:hover {
  background-color: var(--settingsBtnHover);
  color: #202124;
}

.sorter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sorterSettings {
  margin-left: auto;
}

.moreOptions {
  width: 27px;
  height: 27px;
  border-radius: 5px;
  border: 0.6px;
  background-color: var(--buttonBg);
  cursor: pointer;
  color: var(--sidebarGrayText);
}

.moreOptions:hover {
  background-color: var(--settingsBtnHover);
}

.moreTooltip {
  width: 137px;
  padding: 4px 0;
  border-radius: 8px;
}

.moreTooltipItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  color: var(--sidebarGrayText);
}

.moreTooltipItem:hover {
  background-color: var(--notificationHover);
}

.viewTooltipItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  color: var(--sidebarGrayText);
}

.viewTooltipItemActive {
  background-color: var(--notificationHover);
}

.viewTooltipItem:hover {
  background-color: var(--notificationHover);
}

.viewTooltipItem span {
  margin-left: 8px;
}

.viewTooltipItem svg {
  color: var(--sidebarIcon);
}

.text {
  font-family: $sf_pro;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--textColor);
}

.choosenFileType {
  font-family: $sf_pro;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  color: #494951;
  border-radius: 8px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.choosenFileTypeSelected {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 0.6px;
  background-color: var(--folderHeader);
  border: 0.2px solid var(--settingsBorderColor);
  color: #007aff;
  cursor: pointer;
}

.choosenFileType:hover svg {
  color: #007aff;
}

.sortWrapper {
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  cursor: pointer;
  color: #494951;
}

.sortWrapper:hover svg {
  color: #fff;
}

// .choosenColor:hover {
//   background-color: var(--settingsBtnHover);
// }

.choosenColor {
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  color: #494951;
}

.choosenColor:hover svg {
  color: #fff;
}

.colors {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 8px;
  gap: 10px;
  width: 137px;
}

.emptyColor {
  background: url(/static/assets/img/icons/colors/empty.svg) no-repeat center
    center;
  border: 1px solid $fifty_color;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 2px;
}

.colorItem {
  cursor: pointer;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 2px;

  &__box {
    border-radius: 2px;
    &-disabled {
      cursor: default;

      &:hover {
        cursor: default;

        &:not(.active) {
          margin: 0 7.5px 0 7.5px;
        }
      }
    }
  }
}

.option {
  font-family: $sf_pro;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: left;
  color: var(--sidebarGrayText);
  padding: 4px 8px 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 28px;
  width: 93px;
}

.optionSort {
  font-family: $sf_pro;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.005em;
  text-align: left;
  color: var(--sidebarGrayText);
  padding: 4px 8px 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 28px;
  width: 137px;
}

.optionSortData {
  align-items: center;
  display: flex;
}

.optionSortBtn {
  cursor: pointer;
  background-color: var(--headerDirectionBg);
  color: var(--iconColor);
}

.optionSortData > button {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 2px;
}

.optionText {
  margin-left: 8px;
}

.optionInfo {
  display: flex;
  align-items: center;
}

.option:hover {
  background-color: var(--notificationHover);
}

.option {
  color: var(--sidebarIcon);
}

.activeOption {
  background-color: var(--notificationHover);
}

.typeOption {
  width: 137px;
}

.closeIcon {
  cursor: pointer;
  width: 7px;
  height: 7px;
}
