.registrationStepContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
  width: 100%;
  max-width: 870px;
}

.scrollContent {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  padding-top: 250px;
}

.shadowBlock {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 10px;
  width: calc(100% - 10px);
  height: 150px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.question {
  color: #fff;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: pre-line;

  span {
    font-weight: 300;
  }
}

.answerWrapper {
  margin-top: 18px;
  margin-bottom: 18px;
}

.answer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  &::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
  }
}

.editButton {
  cursor: pointer;
  color: #fff;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.footerBlock {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.scrollContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scrollContent::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.continueButtonWrapper {
  position: absolute;
  width: 100%;
  background-color: #000;
}

.displayNone {
  display: none;
}

.continueButton {
  margin: 0 auto;
  width: 100%;
  max-width: 398px;
  padding: 12px 16px;
  border-radius: 14px;
  border: 0.7px solid #ffe24a;
  color: #ffe24a;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  &:hover {
    color: #ffe24a;
    border-color: #ffe24a;
  }
}

.omitContent {
  position: relative;
  bottom: -55px;
  z-index: 10;
  background-color: #000;
}

@media only screen and (max-width: 730px) {
  .scrollContent {
    padding-top: 20px;
  }

  .answer,
  .question {
    font-size: 14px;
  }

  .registrationStepContainer {
    gap: 32px;
  }

  .footerBlock {
    height: 50px;
  }

  .shadowBlock {
    top: -13px;
    left: 0px;
    right: 0px;
  }
}
