.account-plan-short-details {
  padding: 30px 15px 15px;
  background: #ffffff;

  &__top-info {
    display: flex;
    align-items: center;
    &_bold {
      color: #10161a;
    }

    &_blue {
      color: #206acf;
    }
  }

  &__top-info-dot {
    margin: 0 5px;
    width: 2px;
    height: 2px;
    background-color: #7c7e88;
  }

  &__top-info-cancel {
    cursor: pointer;
  }

  &__bottom-info {
    margin-top: 13px;
    color: #58595f;
  }
}
