@import 'static/assets/styles/global/global-varibles.scss';

.overlay {
  background: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1006;
}

.modal {
  display: block;
}

.wrapper {
  font-family: $sf_pro;
  position: absolute;
  width: 600px;
  top: 50%;
  left: 50%;
  background-color: var(--baseColor, #000);
  transform: translate(-50%, -50%);
  padding: 61px;

  &__exit {
    color: var(--iconColor, #ffffff);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--textColor, #ffffff);
}

.description {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--textColorGray, #7d7d7d);
  margin: 0;
}

.actions {
  margin-top: 25px;
  justify-content: center;
  display: flex;
}

.cancelBtn {
  color: var(--textColor, #fff) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

.buyBtn {
  height: 48px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

.closeIcon {
  color: var(--iconColor, #fff);
}
