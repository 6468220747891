@import 'static/assets/styles/global/global-varibles';


.container {
  border-top: 1px solid var(--dividingLine);
  height: 30px;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  
  & > div {
    height: 27px;
    margin-top: 1px;
  }

  span {
    font-size: 12px;
  }
}

.settingsContent span {
  text-transform: uppercase;
  color: var(--settingsSidebarColor) !important;
}

.settingsContent path {
  fill: var(--settingsSidebarColor) !important;
}

.settingsContent circle  {
  stroke: var(--settingsSidebarColor) !important;
}
 
.containerColapsed {
  padding-left: 0;
  padding-right: 0;
}
