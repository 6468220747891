.drawWrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.canvasContent {
  position: relative;
  display: flex;
  gap: 18px;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 60px);
  &__canvas {
    width: 50%;
    border-radius: 10px;
    border: 0.5px solid var(--studioPanelBorder);
    height: 100%;

    & canvas {
      border-radius: 10px;
      touch-action: none;
      user-select: none;
      cursor: crosshair;
      cursor: url('./assets/brush_cursor.svg'), auto;
    }

    &:hover {
      border-color: var(--iconColor);
    }
  }
  &__image {
    width: 50%;
    height: 100%;
    border-radius: 10px;
    border: 0.5px solid var(--studioPanelBorder);
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
    &:hover {
      border-color: var(--iconColor);
    }
  }
}

.swapButton {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--iconColor);
  border-radius: 30px;
  border: 0.5px solid #fff;
  background: var(--swapButton);
  width: 40px;
  height: 40px;
  & svg {
    width: 24px;
    height: 24px;
  }
}

.panelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: auto;
  height: auto;
  & .colorPicker {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    & > div {
      position: absolute;
      top: -170px;
      left: 40px;
    }
  }
  & .brushSize {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 0.5px solid var(--studioPanelBorder);
    height: 100%;
    width: auto;
    padding: 0 9px 0 5px;
    height: 38px;
    border-radius: 10px;

    &__label {
      color: var(--textColor);
      border-radius: 6px;
      border: 0.5px solid var(--studioPanelBorder);
      padding: 5px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      min-width: 42px;
      max-height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & input[type='range'] {
      cursor: pointer;
      width: 161px;
      padding: 1px 0;
      border-radius: 8px;
      height: 2px;
      outline: none;
      border: none;
      transition: background 450ms ease-in;
      accent-color: #fff;
      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
      }
    }
  }

  & .undo {
    cursor: pointer;
    width: 48px;
    height: 100%;
    padding: 6px;
    border-radius: 6px;
    border: 0.5px solid var(--studioPanelBorder);
    color: var(--studioPanelBorder);
  }
  & .reset {
    cursor: pointer;
    width: 48px;
    height: 100%;
    color: var(--studioPanelBorder);
    padding: 6px;
    border-radius: 6px;
    border: 0.5px solid var(--studioPanelBorder);
  }
}

.neyraLogo {
  width: 250px;
  height: 150px;
  color: var(--neyraLogoColor);
}

.drawSettings {
  width: 100%;
  height: 38px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;

  & input {
    background: var(--chatInput);
    height: 60px;
    display: flex;
    border-radius: 20px;
    border: 0.5px solid #ffffff8c;
    font-family: 'Nunito';
    color: var(--textColor);
    padding: 2px 24px;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    color: #ffffff8c;
    flex-grow: 1;
    &:hover,
    &:focus {
      border-color: #ffffff;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    height: 100%;
  }

  & button {
    cursor: pointer;
    padding: 8px 24px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid var(--iconColor);
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0em;
    color: var(--iconColor);
    &:hover {
      color: var(--textColor);
      border-color: var(--textColor);
    }
  }
  &__price {
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0em;
    color: var(--textColor);
    border: 0.5px solid var(--lightGrayTab);
    padding: 8px 12px;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    margin: 0;
  }
}

.driveBtn {
  min-width: 132px;
}

.loader {
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 10px 0 var(--textColor), -10px 0 var(--studioLoaderShadow);
    background: var(--textColor);
  }
  33% {
    box-shadow: 10px 0 var(--textColor), -10px 0 var(--studioLoaderShadow);
    background: var(--studioLoaderShadow);
  }
  66% {
    box-shadow: 10px 0 var(--studioLoaderShadow), -10px 0 var(--textColor);
    background: var(--studioLoaderShadow);
  }
  100% {
    box-shadow: 10px 0 var(--studioLoaderShadow), -10px 0 var(--textColor);
    background: var(--textColor);
  }
}
