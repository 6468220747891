.analytics {
  &-block {
  }

  &-container {
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
  }

  &-item {
    background: $primary_light;
    box-shadow: 0 1px 0 $forty_color;
    //width: 138px;
    min-width: 138px;
    height: 120px;
    margin: 0 17px 0 0;
    text-align: center;

    &__title {
      font-size: 32px;
      line-height: 35px;
      margin: 35px auto 5px auto;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 21px;
      margin: 0;
      color: $light_grey;
    }
  }
}
