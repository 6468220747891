.wrapper {
  display: inline-block;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .wrapper {
    opacity: 1 !important;
    visibility: visible !important;
    right: 10px !important;
    top: 14px !important;
  }
}
