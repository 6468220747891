.wrapper {
  min-height: 100vh;
  background-color: #f6f8fc;
  padding: 40px;
  position: relative;
}

.logo {
  display: block;
  margin: 0 auto;
}

.inviteForm {
  margin: 40px auto 0 auto;
}

.inviteSent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 80px);
}

@media screen and (min-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .inviteForm {
    margin: 0;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .inviteSent {
    margin-top: 0;
    position: static;
    transform: none;
  }
}
