@import 'static/assets/styles/global/global-varibles';

.login {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    margin: 21px 0 0px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    
  }
  &__list {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 43px;
  }
  &__button {
    width: 42px;
    height: 42px;
    cursor: pointer;
    border: none;
    border: .5px solid var(--switchBgInactive, #B9B9B9);
    border-radius: 6px;

    svg {
      width: 27px;
      height: 24px;
    }
  }
}

@media screen and (max-width: 720px) {
  .login {
    &__wrapper {
      border-left: none;
      padding-left: 0;
      margin-bottom: 45px;
    }
    
    &__button {
      height: 60px;
    }
  }
}