.video__wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  .popup__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 24px;
    .iframe__wrapper {
      width: 85vw;
      height: 65vh;
      background: transparent;
      padding: 0 !important;
    }
    .close__popup {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 24px;
      height: 24px;
      background: url(/static/assets/img/white_cross.svg) center center / contain
        no-repeat;
      cursor: pointer;
    }
  }
}
.video__popup {
  display: none;
  &.active {
    display: block;
  }
}
