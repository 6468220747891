.tooltip {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: $box_shadow;
  min-height: 15px;
  color: $primary_light;
  white-space: nowrap;
  padding: 8px 13px;
  margin-bottom: 8px;
  border-radius: 3px;
  z-index: 2;
  font-family: $sf_pro;
  font-size: 11px;
  line-height: 16px;

  &-visible {
    display: block;
  }

  &-right {
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      left: -9px;
      display: block;
      z-index: 1;
      width: 0;
      height: 0;
      border-right: 9px solid $box_shadow;
      background: $box_shadow;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }
  }

  &-right__white {
    &:before {
      content: '';
      position: absolute;
      top: 30%;
      left: -9px;
      display: block;
      z-index: 1;
      width: 0;
      height: 0;
      border-right: 10px solid white;
      border-top: 6px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  &-bottom {
    top: calc(100% + 10px);
    margin: 0;
    bottom: auto;
    left: 22px;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }
  }

  .green {
    color: $color_green;
  }

  .red {
    color: $colUrgent;
  }

  ul {
    margin: 0;
    padding: 0 0 0 16px;
  }

  &_white {
    background-color: white !important;
    color: black !important;
    border-radius: 8px;
    box-shadow: 2px 2px 16px rgba(26, 49, 139, 0.16);
  }
}

.tooltip-wrapper:hover > .tooltip {
  display: block;
}
