.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay);
  z-index: 1005;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 620px;
  padding: 40px;
  text-align: center;
  background: var(--baseColor);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.closeBtn {
  cursor: pointer;
  margin-top: 14px;
  margin-right: 14px;
  margin-bottom: 11px;
  margin-left: auto;
  color: var(--iconColor);
}
