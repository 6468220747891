.context-menu {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 210px;
  background: var(--tooltipContentSecond);
  box-shadow: var(--boxShadow);
  box-sizing: border-box;
  z-index: 1025;

  &-item {
    font-size: 12px;
    color: #4b576c;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    height: 32px;
    padding: 0 10px 0 20px;
    cursor: pointer;

    &:last-child {
      padding: 0 22px 0 20px;
    }

    &:hover {
      background: var(--ruleColor);
      mix-blend-mode: normal;
      color: var(--primeTextColor);

      svg {
        color: var(--primeTextColor);
        path {
          fill: var(--primeTextColor);
        }
      }
    }

    &:hover & {
      &-name {
        color: var(--primeTextColor);
      }
    }

    svg {
      color: var(--contextIcon);
    }

    &-name {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: var(--textColor);
      margin: 0 0 0 15px;
    }

    &-divider {
      border-bottom: 0.5px solid var(--contextBorder);
    }

    &.disabled {
      &:hover {
        background: $context_color;
        mix-blend-mode: normal;
        color: $forty_color;

        svg {
          path {
            fill: $forty_color;
          }
        }
      }

      svg {
        path {
          fill: $forty_color;
        }
      }
    }

    &.disabled & {
      &-name {
        color: $forty_color;
      }
    }

    &.disabled:hover & {
      &-name {
        color: $forty_color;
      }
    }
  }

  .uploadItem,
  .viewItem,
  .guardiansItem {
    position: relative;

    div:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 1px;
      width: 210px;
      border-bottom: 0.5px solid var(--contextBorder);
    }
  }

  .imageToImage {
    position: relative;
    div:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
      height: 1px;
      width: 210px;
      border-bottom: 0.5px solid var(--contextBorder);
    }
  }

  .nftItem {
    position: relative;

    div:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 1px;
      width: 210px;
      border-bottom: 0.5px solid var(--contextBorder);
    }

    div:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -1px;
      height: 1px;
      width: 210px;
      border-bottom: 0.5px solid var(--contextBorder);
    }
  }
}
