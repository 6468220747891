.file-rename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 125px);
  padding: 0 4.5vw;

  .bottom-button {
    margin-top: 44px;
  }

  .bottom-link {
    margin-top: 10px;
  }
}
