@import 'static/assets/styles/global/global-varibles.scss';

.wrapper {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-family: $sf_pro;
  border-bottom: 1px solid var(--borderCardColor);
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_titleWrapper {
    display: flex;
    gap: 33px;
    align-items: center;
    &_textContent {
      h2 {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: var(--textColor);
      }
      span {
        margin: 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: var(--newGrayTextColor) !important;
      }
    }
  }
  button {
    font-weight: 600;
    width: 179px;
    height: 40px;
  }

  &_textButton {
    border-radius: 3px;
    width: 100px !important;
    min-width: 100px;
    letter-spacing: 0;
  }
}

.cryptoLabel {
  font-size: 10px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 8px;
  color: var(--textColor);
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &_inputSection {
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    &_cryptoPlatformIcon {
      position: absolute;
      top: 13px;
      left: 16px;
      svg {
        width: 24px;
        height: 22px;
      }
    }
    &_input:disabled {
      flex: 1;
      text-align: end;
      padding: 0 55px;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-color: var(--profileCard);
      background-color: var(--profileCard);
      color: var(--textColor);
    }
    &_copyAddressIcon {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 14px;
      svg {
        height: 16px;
        width: 16px;

        &:hover {
          path {
            fill: rgb(15, 115, 239);
          }
        }
      }
    }
  }
}

.copySvg path {
  fill: var(--iconColor);
}

.overlay {
  background: rgba(#10161a, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
}

.modal {
  display: block;
  &__wrapper {
    h2 {
      margin: 0px;
    }
    position: absolute;
    width: 700px;
    top: 50%;
    left: 50%;
    background: #ffffff;
    transform: translate(-50%, -50%);
  }
  &__title {
    text-align: center;
    font-family: $sf_pro;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  &__extensionItemsWrapper {
    display: flex;
    gap: 16px;
  }

  &__addWalletModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 377px;
    padding: 40px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 17px;
    height: 40px;
    button {
      width: 179px;
    }
  }
  &__exit {
    color: var(--iconColor, #ffffff);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}

.inputSection_cryptoInfo {
  display: flex;
}

.inputSection_name {
  margin: 0 0 0 16px;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--textColor);
}

.preload {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    animation: lds-dual-ring 1.1s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 880px) {
  .wrapper {
    padding-right: 0;
  }
  .inputSection_name {
    display: none;
  }
}
@media (max-width: 550px) {
  .wrapper {
    margin-top: 16px;
    padding: 16px 0;
    gap: 20px;
  }
  .headerWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    button {
      width: 100%;
    }
    &_titleWrapper {
      gap: 33px;
      svg {
        display: none;
      }
      &_textContent {
        h2 {
          font-size: 16px;
        }
      }
    }
  }
  .mainContainer {
    margin-left: 0;
  }
  .modal {
    &__wrapper {
      h2 {
        margin: 0px;
      }
      width: 287px;
    }
    &__title {
      font-size: 20px;
      line-height: 19px;
    }
    &__extensionItemsWrapper {
      button {
        width: 125px;
        height: 123px;
        svg {
          width: 44px;
          height: 39px;
          margin-bottom: 10px;
        }
        span {
          margin: 0;
        }
      }
      gap: 10px;
    }

    &__addWalletModalContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 299px;
      padding: 40px 13px 25px 13px;
    }
    &__buttons {
      gap: 10px;
      button {
        min-width: auto;
        width: 125px;
      }
    }
  }
}
