@import 'static/assets/styles/global/global-varibles';

.button {
  position: relative;
  height: 48px;
  min-width: 132px;
  text-transform: uppercase;
  font-family: $sf_pro;
  letter-spacing: 0.2em;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  cursor: pointer;
}

.themePrimary {
  display: inline-block;
  background-color: #0f73ef;
  color: #ffffff;
  border: 1px solid #0f73ef;
  min-height: 40px;
}

.themeBorderedDark {
  border: 1px solid var(--border);
  background-color: transparent;
  text-transform: uppercase;
  color: var(--textColor);
  min-height: 40px;
}

.themeDark {
  background-color: var(--buttonColor, #202124);
  text-transform: uppercase;
  color: var(--avatarText, #ffffff);
  min-height: 40px;

  &:hover {
    background-color: $primary_color;
  }
}

.themePrimary:hover {
  background-color: #197fff;
}

.themeSecondary {
  display: inline-block;
  background-color: #f6f8fc;
  color: #202124;
  border: 1px solid #202124;
  min-height: 48px;
}

.themeSecondary:hover {
  background-color: #e8f0fd;
}

.themeTransparent.button {
  display: inline-block;
  background-color: transparent;
  color: var(--textColor);
  border: none;
  padding: 0;
  min-height: auto;
}
.themeBordered {
  letter-spacing: 1.4px;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
}

.themeDisabledDark {
  cursor: not-allowed;
  color: $mainBlack !important;
  background-color: var(--grayBgSecond, #7D7D7D) !important;
}

.themeDisabledPrimary {
  display: inline-block;
  background-color: #c1c9d9;
  color: #ffffff;
  border: 1px solid #c1c9d9;
  min-height: 48px;
  cursor: not-allowed;
}

.themeNewBordered {
  position: relative;
  background-color: white;
  height: 36px;
  min-height: 20px;
  padding: 0;
  color: black;
  & > svg {
    position: absolute;
  }
  & > p {
    margin: 0;
  }
  & svg:first-child {
    top: -7px;
    left: -7px;
  }
  & svg:nth-child(2) {
    bottom: -6px;
    left: -6px;
    transform: rotate(180deg);
  }
  & svg:nth-child(4) {
    top: -6px;
    right: -6px;
  }
  & svg:nth-child(5) {
    bottom: -6px;
    right: -6px;
    transform: rotate(90deg);
  }
}

.themeNewBorderedDark {
  position: relative;
  background-color: var(--buttonColor, #202124);
  height: 36px;
  min-height: 20px;
  padding: 0;
  color: var(--avatarText, #FFFFFF);
  & > svg {
    position: absolute;
    color: var(--iconSecondColor, #202124);
  }

  & > p {
    margin: 0;
  }
  & svg:first-child {
    top: -7px;
    left: -7px;
  }
  & svg:nth-child(2) {
    bottom: -6px;
    left: -6px;
    transform: rotate(180deg);
  }
  & svg:nth-child(3) {
    top: -6px;
    right: -6px;
  }
  & svg:nth-child(4) {
    bottom: -6px;
    right: -6px;
    transform: rotate(90deg);
  }
  & svg:nth-child(5) {
    top: -6px;
    right: -6px;
  }
}

.themeNewBorderedBlue {
  position: relative;
  background-color: #0f73ef;
  height: 36px;
  min-height: 20px;
  padding: 0;
  color: black;
  & > svg {
    position: absolute;
    color: #0f73ef;
  }

  & > p {
    margin: 0;
  }
  & svg:first-child {
    top: -7px;
    left: -7px;
  }
  & svg:nth-child(2) {
    bottom: -6px;
    left: -6px;
    transform: rotate(180deg);
  }
  & svg:nth-child(3) {
    top: -6px;
    right: -6px;
  }
  & svg:nth-child(4) {
    bottom: -6px;
    right: -6px;
    transform: rotate(90deg);
  }
  & svg:nth-child(5) {
    top: -6px;
    right: -6px;
  }
}

.themeDisabledNewBorderedDark {
  background-color: #b9b9b9;
}
.themeDisabledNewBorderedBlue {
  background-color: #b9b9b9;
  & svg {
    color: #b9b9b9;
  }
}

.themeDisabledSecondary {
  display: inline-block;
  background-color: transparent;
  color: #7e8a9f;
  border: 1px solid #c1c9d9;
  min-height: 40px;
  cursor: not-allowed;
}
.spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  div {
    background-color: var(--baseColor);
  }
}

.addButton {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0f73ef;

  &Dark {
    background-color: var(--buttonColor);

    & > svg {
      color: var(--baseColor);
    }

    &:hover {
      background-color: #0f73ef;
    }
    &:disabled {
      background-color: #b9b9b9;
    }
  }
}

.addButtonDisabled {
  background-color: #b9b9b9;
  cursor: not-allowed;
}

.copyButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
