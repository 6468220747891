@import 'static/assets/styles/global/global-varibles';
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  display: block;
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: auto;
    max-width: 528px;
    padding: 40px 64px;
    text-align: center;
    background: var(--modalContent);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
  &__title {
    color: var(--textColor);
    text-align: center;
    font-family: $sf_pro;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 0;
  }
  &__text {
    color: #7d7d7d;
    text-align: center;
    font-family: $sf_pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    & a {
      color: #0f73ef;
      font-family: $sf_pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      cursor: pointer;
    }
  }
  &__button {
    cursor: pointer;
    width: 100%;
    padding: 15px 48px;
    margin-top: 24px;

    background-color: var(--buttonColor);
    color: var(--baseColor);
    font-family: $sf_pro;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    &__content {
      width: calc(100vw - 32px);
      padding: 40px 24px !important;
      height: max-content !important;
    }
  }
}
