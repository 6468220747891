@import 'static/assets/styles/global/global-varibles.scss';

.tooltip {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--baseColor);
  min-height: 15px;
  color: $primary_light;
  white-space: nowrap;
  padding: 8px 13px;
  margin-bottom: 8px;
  border-radius: 3px;
  z-index: 2;
  font-family: $sf_pro;
  font-size: 11px;
  line-height: 16px;

  & ul {
    margin: 0;
    padding: 0 0 0 16px;
  }
}
