@import 'static/assets/styles/global/global-varibles.scss';

.wrapper {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  color: var(--iconColor);
}

.styleLight .checkmark {
  border: 0.5px solid var(--checkBoxBorder, #1a318b);
  background: var(--settingsHeadBg, #ffffff);
  border-radius: 3px;
}

.styleDark .checkmark {
  border: 0.5px solid var(--checkBoxBorder, #1a318b);
  background: var(--settingsHeadBg, #ffffff);
  border-radius: 3px;
}

.styleGray .checkmark {
  border: 1px solid #ccceda;
}

.darkClass input:checked ~ .checkmark {
  background: #FFFFFF url(../../static/assets/svg/checkmark-2.svg) no-repeat center;
  border: 0.5px solid #D9D9D9;
}

.lightClass input:checked ~ .checkmark {
  background: #FFFFFF url(../../static/assets/svg/checkmark-2.svg) no-repeat center;
  border: 0.5px solid #4B576C;
}

.stylesPrime.lightClass .checkmark {
  width: 20px;
  height: 20px;
  border-radius: 4px !important;
  border: 1px solid #00000080 !important;
}

.stylesPrime.lightClass > input:checked ~ .checkmark {
  border: 0.5px solid #0f73ef !important;
  background: transparent url(../../static/assets/svg/checkmark-prime.svg) no-repeat center;
}

.stylesPrime.darkClass > input:checked ~ .checkmark {
  border: 0.5px solid white !important;
  background: transparent url(../../static/assets/svg/checkmark.svg) no-repeat center;
}

.stylesPrime.darkClass .checkmark {
  width: 20px;
  height: 20px;
  border-radius: 4px !important;
  border: 1px solid #5e5e5e !important;
}