$rem-baseline: 14px;

html {
  @include rem-baseline;
}
html {
  -webkit-text-size-adjust: none;
  height: 100%;
}

body {
  height: 100%;
  user-select: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--textColor);
  -webkit-box-shadow: none;
  caret-color: var(--textColor);
  transition: background-color 500s ease-in-out 0s;
}

.global__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: hidden;
  position: relative;
  z-index: 0;

  & footer {
    z-index: 1;
  }

  & .SplitPane {
    height: fit-content;
    position: relative;
    z-index: 0;
  }

  &-striped {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 120px;
    }
  }

  .site__pages {
    width: 100%;

    &-right-menu {
      display: grid;
      grid-template-columns: 70% 30%;
      grid-template-rows: 63px auto;
      grid-template-areas:
        'header right'
        'main right';
    }

    &-entity-settings {
      display: grid;
      grid-template-columns: calc(100% - 345px) 345px;
      grid-template-areas:
        'header right'
        'main right';
      margin-top: 57px;

      .home__page {
        padding-top: 0;
        margin-top: 0;
      }

      .main__header {
        &__divider {
          margin-right: 0;
        }
      }
    }
  }
}

.mainPage_wrapper .Pane > div:last-child {
  background-color: var(--sidebarNewBg) !important;
}


.mainPage_wrapper .site__pages, .mainPage_wrapper .main__header {
  background-color: var(--sidebarNewBg) !important;
}

.full-container {
  width: 100vw;
}

.account-container {
  margin: 48px 0 0 0;
}

.full-height-container {
  margin: 0;
}

a {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 25px;
  color: $primary_color;
  text-decoration: none;

  &:hover {
    color: $primary_color-light;
  }
}

button {
  border: none;
  outline: none;
  display: block;
  padding: 0;
  margin: 0;
  background: none;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.main-container {
  height: calc(100% - 114px);
}

.global__wrapper .site__pages-entity-settings .main-container {
  grid-area: main;
}

@media screen and (max-width: 500px) {
  .global__wrapper {
    height: 100%;
    .site__pages {
      height: 100%;
    }
    .site__pages-entity-settings {
      grid-template-columns: 0 100%;
    }
  }
}

div[role='rowgroup'] {
  overflow-x: auto !important;
}
