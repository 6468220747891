@import 'static/assets/styles/global/global-varibles';

.file-container-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 7px;
  padding-right: 40px;
  position: relative;
  .select-item-checkbox {
    display: none;
    width: 18px;
  }
  .select-all-checked {
    display: block !important;
  }
  .select-item-checkbox {
    display: none;
    width: 18px;
    padding-left: 18px;
  }
  &-row {
    padding-left: 24px !important;
  }
  &-small-row {
    padding-left: 19px !important;
  }
  &-square {
    padding-left: 22px !important;
  }
  &:hover {
    .select-item-checkbox {
      display: block;
      padding-left: 0px;
    }
    .title-name-wrapper{
      padding-left: 0px;
    }
  }
  .title-name-wrapper {
    font-family: $sf_pro;;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 18px;
    &-checked{
      padding-left: 0px;
    }
  }
  .header-title {
    font-size: 12px;
    color: #7e8a9f;
    margin-left: 13px;
  }

  .header-title_active {
    cursor: pointer;
    color: #007AFF;
  }

  .header-title_active:hover {
    text-decoration: underline;
  }
  
  &.border-top {
    border-top: 1px solid #E9EBEF;
  }

  &.public-container {
    padding: 18px 18px 0 18px;
  }
}

.sorted-cell:hover {
  cursor: pointer;
  color: #007AFF;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .file-container-header-wrapper {
    display: none;
  }
}
