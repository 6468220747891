@import 'static/assets/styles/global/global-varibles';

.createContainer {
  width: 80%;
}



.modal {
  display: block;
}

.convertModal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 575px;
  min-height: 378px;
  padding: 19px 60px 37px;
  text-align: center;
  background: var(--modalContent);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__title {
    font-family: $sf_pro;
    margin: 0 0 30px 0;
    font-size: 24px;
    line-height: 32px;
    color: var(--textColor);
    font-weight: 400;
  }

  .convertFromTo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;

    .mimeContainer {
      width: 200px;
      height: 50px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      border: 0.5px solid var(--borderSecond);
      color: var(--textColor);
      font-family: $sf_pro;
      font-size: 20px;
      text-transform: uppercase;
    }

    .arrow {
      transform: rotate(90deg);
    }
  }
  .mainData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 55px;
    font-family: $sf_pro;
    min-height: 117px;

    & .fileContainer {
      max-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      & .fileData {
        &__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
          color: var(--textColor);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
        &__info {
          color: #7d7d7d;
          font-size: 8px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
    & .convertOptions {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      row-gap: 8px;
      flex-wrap: wrap;
      max-height: 117px;
      overflow-y: auto;
      overflow-x: hidden;

      & li {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        padding: 4px 0;
        width: 60px;
        height: 23px;
        border-radius: 4px;
        border: 0.5px solid var(--borderSecond);

        font-size: 10px;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
        color: var(--textColor);

        &:hover {
          background-color: #e8e9ed;
          color: #202124;
        }
        &.activeOption {
          background-color: #e8e9ed;
          color: #202124;
        }
      }
    }
  }

  .fileIcon {
    width: 61px;
  }

  .icon__img {
    margin-right: 16px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 40px;

    & button {
      height: 40px;
    }
  }
}

.resultContainer {
  margin-top: 60px;
  min-height: 117px;
  color: var(--textColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.resultContainer svg {
  width: 100px;
}

.convertBtnWrapper {
  position: relative;
  & p {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;

    width: 20px;
    height: 20px;
    background-color: var(--convertModalBtnColor);
    color: var(--convertModalBtnTextColor);
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .convertModal {
    padding: 47px 29px 34px;
    width: calc(100% - 32px);
    max-width: 500px;
    height: fit-content;
    min-height: fit-content;

    &__description {
      font-size: 12px;
      margin-bottom: 16px;
    }
    & .mainData {
      gap: 35px;
    }
  }
}

@media (max-width: 525px) {
  .convertModal {
    max-width: 343px;
    &__title {
      margin-bottom: 24px;
      font-size: 16px;
    }

    & .convertFromTo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 34px 0;

      & .mimeContainer {
        width: 100px;
      }
    }
    & .mainData {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 52px;
      & .convertOptions {
        max-height: 90px;
      }
    }
    .buttons {
      margin-top: 32px;
    }
  }
}
