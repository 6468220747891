.wrapper {
  display: flex;
}

.userMessage {
  justify-content: flex-end;
}

.message {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  min-width: 190px;
  padding: 8px 24px;
  border-radius: 20px;
  background-color: #151515;
}

.text {
  margin: 0;
  color: #fff;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  white-space: pre-line
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blockButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}
