.accessTab {
  width: 346px;
  height: 100%;
  background: #111111;
  border: 0.5px solid #5E5E5E4D;
  border-radius: 20px;
  padding: 16px;
}

.title {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;  
  color: #fff;
}