.createContainer {
  width: 80%;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}
