.tooltip {
  position: fixed;
  z-index: 1000;
  padding: 6px 12px;
  background-color: var(--tooltipBackground);
  color: var(--textColor);
  border-radius: 8px;
  font-size: 10px;
  line-height: 1.6;
  animation: TooltipAppearAnimation 0.3s ease-in-out forwards;
  white-space: nowrap;

  svg {
    position: absolute;
  }
}

@keyframes TooltipAppearAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
