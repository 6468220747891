@import 'static/assets/styles/global/global-varibles';

.wrapper {
  position: relative;
}

.popup {
  position: absolute;
  padding: 16px;
  z-index: 100;
  right: 0;
  top: 100%;
  background: var(--tooltipContentSecond);
  color: var(--textColor);
  font-family: $sf_pro;
  border-radius: 8px;
  box-shadow: var(--boxShadow);
}

.horizontal_left > div:first-child {
  bottom: 100%;
  top: initial;
  left: 0;

  &::before {
    top: initial;
    bottom: -6px;
    left: 16px;
    border-right: initial;
    border-left: 20px solid var(--tooltipContentSecond);
  }
}

.orientation_top > div:first-child {
  bottom: 100%;
  top: initial;

  &::before {
    top: initial;
    bottom: -6px;
  }
}

.orientation_bottom > div:first-child {
  bottom: initial;
  top: 140%;
}

.hideArrow > div:first-child::before {
  content: none;
}

.popup::before {
  position: absolute;
  top: -6px;
  right: 16px;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 20px solid var(--tooltipContentSecond);
}
