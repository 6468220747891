.breadcrumbContextMenu {
  max-height: 440px;
  overflow-y: auto;
}

.breadcrumbDelimiter {
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 10px;
    height: 3px;
  }
}

.breadcrumbDelimiter:hover {
  background: rgba(15, 115, 239, 0.07);

  & svg path {
    fill: rgba(15, 115, 239, 1);
  }
}

.diliverUp {
  cursor: pointer;
  rotate: -90deg;
}

.diliverDown {
  cursor: pointer;
  rotate: 90deg;
}
