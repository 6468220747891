.account-item-wrapper {
  //background-color: #ffffff;
  border-left: 1px solid #ccceda;
  flex: 1 0 150px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  white-space: normal;
  width: 100vw;
  max-width: 470px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  overflow: hidden;
}
