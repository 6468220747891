@import 'static/assets/styles/global/global-varibles';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #000;
  font-family: 'Nunito';
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.canvas {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noBackground {
  background-color: transparent;
  z-index: 1;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.block {
  display: flex;
}

.firstFigure {
  flex: 1;
}
.lastFigure {
  flex: 1;
  justify-content: flex-end;
}

.NeyraLogo {
  position: absolute !important;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);

  & svg path {
    transition: fill-opacity 200ms ease-in-out;
  }

  &:hover svg path {
    fill-opacity: 0.8;
  }
}
