.registrationChatWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 100%;
  padding: 30px;
  padding-bottom: 70px;
  margin: 0 auto;
}

@media only screen and (max-width: 730px) {
  .registrationChatWrapper {
    padding: 0px;
    padding-bottom: 0px;
  }
}
