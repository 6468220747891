.settingsContainer {
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--mainContainerColor);
}

.siteSidebarCollapsed {
  width: initial !important;
}

.settingsContent {
  display: flex;
}

.settingsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-height: calc(100% - 56px);
  overflow-y: auto;
  padding-right: 5px;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.settingsListCollapsed {
  width: 93px;
  align-items: center;
  overflow-y: auto;
  padding-right: 16px;
}

.settingsContentColapsed {
  gap: 10px;
  max-width: 450px;
  min-width: 450px;
  overflow-y: hidden;
  padding: 0;
}

.siteSidebar {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
