.file-action-icons {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__item {
    position: relative;
    font-size: 0;
    cursor: pointer;

    &:hover {
      .tooltip {
        display: flex;
      }
    }

    &:not(:last-child) {
      margin-right: 18px;
    }
  }

  &__item-ico-download {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: 1px solid #e0e2e9;
    font-size: 0;
  }

  &__item-ico-remove {
    max-width: 18px;

    img {
      width: 100%;
    }
  }

  &__item-ico-edit {
    max-width: 20px;

    img {
      max-width: 100%;
    }
  }
}
