.spinnerCircle {
  font-size: 10px;
  margin: 4px auto;
  text-indent: -9999em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2d7ee1;
  background: -moz-linear-gradient(left, #2d7ee1 10%, rgba(193,21,21, 0) 42%);
  background: -webkit-linear-gradient(left, #2d7ee1 10%, rgba(193,21,21, 0) 42%);
  background: -o-linear-gradient(left, #2d7ee1 10%, rgba(193,21,21, 0) 42%);
  background: -ms-linear-gradient(left, #2d7ee1 10%, rgba(193,21,21, 0) 42%);
  background: linear-gradient(to right, #2d7ee1 10%, rgba(193,21,21, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinnerCircle:before {
  width: 50%;
  height: 50%;
  background: #2d7ee1;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.spinnerCircle:after {
  background: var(--baseColor);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dotLoader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, var(--textColor) 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}