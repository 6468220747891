.sidebarContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding-top: 2px;
  overflow-x: visible;
}

.collapsed {
  padding-bottom: 16px;
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  padding-right: 5px;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.sidebarItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border: 0.7px solid #5e5e5e80;
  border-radius: 20px 3px 20px 20px;
  cursor: pointer;
  min-height: 62px;
  max-height: 62px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  & svg {
    color: var(--textColor);
  }
}

.sidebarItem:hover {
  border-color: #999999;
  border-width: 1px;
}

.button {
  cursor: pointer;

  &:hover {
    border-color: #fff;
  }
}

.title {
  flex: 1;
  font-family: 'Nunito';
  color: var(--textColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebarPreview {
  display: flex;
  gap: 12px;
}

.sidebarAction {
  opacity: 0;
  color: var(--textColor);
  transition: opacity .6s;
  cursor: pointer;
}

.sidebarItem:hover .sidebarAction {
  opacity: 1;
}