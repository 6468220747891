@keyframes fillAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.progressBar {
  position: relative;
  height: 38px;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  border: 0.8px solid rgba(255, 255, 255, 0.44);
}

.uploadProgressBar {
  background-color: rgba(170, 167, 143, 0.5);
}

.fill {
  border-radius: 8px;
  height: 100%;
  background-color: white;
  animation-name: fillAnimation;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    white-space: nowrap;
  }
}

.percent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  color: #fff;
  font-family: 'Digital Numbers';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
  & svg {
    width: 38px;
    height: 24px;
  }
}
