@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 16px;
  max-width: 100%;
  min-height: 65px;
  border-bottom: 1px solid #ccceda;
}

.title {
  font-family:$sf_pro, Sans-Serif;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--textColor);

  &__before {
    color: #206acf !important;
  }
}

.exit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 16px;
}

.exit > svg {
  color: var(--iconColor);
}

.hideBorder {
  border: none;
}