@import 'static/assets/styles/global/global-varibles';

.chatWrapper {
  font-family: 'Nunito';
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--mainContainerColor);
}
.category {
  margin-left: 33px;
  margin-right: 37px;
  margin-bottom: 29px;
}

.chatContent {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  height: 100%;
}

.sidebar {
  max-width: 346px;
  width: 100%;
  background-color: transparent;
  padding: 25px 16px 72px 0px;
  overflow-y: auto;
  overflow-x: visible;
  margin-left: -8px;
}

.chatBlock {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  border: 0.5px dashed #5e5e5e;
  border-radius: 20px;
  color: var(--Color, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 0.7px solid var(--Color, #fff);
  }
  &:first-child {
    font-size: 17px;
    justify-content: flex-start;
    gap: 0 12px;
  }
  &:hover {
    border-color: #ffffff;
    .icon {
      border-color: #ffffff;
    }
    .chatCircleIcon {
      border-color: #ffffff;
    }
  }
}

.trashIcon:hover {
  color: red;
}

.chatAvatarWrapper {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.chatCircleIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0.5px dashed #5e5e5e;
  position: relative;
}

.chatCircleIcon::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #ffffff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input {
  height: 142px;
}

.messangerWrapper {
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
  scrollbar-width: 1px;
  scrollbar-color: #e0d6d6;
}
.messangerWrapper::-webkit-scrollbar {
  width: 1px;
}
.messangerWrapper::-webkit-scrollbar-thumb {
  background-color: #e0d6d6;
}
.messangerWrapper::-moz-scrollbar {
  width: 1px;
}
.messangerWrapper::-moz-scrollbar-thumb {
  background-color: #e0d6d6;
}

.inputWrapper {
  height: 100px;
  display: flex;
  width: 75%;
  margin: 0 auto;
}

.clearAction {
  color: #cab35e;
  border-color: #cab35e;
}

.recordAction:hover,
.speakerOption:hover {
  color: #000;
}

.recordActionStart {
  background-color: transparent;
  border-color: #e31010;
  color: #e31010;
}

.recordActionStart:hover {
  border-color: #e31010;
  color: #e31010;
}

.speakerOption {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}

.messanger {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
  padding: 30px 0;
  width: 75%;
}

.shadowBlock {
  position: absolute;
  left: 5px;
  right: 5px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
.shadowBlockBottom {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 90px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.sideBarContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sideBarBlocks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sideBarBlock {
  cursor: pointer;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-left: 2px;
  padding: 11px 16px;
  border: 0.5px solid #5e5e5e;
  border-radius: 20px 3px 20px 20px;
  color: var(--textColor);
  & .mainSideBarButton {
    color: var(--textColor);
  }
  &:ho &:hover {
    border-color: var(--textColor);
    .mainSideBarButton {
      border-color: var(--textColor);
      color: var(--textColor);
    }
  }
}

.sideBarBlock:hover {
  border-color: var(--primeTextColor);
  border-width: 1px;
}

.sideBarDisabledBlock {
  opacity: 0.4;
}

.mainSideBarButton {
  cursor: pointer;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.mainSideBarTitle {
  flex: 1;
  color: var(--textColor);
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.mainSideBarText {
  color: #7d7d7d;
  font-family: 'Nunito';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.connectNeyraRingBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 11px 16px 9px;
  border-radius: 20px;
  border: 0.5px solid #5e5e5e;
  cursor: pointer;

  &:hover {
    border-color: #ffffff;
    .connectNeyraRingCircle {
      border-color: #ffffff;
    }
  }
}

.connectNeyraRingCircle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #5e5e5e;
}

.connectNeyraRingTitle {
  flex: 1;
  color: #fff;
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.factorsBlock {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.factorBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.factors {
  color: #353434;
  text-align: center;
  font-family: 'Nunito';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.percent {
  color: #353434;
  text-align: center;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  text-transform: capitalize;
}

.newChatBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 11px 16px;
  border: 0.5px solid #5e5e5e;
  border-radius: 20px 3px 20px 20px;
  cursor: pointer;

  &:hover {
    border-color: var(--textColor);
    .circleSidebar {
      border-color: var(--textColor);
    }
  }
}

.circleSidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: var(--textColor);
}

.textBlock {
  flex: 1;
}

.newChatTitle {
  margin: 0;
  color: var(--textColor);
  font-family: 'Nunito';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.description {
  margin: 0;
  color: #7d7d7d;
  font-family: 'Nunito';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.arrowButton {
  cursor: pointer;
  color: var(--textColor);
}

.NeyraLogo {
  position: absolute !important;
  top: 22px;
  left: 47px;
  color: var(--textColor);
  & svg path {
    transition: fill-opacity 200ms ease-in-out;
  }

  &:hover svg path {
    fill-opacity: 0.8;
  }
}
