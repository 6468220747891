.radiobutton {
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__radio {
    &-styled {
      position: absolute;
      top: calc(50% - 12px);
      left: 18px;
      height: 23px;
      width: 23px;
      background-color: $primary_light;
      border: 1px solid $radio_unchecked;
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        display: none;
        top: 8px;
        left: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary_light;
      }
    }
  }

  &__label {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $primary_black;
    letter-spacing: 0.1px;
    text-align: start;

    display: block;
    position: relative;
    padding: 12px 18px 12px 56px;
    margin: 0;
    cursor: pointer;
    user-select: none;

    &.selected {
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
      box-shadow: 0 2px 8px $forty_color;
      border: 1px solid $primary_color;
    }

    &:hover input ~ .radiobutton__radio-styled {
      background-color: $primary_color-light;
      height: 24px;
      width: 24px;
      border: none;
    }

    input:checked ~ .radiobutton__radio-styled {
      background-color: $primary_color;
      height: 24px;
      width: 24px;
      border: none;
    }

    input:checked ~ .radiobutton__radio-styled:after {
      display: block;
    }
  }
}
