.auth {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(90deg, #00c6fb -0.78%, #005bea 55.99%);
  background-size: cover;

  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .head__content {
    z-index: 10;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    top: 15px;
    left: 0;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .auth-beta-version-wrapper {
      display: flex;
      align-items: center;

      .auth-beta-version-text {
        font-size: 14px;
        color: white;
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }

    &__logo {
      display: block;
      text-decoration: none;
      cursor: pointer;

      & > img {
        width: 30px;
        height: 44px;
      }
    }
  }

  .auth-title-mobile,
  .auth-text-mobile {
    display: none;
  }

  .login-form-wrapper {
    width: 100%;
    display: flex;
  }

  .auth__form {
    justify-content: flex-end;

    .end-iem {
      display: flex;
      margin-top: 200px;
      justify-content: flex-end;
    }

    .small-end {
      display: flex;
      margin-top: 10px;
      justify-content: flex-end;
    }
  }

  .sign-in-link {
    white-space: nowrap;
  }

  .auth-footer-info-mobile {
    display: none;
  }

  @media screen and (max-width: 980px) {
    .login-form-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .auth-left {
      height: 30vh;
    }

    .auth-right {
      margin: 0 auto;
    }

    .auth-header {
      width: 80%;
    }

    .auth-header,
    .auth__form,
    .auth-footer {
      margin: 0 auto;
    }

    .auth__square {
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 850px) {
    .auth-right {
      width: 75%;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 25px;
    justify-content: flex-start;
    background: linear-gradient(82.56deg, #00a5ff 1.25%, #0047ff 60.2%) 0 0;
    position: relative;
    background-size: cover;

    .head__content {
      display: block;
      top: 0;
      padding-top: 25px;
      padding-bottom: 15px;
      background: linear-gradient(130.56deg, #00a5ff 1.25%, #0047ff 60.2%) 0 0;
      background-size: cover;
      z-index: 1000;
    }

    .header__logo img {
      display: block;
      width: 20px;
      height: 28px;
    }

    .auth-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 12px;

      .auth-beta-version-wrapper {
        .auth-beta-version-text {
          margin-bottom: 0;
        }
      }
    }

    .auth-title-mobile {
      margin: 10px 0 0 0;
      font-size: 16px;
      line-height: 18px;
    }
    .auth-text-mobile {
      text-transform: uppercase;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 1px;
      opacity: 0.6;
    }

    .auth-title-mobile,
    .auth-text-mobile {
      display: block;
      color: $primary_light;
      font-family: $sf_pro ;
      font-weight: normal;
    }

    .login-form-wrapper {
      flex: 1;
      margin-top: 135px;
    }

    .auth-left {
      display: none;
    }

    .auth-right {
      width: 100%;
    }

    .auth-right.auth-right_signup {
      width: 100%;
      height: auto;
    }

    .auth-header {
      display: none;
    }

    .auth-header,
    .auth__form,
    .auth-footer {
      margin: 0;
      width: 100%;
    }

    .auth__form .form__title {
      font-size: 16px;
      line-height: 24px;
    }

    .step-1 .form__title,
    .step-2 .form__title,
    .step-3 .form__title,
    .step-4 .form__title,
    .step-5 .form__title {
      margin-top: 0;
    }

    .auth__square {
      width: 100%;
      margin-top: 110px;
    }

    .auth-footer.step-2 .auth__square,
    .auth-footer.step-3 .auth__square {
      margin-top: 50px;
    }

    .auth__input,
    .auth__input::placeholder {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    .auth__input-button {
      top: 27px;
    }

    .auth__input-help {
      position: static;
    }

    .auth-footer {
      align-items: center;
      flex: 1;
    }

    .auth-footer-wrapper {
      margin-top: auto;
    }
    .auth-footer-info > * {
      text-align: center;
    }
    .auth-footer-info {
      margin-bottom: 100px;
    }
    .auth-footer-info {
      display: none;
      margin-top: 0;
    }
    .auth-footer-info-mobile {
      display: block;
    }
    .auth-footer-info-mobile div:first-child {
      margin-bottom: 20px;
    }
    .step-indicator {
      display: none;
    }

    .step-indicator-mobile {
      position: static;
      transform: none;
      display: flex;
      justify-content: space-between;
      width: 100px;
      margin: 20px auto 0 auto;

      button {
        display: inline-block;
        margin: 0;
      }
    }

    .auth-footer-button-container {
      width: 100%;
    }

    /* Auth. code inputs */
    .auth__code label input {
      padding: 0 5px;
    }

    .auth__input-errors {
      margin-bottom: 30px;
    }
  }
  /* END media 640px */

  &-left {
    width: 30%;
    height: 60vh;
    background: url('/static/assets/img/auth.svg') center center no-repeat;
    background-size: contain;
    margin: 0 auto;

    &-light {
      background-image: url('/static/assets/img/gd-logo-big.svg');
    }

    .loader {
      position: absolute;
      width: 1600px;
      height: 800px;
      top: 50px;
      left: -400px;
      z-index: 1;
    }
  }

  &-right {
    width: 60%;
    margin: auto auto auto 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__no-height {
      height: auto;
    }
  }

  video {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
  }

  &__title {
    color: $primary_light;
    font-family: $sf_pro ;
    font-size: rem(18px);
    line-height: rem(18px);
    margin: 0 9px 0 0;
  }

  &__text {
    color: $primary_light;
    font-family: $sf_pro ;
    font-size: rem(11px);
    line-height: rem(16px);
    letter-spacing: rem(1px);
    margin: 8px 0 0 0;
    text-transform: uppercase;
    opacity: 0.4;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;

    .form {
      &__title {
        color: $primary_light;
        font-family: $sf_pro ;
        font-size: rem(22px);
        line-height: rem(32px);
        margin: 40px 0 12px 0;
        padding: 0;
      }

      &__desc {
        font-family: $sf_pro ;
        font-size: rem(14px);
        line-height: rem(25px);
        color: $primary_light;
        padding: 0;

        ul {
          margin: 0;
          padding-left: 20px;
        }

        .invalid {
          opacity: 0.65;
        }
      }
    }
  }

  &-header {
    padding: 0 0;
  }

  &-footer {
    margin: 64px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 80%;

    &-break-text {
      display: none;
    }

    &-info {
      font-family: $sf_pro ;
      font-size: rem(14px);
      line-height: rem(16px);
      letter-spacing: 0.1px;
      color: $primary_light;

      & > div {
        text-align: right;
        &:first-child {
          margin-bottom: 16px;
        }
      }

      &-text {
        opacity: 0.6;
      }

      a {
        font-family: $sf_pro;
        color: $primary_light;

        &.policy {
          font-family: $sf_pro;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.5px;
          color: $primary_light;
        }
      }

      &-policy {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.5px;
        color: $search_placeholder;
      }
    }

    &-button {
      &-container {
        display: flex;

        .link {
          color: $primary_light;
        }
      }
    }

    &-actions {
      width: 100% !important;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      &-crypto {
        cursor: pointer;
        margin-right: 200px;

        &-metamask {
          font-family: $sf_pro ;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          letter-spacing: 1.4px;
          text-transform: uppercase;
          color: #ffffff;
          padding-left: 8px;
        }
        &-text {
          font-family: $sf_pro ;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          letter-spacing: 1.4px;
          text-transform: uppercase;
          color: #ffffff;
          padding-left: 8px;
        }
        &-icon {
          width: 30em;
          height: 20em;
          margin-left: 25px;
        }
        & svg {
          width: 48px;
          height: 48px;
        }
        &::before {
          content: '•';
          color: white;
        }
      }
    }
  }

  .header__logo {
    opacity: 1;
  }

  &-forgot {
    font-family: $sf_pro ;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-align: right;
    color: #202124;
    margin: 0;
    display: block;
  }

  &-resend {
    color: $primary_light;
    font-family: $sf_pro ;
    font-size: rem(14px);
    line-height: rem(16px);
    margin: 0 0 52px 0;
    letter-spacing: 0.1px;
    text-transform: uppercase;

    &:hover {
      color: $primary_light;
    }

    &-info {
      font-family: $sf_pro ;
      font-size: rem(11px);
      line-height: rem(16px);
      letter-spacing: 0.1px;
      opacity: 0.6;
      color: $primary_light;
      margin: 0 0 8px 0;
      padding: 0;
    }
  }

  &-invite {
    background: $row_light_grey;
    padding: 40px;
    height: 100vh;

    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 294px);
    }

    &-content {
      width: 488px;
      background: $primary_light;
      box-shadow: 0 2px 12px rgba(15, 115, 239, 0.15);
      border: 2px solid $primary_color;
      margin: 0 auto;
      padding: 56px 40px;

      &-pending {
        width: 560px;
        border: none;
        padding: 40px 56px 48px 56px;
      }

      .account-setting {
        &__input {
          background: $row_light_grey;
          border: 1px solid $forty_color;
          box-sizing: border-box;
          padding: 11px 16px;
          margin: 4px 0 0 0;
          font-size: 14px;
          line-height: 24px;

          &-button {
            top: 44px;

            svg {
              path {
                fill: $medium_grey;
              }
            }
          }

          &.error {
            background: rgba($red, 0.05);
            border: 1px solid $red;
          }
        }

        &__label {
          flex-direction: column;
          width: 100%;
          color: $view_overlay;
        }
      }
    }

    &-content-pending & {
      &-title {
        font-size: 18px;
        line-height: 24px;
      }

      &-invitation {
        font-family: $sf_pro ;
        font-size: 14px;
        line-height: 22px;
        color: $dark_grey;
        padding: 0 0 12px 0;
        border-bottom: 1px solid $shadow_table;
        text-align: center;
      }
    }

    &-title {
      font-family: $sf_pro ;
      font-size: 24px;
      line-height: 32px;
      color: $view_overlay;
      text-align: center;

      &-small {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-subtitle {
      font-family: $sf_pro ;
      font-size: 16px;
      line-height: 24px;
      color: $colGrayDark;
      text-align: center;
      margin-top: 28px;
    }

    &-button {
      width: 100%;
      height: 48px;
      margin: 32px 0 0 0;
    }

    &-forgot {
      font-family: $sf_pro ;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      color: $dark_grey;
      margin: 10px 0 0 0;
      display: block;
    }

    &-bottom {
      font-family: $sf_pro ;
      font-size: 14px;
      line-height: 22px;
      color: $dark_grey;
      text-align: center;
      margin: 25px 0 0 0;

      &-link {
        text-transform: uppercase;
      }
    }

    &-join {
      &-container {
        width: 488px;
        margin: 0 0 118px 0;
        text-align: center;

        .button {
          width: 362px;
          height: 48px;
          margin: 0 auto;
        }

        .join-btn {
          margin-top: 24px;
        }
      }

      &-title {
        font-family: $sf_pro ;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $view_overlay;
        margin-top: 24px;
      }

      &-price {
        font-family: $sf_pro;
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #4b576c;
      }

      &-logo {
        &.user-icon {
          width: 72px;
          height: 72px;
          font-family: $sf_pro ;
          font-size: 24px;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        margin: 20px 0 4px 0;

        &-logo {
          &.user-icon {
            width: 40px;
            height: 40px;
            font-family: $sf_pro ;
            font-size: 14px;
            line-height: 14px;
          }
        }

        &-name {
          font-family: $sf_pro ;
          font-size: 16px;
          line-height: 24px;
          color: $primary_black;
          margin: 0 0 0 12px;
        }

        button {
          margin: 0 0 0 auto;
        }
      }
    }

    &-info {
      font-family: $sf_pro ;
      font-size: 14px;
      line-height: 22px;
      color: $dark_grey;
      text-align: center;
      margin: 25px 0 0 0;

      &-link {
        display: inline;
        font-weight: 400;
      }
    }

    &-validation {
      font-family: $sf_pro ;
      font-size: 11px;
      line-height: 16px;
      color: black;
      padding: 10px 0 0 0;

      div {
        font-weight: 400;
      }

      ul {
        margin: 0;
        padding-left: 20px;

        li {
          font-family: $sf_pro ;
          font-size: 11px;
          line-height: 16px;
          color: #7e8a9f;
        }
      }
    }

    &-tooltip {
      top: 10% !important;
      width: 200px;
      background-color: white !important;
      color: black !important;
      border-radius: 8px;
      box-shadow: 2px 2px 16px rgba(26, 49, 139, 0.16);
    }

    &-crypto {
      &-list {
        display: flex;
        justify-content: center;
        margin-top: 25px;

        & > button:first-child {
          margin-right: 14px;
        }
      }
    }
  }

  &-subscribe {
    padding-bottom: 60px;

    &-header {
      margin: 76px auto 16px auto;
      max-width: 992px;

      h3 {
        font-family: $sf_pro ;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        letter-spacing: -0.4px;
        color: $primary_black;
        margin: 10px;
      }

      p {
        font-family: $sf_pro ;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $dark_grey;
        margin: 0;
      }
    }

    &-annual {
      &-info {
        background: rgba(15, 115, 239, 0.1);
        border-radius: 4px;
        width: 106px;
        height: 27px;
        font-family: $sf_pro ;
        font-size: 12px;
        line-height: 15px;
        color: rgba(15, 115, 239, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;

        strong {
          color: $primary_color;
        }
      }

      &-switch {
        font-family: $sf_pro ;
        font-size: 14px;
        line-height: 22px;
        color: $primary_black;
        margin: 0 0 0 20px;
        display: flex;
        align-items: center;

        &-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0 auto 24px auto;
          max-width: 990px;
          min-height: 30px;
        }

        &-label {
          margin: 0 0 0 12px;
        }
      }
    }

    &-container {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    &-item {
      width: 100%;
      max-width: 320px;
      padding: 40px 24px 24px 24px;
      margin: 0 16px 0 0;
      background: $primary_light;
      box-shadow: 4px 4px 20px rgba(26, 49, 139, 0.08);
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }

      &-title {
        font-family: $sf_pro ;
        font-size: 24px;
        line-height: 32px;
        color: $primary_black;
      }

      &-price {
        font-family: $sf_pro ;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -0.4px;
        color: $primary_black;
        margin: 8px 0 32px 0;
      }

      &-list {
        display: flex;
        margin: 0 0 16px 0;
        align-items: baseline;

        &-icon {
          margin: 0 16px 0 0;

          svg {
            width: 8px;
            height: 8px;
          }
        }

        &-text {
          font-family: $sf_pro ;
          font-size: 14px;
          line-height: 16px;
          color: $dark_grey;
        }
      }

      &-button {
        width: 100%;
        height: 48px;
        margin: auto 0 0 0;
      }
    }
  }

  &-no-workspace {
    height: 100vh;

    .head__content {
      margin: 0 auto;
      width: 100%;
      position: fixed;
      top: 15px;
      left: 0;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .no-workspace-main {
      margin-top: 100px;
      height: calc(100vh - 294px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .no-workspace__header {
        .title {
          font-family: $sf_pro ;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #000000;
        }

        .sub-title {
          margin-top: 8px;
          font-weight: 300;
          font-size: 16px;
          line-height: 25px;
          text-align: center;
        }
      }

      .submit-btn {
        margin-top: 24px;
        background: linear-gradient(79.11deg, #00a5ff 27.88%, #0047ff 84.06%);
        cursor: pointer;
        width: 362px;
        height: 48px;
        font-family: $sf_pro ;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: #ffffff;
      }

      .ghost-btn {
        margin-top: 24px;
        font-family: $sf_pro ;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: $primary_color;
      }
    }
  }
}

.step-indicator {
  position: absolute;
  right: 47px;
  top: 50%;
  transform: translateY(-50%);

  &-item {
    width: 7px;
    height: 7px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transform: rotate(-45deg);
    margin: 0 0 23px 0;

    &.active {
      border-color: $primary_light;
    }

    &.is-clickable {
      cursor: pointer;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 24px;

  input {
    display: none;
  }
}

.auth-input__helper {
  position: relative;

  .auth__input {
    padding-right: 145px;
  }

  .auth__input-help {
    right: 0;
    top: 50%;
  }
}

.switcher {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 22px;
  background: linear-gradient(74.72deg, #f2ba4c 23.58%, #f2744c 90.33%);
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(16, 22, 26, 0.3);
    transition: 0.4s;
    border-radius: 50%;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.isChecked {
  .switcher {
    background: linear-gradient(79.11deg, #00a5ff 27.88%, #0047ff 84.06%);
  }

  .switcher:before {
    transform: translate(-23px, -50%);
    left: 100%;
  }
}

.auth-subscribe {
  height: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-subscribe-header {
  margin-bottom: 32px;
}

@media screen and (max-width: 990px) {
  .auth-subscribe-annual-switch-container {
    justify-content: space-between;
    padding: 0 24px;
  }

  .auth-subscribe-annual-switch {
    margin-left: auto;
  }

  .auth-subscribe-header {
    margin-top: 32px;
  }
}

@media screen and (max-width: 640px) {
  .auth-subscribe-item-title {
    font-size: 22px;
  }

  .auth-subscribe-header {
    margin-bottom: 32px;
  }

  .auth-subscribe-header h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 10px 0;
  }
  .auth-subscribe-item-price {
    margin-bottom: 24px;
  }
}

.crypto-auth {
  &-reg-or-log {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 106px;
    }
    &-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    &-title {
      font-family: $sf_pro ;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      color: #fff;
      margin-top: 23px;
      margin-bottom: 5px;
    }
    &-description {
      font-family: $sf_pro ;
      font-size: 22px;
      text-align: center;
      color: #fff;
    }
    &-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #fff;
      margin-top: 20px;
      span {
        font-family: $sf_pro ;
        font-size: 14px;
        text-align: center;
        color: #fff;
      }
    }
    &-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 192px;
      height: 192px;
      border: 1px solid rgba(255, 255, 255, 0.35);
      filter: drop-shadow(4px 4px 20px rgba(26, 49, 139, 0.08));
      margin-left: 7.5px;
      margin-right: 7.5px;
      cursor: pointer;
    }
    &-btn-text {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 22px;
      color: #fff;
    }
    &-footer-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 42px;
      .auth-footer-info-text {
        font-size: 14px;
        text-align: center;
        color: #fff;
        opacity: 0.6;
      }
      .sign-in-link {
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.1px;
        text-transform: uppercase;
        opacity: 1;
        color: #fff;
        font-weight: 700;
        margin-left: 6px;
      }
    }
    .footer-content {
      font-family: $sf_pro;
      margin-bottom: 10px;
      font-size: 10px;
      letter-spacing: 0.5px;
      color: #c1c9d9;
      margin-bottom: 28px;
      .link-text {
        color: #fff;
        font-weight: 400;
        font-size: 10px;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
  &-login {
    width: 100%;
    &-header {
      &-title {
        font-family: $sf_pro ;
        font-size: 32px;
        font-weight: 400;
        text-align: center;
        color: #000;
        margin-top: 23px;
        margin-bottom: 5px;
      }
      &-description {
        font-family: $sf_pro ;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #4b576c;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
    }
    &-select-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 17px 18px;
      width: 360px;
      margin-top: 17px;
      cursor: pointer;
      &:hover {
        border: 1px solid #0f73ef;
        background: #fff;
        box-shadow: 0px 2px 8px #ccceda;
      }
      &-selected {
        background: #fff;
        border: 1px solid #0f73ef;
        box-shadow: 0px 2px 8px #ccceda;
      }
      .account-setting__input-radio-label {
        padding-top: 5px;
      }
      img {
        margin-right: 7px;
        margin-left: 10px;
      }
      &-text {
        font-family: $sf_pro ;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        color: #000;
        font-weight: 400;
        margin-left: 10px;
        text-transform: uppercase;
      }
      &-button {
        font-weight: 400;
        margin-top: 59px;
        padding: 17px 59px;
      }
      &-go-back {
        margin-top: 36px;
        font-family: $sf_pro ;
        font-size: 14px;
        line-height: 25px;
        color: #0f73ef;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  &-sign-up {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    display: flex;

    &-header {
      display: flex;
      align-items: center;
      &-title {
        font-family: $sf_pro ;
        font-size: 32px;
        font-weight: 400;
        text-align: center;
        color: #000;
        margin-top: 23px;
        margin-bottom: 5px;
      }
      &-description {
        font-family: $sf_pro ;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #4b576c;
      }
      &-beta__text {
        font-family: $sf_pro ;
        font-size: 14px;
        padding-bottom: 10px;
        line-height: 13px;
        text-align: right;
        letter-spacing: 0.1px;
        margin-left: 15px;
        color: #ffffff;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
    }
    &-icons-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
    }
    &-select-item {
      display: flex;
      margin-right: 14px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      margin-top: 17px;
      width: 168px;
      height: 168px;
      cursor: pointer;
      background: #fff;
      &-img-wrapper {
        text-align: center;
        height: 56px;
      }
      &:hover {
        border: 1px solid #0f73ef;
        background: #fff;
        box-shadow: 0px 2px 8px #ccceda;
      }
      &-selected {
        background: #fff;
        border: 1px solid #0f73ef;
        box-shadow: 0px 2px 8px #ccceda;
      }
      .account-setting__input-radio-label {
        padding-top: 5px;
      }
      img {
        margin-right: 7px;
        margin-left: 10px;
      }
      &-text {
        font-family: $sf_pro ;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        color: #000;
        font-weight: 400;
        margin-left: 10px;
        text-transform: uppercase;
      }
      &-button {
        font-weight: 400;
        margin-top: 59px;
        padding: 17px 59px;
      }
      &-go-back {
        margin-top: 36px;
        font-family: $sf_pro ;
        font-size: 14px;
        line-height: 25px;
        color: #0f73ef;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  &-modal {
    .modal__text {
      margin-top: 18px;
      margin-bottom: 56px;
    }
    .modal__title {
      margin-top: 18px;
    }
  }
}

.crypto-sign-up {
  background: linear-gradient(90.05deg, #00c6fb -0.78%, #005bea 55.99%);
  height: 100vh;
  width: 100%;
  &-header {
    &-title {
      font-family: $sf_pro ;
      font-size: 32px;
      font-weight: 400;
      text-align: center;
      color: white;
      margin-top: 23px;
      margin-bottom: 5px;
    }
    &-description {
      margin-top: 12px;
      margin-bottom: 0;
      font-family: $sf_pro ;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: white;
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
  }
  &-icons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }
  &-select-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 4px 4px 20px rgba(26, 49, 139, 0.08);
    border: 2px solid transparent;
    padding: 35px 0 35px 0;
    width: 168px;
    height: 168px;
    margin-right: 14px;
    cursor: pointer;
    &:hover {
      border: 2px solid #ffffff;
    }
    &:last-child {
      margin-right: 0;
    }
    .account-setting__input-radio-label {
      padding-top: 5px;
    }
    img {
      margin-right: 7px;
      margin-left: 10px;
    }
    &-text {
      font-family: $sf_pro ;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0;
    }
    &-button {
      font-weight: 400;
      margin-top: 59px;
      padding: 17px 59px;
    }
    &-go-back {
      margin-top: 36px;
      font-family: $sf_pro ;
      font-size: 14px;
      line-height: 25px;
      color: #0f73ef;
      text-decoration: none;
      cursor: pointer;
    }
    &-img-wrapper {
      width: 59px;
      height: 59px;

      & > img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  }
  &-actions {
    display: flex;
    flex-direction: column;

    &__confirm {
      cursor: pointer;
      width: 272px;
      background: #0f73ef;
      border: 2px solid #ffffff;
    }
    &__back {
      cursor: pointer;
      margin: 0 auto;
      width: 199px;
      background: transparent;
      border: none;
      &:hover {
        border: 1px solid #ffffff;
        background: transparent;
      }
    }
  }
  &-logo {
    padding: 24px 24px;
  }

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: calc(100% - 204px);
    &-col {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  &-benefits {
    display: flex;
    flex-direction: column;
    & > div {
      font-family: $sf_pro ;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #ffffff;
      margin-bottom: 9px;
    }
  }

  &-title {
    font-family: $sf_pro ;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.4px;
    color: #ffffff;
    mix-blend-mode: normal;
    margin-bottom: 10px;
  }

  &-process-name {
    font-family: $sf_pro ;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;
    color: #ffffff;

    margin-bottom: 20px;
  }

  &-processing {
    font-family: $sf_pro ;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 50px;
    padding: 0 55px;
  }

  .lds-default {
    position: relative;
    width: 0;
    margin-left: 10px;
  }
  .lds-default div {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotateX(45deg) rotate(45deg); /* needs Y at 0 deg to behave properly*/
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    left: 160px;
    top: -12px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.2s;
    left: 140px;
    top: -12px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.4s;
    left: 120px;
    top: -12px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.7s;
    left: 100px;
    top: -12px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.8s;
    left: 80px;
    top: -12px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: rotateX(45deg) rotate(45deg) scale(1);
      opacity: 0.3;
    }
    50% {
      transform: rotateX(45deg) rotate(45deg) scale(1.5);
      opacity: 1;
    }
  }
}
.auth-footer-crypto-wallet {
  svg {
    width: 30px;
    height: 30px;
  }
}

.welcome {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(90deg, #00c6fb -0.78%, #005bea 55.99%);
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-container {
    position: relative;

    &__header {
      position: absolute;
      left: 25px;
      top: 22px;
    }

    &__wrapper {
      max-width: 1920px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__footer {
      position: absolute;
      width: 100%;
      max-width: 1920px;
      padding: 0 150px;
      bottom: 0;
      bottom: 25px;

      .auth-footer-info {
        margin: 0;
      }
    }
  }

  &-logo {
    width: 30%;
    height: 30vh;
    background: url('/static/assets/img/gd-logo-big.svg') center center
      no-repeat;
    background-size: contain;
    margin: 0 auto;
  }

  &-workspace {
    &-logo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h3 {
        font-family: $sf_pro;
        font-size: 18px;
        line-height: 18px;
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 10px;
      }

      p {
        font-family: $sf_pro;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.4;
        margin: 0;
      }
    }

    &-avatar {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100px;
      height: 100px;

      span {
        font-size: 20px;
        line-height: 19px;
      }
    }
  }
  &__login {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
      font-family: $sf_pro;
      font-size: 24px;
      line-height: 48px;
      color: #ffffff;
      margin-bottom: 10px;

      &-title {
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 48px;
        margin: 0;

        & > strong {
          font-weight: 400;
        }
      }

      &-subtitle {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        margin: 0;
      }
    }

    &__list {
      display: flex;
    }

    &-item {
      font-family: $sf_pro;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      width: 168px;
      height: 168px;
      background: #1c82eb;
      border: none;
      cursor: pointer;

      &:hover {
        border: 1px solid #ffffff;
        background: #1c82eb;
      }

      &:first-child {
        margin-right: 14px;
      }

      &:last-child > svg {
        height: 56px;
        padding-top: 20px;
      }

      & > p,
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }

  &__net {
    position: absolute;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.1px;
    color: #ffffff;
    bottom: 25px;
    right: 24px;
  }

  &-actions {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #d6e2f5;
      width: 199px;
      height: 49px;
      cursor: pointer;

      &:hover {
        color: #d6e2f5;
      }
    }
  }

  &__sign-in {
    &-title {
      font-family: $sf_pro;
      font-weight: 400;
      font-size: 30px;
      line-height: 48px;
      letter-spacing: -0.4px;
      color: #ffffff;
      margin: 0;
    }
    &__header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &-title {
        font-family: $sf_pro;
        margin-top: 0;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
      }
      &-subtitle {
        font-family: $sf_pro;
        margin-top: 0;
        margin-bottom: 6px;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.4;
      }
    }
  }

  .footer-info {
    align-self: flex-end;
    margin-right: 100px !important;
    margin-bottom: 28px !important;
    z-index: 1;
  }
}

.welcome__login__header-crypto {
  margin-top: 20px;
}

.auth-footer-break-text {
  display: none;
} 

@media screen and (max-width: 980px) {
  .welcome-actions__back {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}


@media screen and (max-width: 480px) {
  .auth-invite{
    &-main {
      & .button {
        width: auto;
      }
    }
    &-join-container {
      width: auto;
    }

    &-content{
      width: auto;
      margin: 15px;
    }
  }

  .auth-footer-break-text {
    display: block;
  } 
}