.filesSkeleton {
  overflow: hidden;
  position: relative;
  width: 100%;

  &_sqr {
    padding: 5px 0 0 0;
  }
  &_sqr & {
    &__group {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    &__desc {
      flex: 1 1 212px;
      min-width: 212px;
      max-width: 300px;
      min-height: 242px;
    }
  }

  &_smallRow & {
    &__desc {
      height: 40px;
    }

    &__element {
      &:not(:last-child) {
        margin: 0 0 1px 0;
      }
    }
  }

  &__gradientCover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    transform: translateX(-100%);

    animation: animateGradientCover 1.1s infinite;
    opacity: 0.6;
  }

  &__group {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__element {
    background: var(--skeletonItem);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__title {
    max-width: 197px;
    height: 34px;
  }

  &__desc {
    height: 87px;
  }
}

@keyframes animateGradientCover {
  0% {
    transform: translateX(-100%);
    left: 0;
  }
  80% {
    transform: translateX(0);
    left: 100%;
  }
  100% {
    transform: translateX(0);
    left: 100%;
  }
}
