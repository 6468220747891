@import '../../static//assets/styles/global/global-varibles.scss';

.wrapper {
  font-family: $sf_pro;
  color: var(--textColor);
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
  padding: 8px 16px;
  display: block;
  width: 100%;
  height: 100%;
}

.wrapper:hover {
  color: var(--textColor);
}

@media screen and (max-width: 480px) {
  .wrapper {
    font-size: 12px;
  }
}
