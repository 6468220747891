@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 42px;
  margin-top: 30px;
}

.wrapper > button:first-child {
  margin-right: 22px;
}

.wrapper > button > svg > path {
  stroke: black;
}

.tooltipWrapper {
  position: absolute;
  top: -80px;
  right: 80px;
  width: 152px;
  height: 70px;
  background: #FFFFFF;
  border-radius: 8px;
  filter: drop-shadow(2px 2px 16px rgba(26, 49, 139, 0.16));
}

.tooltipTitle {
  text-align: center;
  text-transform: initial;
  letter-spacing: initial;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: 5px 0 4px 0;
}

.tooltipText {
  text-align: center;
  text-transform: initial;
  letter-spacing: initial;
  font-size: 10px;
  line-height: 150%;
  color: #7E8A9F;
}

.arrowDown {
  position: relative;
  bottom: 12px;
  left: 10px;
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid white;
} 

@media screen and (max-width: 480px) {
  .wrapper {
    margin-bottom: 36px;
  }
}