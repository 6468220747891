@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}
.modal {
  display: block;
  &__wrapper {
    position: absolute;
    width: 504px;
    top: 50%;
    left: 50%;
    background: var(--baseColor, #000);
    transform: translate(-50%, -50%);
  }
  &__exit {
    color: var(--iconColor, #fff);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
  &__title {
    text-align: center;
    font-family: $sf_pro;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: var(--textColor, #fff);
    margin: 0;
  }
  &__description {
    white-space: pre-wrap;
    text-align: center;
    font-family: $sf_pro;
    color: var(--textColorGray, #7d7d7d);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  &__bugReportMessageModalContent {
    height: 280px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 17px;
    min-height: 48px;
    button {
      width: 132px;
      min-width: auto;
      font-weight: 500;
    }
  }
}
