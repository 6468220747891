@import 'static/assets/styles/global/global-varibles';

.textArea {
  height: 130px;

  & > label {
    color: #fff !important;
  }

  & > textArea {
    height: 90px;
    margin-bottom: 0;
    padding-left: 18px;
    background-color: transparent;
    border-radius: 8px;
    color: #FFF;
  }
}

.saveButton {
  margin-top: 24px;
  width: 100%;
  height: 38px;
  border-radius: 8px;
  background-color: transparent !important;
  border: 0.8px solid #FFFFFF80 !important;
  min-height: 38px;
  color: #FFF !important;

}

.saveButton:disabled {
  color: #FFFFFF33 !important;
  border: 0.8px solid #FFFFFF80 !important;
}

.saveButton > div > div {
  background-color: #FFFFFF;
}

.numberInput {
  height: 90px;

  & > label {
    color: #fff !important;
  }

  & > input {
    padding-left: 18px;
    background-color: transparent;
    height: 44px;
    border-radius: 8px;
    color: #fff;
  }
}

.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.currencyItem {
  position: absolute;
  color: #7e8a9f;
  right: 12px;
  top: 44px;
}

.input {
  height: 90px;

  & > label {
    color: #fff !important;
  }

  & > input {
    padding-left: 18px;
    background-color: transparent;
    height: 44px;
    border-radius: 8px;
    color: #fff;
  }
}

.unlimitedOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionMarker {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  padding: 2px 6px;
  background: rgba(15, 115, 239, 0.1);
  border-radius: 4px;
  font-size: 10px;
  line-height: 16px;
  color: #0f73ef;
}

.selectedOption span {
  display: none;
}

.selectedPleceholder {
  font-size: 14px;
  line-height: 24px;
  color: #7e8a9f;
}
