.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  width: calc(100% - 330px);
  height: calc(100% - 74px);
}

.modalClose {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  width: calc(100% - 330px);
  height: calc(100% - 74px);
}

.modalContent {
  position: absolute;
  display: flex;
  background: #000;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.cityItem {
  cursor: pointer;
}

.cityItem:not(:last-child) {
  border-bottom: 0.5px solid #FFFFFF80
}

.cityItem:hover p {
  color: #F5E12D;
}

.cityText {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  padding: 16px 20px 16px 20px;
  text-align: center;
  margin: 0;
}
