.container {
  height: 100%;
  background-color: var(--sidebarNewBg);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--newDividingLine);
}

.containerTec {
  height: calc(100% - 20px);
  top: 20px;
}

@media (max-width: 13400px) {
  .containerTec {
    height: calc(100% - 31px);
    top: 31px;
  }
}

@media (max-width: 680px) {
  .containerTec {
    height: calc(100% - 45px);
    top: 45px;
  }
}

@media (max-width: 460px) {
  .containerTec {
    height: calc(100% - 57px);
    top: 57px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
}
