.home__page {
  width: 100%;
  height: 100%;
  margin-top: 57px;

  h3 {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 24px;
    color: $view_overlay;
  }

  &-container {
    width: 100%;

    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: auto;

      p {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $empty_color;
        margin: 0 auto;
        width: 520px;
        text-align: center;
      }
    }

    .manage-button {
      margin: 84px auto 0 auto;
    }
  }

  .folders-wrapper {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
