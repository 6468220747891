body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-aspect-ratio: 9/16) {
  body {
    orientation: portrait;
  }
}

:root {
  --onboard-wallet-columns: 1;
  --onboard-modal-background: #0f0f15;
  --onboard-modal-color: #ffffff;
  --onboard-font-family-normal: 'SF Pro Text';
  --onboard-font-family-semibold: 'SF Pro Text';
  --onboard-font-family-light: 'SF Pro Text';
  --onboard-connect-content-width: 400px;
  --onboard-connect-content-height: 320px;
  --onboard-wallet-button-border-radius: 20px;
}
