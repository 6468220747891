.shippingForm {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito';
  margin-top: 60px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shippingInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.shippingInfoText {
  color: #FFFFFF50;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  margin: 0;
}

.shippingInfoValue {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;
}

