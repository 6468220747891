.directionButton {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: var(--dirButton);
  border-radius: 2px;
  margin-left: 4px;

  & svg {
    fill: #007aff;
  }
}

.directionButtonDesc > svg {
  transform: rotate(180deg);
}
