$wrapperMaxWidth: 1158px;

$textLargeSize: rem(16px);
$textMiddleSize: rem(14px);
$textSmallSize: rem(12px);
$titleLargeSize: rem(64px);
$titleMiddleSize: rem(24px);
$titleSmallSize: rem(18px);

$primaryColor: #206acf;
$secondaryColor: #0e67d5;

$darkTitleColor: #111213;
$middleTitleColor: #a5acb2;
$lightTitleColor: #ffffff;

$darkTextColor: #434648;
$lightTextColor: #ffffff;

$primaryBorderColor: rgba(165, 172, 178, 0.45);
button.landing__sign__in {
  min-width: 56px;
  height: 16px;
  color: $darkTitleColor;
  font-family: 'Gotham Pro', serif;
  font-size: $textSmallSize;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  white-space: nowrap;
  margin-right: 32px;
  text-decoration: none;
}
button.landing__sign__up {
  min-width: 210px;
  padding: 0 24px;
  height: 44px;
  border-radius: 28px;
  cursor: pointer;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $primaryColor;
  font-family: 'Gotham Pro', serif;
  font-size: $textSmallSize;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  transition: ease-out 0.4s;
  border: 1px solid $primaryColor;
  text-decoration: none;
  background: transparent;
}
button.banner__primary {
  min-width: 264px;
  padding: 0 24px;
  height: 56px;
  border-radius: 28px;
  background-color: $primaryColor;
  color: #ffffff;
  font-family: 'Gotham Pro', serif;
  font-size: $textMiddleSize;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border: none;
  transition: ease-out 0.4s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 100%;
  text-decoration: none;
}
button.banner__secondary {
  min-width: 264px;
  padding: 0 24px;
  height: 56px;
  border-radius: 28px;
  border: 1px solid $primaryColor;
  cursor: pointer;
  color: $primaryColor;
  font-family: 'Gotham Pro', serif;
  font-size: $textMiddleSize;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(250, 250, 250, 0.7);
  transition: ease-out 0.4s;
  text-decoration: none;
  & > span.play__image {
    display: block;
    width: 14px;
    height: 16px;
    background: url('/static/assets/img/button_play.svg') no-repeat center
      center;
    background-size: 14px 16px;
    margin-right: 20px;
  }
}
button.footer__btn,
button.footer__btn {
  min-width: 264px;
  padding: 0 24px;
  height: 56px;
  border-radius: 28px;
  background-color: transparent;
  outline: none;
  line-height: 100%;
  cursor: pointer;
  font-family: 'Gotham Pro', serif;
  font-size: $textMiddleSize;
  margin-bottom: 32px;
  transition: ease-out 0.4s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(250, 250, 250, 0.4);
  color: #f0f2f6;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
}
button.subscribe__form__btn {
  width: 100%;
  max-width: 264px;
  padding: 0 24px;
  height: 56px;
  border-radius: 28px;
  border: none;
  background-color: #ffffff;
  outline: none;
  line-height: 100%;
  cursor: pointer;
  color: $primaryColor;
  font-family: 'Gotham Pro', serif;
  font-size: $textMiddleSize;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 24px;
  @media screen and (max-width: 640px) {
    max-width: 75%;
  }
}
.lang--btn--hover:hover {
  transform: scale(1.05);
}
.social--hover:hover {
  border-color: $primaryColor !important;
}
