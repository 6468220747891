.registrationStepContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 870px;
}

.shadowBlock {
  position: absolute;
  left: 5px;
  right: 5px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.shadowBlockBottom {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 160px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  display: none;
}

.lowerShadow {
  bottom: 90px;
}

.scrollContent {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 60px;
  padding-right: 10px;
}

.question {
  color: #fff;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footerBlock {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 63px;
}

.scrollContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scrollContent::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.continueButton {
  margin: 0 auto;
  width: 100%;
  max-width: 398px;
  padding: 12px 16px;
  border-radius: 14px;
  border: 0.7px solid #ffe24a;
  color: #ffe24a;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

@media only screen and (max-width: 730px) {
  .question {
    font-size: 14px;
  }

  .registrationStepContainer {
    gap: 16px;
  }

  .footerBlock {
    gap: 32px;
  }

  .smallButton {
    font-size: 14px;
    height: 51px;
  }

  .shadowBlock {
    top: -13px;
    left: 0px;
    right: 0px;
  }
}
