@import 'static/assets/styles/global/global-varibles';

.wrapper {
  font-family: $sf_pro;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 16px);
  overflow-y: auto;
}

.copyBtn {
  width: 32px;
  height: 32px;
  background: #1A1A1A;
  border-radius: 22px;
}

.copyBtn svg {
  width: 10px;
  height: 11px;
}

.copyBtn path {
  fill: white;
}

// SWICHES 

.switches {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 10px;
  color: var(--textColorGray);
  border-bottom: 0.5px dashed var(--dashedDividerBorder);}

.switchActive > .switchView {
  color: var(--textColor);
}

.switchView {
  display: flex;
  align-items: center;
}

.switchView > svg {
  color: #fff;
}

.switcher {
  display: flex;
  align-items: center;
}

.switchItem > span {
  background-color: transparent;
  border: 0.5px solid #FFFFFF50;
}

.switchItem > span > span {
  top: 2px !important;
  background-color: #FF1717;
}

.switchItem input:checked ~ span {
  background-color: transparent !important;
  border: 0.5px solid #FFFFFF !important;
}

.switchItem input:checked ~ span > span {
  background-color:#FFFFFF !important;
}


.switcherText {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 14px;
  color: var(--textColor);
}

.switchViewText {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: fit-content;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
}

// INPUT

.inputContainer {
  height: 44px;
  min-height: 44px;
}

.inputText {
  color: var(--textColor);
  font-size: 13px;
}

.inputField {
  width: 100%;
  margin-bottom: 0;
  background-color: transparent;
  border: 1px solid var(--inputBorder);
  color: var(--textColor);
  height: 44px;
  min-height: 44px;
  padding: 12px 16px;
  font-size: 12px;
  line-height: 24px;
}

.inputField, .inputField:focus {
  box-shadow: none !important;
  border: none !important;
}

.inputContent {
  display: flex;
  justify-content: space-between;
  background: transparent;
  height: 44px;
  border-radius: 8px;
}

.inputContentError {
  margin-bottom: 22px;
  background-color: rgba(237, 29, 45, 0.05) !important;
  border: 1px solid #ed1d2d !important;
}

.inputContentError input {
  background-color: transparent !important;
}

.inputContentDisabled {
  background-color: var(--inputDisabled);
}

.inputButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  margin: 10px 16px 10px;
  background-color: #111111;
  border: 0.2px solid #FFFFFF
}

.inputButton:disabled {
  border: 0.2px solid #FFFFFF80;
}

.inputButton > svg {
  color: white;
}

.input {
  position: relative;
  border: 0.5px solid var(--inputBorder);
  background-color: var(--inputBg);
  height: 44px;
  background-color: transparent;
  border-radius: 8px;
}

.inputErrorMsg {
  position: absolute;
  margin: 0;
  text-align: initial;
  font-size: 12px;
  line-height: 18px;
  color: #ed1d2d;
}
//

.potentials {
  width: 100%;
  padding: 20px 18px 8px 18px;
  position: absolute;
  background: var(--tooltipContentSecond);
  border: 0.5px solid var(--inputBorder);
}

.potential {
  display: flex;
  cursor: pointer;
}

.potential:not(:last-child) {
  margin-bottom: 14px;
}

.potentialDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 14px;
  width: calc(100% - 20px);
}

.potentialMember {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.potentialMember span {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: white;
}

.potentialIcon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.potentialMetamaskIcon {
  min-width: 32px;
  height: 32px;
}

.potentialName, .potentialEmail {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.potentialName {
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #000000;
}

.potentialEmail {
  font-family: $roboto;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #92929D;
}

.nextUsers {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 2px 10px;
}

.nextUser:nth-child(odd) {
  margin-right: 8px;
}

.nextUser {
  width: calc(50% - 4px);
  display: flex;
  background: #F6F8FC;
  padding: 10px 8px;
  margin-bottom: 6px;
}

.nextUserName {
  font-family: $roboto;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.nextUserBtn {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-left: 4px;
}

// MEMBERS

.membersContent {
  margin-top: 8px;
}

.membersTitle {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.memberTitle {
  margin: 0;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: var(--textColor);
}

.memberOpened > svg {
  transform: rotate(180deg);
}

.memberOpenBtn {
  cursor: pointer;
  color: var(--iconColor);
}

.memberList {
  margin: 0;
  padding: 0;
  list-style-type: none;  
}

.member {
  display: flex;
  margin: 9px 10px 0 18px;
}

.memberLogo {
  min-width: 32px;
  height: 32px;
}

.memberLogo > svg {
  width: 100%;
  height: 100%;
}

.memberLogo > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.memberName, .memberRole {
  margin: 0;
}

.memberAbout {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.memberAbout > svg {
  color: var(--iconColor);
}

.memberDesc {
  width: calc(100% - 110px);
}

.memberName {
  color: var(--textColor);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.memberRole {
  font-family: $roboto;
  font-size: 12px;
  line-height: 12px;
  color: $colGrayMed;
  opacity: 0.8;
}

.memberCustomLogo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(74.72deg, #F2BA4C 23.58%, #F2744C 90.33%);
  border-radius: 50%;
}

.memberCustomLogo > span {
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  text-align: center;
  margin: 0;
}

.memberAction {
  display: flex;
  align-items: center;
}

.memberStatus {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: $status_orange;
  margin: 0 16px 0 0;
  text-transform: uppercase;
}

.memberShareLink {
  color: var(--iconColor);
  cursor: pointer;
}

.removeMember {
  color: var(--iconColor);
  cursor: pointer;
  margin-left: 10px;
}

// INVITE LINK

.inviteLink {
  margin-bottom: 10px;
  margin-top: auto;
}

.inviteLinkHeader {
  font-size: 13px;
  color: var(--textColor);
}

.inviteLinkCopy {
  cursor: pointer;
  margin-top: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.inviteLinkCopy > svg {
  height: 18px !important;
  width: 18px !important;
  color: #fff;
}

.inviteLinkCopy:disabled {
  background-color: $colGrayLight;
}

.inviteLinkCopy > svg {
  width: 10px;
  height: 11px;
}

.inviteLinkInput {
  display: flex;
  justify-content: space-between;
  height: 48px;
  margin-top: 8px;
  padding: 0 8px 0 16px;
  background-color: #1B1B1B;
  border-radius: 8px;
}

.inviteLinkInput p {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
  width: calc(100% - 40px);
  margin: 0;
}

.deleteInvited {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
}

.deleteInvited > button {
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $colGrayMed;
}

// SharePopup
.shareDate  {
  position: relative;
}

.shareContent {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--textColor);
}

.shareContent > svg {
  margin-left: 4px;
  margin-right: 6px;
}

.sharePopup {
  box-shadow: 2px 8px 24px rgba(26, 49, 139, 0.15);
  border-radius: 3px;
  position: absolute;
  z-index: 100;
  background-color: var(--tooltipContent);
  right: -47px;
  top: calc(100% + 5px);
  display: none;
  width: 310px;
  padding: 12px;
}

.sharePopupOpen {
  display: block;
}

.dataPikerWrapper {
  padding: 0 !important;
}

.dataPikerWrapper input {
  padding-left: 16px !important;
  height: 48px !important;
  font-size: 14px;
  line-height: 24px;
}

.sharePopup .rc-time-picker-panel-inner {
  background-color: #FFFFFF;
}

.timePicker {
  margin-top: 8px;
  position: relative;
  width: 100%;

  input {
    background-color: var(--inputBg);
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid var(--inputBorder);
    border-radius: 0;
    height: 48px;
    padding: 12px 0 12px 14px;
    color: var(--textColor);
  }

  svg {
    right: 10px;
    top: 12px;
    position: absolute;
  }
}

.sharePopupClear {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 22px;
  color: $medium_grey;
  cursor: pointer;
}

.sharePopupClear span {
  margin-left: 4px;
}

.sharePopupTitle {
  text-align: center;
}

.timePickerPopup > div:first-child {
  background-color: #FFFFFF;
}

.timePickerPopup > div:first-child input {
  background-color: #FFFFFF;
}

// Hint

.hintText {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.hintTitleCenter {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  text-align: center;
}

.hintTextCenter {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #7E8A9F;
  text-align: center;
  margin: 0;
}

.hintTextStrong {
  cursor: pointer;
  color: #1073EF;
  text-decoration: underline;
}

.tooltipHint {
  opacity: 1;
}

.tooltipHint > div {
  opacity: 1 !important;
}

.tooltipHint > div > div {
  font-size: $sf_pro;
  font-size: 11px;
  padding: 8px !important;
}