@import 'static/assets/styles/global/global-varibles.scss';

.notification {
  font-family: $sf_pro;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
}

.notificationTyper {
  display: inline-block;
}

.notificationText {
  position: relative;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s forwards;
  font-size: 1.6rem;
  width: 0;
  margin: 0;
}

.blinkingPipe {
  position: absolute;
  right: -5px;
  bottom: 0px;
  animation-delay: 1s;
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
  animation: blinkAnimation 0.75s infinite;
  font-family: 'Nunito';
  text-transform: uppercase;
  background: linear-gradient(90deg, #e55d87 0%, #5fc3e4 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinkAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
