.overlay {
  position: fixed;
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  top: 23px;
  left: 23px;
  right: 25px;
  bottom: 78px;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000000D;
  backdrop-filter: blur(9.5px);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  color: var(--textColor);
}

.chatWrapper {
  display: flex;
}

.chatWrapper > div {
  margin: auto auto 0 0;
  width: calc(100% - 280px);
}

.closeBtn {
  z-index: 11;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 20px;
  color: #fff;
  cursor: pointer;
}

.closeBtn > div > svg {
  width: 20px;
  height: 20px;
}

.closeBtn > div > div > svg {
  display: none;
}
