.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-top: none;
  display: none;
}

.react-datepicker {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: var(--sidebarTabsBackground);
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  width: 100%;
}
.react-datepicker__month-container {
  width: 312px;
}

.react-datepicker__header {
  text-align: center;
  background-color: var(--sidebarTabsBackground);
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: var(--textColor);
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--textColor);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day--outside-month {
  color: var(--textColorGray) !important;
}

.react-datepicker__header {
  border: none;
  margin-bottom: 20px;
  padding-top: 20px;
}

.react-datepicker__week {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
}
.react-datepicker__day-names {
  display: flex;
  gap: 5px;
  justify-content: space-evenly;
  margin-top: 20px;
}

.react-datepicker__day-name {
  color: var(--neyraBorderNavigationColor);
  font-family: 'Nunito';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.react-datepicker__day {
  position: relative;
  color: var(--textColor);
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 31px;
}

.react-datepicker__day--selected,
.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected {
  color: #f5e12d !important;
  text-align: center;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  background-color: transparent;
  position: relative;
}

.react-datepicker__day--today {
  color: var(--textColor);
}
.react-datepicker__day--selected::after,
.react-datepicker__day:hover::after,
.react-datepicker__day--today::after,
.react-datepicker__day--keyboard-selected::after {
  content: '';
  border-radius: 50px;
  border: 1px solid var(--textColor);
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-datepicker__current-month {
  color: var(--textColor);
  font-family: 'Nunito';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 10px;
}

.react-datepicker__navigation--previous {
  left: auto;
  right: 40px;
  top: 22px;
}

.react-datepicker__navigation--next {
  top: 22px;
}
