@import 'static/assets/styles/global/global-varibles';

.password-popup {
  max-width: 471px;
  width: 100%;

  background-color: $row_light_grey;
  box-shadow: -4px 0 12px rgba(16, 22, 26, 0.11);

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 34px;
    padding-bottom: 20px;
    box-sizing: border-box;
    overflow: auto;

    & > * {
      flex-shrink: 0;
    }

    .activate-error {
      color: $red;
      margin: 10px 0 -22px 108px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-family: $sf_pro;
    color: $forty_color;
    line-height: rem(40px);

    &-pin {
      color: $dark_grey;
      line-height: 19px;
      text-align: center;
      margin: 0 0 40px 0;
    }
  }

  &__txt {
    &_visible {
      color: $view_overlay;
    }
  }

  &__set-text {
    margin-bottom: 30px;
    color: var(--textColor);
    line-height: 1;
  }

  &__res-numbers {
    display: flex;
  }

  &__res-num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 70px;
    border: 1px solid var(--inputBorder);
    background-color: var(--inputBg);
    color: var(--textColoR);
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 24px;

    &.default {
      font-size: 14px;
      line-height: 24px;
      color: $search_placeholder;
    }

    &-error {
      border-color: $red;
      color: $red;
      background-color: $rose;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__pin-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__remove-char-icon {
    position: absolute;
    top: 50%;
    left: 100%;
    display: flex;
    align-items: center;

    width: 48px;
    height: 48px;

    font-size: 0;

    cursor: pointer;
    transform: translate(8px, -50%);
  }

  &__subtxt {
    letter-spacing: rem(1.4px);
  }

  &__numbers {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 30px 0;
    user-select: none;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 208px;
    margin: 0 0 4px 0;

    &:last-child {
      justify-content: center;
      margin-bottom: 0;
    }
  }

  &__num {
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 64px;
    height: 64px;

    font-family: $sf_pro;
    font-size: 20px;
    line-height: 24px;
    color: $medium_grey;

    cursor: pointer;

    &:hover {
      background: $row_light_grey;
      color: $view_overlay;
    }
  }

  &__btn {
    display: block;
    margin: 24px auto 0 auto;
    width: 196px;
    &.button-raw {
      height: 48px;
    }
  }

  &__ico {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .password-popup {
    &__container {
      margin: 0;
    }
    &__res-num {
      width: 44.79px;
      height: 55px;
    }
    &__pin-wrapper {
      margin: 20px 0;
    }
    &__remove-char-icon {
      display: none;
    }
    &__btn {
      margin: 10px auto 0 auto;
      width: 196px;
      &.button-raw {
        width: 174px;
        height: 48px;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-family: $sf_pro;
      color: $forty_color;
      line-height: rem(40px);

      &-pin {
        width: 259px;
        color: $dark_grey;
        line-height: 19px;
        text-align: center;
        margin: 10px 0;
      }
    }
  }
}
