@import 'static/assets/styles/global/global-varibles';

.userIcon {
  & model-viewer {
    width: 30px;
    height: 30px;
  }
  height: 32px;
  width: 32px;
  background-color: $primary_light;
  border-radius: 50%;
  border: 1px solid $primary_light;
  margin: 0 -3px 0 0;
  color: $primary_light;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $sf_pro;
  font-size: 12px;
  & svg:nth-child(1) {
    width: 20px;
    height: 20px;
  }

  &__image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    font-size: 14px;
    line-height: 13px;
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 14px 0 0 0;
  }
}

.moreUsers {
  height: 28px;
  width: 28px;
  background-color: $more_icon_color;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid $primary_light;
  margin: 0 0 0 -4px;
  color: $primary_light;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
}
