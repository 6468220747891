.errorMessage {
  position: absolute;
  bottom: -6px;
  font-size: 12px;
  color: #ed1d2d;
}

.errorInput > input {
  background-color: #fff6f7 !important;
  border: 1px solid #ed1d2d !important;
}

.action {
  position: relative;
}

.commentBtn {
  min-height: 34px;
  width: 110px;
  padding: auto;
}
