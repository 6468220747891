.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--mainContainerColor);
  z-index: 9999;
}

.templateContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 20px;
}

.title {
  font-family: 'Nunito';
  z-index: 1;
  font-size: 35px;
  font-weight: 700;
  line-height: 49px;
  color: var(--textColor);
  margin: 0;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.actionHeader .title {
  font-family: 'SF Pro Text';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  flex-grow: 1;
}

.actionContent {
  display: flex;
}

.actionHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px 18px 0 24px;
  z-index: 1;
}

.noPadding {
  padding: 0;
  overflow: hidden;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin: auto 0;
  color: var(--iconColor);
}

// INPUT STYLES

.input {
  width: 100%;
  border: none;
  font-family: 'Nunito';
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  background-color: transparent;
  border-bottom: 1px solid var(--textColor);
  padding: 0 4px 2px 4px;
  color: var(--textColor);
}

// BUTTON

.cancelBtn {
  height: 40px;
  min-width: 170px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--addBtn);
  color: var(--textColor);
  cursor: pointer;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
}

.cancelBtn:hover {
  border-radius: 3px 6px 6px 6px;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    z-index: 2;
    background: var(--addBtn);
    clip-path: polygon(
      5% 8.2%,
      7.6% 5.6%,
      10.4% 3.6%,
      13.8% 1.8%,
      18.2% 0.6%,
      22.6% 0%,
      50% 0%,
      75% 0%,
      85% 0%,
      88.6% 1.2%,
      90.2% 4.8%,
      89.6% 8.8%,
      87.4% 12.4%,
      75% 24.8%,
      50% 49.6%,
      25% 74.4%,
      13.8% 86.6%,
      11% 89.8%,
      7.6% 92.2%,
      4.4% 92.6%,
      1.6% 90.6%,
      0% 87.2%,
      0% 82%,
      0% 74.4%,
      0% 62%,
      0% 49.6%,
      0% 23%,
      0.6% 18.6%,
      1.6% 14.8%,
      3.2% 11.4%
    );
  }

  .borderTopRadius {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -0.5px;
    left: -0.5px;
    z-index: 1;
    background: var(--mainContainerColor);
    background-position: 30vw 50vh;
    background-size: 100vw 100vh;
    clip-path: polygon(
      0% 0%,
      100.2% 0%,
      87.6% 1%,
      82.2% 1.6%,
      78% 2.4%,
      74.4% 4%,
      70.4% 6.2%,
      6.8% 74%,
      4.4% 76.8%,
      3.2% 80.2%,
      1.8% 85.6%,
      1% 91.6%,
      0% 100%
    );
  }
}

.submitBtn {
  height: 40px;
  min-width: 170px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--textColor);
  color: var(--baseColor);
  cursor: pointer;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
}

.submitBtn:hover {
  border-radius: 3px 6px 6px 6px;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    z-index: 2;
    background-color: #00a5ff;
    clip-path: polygon(
      5% 8.2%,
      7.6% 5.6%,
      10.4% 3.6%,
      13.8% 1.8%,
      18.2% 0.6%,
      22.6% 0%,
      50% 0%,
      75% 0%,
      85% 0%,
      88.6% 1.2%,
      90.2% 4.8%,
      89.6% 8.8%,
      87.4% 12.4%,
      75% 24.8%,
      50% 49.6%,
      25% 74.4%,
      13.8% 86.6%,
      11% 89.8%,
      7.6% 92.2%,
      4.4% 92.6%,
      1.6% 90.6%,
      0% 87.2%,
      0% 82%,
      0% 74.4%,
      0% 62%,
      0% 49.6%,
      0% 23%,
      0.6% 18.6%,
      1.6% 14.8%,
      3.2% 11.4%
    );
  }

  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    z-index: 2;
    transform: rotate(180deg);
    background: #00a5ff;
    clip-path: polygon(
      5% 8.2%,
      7.6% 5.6%,
      10.4% 3.6%,
      13.8% 1.8%,
      18.2% 0.6%,
      22.6% 0%,
      50% 0%,
      75% 0%,
      85% 0%,
      88.6% 1.2%,
      90.2% 4.8%,
      89.6% 8.8%,
      87.4% 12.4%,
      75% 24.8%,
      50% 49.6%,
      25% 74.4%,
      13.8% 86.6%,
      11% 89.8%,
      7.6% 92.2%,
      4.4% 92.6%,
      1.6% 90.6%,
      0% 87.2%,
      0% 82%,
      0% 74.4%,
      0% 62%,
      0% 49.6%,
      0% 23%,
      0.6% 18.6%,
      1.6% 14.8%,
      3.2% 11.4%
    );
  }

  .borderTopRadius {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -0.5px;
    left: -0.5px;
    z-index: 1;
    background: var(--mainContainerColor);
    background-position: 45vw 50vh;
    background-size: 100vw 100vh;
    clip-path: polygon(
      0% 0%,
      100.2% 0%,
      87.6% 1%,
      82.2% 1.6%,
      78% 2.4%,
      74.4% 4%,
      70.4% 6.2%,
      6.8% 74%,
      4.4% 76.8%,
      3.2% 80.2%,
      1.8% 85.6%,
      1% 91.6%,
      0% 100%
    );
  }

  .borderBottomRadius {
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: -0.5px;
    right: -0.5px;
    z-index: 1;
    display: block;
    transform: rotate(180deg);
    background: var(--mainContainerColor);
    background-position: 48vw 50vh;
    background-size: 100vw 100vh;
    clip-path: polygon(
      0% 0%,
      100.2% 0%,
      87.6% 1%,
      82.2% 1.6%,
      78% 2.4%,
      74.4% 4%,
      70.4% 6.2%,
      6.8% 74%,
      4.4% 76.8%,
      3.2% 80.2%,
      1.8% 85.6%,
      1% 91.6%,
      0% 100%
    );
  }
}
