.tabs {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0 0 15px 0;
  padding: 0 15px 0 0;
  //box-shadow: 0 1px 0 $shadow_grey;

  &-item {
    padding: 32px 31px 25px 31px;
    font-family: $sf_pro;
    font-size: 16px;
    line-height: 24px;
    color: $medium_grey;
    text-decoration: none;

    &.active {
      font-family: $sf_pro;
      color: $primary_dark;
    }
  }
}
