@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  position: relative;
  margin-top: 32px;
  font-family: $sf_pro;
}

.action {
  cursor: pointer;
  color: var(--textColor);
  font-size: 12px;
  line-height: 16px;
}

.popup {
  width: 176px;
  flex-direction: column;
  bottom: 100%;
  background: var(--tooltipContentSecond);
  border-bottom: 0.5px solid var(--inputBorder);
  box-shadow: 0px 2px 8px var(--boxShadow);
  position: absolute;
  display: none;
  padding: 6px 0;
}

.popupOpen {
  display: flex;
}

.popupItem {
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  color: var(--textColor);
  padding: 0 16px;
}

.popupItem:hover {
  background-color: var(--ruleColor);
}

.popupItem > span {
  margin-left: 8px;
}