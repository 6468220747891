@import 'static/assets/styles/global/global-varibles.scss';

.container {
  font-family: $sf_pro;
  background-color: var(--settingsMenuBg);
  box-shadow: 0px 2px 12px rgba(26, 49, 139, 0.15);
  border-radius: 8px;
  padding: 8px 0;
  margin: 0;
  width: 224px;
  max-height: 70vh;
  overflow-y: auto;
  z-index: 10000 !important;
}

.container > li {
  list-style: none;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 5px 24px;
  cursor: pointer;
}

.menuItem:hover {
  background-color: var(--sidebarHover);
}

.icon {
  min-width: 33px;
}

.workspaceTitle {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.workspaceRole {
  font-family: $sf_pro;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: var(--textColorGray);
}

.workspaceName {
  letter-spacing: 0.1px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: var(--textColor);
}

.subMenu {
  margin: 0 0 8px 0;
  padding: 0px;
  list-style: none;
  border-bottom: 1px solid $colGrayBg;
}

.submenuItem {
  padding: 0 0 0 14px;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 22px;
  color: var(--textColor);
}

.submenuItem:hover {
  background-color: var(--sidebarHover);
  color: var(--textColor);
}
.textWithBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.membersListItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.membersMenuItem {
  width: 100%;
}

.addMembersButton {
  flex-shrink: 0;
}

.addNewWorkspaceButton {
  width: 32px;
  height: 32px;
}

.newWorkspaceLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  font-family: $sf_pro;
  color: var(--textColor);
  margin-left: 16px;
}

.title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--textColor);
  margin: 8px auto;
  cursor: pointer;
}

.hr {
  margin: 0 8px;
  border: none;
  border-bottom: 0.2px solid var(--settingsHrColor);
  margin-bottom: 4px;
}

.hrSecont {
  margin: 0 8px;
  border: none;
  border-bottom: 0.2px solid var(--settingsHrColor);
  margin-bottom: 4px;
  margin-top: 4px;
}

@media screen and (max-width: 480px) {
  .workspaceName {
    font-size: 12px;
  }
  .workspaceRole {
    font-size: 8px;
  }
  .newWorkspaceLabel {
    font-size: 12px;
  }
}
