@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modalWrapper {
  display: block;
}

.modalContent {
  display: flex;
  margin-bottom: 20px;
  color: #4b576c;
  min-height: 296px;
}

.header {
  font-family: $sf_pro;
  line-height: 32px;
  color: var(--textColor);
  margin: 0 0 24px 0;
  font-size: 24px;
  font-weight: 700;
}

.modalConten div[role='grid'] {
  padding-right: 3px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 500px;
  max-width: 488px;
  padding: 40px;
  text-align: center;
  background: var(--modalContent);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 650px;

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}
.modalBtn {
  display: flex;
  justify-content: center;
  margin: auto 0 0 0;
  padding: 0;
}

.moveContainer {
  display: flex;
  height: 420px;
  padding: 32px 0;
}
.moveLeft {
  width: 50%;
  position: relative;
  padding: 0 0 0 30px;
}
.moveLeftItem.moveLeftMultiple {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 32px;
  justify-content: flex-start;
  gap: 0;
  margin-top: 0;
}
.moveLeftItem.moveLeftMultiple svg {
  width: 14px;
  height: 100%;
  margin-right: 8px;
}
.moveLeftMultiple .moveLeft-item {
  padding: 0;
}
.moveLeftSingle {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.moveLeftItem {
  flex-direction: column;
  padding: 0;
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: flex-start;
  gap: 22px;
  margin-top: 40px;
}
.moveLeftSingle .moveLeftItemImage {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100px;
}
.moveLeftItem svg {
  width: 100%;
  height: 100px;
}
.moveLeftItemTitle {
  font-weight: 400;
  font-size: 11px;
  display: block;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.moveLeftSingle .moveLeftItemName {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  word-break: break-all;
  color: var(--textColor);
}
.moveLeftItemName {
  font-size: 12px;
}
.moveLeftItemImage {
  margin: 0;
}
.moveRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0;
  border-left: 2px solid #eaedf4;
}
.moveRightTitle {
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  padding: 0 0 9px 30px;
  color: var(--textColorGray);

  display: flex;
  justify-content: center;
  align-items: center;
  & .moveRightTitleHighlight {
    font-weight: 500;
  }
}
.moveLeftTitle {
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 24px;
  padding-bottom: 9px;
  padding-left: 21px;
  display: flex;
  color: var(--textColorGray);
}
.moveRightTitleHighlight {
  color: #0f73ef;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}
.moveRight .modal__buttons {
  padding-right: 30px;
}

.folderInTree > svg {
  margin-right: 8px;
}

.folderInTree {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--textColor);
}

.folderInTreeDisabled {
  color: var(--textColorGray);
}

.selectedFolder {
  color: $primary_color;
}

.hint {
  margin: 0;
  padding: 8px 8px;
}

.tree-folder {
  .rstcustom {
    &__node {
      & > div {
        position: absolute;
        left: 30px;
      }
    }
  }

  &-selected {
    background-color: $primary_light;

    .rstcustom {
      &__expandButton {
        &:after {
          border-top-color: $primary_color;
        }
      }

      &__collapseButton {
        &:after {
          border-top-color: $primary_color;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modalContent {
    flex-direction: column;
    min-height: auto;
    justify-content: center;
    align-items: center;
  }
  .modal {
    min-height: 500px;
    width: 288px;
    padding: 40px 9px 11px;
  }
  .moveLeft {
    width: 100%;
    padding: 0;
    max-height: 65px;
    margin-bottom: 30px;
    & > div + div {
      height: 62px !important;
    }
  }
  .moveLeftItem {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 0;
    margin-top: 0;

    & svg {
      width: 30px;
      height: 40px;
    }
  }
  .moveLeftSingle .moveLeftItemImage {
    height: 40px;
    width: auto;
    margin-right: 24px;
  }
  .modalContent {
    padding: 0 17px;
    flex-grow: 1;
  }
  .moveLeftTitle {
    padding-left: 0;
    font-size: 10px;
    line-height: 150%;
  }
  .moveLeftSingle .moveLeftItemName {
    font-size: 10px;
    font-weight: 500;
    line-height: 150%;
  }
  .moveLeftItemName {
    font-size: 10px;
    font-weight: 500;
    line-height: 150%;
  }
  .modalBtn {
    margin-top: 0;
    & .moveBtn {
      width: 100%;
    }
  }
  .moveRight {
    height: 240px !important;
    width: 100%;
    border-left: none;
    & .moveRightTitle {
      padding-left: 0;
    }
  }
  .moveLeftItem.moveLeftMultiple {
    padding-left: 0;
  }
  .moveRightTitle {
    white-space: nowrap;
    font-size: 12px;
    line-height: 24px;
    & span {
      margin-left: 5px;
      font-size: 12px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .header {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
  }

  .tree-folder {
    .rstcustom {
      &__node {
        & > div {
          position: absolute;
          left: 0;
        }
      }
    }
  }
}
