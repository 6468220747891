.header-add-new {
  position: absolute;
  top: 51px;
  //right: 0;

  width: 232px;
  height: auto;
  padding: 5px 0;

  background: $primary_light;
  box-shadow: 0 2px 8px $forty_color;

  transform: translate(0, 0);
  z-index: 100;
  user-select: none;

  &__exit {
    cursor: pointer;
  }

  &__list {
    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 20px;
    width: 100%;
    cursor: pointer;

    font-family: $sf_pro;
    font-size: rem(12px);
    line-height: 16px;
    color: $dark_grey;

    &:hover {
      background: rgba(15, 115, 239, 0.07);
      color: $primary_color;

      svg {
        & > path {
          fill: $primary_color;
        }

        line,
        rect {
          stroke: $primary_color;
        }
      }
    }

    &-icon {
      &-wrapper {
        width: 23px;
      }
    }
  }

  &__item-text {
    margin-left: 14px;
  }
}

.header-add-modal {
  width: 100%;
  height: 540px;
  max-width: 761px;
  padding: 176px 144px 215px 144px;
  margin: auto;
  background: var(--baseColor);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 273px);
  left: calc(50% - 327px);

  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: #7d7d7d;
  }

  &__title {
    margin-top: 63px;
    font-family: $sf_pro;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &__item {
    max-width: 120px;
    max-height: 70px;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &__description {
      &__disableMarker {
        font-size: 8px;
        color: #000000;
        background: #e7f1fd;
        padding: 2px 4px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        border-radius: 4px;
      }
    }

    &__large {
      background: #f6f8fc;
      margin-right: 12px;
      margin-bottom: 25px;
      border-radius: 5px;

      &:hover {
        box-shadow: 2px 8px 24px rgba(26, 49, 139, 0.15);
      }
    }

    &__medium {
      height: 80px;
      color: var(--textColor);
      &:hover {
        color: var(--primeColor);

        & path {
          fill: var(--primeColor);
        }
      }
    }

    &__small {
      width: 140px;
      min-height: 125px;
      margin-right: 14px;

      &:hover {
        box-shadow: 2px 8px 24px rgba(26, 49, 139, 0.15);
      }

      & svg {
        margin-top: 18px;
      }
    }

    &__icon {
      left: -10px;
      position: relative;
    }

    &__large &__title {
      font-family: $sf_pro;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
    }

    &__small &__title {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 118%;
    }

    &__medium &__title {
      font-family: $sf_pro;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
    }

    &__large &__description {
      font-family: $sf_pro;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      width: 136px;
      color: #7e8a9f;
      text-align: center;
    }

    &__small &__description {
      width: 90px;
      font-family: $sf_pro;
      font-size: 8px;
      font-weight: 400;
      line-height: 12px;
      text-align: center;
    }

    &-tooltip {
      background-color: #ffffff;
      color: black;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      bottom: auto;
      position: absolute;
      top: -40px;
      border-radius: 2px;
      filter: drop-shadow(2px 8px 24px rgba(26, 49, 139, 0.15));
    }

    &__text {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__header {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .header-add-modal__item-tooltip {
        display: block;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  &-container {
    position: relative;
    background: #2f2f2f;
    margin-bottom: 12px;
    height: 290px;
  }

  &-button {
    display: flex;
    justify-content: flex-end;
    width: 484px;

    &-stop {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: #c1c9d9;
      color: #ffffff;
      padding: 11px 28px;
      border: 1px solid #c1c9d9;
      min-height: 48px;
      & > svg {
        margin-right: 10px;
      }
    }
    &-play {
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        margin-right: 10px;
      }
    }
  }

  &-timer {
    position: relative;
    bottom: 30px;
    font-family: $sf_pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: #fff;
    height: 0;
  }

  &-date {
    top: 23px;
    left: 21px;
    position: absolute;
    font-family: $sf_pro;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }

  &-rec {
    top: 23px;
    right: 21px;
    position: absolute;
    color: #ed1d2d;
    font-family: $sf_pro;
    font-size: 10px;
    line-height: 12px;
    & > svg {
      width: 9px;
      height: 9px;
    }
  }
  &-camera {
    top: calc(50% - 12.5px);
    left: calc(50% - 12.5px);
    position: absolute;
  }
}

@media screen and (max-width: 660px) {
  .header-add-modal {
    padding: 0;
    width: calc(100vw - 32px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 100%;
    max-height: 446px;

    &__content {
      &__small {
        justify-content: space-between;
        flex-wrap: wrap;
        min-width: 312px;
      }
    }

    &__item {
      &__large {
        flex-direction: row;
        width: calc(100vw - 42px);
        max-width: none;
        min-height: 92px;
        justify-content: space-around;
      }

      &__small {
        height: 136px;
      }

      &__header {
        width: 92px;
        height: 92px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header-add-modal {
    padding: 0;
    max-height: 360px;
    &__item__medium {
      width: 120px;
    }
  }
}
