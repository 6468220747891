.landing__page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    display: block;
  }
  p {
    margin: 0;
    padding: 0;
    display: block;
  }
  button {
    transition: ease-out 0.4s;
  }
  .btn--hover:hover {
    transform: scale(1.05);
  }
  .social--hover:hover {
    border-color: $primaryColor !important;
  }
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
  .landing__header {
    width: 100%;
    height: auto;
    .header__content {
      width: 100%;
      height: auto;
      max-width: 1920px;
      background-color: #ffffff;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      padding: 16px 24px;
      @media screen and (max-width: 640px) {
        display: none;
      }
      &__social {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .social__link {
          width: 40px;
          height: 40px;
          border: 1px solid $primaryBorderColor;
          border-radius: 100%;
          cursor: pointer;
          transition: 0.3s ease;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          use {
            fill: #000000;
            transition: 0.3s ease;
          }
          svg {
            transition: 0.3s ease;
          }
          &.facebook--link {
            margin-right: 12px;
            &:hover {
              use,
              svg {
                fill: $primaryColor;
              }
            }
          }
          &.twitter--link {
            margin-right: 12px;
            &:hover {
              use,
              svg {
                fill: $primaryColor;
              }
            }
          }
          &.telegram--link:hover {
            use,
            svg {
              fill: $primaryColor;
            }
          }
        }
      }
      &__logo {
        outline: none;
        text-decoration: none;
        margin: 0;
        padding: 0;
        display: block;
        width: 121px;
        height: 65px;
        background: url('/static/assets/img/logo_svg.svg') center center / 121px
          65px no-repeat;
        cursor: pointer;
      }
      &__buttons {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .mobile__wrapper {
      display: none;
      position: relative;
      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 24px;
      }
      .menu__label {
        & > span {
          transition: 0.3s ease;
          display: block;
          width: 25px;
          height: 4px;
          background: #000000;
          &:not(:first-child) {
            margin-top: 4px;
          }
        }
      }
      .mobile__content {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: -100vw;
        transform: translateY(100%);
        transition: 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
        background: #ffffff;
        border: 1px solid $primaryBorderColor;
        width: 100vw;
        padding: 16px 24px;
        z-index: 10;
        & > *:first-child {
          margin-bottom: 24px;
        }
      }
    }
    #menuInput {
      display: none;
    }
    #menuInput:checked + label.menu__label + div.mobile__content {
      opacity: 1;
      left: 0;
    }
    #menuInput:checked + label.menu__label {
      & > span {
        opacity: 1;
        &:first-child {
          transform: rotate(45deg) translate(3px, 8px);
          margin: 0;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          transform: rotate(-45deg) translate(3px, -8px);
        }
      }
    }
  }
  .anchors {
    position: fixed;
    right: 24px;
    top: 45vh;
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    &__link {
      width: 16px;
      height: 1px;
      background-color: #d6dadc;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &.active {
        background-color: $primaryColor;
        width: 40px;
      }
      &:hover {
        background-color: $primaryColor;
      }
    }
  }
  .describe__box {
    width: 100%;
    background: #ffffff;
    padding: 0 0 205px;
    &.first--box {
      padding-top: 205px;
    }
    @media screen and (max-width: 640px) {
      padding: 0 24px 205px;
    }
    .describe__content {
      width: 100%;
      max-width: $wrapperMaxWidth;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text__box {
        flex: 1 0 540px;
        max-width: 100vw;
        @media screen and (max-width: 1158px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }
        &__position {
          color: $primaryColor;
          font-family: 'Gotham Pro', serif;
          font-size: $textMiddleSize;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: 2px;
          margin-bottom: 24px;
          & > span.grey--color {
            color: $middleTitleColor;
          }
          & > span.grey--strip {
            width: 1px;
            height: 12px;
            color: #a5acb2;
            opacity: 0.5;
          }
        }
        &__title {
          width: 100%;
          max-width: 373px;
          color: $darkTitleColor;
          font-family: 'Gotham Pro', serif;
          font-size: $titleLargeSize;
          line-height: 80px;
          letter-spacing: -2.4px;
          @media screen and (max-width: 1158px) {
            text-align: center;
          }
          @media screen and (max-width: 1000px) {
            font-size: rem(48px);
            line-height: 60px;
          }
          @media screen and (max-width: 640px) {
            font-size: rem(36px);
            line-height: 48px;
          }
        }
        &__strip {
          width: 61px;
          height: 2px;
          background-color: $middleTitleColor;
          opacity: 0.5;
          margin: 32px 0;
        }
        &__description {
          width: 100%;
          max-width: 391px;
          color: $darkTextColor;
          font-family: 'Gotham Pro', serif;
          font-size: $titleSmallSize;
          line-height: 32px;
          @media screen and (max-width: 1158px) {
            text-align: center;
          }
          @media screen and (max-width: 640px) {
            font-size: rem(16px);
            line-height: 24px;
          }
        }
      }
      .image__box {
        flex: 1 0 610px;
        max-width: 100vw;
        &.atomization--image {
          background: url('/static/assets/img/image__atomization.svg') no-repeat
            center center;
          background-size: contain;
          max-width: 100%;
          min-height: 500px;
        }
        &.upload--image {
          background: url('/static/assets/img/image__upload.svg') no-repeat
            center center;
          background-size: contain;
          max-width: 100%;
          min-height: 500px;
        }
        &.security--image {
          background: url('/static/assets/img/image__security.svg') no-repeat
            center center;
          background-size: contain;
          max-width: 100%;
          min-height: 500px;
        }
      }
    }
  }
  .features {
    width: 100%;
    max-width: $wrapperMaxWidth;
    background-color: transparent;
    padding-top: 116px;
    margin: 0 auto;
    &__subtitle {
      color: $primaryColor;
      font-family: 'Gotham Pro', serif;
      font-size: $textLargeSize;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 2.29px;
      text-align: center;
      @media screen and (max-width: 1000px) {
        font-size: rem(14px);
        line-height: 14px;
      }
    }
    &__title {
      color: $darkTitleColor;
      font-family: 'Gotham Pro', serif;
      font-size: $titleLargeSize;
      line-height: 100px;
      letter-spacing: -2.4px;
      margin-bottom: 40px;
      text-align: center;
      @media screen and (max-width: 1000px) {
        font-size: rem(48px);
        line-height: 60px;
      }
    }
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      .feature__box {
        flex: 1 0 550px;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 400px;
        padding-top: 40px;
        @media screen and (max-width: 640px) {
          padding: 40px 24px 0;
        }
        &:nth-child(even) {
          border-left: 1px solid $primaryBorderColor;
          @media screen and (max-width: 1095px) {
            border-left: none;
          }
        }
        &:nth-child(n + 3) {
          border-top: 1px solid $primaryBorderColor;
        }
        &:nth-child(-2n + 2) {
          @media screen and (max-width: 1095px) {
            border-top: 1px solid $primaryBorderColor;
          }
        }
        &__image {
          width: 98px;
          height: 105px;
          margin-bottom: 16px;
          &.image--time {
            background: url('/static/assets/img/feature_time.svg') no-repeat
              center center;
            background-size: 98px 95px;
          }
          &.image--wallet {
            background: url('/static/assets/img/feature_wallet.svg') no-repeat
              center center;
            background-size: 88px 105px;
          }
          &.image--capsule {
            background: url('/static/assets/img/feature_capsule.svg') no-repeat
              center center;
            background-size: 88px 100px;
          }
          &.image--shards {
            background: url('/static/assets/img/feature_shards.svg') no-repeat
              center center;
            background-size: 88px 105px;
          }
        }
        &__title {
          color: $darkTitleColor;
          font-family: 'Gotham Pro', serif;
          font-size: $titleMiddleSize;
          line-height: 48px;
          letter-spacing: -0.8px;
          text-align: center;
        }
        &__text {
          width: 100%;
          max-width: 386px;
          color: $darkTextColor;
          font-family: 'Gotham Pro', serif;
          font-size: $textMiddleSize;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
  .main__banner {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-top: 118px;
    &__title {
      color: $darkTitleColor;
      font-family: 'Gotham Pro', serif;
      font-size: $titleLargeSize;
      line-height: 100px;
      letter-spacing: -2.4px;
      margin-bottom: 16px;
      text-align: center;
      @media screen and (max-width: 1000px) {
        font-size: rem(48px);
        line-height: 64px;
      }
      @media screen and (max-width: 640px) {
        font-size: rem(36px);
        line-height: 48px;
        max-width: calc(100vw - 48px);
      }
      z-index: 3;
    }
    &__text {
      font-family: 'Gotham Pro', serif;
      font-size: $titleSmallSize;
      line-height: 24px;
      margin-bottom: 40px;
      text-align: center;
      color: #434648;
      @media screen and (max-width: 1000px) {
        font-size: rem(16px);
        line-height: 20px;
      }
      @media screen and (max-width: 640px) {
        max-width: calc(100vw - 48px);
      }
      z-index: 3;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      & > * {
        margin: 8px;
      }
      z-index: 3;
    }
    &__notebook {
      width: 100%;
      height: 780px;
      background: linear-gradient(to bottom, transparent 50%, $primaryColor 50%),
        url(/static/assets/img/bannerBg.svg) center 50% / 120% no-repeat;
      margin: 85px auto 0;
      z-index: 3;
      position: relative;
      @media screen and (max-width: 900px) {
        height: 680px;
      }
      @media screen and (max-width: 800px) {
        height: 580px;
      }
      @media screen and (max-width: 700px) {
        height: 480px;
      }
      @media screen and (max-width: 600px) {
        height: 380px;
      }
      @media screen and (max-width: 500px) {
        height: 280px;
      }
      .notebook__image {
        width: 100%;
        max-width: 1108px;
        height: 100%;
        background: url('/static/assets/img/notebook.png') no-repeat center 0;
        background-size: 1108px 883px;
        margin: 0 auto;
        @media screen and (max-width: 1108px) {
          background: url('/static/assets/img/notebook.png') no-repeat center
            100%;
          background-size: contain;
        }
      }
      .notebook__play {
        position: absolute;
        right: 0;
        left: 0;
        top: 249px;
        background: url('/static/assets/img/video_play.svg') no-repeat center
          center;
        background-size: 108px;
        margin: 0 auto;
        width: 108px;
        height: 108px;
        opacity: 0.98;
        cursor: pointer;
        @media screen and (max-width: 1108px) {
          top: 30%;
        }
        @media screen and (max-width: 640px) {
          top: 22%;
          width: 68px;
          height: 68px;
          background-size: 68px;
        }
      }
    }
    &__animate {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff;
      z-index: 1;
    }
    &__filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.95) 30%,
        rgba(250, 250, 250, 0) 40%
      );
      z-index: 2;
    }
  }
  .secondary__banner {
    width: 100%;
    background-color: $primaryColor;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    &__content {
      width: 100%;
      max-width: $wrapperMaxWidth;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      .banner__box {
        flex: 1 0 250px;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0 41px 80px;
        &__image {
          margin-bottom: 23px;
          width: 92px;
          height: 85px;
          &.image--encryptered {
            background: $primaryColor url('/static/assets/img/encryptered.svg')
              no-repeat center 0;
            background-size: 77px 85px;
          }
          &.image--personal {
            background: $primaryColor url('/static/assets/img/personal.svg')
              no-repeat center 0;
            background-size: 71px 75px;
          }
          &.image--anonymous {
            background: $primaryColor url('/static/assets/img/anonymus.svg')
              no-repeat center 0;
            background-size: 92px 72px;
          }
        }
        &__title {
          color: $lightTextColor;
          font-family: 'Gotham Pro', serif;
          font-size: $titleMiddleSize;
          line-height: 48px;
          letter-spacing: -0.8px;
          margin-bottom: 16px;
          text-align: center;
        }
        &__text {
          width: 100%;
          max-width: 324px;
          color: $lightTextColor;
          font-family: 'Gotham Pro', serif;
          font-size: $textMiddleSize;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
  .technologies {
    width: 100%;
    background: $primaryColor url('/static/assets/img/technologiesBg.svg')
      no-repeat center 110%;
    @media screen and (max-width: 1100px) {
      background: $primaryColor url('/static/assets/img/technologiesBg.svg')
        no-repeat center 105%;
    }
    background-size: contain;
    padding: 116px 0 166px;
    &__content {
      width: 100%;
      max-width: $wrapperMaxWidth;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    &__subtitle {
      opacity: 0.5;
      color: #ffffff;
      font-family: 'Gotham Pro', serif;
      font-size: $textLargeSize;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 2.29px;
      margin-bottom: 16px;
      @media screen and (max-width: 1000px) {
        font-size: rem(14px);
        line-height: 14px;
      }
    }
    &__title {
      color: #ffffff;
      font-family: 'Gotham Pro', serif;
      font-size: $titleLargeSize;
      line-height: 80px;
      letter-spacing: -2.4px;
      margin-bottom: 16px;
      @media screen and (max-width: 1000px) {
        font-size: rem(48px);
        line-height: 60px;
      }
    }
    &__text {
      width: 100%;
      max-width: 900px;
      color: #ffffff;
      font-family: 'Gotham Pro', serif;
      font-size: $textLargeSize;
      margin-bottom: 42px;
      line-height: 20px;
      text-align: center;
    }
    &__box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.225);
      background-color: #ffffff;
      .box__item {
        flex: 1 0 575px;
        min-height: 493px;
        max-width: 100vw;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 100px;
        &:last-child {
          border-left: 1px solid $primaryBorderColor;
          @media screen and (max-width: 1158px) {
            border-left: none;
            border-top: 1px solid $primaryBorderColor;
          }
        }
        &__image {
          width: 98px;
          height: 112px;
          margin-bottom: 36px;
          &.image--auth {
            background: url('/static/assets/img/authenticator.svg') no-repeat
              center center;
            background-size: 98px 112px;
          }
          &.image--notes {
            background: url('/static/assets/img/notes.svg') no-repeat center
              center;
            background-size: 88px 101px;
          }
        }
        &__title {
          text-transform: capitalize;
          color: $darkTitleColor;
          font-family: 'Gotham Pro', serif;
          font-size: $titleMiddleSize;
          line-height: 48px;
          letter-spacing: -0.8px;
          margin-bottom: 8px;
        }
        &__text {
          width: 100%;
          max-width: 386px;
          text-align: center;
          color: $darkTextColor;
          font-family: 'Gotham Pro', serif;
          font-size: $textMiddleSize;
          line-height: 24px;
        }
      }
    }
  }
  footer {
    width: 100%;
    background-color: $secondaryColor;
    padding: 135px 0;
    .footer__content {
      width: 100%;
      max-width: $wrapperMaxWidth;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__title {
        width: 100%;
        text-align: center;
        color: #ffffff;
        font-family: 'Gotham Pro', serif;
        font-size: $titleLargeSize;
        line-height: 88px;
        letter-spacing: -2.4px;
        margin-bottom: 40px;
        @media screen and (max-width: 1000px) {
          font-size: rem(48px);
          line-height: 60px;
        }
        @media screen and (max-width: 640px) {
          font-size: rem(36px);
          line-height: 48px;
        }
      }
      &__text {
        width: 100%;
        max-width: 400px;
        font-size: $textMiddleSize;
        line-height: 24px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 11px;
        opacity: 0.6;
        color: #ffffff;
        font-family: 'Gotham Pro', serif;
        text-transform: uppercase;
        @media screen and (max-width: 640px) {
          font-size: rem(16px);
          line-height: 18px;
        }
        & > span {
          font-family: 'Gotham Pro', serif;
        }
      }
      .footer__logo {
        width: 39px;
        height: 29px;
        cursor: pointer;
        display: block;
        background: url('/static/assets/img/footerLogo.png') no-repeat center
          center;
        background-size: 39px 29px;
      }
      .footer__logo__text {
        width: 100%;
        color: #ffffff;
        font-family: 'Gotham Pro', serif;
        font-size: $textSmallSize;
        line-height: 24px;
        font-weight: 300;
        text-align: center;
        margin: -8px 0 0 20px;
      }
    }
  }
  .footer__form {
    width: 100%;
    max-width: 560px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    &__controls {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__email {
      width: 100%;
      border: none;
      outline: none;
      border-radius: 2px;
      margin-bottom: 40px;
      font-family: 'Gotham Pro', serif;
      font-size: rem(16px);
      padding: 0 24px;
      height: 56px;
      background-color: #f0f2f6;
      color: $darkTextColor;
      line-height: 24px;
    }
  }
  .success__subscription,
  .error__subscription {
    display: none;
    width: 100%;
    text-align: center;
    font-family: 'Gotham Pro', serif;
    font-size: rem(18px);
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 32px;
    & > a {
      color: #ffffff;
      font-family: 'Gotham Pro', serif;
      font-size: rem(16px);
      text-decoration: underline;
      line-height: 20px;
      cursor: pointer;
      margin-top: 16px;
      width: auto;
    }
    @media screen and (max-width: 640px) {
      max-width: 85vw;
      margin: 0;
      & > * {
        max-width: 85vw;
        width: auto;
      }
    }
  }
}
.hidden--element {
  display: none !important;
}
.overflow--hidden {
  overflow: hidden !important;
}
