.account-security {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #ffffff;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__activated-btn {
    &-disabled {
      position: relative;
      cursor: not-allowed;
    }

    position: relative;
    cursor: pointer;

    & > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & > span[data-status='true'] {
      visibility: visible;
      opacity: 1;
    }

    & > span[data-status='false'] {
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      & > span[data-status='true'] {
        visibility: hidden;
        opacity: 0;
      }

      & > span[data-status='false'] {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.security-options {
  width: 100%;
  height: 100%;
  //background-color: #f6f8fc;
  overflow-x: hidden;
  overflow-y: auto;

  .option-item {
    width: 100%;
    box-shadow: inset 0 -1px 0 #e5e7ec;
    background-color: #ffffff;
    margin-bottom: 4px;
    padding: 24px 16px;

    &:last-child {
      margin-bottom: 32px;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;

      .content-image {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .image-icon {
          width: 18px;
          height: 20px;
          display: inline-block;
          border-radius: 60px;
          border: 1px solid #e5e7ec;
          padding: 19px;
          margin-right: 12px;

          .protection-icon {
            &--blue {
              background: url(/static/assets/img/protection-icon-blue.svg)
                no-repeat center center;
            }
          }

          .locked-icon {
            &--blue {
              background: url(/static/assets/img/locked-icon-blue.svg) no-repeat
                center center;
            }
          }

          .key-icon {
            &--blue {
              background: url(/static/assets/img/key-icon-blue.svg) no-repeat
                center center;
            }
          }

          .password-icon {
            &--blue {
              background: url(/static/assets/img/password-icon-blue.svg)
                no-repeat center center;
            }
          }
        }

        .image-text {
          color: $title_section;
          line-height: rem(24px);
          text-transform: uppercase;
        }
      }
    }

    &__text {
      width: 100%;
      color: $title_section;
      line-height: rem(21px);
    }
  }

  &-modal {
    .option-item {
      text-align: left;
      border: none;
      box-shadow: inset 0 1px 4px $shadow_table;
      margin: 0;
      padding: 9px 13px 11px 13px;

      &:last-child {
        margin-bottom: 0;
      }

      &__content {
        margin: 0 0 7px 0;

        .content-image {
          align-items: initial;

          .image-icon {
            width: 21px;
            height: 21px;
            background-size: 9px 10px;
            padding: 6px;
            border-radius: 50%;
            margin: 0 5px 0 0;
          }

          .image-text {
            font-size: 12px;
          }
        }
      }
    }
  }
}
