.team-sharing {
  width: 100%;
  padding: 32px 16px 75px 16px;

  h3 {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 24px;
    color: $primary_dark;
  }

  .team-sharing-tabs {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: 0 0 15px 0;
    padding: 0 15px 0 0;
    box-shadow: 0 1px 0 $shadow_grey;

    &-item {
      padding: 32px 31px 25px 31px;
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $fifty_color;

      &.active {
        border-bottom: 2px solid $primary_color;
        color: $primary_dark;
      }
    }

    .button {
      margin: 0 0 0 auto;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;

    .access-request-item {
      flex-direction: row;
      width: 100%;
      height: 88px;

      .icon__img--folder {
        width: 52px;
        height: 41px;
      }

      .file-action {
        top: 30px;
        right: 40px;
      }

      &__file-name {
        font-family: $sf_pro;
        font-size: 16px;
        line-height: 24px;
        color: $primary_dark;
        padding: 22px 23px;
      }

      &__admin {
        font-family: $sf_pro;
        font-size: 11px;
        line-height: 16px;
        color: $fifty_color;
      }

      &__file-number {
        display: none;
      }

      &__users {
        padding: 0 106px 0 0;
        margin: 0 0 0 auto;
      }
    }

    &__empty {
      padding: 82px 0 0 0;

      &-icon {
        text-align: center;
      }

      h3 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }

      p {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $fifty_color;
        margin: 0 auto;
        width: 495px;
        text-align: center;
      }

      .bottom-button {
        margin: 43px auto 0 auto;
      }
    }
  }
}
