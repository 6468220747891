.container {
  position: relative;
}

.button {
  color: var(--terminalBorder);
  cursor: pointer;

  &:hover {
    color: var(--primeTextColor);
  }
}

.activeButton {
  color: #fff;
}

.startButton {
  > *:first-child {
    display: block;
  }
  > *:last-child {
    display: none;
  }

  &:hover {
    > *:first-child {
      display: none;
    }
    > *:last-child {
      display: block;
    }
  }
}
