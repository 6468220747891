.sidebarFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.sidebarFooterCollapsed {
  position: absolute;
  bottom: 18px;
  right: 20px;
}

.footerActionsBtn {
  width: 48px;
  height: 38px;
  border: 0.8px solid var(--neyraBorderButtonsColor);
  color: var(--neyraBorderButtonsColor);
  border-radius: 8px;
  cursor: pointer;
  & svg {
    fill: var(--neyraBorderButtonsColor);
  }
}

.footerActionsTokens {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 77px;
  height: 38px;
  border: 0.8px solid var(--neyraBorderButtonsColor);
  color: var(--neyraBorderButtonsColor);
  border-radius: 8px;
  cursor: pointer;
}

.footerActionsTokens:hover .footerActionsTokensСircle {
  background-color: var(--primeTextColor);
}

.activeButtonToken {
  color: #ebf0b9fc;
  border-color: #ebf0b9fc;

  .footerActionsTokensСircle {
    background-color: #ebf0b9fc;
  }
}

.warningButtonToken {
  color: #b52f12;
  border-color: #b52f12;

  .footerActionsTokensСircle {
    background-color: #b52f12;
  }
}

.footerActionsTokensСircle {
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 50%;
  background-color: var(--neyraBorderButtonsColor);
}

.footerActionsBtn:hover,
.footerActionsTokens:hover {
  border: 0.8px solid var(--primeTextColor);
  color: var(--primeTextColor);
  & svg {
    fill: var(--primeTextColor);
  }
}