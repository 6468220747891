@import 'static/assets/styles/global/global-varibles';

section.editorContainer,
div.editorContainer {
  background-color: var(--baseColor);
}

.EditorName_wrapper {
  display: flex;
  align-items: center;
  font-family: $sf_pro;
  font-size: 16px;
  line-height: 24px;
  color: $primary_dark;
  margin: 25px 0 14px 10px;
}

.EditorName_icon {
  margin-right: 10px;
  fill: var(--iconColor);
}

.EditorName_title {
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  min-width: 120px;
  height: 36px;
  color: var(--membersInfoIconColor);
}

.EditorName_input {
  background-color: #e8f0fd;
  font-family: $sf_pro;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202124;
  border: none;
  padding: 6px;
  caret-color: #1273ef;
}

.preloader {
  opacity: 0.5;
}

.shareBtn {
  padding: 0;
  font-size: 12px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  margin-right: 5px;

  & svg {
    fill: var(--iconColor);
  }
}

.loginBtn {
  padding: 6px 8px;
  font-size: 12px;
  min-width: 70px;
  width: 64px;
  height: 26px;
  min-height: 30px;
}

.nameInput {
  display: block;
  border: 1px solid transparent;
  background-color: transparent;
  outline: none;
  font-family: $sf_pro;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--textColor);
  width: 100%;
  max-width: 650px;
  margin: 0 auto 10px auto;
  padding: 1px 6px;
  border-radius: 4px;
  &:hover {
    border: 1px solid #747775;
  }
  &:focus {
    outline: 1px solid #0b57d0;
    border: 1px solid #0b57d0;
  }
}

.fileName {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: $mainBlack;
  max-width: 650px;
  margin: 0 auto 10px auto;
}

.saveBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  background: #efeeee;
  border-radius: 1px;
  font-family: $sf_pro;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #b7b7b7;
  margin-right: 24px;
  cursor: pointer;
  user-select: none;
  &.active {
    background: var(--textColorThird);
    color: var(--baseColor);
  }
}

.exitQuestion,
.exitMessage {
  font-family: $sf_pro;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--textColor);
}
.exitMessage {
  margin-bottom: 24px;
}

.exitButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
  .exitLeave {
    width: 201px;
    height: 48px;
  }

  .exitLeave,
  .exitClose {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-family: $sf_pro;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--textColor);
    &:hover {
      opacity: 0.9;
    }
  }

  .exitClose {
    width: 130px;
    height: 48px;
    background: var(--switchBgActive);
    color: var(--secondModalOverlay);
  }
}

.inputWrapper {
  position: relative;
  max-width: 650px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hintWrapper {
  padding: 5px;
  text-align: center;
  font-size: 12px;
}
