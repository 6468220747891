.user-roles {
  &-block {
    position: relative;
    width: 50%;
    padding: 0 0 0 8px;

    .view-all {
      position: absolute;
      right: 9px;
      top: 18px;
      font-size: 12px;
      line-height: 24px;
      color: $primary_color;
      text-decoration: none;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
    background: $primary_light;
    font-size: 12px;
    line-height: 24px;
    color: $light_grey;
    //padding: 15px 24px 0;

    //&:first-child {
    //  padding: 24px 24px 0;
    //}
    //
    //&:last-child {
    //  padding: 15px 24px 32px;
    //}

    &__icon {
      height: 57px;
      width: 57px;
      background-color: $fifty_color;
      border-radius: 50%;
      display: inline-block;
      position: relative;
    }

    &__status {
      position: absolute;
      right: 4px;
      bottom: 4px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid $primary_light;
      margin: 0 -3px 0 0;

      &-active {
        background: $active_color;
      }

      &-inactive {
        background: $inactive_color;
      }
    }

    &__name-role {
      padding: 0 20px 0 13px;
    }

    &__name {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $primary_dark;
    }

    &__role {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__access {
      margin: 0 0 0 auto;
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 14px;
      color: $active_color;

      &-restricted {
        color: $error;
      }
    }
  }
}
