.settings-shared-folder {
  padding: 5px 4.5vw;
  height: 80vh;
  justify-content: center;
  width: 44vw;

  .right-menu__label {
    margin-top: auto;
  }

  .middle-button {
    margin-top: 50px;
  }

  .bottom-button {
    margin-top: auto;
  }
}
