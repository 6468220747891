.account-plan-storage-edit-item {
  text-align: center;

  cursor: pointer;

  &.active &__space {
    color: #206acf;
  }

  &__space {
    color: #1b1a1e;
    //margin-bottom: 9px;
  }
}
