@import 'static/assets/styles/global/global-varibles';

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.canvas {
  width: 100%;
  height: 100%;
  padding: 20px;
  cursor: inherit !important;
}

.image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NeyraLogo {
  position: absolute !important;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);

  & svg path {
    transition: fill-opacity 200ms ease-in-out;
  }

  &:hover svg path {
    fill-opacity: 0.8;
  }
}
