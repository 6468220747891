.account-plan-storage-slider {
  .rangeslider {
    height: 4px;
    box-shadow: none;
    border: none;
    background: #e5e7ec;
    cursor: pointer;

    &__fill {
      background: #5da5ff;
      box-shadow: none;
    }

    &__handle {
      width: 16px;
      height: 16px;
      background: #ffffff;
      box-shadow: none;
      border: 6px solid #206acf;
      transition: width 0.3s, height 0.3s;

      &::after {
        display: none;
      }
    }
  }

  &_move {
    .rangeslider {
      &__handle {
        width: 20px;
        height: 20px;
      }
    }
  }
}
