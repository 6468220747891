.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 34px;
}

.logo {
  max-width: 500px !important;
  color: var(--neyraLogoColor) !important;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 15px;

  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.24px;

  span {
    font-family: 'Digital Numbers';
  }
}

@media only screen and (max-width: 730px) {
  .container {
    gap: 20px;
  }

  .logo {
    max-width: 294px !important;
    height: auto !important;
  }

  .text {
    font-size: 13px;
    letter-spacing: 0.131px;
  }
}
