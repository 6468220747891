.account-setting {
  background: $primary_light;
  width: 80%;
  margin: 0 auto 118px auto;
  padding: 40px;

  h3 {
    font-family: $sf_pro;
    font-size: 32px;
    line-height: 48px;
    color: $primary_dark;
  }

  &-clarification {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 22px;
    color: $light_grey;
    box-shadow: inset 0 -1px 0 $shadow_grey_light;
    padding: 0 0 40px 0;
    text-align: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
    background: $primary_light;
    margin: 0 auto;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 0 23px 0;
    border-bottom: 1px solid $shadow_grey;
    margin: 0 0 21px 0;

    &:last-child {
      border: none;
    }

    &-title {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
    }

    &-description {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    &-notes {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    .expand-button {
      width: 121px;
    }

    .sign-out-button {
      background-image: url('/static/assets/img/icons/action/sign-out.svg');
      background-repeat: no-repeat;
      background-position: left 32px center;
      padding-left: 50px;
    }

    .deactivate-button {
      color: #58595f;

      &:hover {
        background: $row_light_grey;
      }

      &:active {
        background: $row_light_grey;
      }
    }

    &-expanded-content {
      width: 100%;
      height: 40px;
    }
  }

  &__label {
    display: flex;
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $primary_dark;
    padding: 0;
    margin: 24px 0 0 0;
    width: calc(50% - 20px);
    position: relative;

    &-column {
      flex-direction: column;
    }

    .tooltip {
      top: calc(-36%);
      bottom: auto;
      left: calc(100% + 17px);
      transform: none;
      padding: 16px;

      &-content {
        width: 200px;
      }

      .green {
        color: $color_green;
      }
    }

    ul {
      margin: 0;
      padding: 0 0 0 16px;
    }
  }

  input {
    background: $primary_light;
    border: 1px solid $forty_color;
    box-sizing: border-box;
    padding: 11px 16px;
    margin: 12px 0 0 0;
    font-size: 14px;
    line-height: 24px;

    &::placeholder {
      color: $thirty_color;
    }

    &.error {
      background: rgba($red, 0.05);
      border: 1px solid $red;
    }
  }

  &__input {
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 0 16px 0;
      box-shadow: 0 1px 0 $forty_color;

      .switch-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &.lock-access-container {
          margin: 40px 0 24px 0;
        }
        p {
          margin: 0;
        }
      }

      .setting-text {
        font-size: 16px;
        line-height: 24px;
      }

      .secret-password-container {
        width: calc(50% - 20px);
        position: relative;
        margin-top: 24px;
        .account-setting__label {
          width: 100%;
          margin-top: 0px;
        }

        .edit-button {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &-header {
        display: flex;
        align-items: baseline;
        margin: 24px 0 0 0;

        .account-setting__label {
          margin: 0 0 0 auto;
        }
      }

      &-bottom {
        align-items: flex-end;
      }

      &-column {
        flex-direction: column;
      }

      h4 {
        margin: 24px 0 8px 0;
      }

      h5 {
        margin: 24px 0 12px 0;
      }

      input {
        margin: 0;
        width: 100%;
      }

      .add-button {
        margin: 0;
        background: $primary_color;

        &:hover {
          background-color: $primary_color-light;
        }
      }
    }

    &-radio {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &-styled {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 1px solid $forty_color;
        border-radius: 50%;

        &:after {
          content: '';
          position: absolute;
          display: none;
          top: 8px;
          left: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $primary_light;
        }
      }

      &-label {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $title_section;
        display: block;
        position: relative;
        padding: 0 0 0 35px;
        margin: 0 0 16px 0;
        cursor: pointer;
        user-select: none;

        &:hover input ~ .account-setting__input-radio-styled {
          background-color: $primary_color-light;
          height: 24px;
          width: 24px;
          border: none;

          &:after {
            display: block;
          }
        }

        input:checked ~ .account-setting__input-radio-styled {
          background-color: $primary_color;
          height: 24px;
          width: 24px;
          border: none;
        }

        input:checked ~ .account-setting__input-radio-styled:after {
          display: block;
        }
      }

      &-description {
        font-family: $sf_pro;
        font-size: 11px;
        line-height: 16px;
        color: $fifty_color;
      }

      &-title {
        display: flex;
        flex-direction: column;
      }
    }

    &-description {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $dark_grey;
      margin: 8px 0;
    }

    &-errors {
      color: $red;
    }

    &-button {
      position: absolute;
      top: 38px;
      right: 14px;
      cursor: pointer;
    }
  }

  .checkbox {
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
    min-width: 24px;

    &__container {
      display: flex;
      flex-direction: column;
      margin: 12px 0 0 0;
    }

    &__label {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $title_section;
      margin: 0 0 16px 0;
    }
  }

  &__buttons {
    display: flex;
    margin: auto 0 0 0;
    padding: 12px 0 0 0;

    &-end {
      justify-content: flex-end;
    }

    .delete {
      width: 188px;
      height: 48px;
      color: $red;
      outline-color: $red;
      margin: 0 auto 0 0;

      &:hover {
        background-color: $red;
        color: $primary_light;
      }
    }

    .decline {
      height: 48px;
      width: auto;
      padding: 19px 47px 17px 47px;
      outline: 1px solid $twenty_color;
    }

    .accept {
      height: 48px;
      width: auto;
      padding: 19px 47px 17px 47px;
      outline: 1px solid $primary_color;
      margin: 0 0 0 12px;
    }

    .cancel {
      outline: 1px solid $twenty_color;
      width: auto;
      height: 40px;
    }

    .save {
      width: auto;
      height: 40px;
      outline: 1px solid $primary_color;
      margin: 0 0 0 12px;
    }
  }

  &__avatar {
    &-container {
      display: flex;
      padding: 40px 0;
      box-shadow: 0 1px 0 $forty_color;

      header {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $view_overlay;
      }
    }

    &-icon.user-icon {
      width: 72px;
      height: 72px;
      font-size: 28px;
    }

    &-buttons {
      display: flex;
      padding: 36px 0 0 0;

      .upload {
        min-width: 148px;
        height: 40px;
        margin: 0 0 0 16px;
        outline-color: $twenty_color;
        padding: 6px 16px 4px 16px;
      }

      .remove {
        width: 124px;
        height: 40px;
        margin: 0 0 0 16px;
        padding: 6px 16px 4px 36px;
        background: url(/static/assets/img/cancel-icon--big.svg) no-repeat left
          20px center;
        background-size: 12px 12px;
        color: $dark_grey;
        outline-color: $twenty_color;
      }
    }
  }

  &__bottom {
    position: fixed;
    bottom: 0;
    left: 179px;
    padding: 16px 0;
    height: 80px;
    width: calc(100vw - 178px);
    background: $primary_light;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $preview_container;
  }
}

.account-notification {
  &__item {
    p {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 25px;
      color: $light_grey;
    }

    &-email {
      width: 60%;
    }
  }
}
