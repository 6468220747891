.checkPageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__iconLogo {
    top: 30px;
    left: 30px;
    position: absolute;
  }
  &__answerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-family: Gotham Pro;
    text-align: center;
    &_title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
    &_description {
      white-space: pre-wrap;
      max-width: 357px;
      color: #7e8a9f;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
