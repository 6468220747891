.file-content {
  &-image {
    max-height: calc(100vh - 130px);
    max-width: calc(100vw - 150px);

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &-svg {
        background: none;
        & div {
          svg {
            max-height: calc(100vh - 130px);
            max-width: calc(100vw - 150px);
          }
        }
      }
      &-default {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 75vh;

        svg {
          max-height: 98px;
          max-width: 85px;
        }
      }

      &.audio-player {
        background: none;
        height: 75vh;
        justify-content: center;

        .rhap_time {
          color: $primary_light;
        }

        .rhap_volume {
          &-button {
            svg {
              path {
                fill: $primary_light;
              }
            }
          }

          &-bar {
            height: 2px;
          }

          &-indicator {
            background: $primary_light;
          }
        }

        .rhap_progress {
          &-filled {
            background-color: $primary_color;
          }
        }
      }

      &.video-player {
        & video {
          height: 75vh;
        }
        .loader,
        .error {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }
        .error {
          font-size: 24px;
          color: rgb(86, 44, 44);
        }
      }
    }

    &-title {
      font-family: $sf_pro;
      font-size: 18px;
      line-height: 30px;
      color: $primary_light;
      margin: 28px 0 36px 0;
      white-space: pre-wrap;
      text-align: center;
    }
  }

  &-audio-image {
    min-width: 486px;
    min-height: 141px;
    margin: 0 0 92px 0;
  }

  &-pdf-view {
    height: 82vh;
    margin: 50px 0 0 0;
    background: none;
    overflow-x: hidden;

    &-page {
      &-number {
        background: rgba(0, 0, 0, 0.66);
        font-family: $sf_pro;
        font-size: 18px;
        line-height: 24px;
        color: $primary_light;
        text-align: center;
        width: 204px;
        height: 52px;
        padding: 14px 0;
        z-index: 1;
        position: absolute;
        bottom: 25px;
        margin: 0;
        backdrop-filter: blur(2.5px);
      }
    }

    &-prev,
    &-next {
      position: absolute;
      width: 72px;
      height: 72px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }

    &-hide {
      display: none;
    }

    &-loader {
      display: flex;
      align-items: center;
      height: calc(100vh - 90px);
    }
  }

  &-document {
    &-container {
      .file-content-pdf-view-page-number {
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }

    &-view {
      height: 842px;
      pointer-events: none;
    }
  }
}

.pdf-view-document {
  margin: auto;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.react-pdf {
  &__Page {
    margin: rem(0 0 31px 0);
  }
}

:root {
  --color-mbox: rgba(0, 0, 0, 0.2);
  --margin: 4px;
  --secondBaseColor: #000000;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

#controls {
  display: none;
}

@media screen {
  body {
    background-color: var(--secondBaseColor);
  }

  .pagedjs_page {
    background-color: #fdfdfd;
    margin: calc(var(--margin) * 4) var(--margin);
    flex: none;
    box-shadow: 0 0 0 1px var(--color-mbox);
  }

  .pagedjs_pages {
    width: calc(var(--pagedjs-width) + (var(--margin) * 2));
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    transform-origin: 0 0;
    margin: 0 auto;
  }

  #controls {
    margin: 20px 0;
    text-align: center;
    display: block;
  }

  .pagedjs_first_page {
    /*margin-left: calc(50% + var(--margin));*/
  }
}

@media screen {
  .debug .pagedjs_margin-top .pagedjs_margin-top-left-corner,
  .debug .pagedjs_margin-top .pagedjs_margin-top-right-corner {
    box-shadow: 0 0 0 1px inset var(--color-mbox);
  }

  .debug .pagedjs_margin-top > div {
    box-shadow: 0 0 0 1px inset var(--color-mbox);
  }

  .debug .pagedjs_margin-right > div {
    box-shadow: 0 0 0 1px inset var(--color-mbox);
  }

  .debug .pagedjs_margin-bottom .pagedjs_margin-bottom-left-corner,
  .debug .pagedjs_margin-bottom .pagedjs_margin-bottom-right-corner {
    box-shadow: 0 0 0 1px inset var(--color-mbox);
  }

  .debug .pagedjs_margin-bottom > div {
    box-shadow: 0 0 0 1px inset var(--color-mbox);
  }

  .debug .pagedjs_margin-left > div {
    box-shadow: 0 0 0 1px inset var(--color-mbox);
  }
}
