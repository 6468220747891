@import 'static/assets/styles/global/global-varibles';

.modal {
  font-family: $sf_pro;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 528px;
  padding: 0;
  text-align: center;
  background: var(--baseColor);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.content {
  padding: 0px 64px 40px 64px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--textColor);
  margin-bottom: 24px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
}

.subTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
  margin-bottom: 8px;
  color: #ED1D2D;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;  
  text-transform: uppercase;
}

.subTitle > svg {
  margin-right: 6px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.buttonGroup > button {
  width: 188px;
  height: 48px;
}