@import 'static/assets/styles/global/global-varibles';

.wrapper {
  font-family: $sf_pro;
  padding: 40px;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  width: 488px;
  background: #F9F9F9;
  margin: 0 auto;
  padding: 48px 40px 37px 40px;
}

.title {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  color: #10161a;
  text-align: center;
}

.footer {
  margin-top: 32px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  color: #7D7D7D;
}

.field > input {
  background-color: #FFFFFF;
}

.field > label {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #202124;
}

.link {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: right;
  color: #202124;
}

.button {
  width: 100%;
  margin-top: 35px;
  height: 48px;
  padding: 15px 48px 15px 48px;
  background-color: #202124;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.successTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.successText {
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #7D7D7D;
}

.successResend {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0em;
  color: #202124;
  text-align: center;
  text-decoration: underline;
}