.sharing-folder {
  width: 100%;
  padding: 32px 16px 75px 16px;
  position: relative;

  h3 {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 24px;
    color: $primary_dark;
  }

  &-actions {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 40px;
    right: 18px;

    & > div {
      margin: 0 0 0 38px;
    }

    &__upload {
    }

    &__folder {
    }

    &__trash {
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 88px;
    margin: 0 0 15px 0;
    padding: 0 80px 0 20px;
    background: $primary_light;
    position: relative;
    cursor: pointer;
    user-select: none;

    transition: box-shadow 0.3s, transform 0.3s;

    &:hover {
      box-shadow: 0 6px 19px -5px rgba(0, 0, 0, 0.2);

      .file-action {
        opacity: 1;
        visibility: visible;
      }
    }

    .button {
      text-decoration: none;
    }

    .file-action {
      transition: all 0.3s;
      position: absolute;
      top: 27px;
      right: 25px;
      opacity: 0;
      visibility: hidden;
    }

    &__file-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
      padding: 12px 20px;
    }

    &__file-date {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__file-size {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $primary_color;
      margin: 0 23px 0 auto;
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    height: 98px;

    input {
      width: 207px;
      height: 48px;
      border: 1px solid $forty_color;
      padding: 12px 16px;

      &::placeholder {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $fifty_color;
      }
    }

    &__search {
      background: $row_light_grey url(/static/assets/img/icons/input/search.svg)
        no-repeat scroll 172px 15px;
    }

    &__calendar {
      margin: 0 0 0 15px;
      background: $row_light_grey
        url(/static/assets/img/icons/input/calendar.svg) no-repeat scroll 172px
        15px;
    }

    &__users {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0 0 0 150px;

      .user-icon {
        height: 32px;
        width: 32px;
        background-color: $fifty_color;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $primary_light;
        margin: 0 -3px 0 0;
      }

      .more-users {
        height: 28px;
        width: 28px;
        background-color: $more_icon_color;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $primary_light;
        margin: 0 0 0 -4px;
        color: $primary_light;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
      }
    }

    .button {
      margin: 0 0 0 auto;
    }
  }
}
