@import 'static/assets/styles/global/global-varibles';
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  display: block;
  &__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;

    background: var(--baseColor);
    box-shadow: var(--boxShadow);

    transform: translate(-50%, -50%);
  }
  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    svg {
      path {
        fill: var(--iconColor);
      }
      height: 18px;
      width: 18px;
    }
  }
  &__imageToImageContainer {
    padding: 58px 68px 58px 68px;
    display: flex;
    max-width: 630px;
    flex-direction: column;
    justify-content: space-between;
  }
  &__title {
    text-align: start;
    font-family: $sf_pro;
    font-size: 12px;
    color: var(--textColor);
    font-weight: 400;
    span {
      margin-right: 6px;
    }
    &__icon {
      &__label {
        color: var(--textColor);
        font-size: 9px;
      }
    }
  }

  &__imageContent {
    & > svg {
      width: 12px;
      height: 12px;
      position: absolute;
      path {
        stroke: var(--iconColor);
      }
    }

    & > svg:nth-child(1) {
      transform: rotate(0deg);
      top: -1px;
      left: -1px;
    }
    & > svg:nth-child(2) {
      bottom: -1px;
      left: -1px;
      transform: rotate(180deg);
    }
    & > svg:nth-child(3) {
      top: -1px;
      right: -1px;
      transform: rotate(0deg);
    }
    & > svg:nth-child(4) {
      fill: var(--iconColor);
      bottom: 0;
      right: 0;
      transform: rotate(90deg);
    }
    margin-top: 42px;
    padding: 26px 23px 20px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    width: 494px;
    height: 494px;
    margin-left: auto;
    margin-right: auto;
    img {
      box-sizing: border-box;
      width: 440px;
      height: 440px;
    }
    &__textLoader {
      font-family: $sf_pro;
      position: absolute;
      top: -10px;
      left: 30px;
      font-size: 12px;
      color: var(--textColor);
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 50px 21px 0 32px;
    font-size: 12px;
    color: var(--textColor);
    button {
      height: 27px;
      min-width: auto;
      font-weight: 500;
      &:nth-child(1) {
        width: 256px;
        svg > path {
          stroke: var(--iconColor);
        }
      }
      &:nth-child(2) {
        width: 125px;
        background-color: #0f73ef;
        svg > path {
          stroke: #0f73ef;
        }
      }
    }
  }
  &__button__disabled {
    background-color: #7e8a9f !important;
  }
  &__imageFilters {
    max-width: 100%;
    &__title {
      font-size: 12px;
      color: var(--textColor);
      font-family: $sf_pro;
    }
    & > p + div {
      background-color: transparent;
    }
  }
}

@media (max-width: 1024px) {
  .modal {
    &__wrapper {
      width: 100%;
      height: 100%;
    }
    &__imageToImageContainer {
      justify-content: flex-start;
      padding: 50px 16px;
      max-width: 526px;
    }
  }
}

@media (max-width: 520px) {
  .modal {
    &__imageToImageContainer {
      max-width: 100%;
    }
    &__imageContent {
      width: 100%;
      height: 100%;
      max-width: 358px;
      max-height: 370px;
      margin: 30px 0 0 0;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        max-width: 315px;
        max-height: 316px;
      }
    }

    &__buttons {
      margin: 30px 21px 0 32px;
      column-gap: 10px;
      button {
        &:nth-child(1) {
          width: 139px;
        }
        &:nth-child(2) {
          width: 139px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .modal {
    &__imageToImageContainer {
      width: 100%;
    }
  }
}
