.video-player {
  .video-react {
    overflow: hidden;
    background-color: transparent;
    width: 84vw;
    height: calc(100vh - 102px);

    &-video {
      background-color: $primary_black;
    }

    &-playing {
      .video-react-big-play-button {
        &:before {
          content: '\f203';
        }
      }
    }

    &:hover {
      .video-react {
        &-big-play-button {
          &.big-play-button-hide {
            display: block;
          }
        }
      }
    }

    .video-react {
      &-big-play-button {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: $primary_light;
        box-shadow: 0 2px 12px rgba(26, 49, 139, 0.15);
        margin: 0;
        border: none;

        &:before {
          color: $view_overlay;
          position: relative;
        }
      }

      &-control-bar {
        background-color: transparent;
        justify-content: center;
      }

      &-play-control {
        width: 12px;
        height: 16px;
        margin: 0 9px 0 16px;
      }

      &-time-control {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        padding: 0;
      }

      &-slider {
        background-color: $fifty_color;
      }

      &-fullscreen-control {
        width: 15px;
        height: 15px;
        margin: 0 14px;
      }

      &-progress-control {
        margin: 0 0 0 11px;
      }

      &-progress-control & {
        &-progress-holder {
          margin: 0;
        }

        &-load-progress {
          background-color: $primary_light;
        }

        &-play-progress {
          background-color: $primary_color;

          &:before {
            content: '';
          }
        }
      }
    }
  }
}
