.commentsWrapper {
  width: 346px;
  border: 0.5px solid #5E5E5E4D;
  border-radius: 20px;
  padding: 16px;
  background-color: #111111;
  ;
  height: 100%;
  overflow: hidden;
  &__title {
    color: #fff;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0;
    margin-bottom: 16px;
  }
  &__input {
    width: 100%;
    padding: 10px 16px;
    margin: 0 0 16px;
    border-radius: 12px;
    border: 0.7px solid #FFFFFF99;
    color: #ffffff;
    background-color: transparent;
    font-family: 'Nunito';
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    &::placeholder {
      color: #FFFFFF80;
      font-family: 'Nunito';
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  &__list {
    height: 90%;
    overflow: auto;
  }
}

.commentItem {
  margin-bottom: 24px;
}

.commentInfo {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;

  &__username {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    width: 100%;
    color: #fff;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 8px;
    padding-right: 5px;
  }
  &__date {
    margin-left: auto;
    white-space: nowrap;
    color: #fff;
    font-family: 'Nunito';
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    opacity: 0.7;
  }
}

.commentMessage {
  width: 100%;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito';
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
  white-space: wrap;
  word-break: break-word;
  margin-bottom: 16px;
}

.btnsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.replayBtn {
  cursor: pointer;
  border-radius: 8px;
  border: 0.5px solid #f5e12d;
  color: #f5e12d;
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 6px;
  width: 70px;
}

.cancelBtn {
  cursor: pointer;
  padding: 6px;
  width: 70px;
  color: #fff;
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.reply {
  margin: 16px 0 0 0;
  padding: 0 0 0 50px;
}
