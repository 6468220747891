.circle1,
.circle2 {
  transform-origin: 50% 50%;
}

.circle2 {
  transform: scale(0);
}

.button {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }

  &.loading {
    svg {
      animation: CircleActionLoading 5s linear infinite;
    }
  }

  &.active-no-animation {
    .circle2 {
      transform: scale(1);
    }
  }

  &.active {
    animation: CircleActionActivation 0.5s ease-in-out forwards;
    .circle2 {
      animation: CircleActionCircle2ActivationAnimation 0.5s ease-in-out
        forwards;
    }
  }

  &.inactive {
    animation: CircleActionDeactivation 0.5s ease-in-out forwards;
    .circle2 {
      animation: CircleActionCircle2DeactivationAnimation 0.5s ease-in-out
        forwards;
    }
  }
}

@keyframes CircleActionLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes CircleActionActivation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes CircleActionDeactivation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes CircleActionCircle2ActivationAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes CircleActionCircle2DeactivationAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
