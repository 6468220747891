.cubic-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border: 1px dashed #ccceda;
  width: 100%;
  height: 100%;
  background: none;
  cursor: pointer;

  &__img {
    font-size: 0;
    width: 76px;
    height: 102px;

    img {
      max-width: 100%;
    }
  }

  &__desc {
    color: #abadb9;
    line-height: 1;
    margin-top: 16px;
  }

  p {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $fifty_color;
    width: 50%;
  }
}
