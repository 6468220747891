@import 'static/assets/styles/global/global-varibles';

.titile {
  font-family: $sf_pro;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4b576c;
  margin: 0;
}
