.buyTokenWrapper {
  font-family: 'Nunito';
  width: 100%;
  height: 100%;
  padding: 48px 34px 32px 40px;
  margin: 0;
  overflow-y: auto;
}

.buyTokenWrapperCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 0;  
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.selector {
  display: flex;
  width: 100%;
  height: 66px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 10px 16px;
  margin-top: 24px;
}

.selectorAmount {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.selectorAmountValue {
  width: 100%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.inputContent {
  display: flex;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input::-ms-clear {
  display: none;
}

.input  {
  width: calc(100% - 10px);
  border: none;
  text-align: left;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: scroll;
}

.input:disabled {
  color: #000;
}

.inputCurrency {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;  
  text-align: center;
  color: #202124;
}

.valueText {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  margin-bottom: 0;
  margin-top: 4px;
  color: #7D7D7D;
}

.border {
  margin: auto;
  height: 50px;
  width: 1px;
  background-color: #b9b9b9;
}

.selectorCredits {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.selectorCreditsContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
}

.creditsValue {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: right;
  margin: 0;
  color: #202124;
}

.creditsText {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  margin: 0;
  margin-top: 4px;
  color: #7D7D7D;
}

.paymentMethod {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  height: 64px;
}

.paymentBtn {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #FFFFFF33;
}

.paymentBtn > svg {
  color: #FFFFFF;
}

.paymentBtnActive {
  border-color: #fff;
}

.paymentBtnText {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

.payBtn {
  width: 100%;
  height: 54px;
  font-size: 12px;
  font-weight: 510;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 3px;
  text-transform: uppercase;
}

.web3Payment {
  margin-top: 24px;
}

.web3PaymentDesc {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF80;
}

.qrWrapper {
  display: flex;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
}

.web3Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 19px;
}

.web3FormItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.web3FormItemInput {
  display: flex;
  height: 45px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
}

.web3FormItemLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #7D7D7D;
}

.addressInput {
  width: calc(100% - 20px);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.web3FormItemInput svg {
  width: 20px;
  height: 20px;
  color: white;
}

.web3FormItemInput button {
  width: 20px;
  height: 20px;
}