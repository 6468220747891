.circle {
  transition: 0.5s;
  opacity: 1;
  transform: translateY(0%) translateX(0%) scale(0);
}

.button {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }

  &.active-no-animation {
    .circle {
      transform: translateY(0) translateX(0) scale(70);
      opacity: 1;
    }
  }

  &.active {
    animation: TriangleActionActivation 0.5s ease-in-out forwards;
    .circle {
      transform: translateY(0) translateX(0) scale(70);
      opacity: 1;
    }
    .circle {
      transform: translateY(0) translateX(0) scale(70);
      opacity: 1;
    }
  }

  &.inactive {
    animation: TriangleActionDeactivation 0.5s ease-in-out forwards;
  }
}

@keyframes TriangleActionActivation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes TriangleActionDeactivation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
