.create-shared-folder {
  padding: 5px 4.5vw;

  p {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $fifty_color;

    a {
      color: $primary_color;
      text-decoration: none;
    }
  }

  &__action-links {
    display: flex;
    flex-direction: row;
    align-items: center;

    .link {
      color: $fifty_color;
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      width: auto;
      height: auto;
      text-transform: none;
      letter-spacing: 1px;

      &.active {
        color: $primary_dark;
      }
    }

    .splitter {
      color: $forty_color;
    }
  }

  .bottom-button {
    margin-top: 58px;
  }
}
