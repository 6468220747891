@import 'static/assets/styles/global/global-varibles';

.container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.block {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
}

.text {
  color: #fff;
  font-family: 'Nunito';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.2px;
}

.revers {
  flex-direction: row-reverse;
}
