.container {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.notification {
  color: var(--textColor);
  opacity: 0.8;
  animation: NotificationAppearAnimation 0.4s ease-in-out forwards;
}

@keyframes NotificationAppearAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    opacity: 0.8;
    transform: scale(1);
  }
}
