.folder {
  flex-wrap: wrap;
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: 88px;
  padding: 16px 24px;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.3s, transform 0.3s;
  position: relative;

  &.selected-entity {
    box-shadow: var(--folderBoxShadow);
    // border: 1px solid #206acf;

    &::before {
      background: linear-gradient(180deg, #2d7ee1, #0f73ef45) border-box;
    }

    &.folder__selected {
      box-shadow: none;
      border: none;
    }
  }

  &_scaled {
    opacity: 0.45;
  }

  .folder__icon-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  .select-item-checkbox {
    display: none;
  }

  .folder__title {
    position: relative;
  }

  .folder__title:hover {
    .tooltip {
      display: flex;
    }
  }

  &.folder_square {
    .folder__icon-image {
      transform: translateX(-7px);
    }

    .folder-action-avatar {
      position: absolute;
      opacity: 0;
      top: 10px;
      right: 10px;
      display: flex;
      color: var(--iconColor);
      cursor: pointer;
    }

    .folder-action-avatar-active {
      color: #2d7ee1;
    }

    .file-action {
      right: 10px;
    }

    .select-item-checkbox {
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }

  &.folder_small_row {
    .select-item-checkbox {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    .file__checkbox {
      display: block;
    }

    .select-item-checkbox {
      display: block;
    }

    .folder-action-avatar {
      opacity: 1;
    }

    .file-action {
      opacity: 1;
      visibility: visible;
    }
  }

  .file-action {
    position: absolute;
    right: 10px;
    opacity: 0;
    visibility: hidden;
  }

  &_pending {
    opacity: 0.6;
  }

  &_selected {
    background: var(--folderActive);

    &.selected-entity {
      border: none;
    }

    .checkbox {
      display: block;
      border: none;
      background: $primary_color url(/static/assets/img/icon--checked.svg)
        center center/14px 11px no-repeat;
      &::after {
        opacity: 1;
      }
    }
  }

  &_selected & {
    &_square {
      padding: 0;
    }
  }

  &_square {
    position: relative;
    border-radius: 8px;
    width: 242px;
    min-height: 242px;
    max-height: 250px;
    padding: 0;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;
    flex: 1 1 212px;
    min-width: 212px;
    max-width: 300px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 1px solid transparent;
      background: linear-gradient(180deg, var(--fileBorderUp), var(--fileBorderDown)) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    &_dots {
      position: absolute;
      display: flex;
      justify-content: center;
      gap: 10px;
      background-color: var(--mainContainerColor);
      height: 10px;
      top: 0;
      width: 70px;
      left: 50%;
      transform: translate(-50%, -50%); 
      cursor: pointer;

      &__item {
        display: block;
        min-width: 2px;
        height: 2px;
        margin-top: 5px;
        background-color: var(--fileBorderUp);
      }
    }

    .file-action {
      top: 10px;
    }

    &:hover {
      .checkbox {
        visibility: visible;
        opacity: 1;
      }
    }

    .icon__img--folder {
      width: 136px;
      height: 93px;
      margin: 0;
    }

    .checkbox {
      position: absolute;
      top: 15px;
      left: 15px;
      margin: 0;
      padding: 0;
    }
  }

  &_square & {
    &__first-info {
      display: block;
    }

    &__title {
      margin: 16px 0 0 0;
      padding: 0 10px;
      box-sizing: border-box;
      text-align: center;
      white-space: nowrap;
      z-index: auto;
      position: relative;

      &:hover .folder-tooltip {
        display: flex;
      }
    }

    &__edit {
      top: 235px;
      right: 40px;

      &:before {
        right: 75px;
      }
    }

    &__first-info {
      margin: 41px 0 0 0;
    }

    &__data {
      height: 100%;
      width: 100%;
      border-top: 0.1px solid var(--fileBorderSeparate);
      margin: 25px 0 0 0;
    }

    &__desc {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
    }

    &__secured-shared {
      &-container {
        top: 146px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }
      &-crypto {
        width: 15px;
        height: 15px;
      }
    }
  }

  &_small_row {
    min-height: 35px;
    height: 58px;
    padding: 10px 40px 10px 19px;
    margin: 0;
    border: none;
    flex-wrap: nowrap;

    &:hover {
      box-shadow: 0 1px 8px rgba(26, 49, 139, 0.25);
      z-index: 1000;
    }

    &:nth-child(2n) {
      background-color: transparent;
    }

    .icon__img--folder {
      width: 59px;
      height: 40px;
      margin: 0;

      svg {
        max-width: 59px;
        max-height: 40px;
      }
    }

    .file__checkbox {
      width: rem(18px);
      height: rem(18px);
      margin: 0 15px 0 0;
    }

    &.header {
      font-size: 11px;
      height: 35px;

      .folder__title {
        font-family: $sf_pro;
        font-size: 11px;
        line-height: 16px;
        color: var(--textColor);
      }
    }

    &.folder_selected {
      background: var(--sidebarHover);
      box-shadow: var(--boxShadow);
      z-index: 1;
    }
  }

  &_small_row & {
    &__title {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: var(--textColor);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__first-info {
      display: flex;
      gap: 10px;
      min-width: 90px;
      max-width: 90px;

      & > div {
        margin-left: auto;
      }
    }

    &__data {
      padding: 0 0 0 9px;
      width: 29%;
    }

    &__shared {
      width: 15%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $primary_color;
      z-index: 0;
    }

    &__secured {
      width: 16%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $fifty_color;
      z-index: 0;
    }

    &__created {
      width: 20%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__size {
      display: flex;
      width: 16%;
      font-family: $sf_pro, 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $fifty_color;
      z-index: 0;
    }

    &__edit {
      top: 32px;
      right: calc(50% - 121px);

      &:before {
        right: calc(50% - 6px);
      }
    }
  }

  &_file {
    padding: 6px 0 6px 0;
  }

  &_file & {
    &__members {
      width: 20%;
    }

    &__modified {
      width: 20%;
    }
  }

  &__first-info {
    display: flex;
    align-items: center;
    width: 114px;
    justify-content: flex-end;
    z-index: 0;
  }

  &__checker {
    display: flex;
    align-items: center;
    z-index: 0;
    width: calc(29% + 85px);
    &__title {
      font-family: $sf_pro;
      width: 18%;
      font-size: 12px;
      color: #7e8a9f;
    }

    &__create {
      display: flex;
      font-family: $sf_pro;
      width: 20%;
      font-size: 12px;
      color: #7e8a9f;
    }

    &__share {
      font-family: $sf_pro;
      width: 15%;
      font-size: 12px;
      color: #7e8a9f;
    }

    &__security {
      font-family: $sf_pro;
      width: 16%;
      font-size: 12px;
      color: #7e8a9f;
    }

    &__size {
      display: flex;
      font-family: $sf_pro;
      width: 16%;
      font-size: 12px;
      color: #7e8a9f;
    }
  }

  &__desc {
    color: var(--textColorSecond);
  }

  &__title {
    color: var(--textColor);
    line-height: rem(24px);
    word-break: break-all;
    z-index: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    max-width: 636px;
    width: 50%;
    height: 100%;
    flex: 2;
  }

  &__items__size {
    color: $fifty_color;
    line-height: rem(16px);
  }

  & .file-action {
    margin-left: auto;
  }

  &__edit {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 82px;
    right: calc(50% - 121px);
    width: 284px;
    height: 120px;
    background: $edit_background;
    padding: 18px 20px 16px 20px;
    box-sizing: content-box;
    z-index: 121;
    outline: 0.5px solid #e4e7ee;

    &:before {
      content: '';
      position: absolute;
      top: -12px;
      right: calc(50% - 6px);
      display: block;
      z-index: 1;
      width: 0;
      height: 0;
      border-bottom: 12px solid $edit_background;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
    }

    &-title {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $primary_black;
    }

    &-input {
      background: $primary_light;
      height: 48px;
      padding: 8px 14px;
      width: 100%;
      border: 1px solid #ccceda;
      box-sizing: border-box;
      margin: 8px 0 0 0;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;

      &:focus {
        border: 1px solid $primary_color;
      }

      &::placeholder {
        color: #abadb9;
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-buttons {
      display: flex;
      margin: 11px 0 0 0;
      justify-content: flex-end;

      .button-raw {
        width: 113px;
      }

      .cancel {
        margin: 0 10px 0 0;
      }
    }
  }

  &__inup-wrapper {
    width: 100%;
    margin-right: 40px;
  }

  &__secured-shared {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: var((--folderIndicator));
    margin: 0 6px 0 0;
    border: 1px solid var(--folderIndicator);

    &-container {
      display: flex;
      align-items: center;
      position: absolute;
      right: 82px;

      .users-container {
        padding: 0;
        min-width: 40px;
      }
    }
  }

  &__secured-shared svg path {
    fill: var(--folderIndicatorIcon);
  }

  &_over {
    background: $selected_background;
    border: 1px solid $primary_color;

    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 9px;
      width: calc(100% - 18px);
      height: calc(100% - 18px);
      background-color: $primary_light;
      z-index: 0;
    }

    &.folder {
      padding: 15px 23px;

      .folder__secured-shared-container {
        right: 81px;
      }
    }

    &.folder_small_row {
      padding: 9px 49px 9px 9px;

      &:before {
        top: 3px;
        left: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
      }
    }

    &.folder_square {
      margin: 0;
      padding: 8px;
      flex-wrap: nowrap;

      .folder__title {
        margin-bottom: 0;
      }

      .folder__secured-shared-container {
        top: 154px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .folder-tooltip {
    display: none;
  }
  .folder__checker {
    &__title {
      display: none;
    }
    &__title:nth-child(n) {
      width: 20%;
    }
    &__title:nth-child(5) {
      width: 12%;
    }
  }
  .folder {
    .select-item-checkbox {
      display: block;
    }
    .file-action {
      visibility: visible;
      opacity: 1;
    }
    &_square {
      min-width: 0;
      .folder__secured-shared-container {
        top: 151px;
      }
      width: 231px;
      margin: 0px;
      height: 242px;
      min-height: auto;
      .icon__img--folder {
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .folder {
        &__title {
          margin: 16px 0 0 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
        &__desc {
          font-size: 10px;
          line-height: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &__data {
          margin-top: 0;
          max-height: 77px;
          max-width: 100%;
          padding: 0 5px;
          height: 100%;
          background-color: var(--folderColor);
        }
        &__icon-image {
          width: 136px;
          height: 93px;
        }
        &__first-info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          height: 165px;
        }
      }
    }
  }
  .folder_small_row {
    & .folder__data {
    }
    & .folder__created {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    & .folder__shared {
      padding-left: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 700;
      display: flex;
      gap: 7px;
      align-items: center;
      font-family: $sf_pro;
      svg {
        width: 8.13px;
        height: 8.94px;
      }
    }
    .folder__secured {
      padding-left: 15px;
      width: 20%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--textColorSecond);
    }
    .folder__size {
      width: 12%;
    }
  }
}

@media (max-width: 768px) {
  .folder_small_row .folder__first-info .folder__icon .icon__img--folder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
  }
}

@media (max-width: 480px) {
  .folder {
    &_square {
      .folder__secured-shared-container {
        top: 95px;
      }
      width: 150px;
      height: 160px;

      .file-action {
        top: 6px !important;
      }

      .folder {
        &__title {
          margin: 10px 0 0 0;
          font-size: 12px;
          line-height: 16px;
        }
        &__desc {
          font-size: 10px;
          line-height: 11px;
        }
        &__data {
          height: 51px;
        }
        &__icon-image {
          width: 90px;
          height: 61px;
        }
        &__first-info {
          height: 109px;
        }
      }
    }
    &_small_row {
      padding: 10px 18px 10px 10px;
      .folder {
        &_square {
          .folder__secured-shared-container {
            top: 151px;
          }
          width: 231px;
          margin: 0px;
          height: 242px;
          .icon__img--folder {
            height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .folder {
            &__title {
              margin: 16px 0 0 0;
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
            }
            &__desc {
              font-size: 10px;
              margin: 5px 0 10px 0;
              line-height: 16px;
            }
            &__data {
              margin-top: 0;
            }
            &__icon-image {
              width: 136px;
              height: 93px;
            }
            &__first-info {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
              height: 165px;
            }
          }
        }
        &__data {
          width: 65%;
        }
        &_edited {
          align-items: flex-start;
        }
        &__first-info {
          min-width: 71px;
        }
        &__created {
          display: none;
        }
        &__shared {
          display: none;
        }
        &__secured {
          display: none;
        }
        &__size {
          display: none;
        }
        &__text {
          width: 85%;
        }
      }
      .file-action {
        visibility: visible;
        opacity: 1;
      }

      &.header {
        display: none;
      }
      & > button {
        width: 22px;
      }
    }
  }
}
