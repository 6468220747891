@import 'static/assets/styles/global/global-varibles.scss';

.modal {
  display: block;
}

.tooltip {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 500;
  color: #10161a;

  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }

  &_text {
    font-family: $sf_pro;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #000000;
    margin: 0 0 40px 0;
    text-align: center;
  }

  &_container {
    padding: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(16, 22, 26, 0.197549);
    border-radius: 2px;
  }
}
