@import 'static/assets/styles/global/global-varibles';

.input {
  margin-bottom: 14px;

  & > input {
    font-family: 'SF Pro Text', sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding-left: 18px;
    border-radius: 8px;
    height: 44px;
    background-color: transparent;
    color: #FFFFFF;
  }

  & > input::placeholder {
    font-size: 14px;
  }

  & > label {
    color: #fff !important;
  }
}

.saveButton {
  font-family: $sf_pro;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  margin-top: 24px;
  min-height: 42px;
  height: 38px;
  min-height: 38px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border: 0.8px solid #FFFFFF;
  border-radius: 8px;
  background-color: transparent !important;
  color: #FFFFFF !important;
}

.saveButton:disabled {
  color: #FFFFFF33 !important;
  border: 0.8px solid #FFFFFF80 !important;
}

.saveButton > div > div {
  background-color: #FFFFFF;
}

.option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  &Name {
    margin-left: 6px;
  }

  & > svg {
    width: 15px;
    height: 16px;
  }
}