.account-billing {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  &__preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    user-select: none;
  }

  &__content {
    padding: 29px 17px 0;
    height: 100%;
    overflow-x: hidden;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__title {
    margin-bottom: 18px;
  }

  &__desc {
    margin-top: 6px;
    color: #58595f;
  }

  &__input {
    width: 100%;
    height: 48px;
    border: 1px solid #ccceda;
    padding: 0 15px 0 15px;
    background-color: #f6f8fc;

    color: $primary_dark;
    font-family: $sf_pro;
    font-size: 14px;

    &::placeholder {
      color: $primary_dark;
      font-family: $sf_pro;
      font-size: 14px;
    }
  }

  &__btn {
    width: 164px;
    height: 36px;
    margin-top: 18px;
  }

  &__history-list {
    margin: 0;
    padding: 0;
  }

  &__show-all {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 18px auto 18px;
    cursor: pointer;
  }

  &__show-all:hover & {
    &__show-all-txt {
      color: $primary_color-light;
    }

    &__show-all-arrow svg {
      fill: $primary_color-light;
    }
  }

  &__show-all-txt {
    color: $primary_color;
    letter-spacing: 1.4px;
    margin-right: 8px;
    transition: color 0.3s;
  }

  &__show-all-arrow {
    font-size: 0;

    svg {
      transition: fill 0.3s;
    }
  }
}
