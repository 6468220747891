@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  align-items: inherit;
}

.defaultLogo {
  position: relative;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: #1A1A1A;
  border: 1.2px solid #FFFFFF;
  margin-right: 16px;
}

.defaultLogo > svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}

.inputWrapper {
  width: 212px;
  height: 22px;
  border-bottom: 1px solid var(--primeColor);
}

.inputWrapper > input {
  width: 100%;
  height: 26px;
  border: none;
  font-size: 16px;
  line-height: 22px;
  font-family: $sf_pro;
  background-color: transparent;
  color: var(--textColor);
}

.logoImage {
  height: 100%;
  width: 100%;
}

.logo {
  width: 46px;
  margin-right: 16px;
}