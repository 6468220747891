.view {
  &__buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 auto;
  }

  &__btn {
    cursor: pointer;
    transition: all 0.3s;
  }
}
.non-auth {
  background-color: var(--baseColor, #f6f8fc);

  &-right-sided {
    display: grid;
    grid-template-columns: auto 345px;
    grid-template-rows: 121px auto;
    grid-template-areas:
      'header header'
      'main right';

    header {
      grid-area: header;
    }

    main {
      grid-area: main;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
  }

  main {
    padding: 13px 7px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    border-bottom: none;
    &__logo {
      cursor: pointer;
    }
    &__settings {
      display: flex;
      align-items: center;
      &__accountButtonWrapper {
        position: relative;
      }
      button {
        max-height: 40px;
      }
      &__menu {
        position: absolute;
        right: 41px;
        top: 61px;
        z-index: 100;
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 12px 16px;
      &:first-child {
        background-color: #ffffff;
      }

      &-spaced {
        justify-content: space-between;
      }
      & > svg {
        min-height: 36px;
        min-width: 36px;
      }
    }

    &-buttons {
      display: flex;
      gap: 4px;
    }

    &-sign-in {
      min-width: 140px;
      min-height: 40px;
    }

    &__divider {
      width: 100%;
      border-top: 1px solid $preview_container;
    }

    &__btn {
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }



  &__main {
    &-title {
      font-family: $sf_pro ;
      font-size: 16px;
      line-height: 24px;
      color: $primary_color;
    }
    &-acces-denied {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__button {
        margin-top: 20px;
      }
      &__lock {
        padding-top: 120px;
      }
      &__bold-text {
        padding-top: 4px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: var(--textColor);
      }
      &__text {
        padding-top: 8px;
        font-size: 14px;
        line-height: 22px;
        max-width: 329px;
        text-align: center;
        color: #7e8a9f;
      }
    }
  }
}
