.sidebarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 425px;
  padding: 45px 21px 79px 24px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  position: relative !important;
  cursor: inherit !important;

  svg path {
    fill-opacity: 1;
  }
}

.backButton {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.shippingDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.plusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background: #d9d9d9;
  color: #000;
  font-family: 'SF Pro Text';
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.sidebarPrice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.sidebarPriceItem {
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  color: #fff;
  margin: 0;
}