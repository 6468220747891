@import 'static/assets/styles/global/global-varibles.scss';

.wrapper {
  height: 100vh;
  font-family: $sf_pro;
  background-color: var(--sidebarNewBg);
  position: relative;
  border-right: 1px solid var(--dividingLine);
}

.collapsed > .topContainer {
  margin: 8px 8px 0 8px;
  padding: 0;
}

.collapsed > .topContainer > a > svg {
  margin: 0 auto;
}

.collapsed > .topContainer > .title {
  margin-left: 0;
}

.topContainer {
  padding: 8px 16px 0 16px;
}

.menuItem:hover {
  background-color: var(--grayBg);
}

.menuItem {
  span {
    font-weight: 400;
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.storageInfo {
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 8px;
  margin-top: 16px;
  margin-left: 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  font-family: $sf_pro;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #7D7D7D;
}

.wsSubTitle {
  font-size: 12px;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #7D7D7D;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wsTitle {
  font-size: 12px;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 16px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #7D7D7D;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title > div {
  display: none;
}

.rule {
  position: relative;
  width: calc(100% + 8px);
  left: -4px;
  margin: 24px 0 8px 0;
  border-top: 1px solid var(--ruleColor);
}

.list {
  padding: 0;
  list-style: none;
  margin: 0 8px;
}

.menuLogout {
  width: 100%;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  margin-bottom: 0px;
}

.menuLogout svg {
  color: var(--iconColor);
}

.logoutItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
}

.logoutItemColapsed {
  justify-content: center !important;
  margin: 0 auto !important;
  width: 30px !important;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .title > div {
    display: block;
    margin-right: 20px;
  }
}
