.spaceSidebar {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sidebarInput {
  border: none;
  background-color: transparent;
  color: var(--textColor, #000);
  font-family: 'IBM Plex Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}

.sidebarInput button {
  cursor: pointer;
}

.publishBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.publishBtnCenter {
  justify-content: center;
}

.editContent {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.editSidebar {
  display: flex;
  flex-direction: column;

  gap: 12px;
}

/// sidebarButton

.sidebarButton {
  display: flex;
  width: 100%;
  height: 57px;
  padding: 8px 16px 8px 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border: .5px solid #a5a5a5;
  background: var(--modalBG);
  color: var(--textColor);
  font-family: 'IBM Plex Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
}

.sidebarButton:disabled {
  background: var(--textColorSecond);
}

.create {
  justify-content: center;
}

.sidebarButtonAlignCenter {
  justify-content: center;
}

/// 

/// publishInfo

.publishInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.publishInfoText {
  color: var(--textColor);
  font-family: 'IBM Plex Mono';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
}

.publishInfo h3 {
  margin: 0;
  font-weight: 600;
}

.publishInfo p {
  margin: 0;
}

/// 