@import 'static/assets/styles/global/global-varibles';

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f6f8fc;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.content {
  font-family: $sf_pro;
  display: flex;
  flex-flow: column nowrap;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 400;
  font-size: 24px;
  line-height: 23px;
  color: #7e8a9f;
  margin-top: 0;
  margin-bottom: 8px;
}

.text {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #7e8a9f;
  margin-top: 0;
  margin-bottom: 10px;
}
