@import 'static/assets/styles/global/global-varibles';
@import 'static/assets/styles/global/global-varibles';

.infoOption {
  & > svg {
    width: 14px;
    height: 14px;
  }
}

.optionHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 14px 0;
  color: var(--textColor);

  &Text {
    font-family: $sf_pro;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 0 6px;
  }
}

.optionHeader > svg {
  color: var(--iconColorPrime);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #fff;
  margin: 0 0 16px 0;
}

.option {

  &Body {
    padding: 16px;
    border-radius: 20px;
    background-color: #111111;
    border: 0.5px solid #5E5E5E4D
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 14px;

  &Field {
    display: inline-flex;
    align-items: center;
    background: var(--inputBg);
    padding: 12px 15px;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    &__loader{
      margin: 0 auto;
    }
    svg {
      path {
        fill: var(--iconColor);
      }
    }
  }

  &Address {
    margin: 0;
    font-size: 10px;
    line-height: 10px;
    color: #0f73ef;
    margin-right: 14px;
  }
}

//

.info {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;

  &Title {
    font-size: 12px;
    margin: 0;
    line-height: 24px;
    text-align: center;
    color:#FFFFFF70;
    ;
  }

  &Value {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;

    &Copy {
      color: var(--textColorSecond);
      margin-left: 10px;
    }

    &Link {
      font-family: 'Nunito';
      font-size: 12px;
      font-weight: 400;
      line-height: 16.37px;
      text-align: center;
      line-height: 24px;
      color: #F5E12D;
    }

    &Network {
      font-size: 11px;
      line-height: 11px;
      color: #fff;
      margin-right: 6px;
      margin: 0 6px 0 0;

      & + svg {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
}

.description {
  &Title {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 24px;
    color: var(--textColor);
    margin: 0;
  }

  &Value {
    font-size: 11px;
    line-height: 150%;
    margin: 0;
    color: var(--textColorSecond);
    overflow: hidden;
  }

  &More {
    cursor: pointer;
    color: #0f73ef;
    margin-left: 4px;
  }
}

// MINT Styles

.mintForm {
  &Content {
    margin-top: 12px;
  }

  &Header {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex-flow: row nowrap;
    color: var(--textColor);
    margin-bottom: 8px;
    & > h4 {
      margin: 0;
    }
  }

  &Row {
    display: flex;
    margin-top: 8px;
  }

  &Add {
    margin-left: 10px;
    cursor: pointer;

    & > svg {
      width: 12px;
      height: 12px;

      & > path {
        fill: #0f73ef;
      }
    }
  }

  &Input {
    height: 34px;

    & > input {
      font-size: 10px;
      line-height: 24px;
      height: 36px;
      padding: 0 10px;
      margin-bottom: 0;
      -webkit-appearance: none;
      background-color: transparent;
      border-radius: 8px;
    }

    &Address {
      width: 100%;
    }

    &Count {
      margin-left: 10px;
      width: 60px;

      & input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &Submit {
    margin-top: 24px;
    width: 100%;
    height: 34px;
    padding: 0;
    min-height: 34px;
    font-family: $sf_pro;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    background-color: transparent !important;
    border-radius: 8px;
    border: 0.8px solid #FFFFFF;
    color: #fff !important;
  }

  &Submit:disabled {
    color: #FFFFFF33 !important;
    border: 0.8px solid #FFFFFF80 !important;
  }
}

// holder Styles

.holder {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &List {
    display: flex;
    flex-flow: column nowrap;
    max-height: 350px;
    overflow-y: auto;
  }

  &Address {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4px;
    align-self: center;
    font-size: 10px;
    line-height: 10px;
    color: #0f73ef;
  }

  &Copy {
    align-self: center;
    margin-left: 8px;
    cursor: pointer;

    & > svg {
      color: var(--iconColor);
    }
  }
}
