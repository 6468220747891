.wrapper {
  width: 100%;
  background-color: var(--tooltipContentSecond);
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px 20px;
  .filterCard {
    margin: 0 auto;
    cursor: pointer;
    min-width: 73px;
    min-height: 73px;
    max-width: 73px;
    max-height: 73px;
    padding: 3px;
    border-radius: 8px;
    img {
      border-radius: 8px;
      width: 100%;
    }
    &:hover {
      filter: blur(0.5px);
    }
    &_clearFilter {
      position: relative;
      background-color: rgba(0, 0, 0, 0.403);
      &::after,
      &::before {
        content: '';
        position: absolute;
        display: block;
      }
      &::before {
        width: 35px;
        height: 35px;
        top: 50%;
        left: 50%;
        border-radius: 100%;
        border: 2px solid white;
        transform: translate(-50%, -50%);
      }

      &::after {
        width: 2px;
        height: 35px;
        top: 50%;
        left: 50%;
        background-color: white;
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
    &_selected {
      border: 1px solid var(--primeColor);
      box-shadow: var(--boxShadow);
    }
  }
}
@media (max-width: 1121px) {
  .wrapper {
    margin-bottom: 10px;
  }
}
@media (max-width: 570px) {
  .wrapper {
    margin-bottom: 20px;
  }
}
