@import 'static/assets/styles/global/global-varibles';

.datePicker {
  position: relative;
  z-index: 100;
}

.pickerIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: #fff;
  z-index: -1;
}

.item {
  border: 1px solid #efefef;
  padding: 16px 24px 17px;
  overflow-x: hidden;
}

.header {
  display: flex;
}

.action {
  font-family: $sf_pro;
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
}

.details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about {
  display: flex;
}

.time {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #7c7e88;
}

.edit {
  color: #007aff;
  margin-left: 8px;
  cursor: pointer;
}

.info {
  display: flex;
  gap: 8px;
  margin-left: 30px;
}

.infoIcon {
  display: flex;
  align-items: center;
}

.infoItem {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: 50%;
  height: 23px;
  background: #f6f8fc;
  border-radius: 3px;
}

.infoName {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #1a1a1a;
  margin-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewStatus {
  font-family: $sf_pro;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #007aff;
  text-decoration: underline;
}

.canceled {
  color: #007aff;
}

.logsWrapper {
  width: 346px;
  border: 0.5px solid #5e5e5e4d;
  border-radius: 20px;
  padding: 16px;
  background-color: #111111;
  height: 100%;

  &__title {
    color: #fff;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 0 0 16px;
  }
  &__content {
    margin: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    gap: 24px;
    height: 85%;
    overflow: auto;
  }
}

.datePickerInput {
  border-radius: 8px;
  border: 0.5px solid #FFFFFF66;
  background: transparent;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  padding: 10px 16px;
  color: #fff;
  caret-color: #fff;

  &::placeholder {
    color: #fff;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

.logItem {
  padding: 0 16px 10px;
  width: 100%;
  border-bottom: 0.5px solid #FFFFFF4D;

  &__username {
    color: #fff;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: 'Nunito';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    max-width: 167px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  &__file {
    color: #fff;
    font-family: 'SF Pro Text';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__date {
      color: #fff;
      font-family: 'Nunito';
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      opacity: 0.7;
    }
    &__action {
      color: #FFFFFF80;
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
}
