@import 'static/assets/styles/global/global-varibles';

.fullScreenModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1a1919;

  display: flex;
  justify-content: center;
  align-items: center;
}

.exit {
  position: absolute;
  top: 22px;
  right: 22px;

  cursor: pointer;
  color: #ffffff;
}

.logo {
  color: #ffffff;
  position: absolute;
  cursor: pointer;
  top: 22px;
  left: 22px;
}

.modeSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 70px;
  height: 30px;

  border: 1px solid #ffffff;
  border-radius: 15px;
  box-sizing: border-box;
}

.selector {
  flex: 1;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  &.active_left {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background: rgba(255, 255, 255, 0.8);
  }
  &.active_right {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background: rgba(255, 255, 255, 0.8);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view {
  margin-top: 20px;

  &_image {
    width: 297px;
    max-height: 297px;
    object-fit: cover;

    border: 0.5px dashed var(--dashedDividerBorder);
    &_container {
      position: relative;
    }
  }

  &_upload_icon_container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    cursor: pointer;

    border: 1px solid #fff;
    border-radius: 50%;
    :hover {
      opacity: 0.5;
    }
  }

  &_title {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;

    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    margin-bottom: 19px;
    margin-top: 0;
  }

  &_description {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #d6e2f5;
    margin-bottom: 28px;
    margin-top: 0;
  }

  &_button {
    width: 150px;
  }

  &_corner {
    &_left {
      position: absolute;
      left: -1px;
      top: -1px;
    }
    &_right {
      position: absolute;
      right: -1px;
      top: -1px;
    }
    &_bottom {
      position: absolute;
      left: -1px;
      bottom: -1px;
    }
    &_top {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    width: 340px;
    height: 514px;
    padding: 21px;
    border: 0.5px dashed #454545;
  }

  &_info {
    display: flex;
    flex-direction: column;
    margin-bottom: 37px;
  }
}

.save {
  width: 140px;
  height: 30px;
  background: #ffffff;

  font-family: $sf_pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 118%;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.rotate {
  margin-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}

.bordered {
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  width: 80%;

  &_crypto_price {
    font-family: $sf_pro;
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
    color: #C1C9D9;
  }

  &_left_top {
    position: absolute;
    top: -7px;
    left: -7px;
    color: var(--iconColor, #ffffff);
  }

  &_left_bottom {
    position: absolute;
    bottom: -7px;
    left: -7px;
    transform: rotate(180deg);
    color: var(--iconColor, #ffffff);
  }

  &_right_top {
    position: absolute;
    top: -7px;
    right: -7px;
    color: var(--iconColor, #ffffff);
  }

  &_right_bottom {
    position: absolute;
    bottom: -7px;
    right: -7px;
    transform: rotate(90deg);
    color: var(--iconColor, #ffffff);
  }

  &_typography {
    width: 100%;
    height: 30px;
    background: #000000;
    cursor: pointer;

    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
    &:hover {
      background: #b9b9b9 !important;
    }
  }

  &_dark {

    & > svg {
      color: white;
    }

    & > button {
      background-color: #FFFFFF !important;
      color: #000000;

      & > span {
        color: #4B576C;
      }
    }
  }
  //
  //&_dark {
  //  & > svg > path {
  //    stroke: #000 !important;
  //  }
  //}
  //&_white {
  //  & > svg > path {
  //    stroke: #fff;
  //  }
  //}
}

.dark_svg > path {
  stroke: #000 !important;
}

.white_mode {
  &_typo_color {
    color: #1a1a1a !important;
  }
  &_background {
    background: #ffffff !important;
  }
}

.report {
  position: absolute;
  right: 37px;
  bottom: 30px;
  cursor: pointer;
  &_text {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #d6e2f5;
  }

  &_modal {
    &_container {
      width: 575px;
      background: #ffffff;
      padding: 34px 80px;

      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }
    &_select {
      width: 100%;
      min-width: 100%;
    }
    &_title {
      font-family: $sf_pro, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #1a1a1c;
      margin: 0;
    }
    &_subtitle {
      font-family: $sf_pro, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #7e8a9f;
      margin: 20px 0;
    }
    &_button {
      &_error {
        background: darkred;
      }

      width: 132px;
      height: 48px;
      background: #1a1a1a;

      font-family: $sf_pro, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;

      cursor: pointer;
    }
    &_label {
      font-family: $sf_pro, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      margin: 4px 0;
    }
    &_textarea {
      height: 100px;
      width: 408px;
      background: #ffffff;
      border: 1px solid #d6e2f5;
      margin-bottom: 20px;
      resize: none;
      padding: 10px;
    }
  }
}

.back {
  &_content {
    height: 400px;
  }
  &_container {
    width: 340px;
    height: 514px;
    padding: 4px 24px;
    margin-top: 20px;

    background: #ffffff;
    border: 0.5px dashed #454545;

    &_dark {
      background: #1A1919;
    }
  }
  &_description {
    width: 292px;
    min-height: 100px;
    max-height: 169px;
    padding: 10px 14px;
    background: #f5f5f5;

    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 118%;
    color: #4b576c;
  }
}

.back_dark_color {
  color: white !important;
}

.back_dark_input {
  background-color: #232323 !important;
  color: #FFFFFF !important;
}

.chart_container_back {
  background-color: #303030 !important;

  & svg, h3, p {
    color: white !important;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  & > svg {
    width: 20px;
    height: 20px;
  }
  &_title {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #202124;
    margin: 0;
    padding-left: 11px;
  }
  &_subtitle {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 118%;
    color: #4b576c;
  }
  &_value {
    text-align: center;
    background: #f5f5f5;
    padding: 8px 12px;
    &_active {
      color: #0f73ef;
    }
  }
  &_block {
    margin: 2px 4px;
    min-width: 90px;
  }
}

.blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.chart {
  &_container {
    width: 292px;
    height: 180px;
    background: #f5f5f5;
    padding: 55px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
}

.delimiter {
  width: 100%;
  height: 0.5px;
  border: 0.5px dashed #1a1a1a;
}

.delimiter_dark {
  border-color: #454545;
}

.empty {
  &_title {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #202124;
    margin: 0;
  }

  &_desc {
    font-family: $sf_pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 118%;
    color: #000000;
    text-align: center;
    margin: 0;
  }
}

.button_loader {
  & > div > div {
    background-color: white !important;
  }
}

.spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  div {
    background-color: var(--baseColor);
  }
}
