@import 'static/assets/styles/global/global-varibles';

.basicModal {
  background: rgba(#10161a, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
}

.wrapper {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  max-width: 575px;
  max-height: 552px;
  left: 50%;
  top: calc((100vh - 552px) / 2);
  margin-left: -287px;
}

.modalContent {
  display: block;
}

.head {
  min-height: 48px;
  padding: 0 16px;
  margin: 0;
}

// KEY Popup START
.keyPopup {
  max-width: 575px;
  width: 100%;

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 20px;
    box-sizing: border-box;
    overflow: auto;
  }
  &__preview {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
  &__header {
    font-family: $sf_pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #000000;

    &_deactivate {
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #0f73ef;
      margin-top: 0;
      font-style: normal;
      font-weight: normal;
    }
  }
  &__text {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $medium_grey;

    &_deactivate {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #4c516d;
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    margin-bottom: 79px;

    & > button {
      font-weight: 400;
    }

    &_deactivate {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 37px;

      .disabled_btn {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        cursor: not-allowed;
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 43px;
  }
  &__list_item {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 25px;
    margin-left: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item_text {
    margin-left: 20px;
    width: 302px;

    & > h2 {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $colBlack;
      margin: 0;
    }
    & > p {
      font-family: $sf_pro;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $text_grey;
    }
  }
  &__selector {
    margin: 0 37px;
  }

  &__warning {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #faaa1e;
      margin-left: 5px;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_key {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;

      & > svg {
        position: absolute;
        width: 26px;
        height: 26px;
        color: #7e8a9f;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100%;
        position: absolute;
        border: 1px solid #7e8a9f;
      }
    }
    &_title {
      margin-top: 30px;
      font-family: Gotham Pro;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
    &_action {
      margin-bottom: 40px;
    }
  }
}
// Key Popup END

// Steps START
.keyPopup__step_one {
  .keyPopup__preview {
    margin-top: 38px;
  }
  .keyPopup__header {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .keyPopup__text {
    margin: 0;
  }
  .keyPopup__actions {
    margin-top: 30px;
    margin-bottom: 64px;
  }
}

.keyPopup__step_two {
  .key-popup__header {
    margin-top: 0;
  }
  .keyPopup__actions {
    margin-top: 45px;
    margin-bottom: 36px;

    & > button {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      color: #ffffff;
      width: 266px;
      height: 48px;
    }
  }
}

.keyPopup__step_three {
  .keyPopup__preview {
    margin-top: 38px;
  }
  .keyPopup__header {
    margin-top: 25px;
    margin-bottom: 13px;
  }
  .keyPopup__actions {
    margin-bottom: 59px;
  }
}

.keyPopup__four {
  .keyPopup__header {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .keyPopup__text {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .keyPopup__actions {
    margin: 18px 0 8px 0;

    .disabled_btn {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      color: #0f73ef;
      border: 1px solid #0f73ef;
      background-color: transparent;
      cursor: not-allowed;
    }
  }
}
// Steps END

// Key Tab START
.keyTab {
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
  }

  &__icon {
    position: relative;
    width: 60px;
    height: 60px;
    margin-bottom: 40px;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid #e5e7ec;
      position: absolute;

      border-radius: 100%;
    }
    & > svg {
      position: absolute;
      width: 22px;
      height: 22px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #7e8a9f;
    }
  }

  &__selector {
    width: 100%;
    padding: 0 27px 0 28px;

    input {
      border: 1px solid #d6e2f5;
    }
  }
  &__header {
    font-family: $sf_pro;
    margin: 0 0 20px 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: $colBlack;
  }
  &__sub-header {
    font-family: $sf_pro;
    margin: 0 0 70px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $text_grey;
  }

  &__description {
    font-family: $sf_pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: $light_grey;
    opacity: 0.65;
    margin-bottom: 0;
    margin-top: 12px;
  }
  &__btn-disabled {
    font-family: $sf_pro;
    background-color: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.4px;
    color: #7e8a9f;
    border: none;
  }
  &__actions {
    margin-top: 20px;
  }
}
// Key Tab END

@media (max-width: 480px) {
  .wrapper {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
    width: calc(100% - 30px);
  }
}