.account-plan-confirm {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 70px;
  padding: 0 15px;
  transform: translateY(-100%);
  box-shadow: inset 0 1px 0 #ccceda;
  background-color: #ffffff;

  &__price-desc {
    margin-right: 8px;
  }

  &__action-button {
    width: initial;
    height: initial;
    padding: 10px 30px;
  }
}
