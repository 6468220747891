.wrapper {
  max-width: 490px;
  padding: 20px 40px 40px 40px;
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #10161a;
}

.input {
  margin-bottom: 14px;
}

.button {
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 14px;
}

@media screen and (min-width: 900px) {
  .title {
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (min-width: 1900px) {
  .title {
    font-size: 24px;
  }
}
