.user {
  &-icon {
    model-viewer {
      width: 30px;
      height: 30px;
    }
    height: 32px;
    width: 32px;
    background-color: $primary_light;
    border-radius: 50%;
    border: 1px solid $primary_light;
    margin: 0 -3px 0 0;
    color: $primary_light;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: $sf_pro;
    font-size: 12px;
    svg:nth-child(1) {
      width: 20px;
      height: 20px;
    }
    &-letter {
      padding: 8px 9px 6px 9px;
    }

    &.blue {
      background-color: $color_blue;
    }
    &.green {
      background-color: $color_green;
    }
    &.orange {
      background-color: $color_orange;
    }
    &.purple {
      background-color: $color_purple;
    }
    &.red {
      background-color: $color_red;
    }

    &-container {
      display: flex;
      align-items: center;
      padding: 14px 0 0 0;

      .more-users {
        height: 28px;
        width: 28px;
        background-color: $more_icon_color;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid $primary_light;
        margin: 0 0 0 -4px;
        color: $primary_light;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
      }
    }

    &-image {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
      font-size: 14px;
      line-height: 13px;
    }
  }

  &-info {
    &-item {
      position: relative;
      width: 32px;

      & span {
        margin-top: 0.2em;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      &:not(:last-child) {
        margin: 0 -8px 0 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-popup {
      position: absolute;
      top: 41px;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: $primary_light;
      padding: 0 10px;
      border: 0.5px solid $context_border_color;
      box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.08);
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        top: -8px;
        right: 10px;
        display: block;
        z-index: 1;
        width: 0;
        height: 0;
        border-bottom: 8px solid $primary_light;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }

      &-bottom {
        &:before {
          top: auto;
          bottom: -8px;
          border-top: 8px solid $primary_light;
          border-bottom: none;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //width: max-content;
        padding: 7px 0;
        width: 100%;

        .user-icon {
          margin: 0 8px 0 0;
        }
      }
    }

    &-name {
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 24px;
      color: $primary_dark;
      white-space: nowrap;
    }

    &-email {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $primary_color;
    }

    &-rights {
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 24px;
      color: $fifty_color;
      margin: 0 0 0 3px;
      text-align: right;
      white-space: nowrap;
    }
  }

  &-popup {
    &-position {
      &-container {
        position: relative;
      }
    }
  }

  &-menu {
    width: 40px;
    height: 40px;
    margin: auto auto 14px auto;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &-icon {
      width: 40px;
      height: 40px;
    }

    &-popup {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 44px;
      bottom: 0;
      min-width: 250px;
      background: $primary_light;
      box-shadow: 0 2px 8px $forty_color;
      padding: 0 0 8px 0;
      z-index: 4;

      &-title {
        padding: 23px 16px 22px 19px;
        height: 60px;
        font-family: $sf_pro;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: $primary_black;
        text-align: left;
      }

      &-item {
        padding: 0 16px 0 19px;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $sf_pro;
        font-size: 16px;
        line-height: 24px;
        color: $text_grey;
        cursor: pointer;
        width: 100%;

        &:hover {
          background-color: $active_comment;
        }

        .button {
          padding: 5px 20px;
        }
      }

      &-divider {
        width: 100%;
        border-top: 0.5px solid $context_border_color;
        margin: 0 0 10px 0;
      }
    }
  }
}

.users {
  &-container {
    display: flex;
    padding: 5px 0 0 0;

    .user-icon {
      width: 32px;
      height: 32px;
    }
  }
}
