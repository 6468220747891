.overlay {
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1005;
}
.modal {
  display: block;
}

.formWrapper,
.resetLinkWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 462px;
  height: 281px;
  border-radius: 23px;
  border: 0.7px solid rgba(255, 255, 255, 0.4);
  padding: 40px 31px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7.199999809265137px);
}

.forgotPasswordWrapper {
  padding-top: 35px;
}

.passwordRecoveryWrapper {
  padding: 47px 31px 40px;
}

.resetLinkWrapper {
  padding: 56px 53px 73px;

  & h2 {
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Nunito';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
  }

  & p {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 16px 0 0;
    & span {
      cursor: pointer;
      text-decoration-line: underline;
      transition: color 200ms ease-in-out;
      &:hover {
        color: #fff;
      }
    }
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 31px;
}

.relative {
  position: relative;
}

div.input {
  & input {
    padding: 0;
    margin: 0;
    height: auto;
    padding: 8px 16px 8px;
    color: #fff;
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    font-style: normal;
    height: 40px;

    width: 100%;
    border-radius: 12px;
    border: 0.7px solid rgba(255, 255, 255, 0.6);

    background-color: transparent !important;
    &:focus {
      border: 0.7px solid #f5e12d;
    }
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 300;
  }
}

.inputError {
  & input {
    border-color: #ea0404;
  }
}

.errorMessage {
  position: absolute;
  bottom: -20px;
  left: 16px;
  color: #ea0404;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.passwordError {
  bottom: -26px;
  line-height: 90%;
}

.passwordInput {
  & button {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.6);
  }
}

.forgotPassword {
  margin: 18px 0 11px;
  & a {
    text-align: right;
    cursor: pointer;
    margin: 0;
    display: block;
    width: fit-content;
    margin-left: auto;
    text-decoration: none;
    color: #fff;
    font-family: 'Nunito';
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    &:hover {
      color: #ffe24a;
    }
  }
}

.login,
.resetBtn,
.continueBtn,
.createPasswordBtn {
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
  border-radius: 14px;
  border: 1px solid #373636;

  color: #fff;
  font-family: 'Nunito';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  transition: all 200ms linear;

  &:hover {
    border: 1px solid #f5e12d;
    color: #f5e12d;
  }
}

.resetBtn {
  margin-top: 19px;
}

.continueBtn {
  margin-top: 25px;
}

.createPasswordBtn {
  margin-top: 36px;
  &:disabled {
    border: 1px solid #373636;
    background-color: transparent;
    color: #fff;
  }
}

.forgotPasswordTitle,
.verificationCodeTitle {
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  & h2 {
    color: #fff;
    text-align: center;
    font-family: 'Nunito';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    padding: 0;
    margin: 0;
  }

  & p {
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    font-family: 'Nunito';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;

    .resentCode {
      cursor: pointer;
      text-decoration-line: underline;
    }

    .resentCodeDisabled {
      opacity: 0.5;
    }
  }
}

.verificationCodeTitle {
  padding: 0 8px;

  & h2 {
    font-size: 21px;
    font-weight: 500;
    line-height: 29px;
  }
}

@media screen and (max-width: 730px) {
  .formWrapper {
    max-width: 70vw;
  }
}
