.main__header {
  width: 100%;
  max-width: inherit;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  grid-area: header;
  z-index: 1001;

  &_technical {
    top: 20px;
  }

  &__action__btns {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    width: 180px;

    & svg {
      position: relative;
    }
  }

  &__links {
    margin: 0 16px;
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    align-self: stretch;
    & > a {
      display: flex;
      align-items: center;
      height: 100%;
      color: #7c7e88;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      text-decoration: none;
      position: relative;

      &:not(:first-child) {
        margin-left: 24px;
      }

      &.active {
        color: #10161a;

        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #206acf;
        }
      }
    }
  }

  &__main {
    cursor: pointer;
    &-folder {
      color: $primary_color;
      line-height: 1;
      letter-spacing: rem(1.4px);
    }
    &-is-folder {
      color: $primary_color;
    }
  }

  &__arrow {
    background: url('/static/assets/img/icon-arrow.svg') no-repeat center center;
    width: 7px;
    margin: 0 6px 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__sub-folder {
    color: $primary_dark;
    letter-spacing: rem(1.4px);
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 31px;
    width: 100%;
    height: 49px;
    justify-content: space-between;
    margin-top: 8px;

    &-bottom {
      height: 52px;
    }

    .view__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 0 auto;
      .view__btn {
        cursor: pointer;
        transition: all 0.3s;
        margin: 0 15px 0 0;

        &.btn--list {
          width: 18px;
          height: 18px;
        }
        &.btn--block {
          width: 18px;
          height: 18px;
        }
        &.btn--small-row {
          width: 18px;
          height: 18px;
        }
        &.active {
        }

        svg {
        }
      }
    }

    &-divider {
      height: 25px;
      margin: 0 34px;
      border-left: 1px solid $preview_container;
    }

    &-account {
      justify-content: center;
      margin: 0;
    }

    &__settings {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__sidebar {
      display: none;
    }

  }

  &__info {
    cursor: pointer;

    &.active {
      cursor: pointer;
    }

    &-right {
      margin: 0 15px 0 auto;
    }
  }

  &-add-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60px;
  }

  &__btn {
    position: relative;
    cursor: pointer;
    margin: 0 8px;
    padding: 0;
    justify-content: center;
    transition: transform 0.5s linear;
    &-right {
      margin: 0 0 0 auto;
    }

    &__upload {
      margin: 0 8px;
      cursor: pointer;
    }

    &__member {
      margin: 0 8px;
      cursor: pointer;
    }
  }

  &__divider {
    width: 100%;
    border-top: 1px solid var(--dividingLine);
    margin: 0;
  }

  &__settings_divider {
    width: 100%;
    border-bottom: 1px solid var(--dividingLine);
    margin: 0;
  }
}

.main__header__action__btns .main__header__btn__tokenize svg {
  width: 22px;
  height: 20px;
}

@media (max-width: 1340px) {
  .main__header_technical {
    top: 31px;
  }
}

@media (max-width: 1024px) {
  .hide-is-tablet-selected-entity {
    display: none;
  }
  .main__header__action__btns {
    justify-content: center;
    width: fit-content;
  }
}

@media (max-width: 680px) {
  .main__header_technical {
    top: 45px;
  }
}

@media (max-width: 460px) {
  .main__header_technical {
    top: 57px;
  }
}

@media screen and (max-width: 480px) {
  .main__header__btn.main__header__hidden {
    display: none;
  }
  .main__header__action__btns {
    width: 144px;
    button:nth-child(5) {
      display: none;
    }
  }

  .main__header__actions__sidebar {
    display: flex;
  }

  .search__field {
    margin-left: 5px;
  }

  .main__header__actions {
    padding-left: 16px;
    padding-right: 16px;
  }
  .main__header__actions__settings > div:last-child > div > button {
    padding-right: 0;
  }
}
