.siteSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.settingsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-height: calc(100% - 56px);
  overflow-y: auto;
  padding-right: 5px;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.settingsList::-webkit-scrollbar {
  width: 1px;
}

.settingsList::-webkit-scrollbar-track {
  padding-left: 3px;
}

.settingsContainer {
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--mainContainerColor);
}

.settingsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 2px;
}
