.createContainer {
  width: 80%;
}

.text {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--textColor);
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.footer p {
  margin: 0;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--textColor);
}