.account-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccceda;
  border-bottom: 1px solid #ccceda;
  padding: 0 17px;
  min-height: 60px;

  background: #f6f8fc;

  &__action {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  &__action-text {
    color: #206acf;
  }

  &__action-icon {
    margin-left: 7px;
    font-size: 0;
  }
}
