.navBtn {
  height: 30px;
  height: 30px;
  color: var(--textColor);
  cursor: pointer;
}

.navBtn > svg {
  height: 100%;
}

.productList {
  width: 166px;
}

.productItem {
  display: inline-flex;
  cursor: pointer;
  height: 45px;
  width: 100%;
  margin: 1px 0 0 0;
  align-items: center;
  gap: 16px;
  background-color: var(--profileCard);
  color: var(--textColor);
  border-radius: 8px;
  padding: 8px 18px 8px 10px;
}

.productItem:first-child {
  margin-top: 0;
}

.productItemIcon {
  width: 30px;
  height: 30px;
}

.productItemIcon > svg {
  width: 100%;
  height: 100%;
}

.productItemText {
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: 400;
  line-height: 27.28px;
  text-align: left;
  margin: 0;
}

.tooltipOverlay {
  background-color: transparent !important;
}

.tooltipOverlay > div > div {
  background-color: transparent !important;
  border: none;
  padding: 0;
}

.productItem svg path {
  transition: all .5s;
}

.productItem:hover {
  background-color: var(--tooltipBackground);

  & svg path {
    stroke: var(--iconColor);
    stroke-width: 0.8px;
  }
}