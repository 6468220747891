@import 'static/assets/styles/global/global-varibles';

.datePicker {
  position: relative;
}

.datePicker {
  position: relative;
}

.pickerIcon {
  position: absolute;
  top: 30px;
  right: 38px;
  color: var(--inputEye);
}

.item {
  border: 1px solid #efefef;
  padding: 16px 24px 17px;
  overflow-x: hidden;
}

.header {
  display: flex;
}

.action {
  font-family: $sf_pro;
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--textColor);
}

.details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about {
  display: flex;
}

.time {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #7C7E88;
}

.edit {
  color: #007AFF;
  margin-left: 8px;
  cursor: pointer;
}

.info {
  display: flex;
  gap: 8px;
  margin-left: 30px;
}

.infoIcon {
  display: flex;
  align-items: center;
}

.infoItem {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  max-width: 50%;
  height: 23px;
  background: #F6F8FC;
  border-radius: 3px;
}

.infoName {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #1A1A1A;
  margin-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewStatus {
  font-family: $sf_pro;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #007AFF;
  text-decoration: underline;
}

.canceled {
  color: #007AFF;
}