.option-selector {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 14px;
  border-left: 1px solid $preview_container;
  padding: 0 0 0 11px;
  margin: 0 0 7px auto;

  &-selected {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $dark_grey;
    margin: 0 16px 0 0;

    &.active {
      color: $primary_color;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      //width: 132px;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 7px 0;

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: $primary_color;
        }
      }
    }

    &:hover & {
      &-name {
        color: $primary_color;
      }
    }

    &-container {
      position: absolute;
      top: 35px;
      right: -12px;
      width: 144px;
      padding: 10px 0 12px 14px;
      background: #f2f4f8;
      border: 0.5px solid #e4e7ee;
      box-shadow: 0 2px 8px #ccceda;
      z-index: 100;
    }

    &-name {
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 16px;
      color: $dark_grey;
      margin: 0 0 0 10px;

      &.active {
        color: $primary_color;
      }
    }
  }

  .expanded {
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: $dark_grey transparent;
    display: block;
    width: 0;

    &.active {
      border-color: $primary_color transparent;
    }
  }

  .collapsed {
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: $dark_grey transparent;
    display: block;
    width: 0;
    z-index: 1;

    &.active {
      border-color: $primary_color transparent;
    }
  }

  &-portal {
  }

  &-position-container {
    position: relative;
  }
}
