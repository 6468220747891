.sidebar {
  width: 419px;
  height: 100%;
  padding: 24px 24px 73px 9px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
}

.noBackground {
  background-color: transparent;
}

.blockTitle {
  margin: 0;
  color: #fff;
  font-family: 'Nunito';
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blockSubTitle {
  margin: 0;
  color: #fff;
  font-family: 'Nunito';
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 25px;
}

.blocks {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.block {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 11px;
    left: -36px;
    width: 3px;
    height: 3px;
    background-color: #fff;
    transform: rotate(45deg);
  }
}

.title {
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  text-transform: uppercase;
}
.text {
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.buttonBlock {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -36px;
    width: 3px;
    height: 3px;
    background-color: #07fea5;
    transform: rotate(45deg);
  }
}

.button {
  position: relative;
  width: 100%;
  padding: 20px 0 20px 40px;
  border-radius: 20px;
  border: 0.5px solid #5e5e5e;
  color: #fff;
  font-family: 'Nunito';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  &:hover {
    border-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    right: -2px;
    top: 50%;
    width: 3px;
    height: 3px;
    background-color: #fff;
    transform: translateY(-50%) rotate(45deg);
  }
}

.setupBios {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  padding: 20px 0 20px 40px;
  border-radius: 20px;
  border: 0.5px solid #5e5e5e;
  color: #fff;
  font-family: 'Nunito';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  &:hover {
    border-color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    left: -2px;
    top: 50%;
    width: 3px;
    height: 3px;
    background-color: #fff;
    transform: translateY(-50%) rotate(45deg);
  }
}

.additionalBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.actionBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.disabledIcon {
  opacity: 0.6;
}

.butButton {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  color: #fff;
  font-family: 'Nunito';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
