.inputWrapper {
  display: flex;
  gap: 12px;
  padding: 11px;
  border: 0.5px solid #FFFFFF;
  border-radius: 20px;
}

.inputIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  height: 48px;
  border: 0.5px solid #FFFFFF;
  border-radius: 14px;
}

.input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  caret-color: #F5E12D;
  color: #FFFFFF;
}

.input::placeholder {
  color: white;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover, 
.input:-webkit-autofill:focus, 
.input:-webkit-autofill:active{
  -webkit-text-fill-color: white !important; 
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}