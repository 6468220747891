.map-activity {
  &-block {
    position: relative;

    .view-all {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      line-height: 24px;
      color: $primary_color;
      text-decoration: none;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $primary_light;
    //height: 395px;
    padding: 34px 82px 34px 42px;
  }

  &__header {
    width: 100%;
    font-family: $sf_pro;
    font-size: 18px;
    line-height: 21px;
  }

  &__statistic {
    display: flex;
    flex-direction: column;

    &-item {
      &-ring {
        width: 16px;
        height: 16px;
        border: 4px solid $light_blue;
        border-radius: 50%;
        box-sizing: border-box;
      }

      &-title {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 16px;
        color: $fifty_color;
      }

      &-data {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 24px;
        color: $primary_dark;
      }
    }
  }

  &__map {
    width: 80%;
  }
}
