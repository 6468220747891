.payButton {
  width: 100%;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 21px;
  border-radius: 20px;
  border: 0.8px solid #fff;
  color: #fff;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: #fff;
  }
}

.payButtonDisabled {
  opacity: .2;
}