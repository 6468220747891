@media screen and (max-width: 640px) {
  .auth.sign-in {
    .auth-right {
      height: auto;
      flex: 1;
    }

    .form__title {
      margin-top: 0;
      margin-bottom: 0;
    }

    .form__title ~ .auth__label {
      padding-bottom: 0;
    }

    .auth__input-errors {
      margin-bottom: 10px;
    }

    .auth-forgot {
      margin-top: 10px;
      font-weight: 400;
    }

    .auth-footer {
      justify-content: flex-start;
    }

    .auth__square {
      margin-top: 40px;
    }
  }
}
