.storage-full-popup {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 424px;
  padding-bottom: 57px;

  text-align: center;

  background: #ffffff;

  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__image {
    margin-top: -14px;
  }

  &__title {
    margin: 24px 0 11px 0;
  }

  &__btn {
    margin-top: 37px;
  }
}

@media screen and (max-width: 450px) {
  .storage-full-popup {
    max-width: 95%;
  }
}
