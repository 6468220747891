@import 'static/assets/styles/global/global-varibles';

.wrapper {
  border: 0.6px solid var(--secondBaseColor);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 6px 10px;
}

.wrapper:hover:not(.active) {
  border-radius: 8px;
  border: var(--sidebarActiveBorderItem);
}

.name {
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #202124;
}

.collapsed .name,
.collapsed .betaMatker,
.collapsed .disableMarker {
  display: none;
}

.collapsed .item {
  height: 30px;
  width: 30px;
  
  justify-content: center;

  padding: 0;
}

.collapsed svg {
  color: var(--sidebarIcon);
}

.active {
  background: var(--sidebarActiveItem);
  border-radius: 8px;
  border: var(--buttonBorder);
}

.collapsed {
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.active path, .active circle  {
  fill: var(--sidebarActiveIcon) !important;
}

.active .name {
  font-weight: 500;
}

.wrapper .name {
  color: var(--iconColor);
}

.icon {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  & path {
    fill: var(--sidebarIcon);
  }
  & circle {
    stroke: var(--sidebarIcon);
  }
}

.stroke path {
  stroke: var(--sidebarIcon)
}

.stroke .active  path {
  stroke: var(--sidebarActiveIcon)
}

.betaMatker {
  font-size: 6px;
  color: #1a1a1a;
  background: #e7f1fd;
  padding: 2px 4px;
  border-radius: 4px;
  line-height: 8px;
  margin-left: 6px;
  margin-bottom: 8px;
}
.disableMarker {
  font-size: 8px;
  color: #000000;
  background: #e7f1fd;
  padding: 2px 4px;
  text-transform: uppercase;
  line-height: 10px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  margin-left: 10px;
}

.notContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.unreadBadge {
  font-family: $sf_pro;
  height: 17px;
  background-color: var(--sidebarMarker);
  color: var(--sidebarSelectWS);
  border-radius: 4px;
  padding: 1px 4px;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: right;
  margin-right: 10px;
}

.notContainerCollapsed {
  width: 30px;
  height: 30px;
  justify-content: center;
  margin: 0 auto;
}

.itemContent {
  display: flex;
  align-items: center;
}

.collapsed .countMarker {
  display: none;
}

.countMarker {
  font-family: $sf_pro;
  height: 17px;
  background-color: var(--sidebarMarker);
  color: var(--sidebarMarkerText);
  border-radius: 4px;
  padding: 1px 4px;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: right;
}

.active .countMarker {
  color: var(--sidebarSelectWS);
}

@media screen and (max-width: 480px) {
  .collapsed .name {
    display: block;
  }
  .collapsed .item {
    justify-content: flex-start;
  }
}
