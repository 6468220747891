* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px inherit;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbarColor);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f5a73;
}

#root {
  height: 100%;
}

.file-security-checker {
  &.security-2fa {
    max-width: 575px !important;
    min-height: 710px !important;
  }

  &.security-pin {
    max-width: 575px !important;
    min-height: 710px !important;
  }

  &.security-voice {
    max-width: 606px !important;
    min-height: 710px !important;
  }

  &.security-key {
    max-width: 575px !important;
    min-height: 710px !important;

    .key-tab__icon > svg {
      left: calc(50% + 30px) !important;
    }
  }
}

.rc-tooltip {
  opacity: 1 !important;
}

// global
@import '~sass-rem/rem'; // importing a css file from the nprogress node module
@import '~react-rangeslider/lib/index.css';
@import 'global/global-varibles';
@import 'global/global-styles';
@import 'global/text';
@import 'global/buttons';
@import 'global/checkboxes';
@import 'global/menu-checkbox';
@import 'global/radiobutton';
@import 'global/icons';
@import 'global/mixins';
@import 'global/animations/index';
@import 'global/modal';
@import 'global/audio-player';
@import 'global/video-player';

// auth
@import 'pages/auth/auth';
@import 'pages/auth/auth-crypto';
@import 'pages/auth/auth-shared';
@import 'pages/auth/sign-in';

//main
@import 'pages/home/home-page';

//landing
@import 'pages/landing/landing-settings';
@import 'pages/landing/landing';
@import 'pages/landing/video-popup';

//components
@import 'components/index';
