@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  background-color: transparent;
}

.geoModal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 575px;
  min-height: 505px;
  padding: 56px 64px;
  text-align: center;
  background: var(--modalContent);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  &__title {
    font-family: $sf_pro;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: var(--textColor);
  }
  &__description {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
    color: $medium_grey;

    margin-top: 0;
    margin-bottom: 16px;
  }

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}
.geoInput {
  margin-top: 5px;
  width: 100%;

  & label {
    text-align: start;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
  & > div > div {
    height: 48px;
  }
}

.geoWrapper {
  padding: 0 20px;
  margin: 0;
  position: relative;
  min-width: 49%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: $sf_pro;
  font-size: 14px;
  line-height: 24px;
  color: var(--textColor);
}

.noLocations {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  margin-top: 10px;

  p {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 150%;
    color: $colGrayMed;
  }
}

.locationList {
  position: relative;
  padding: 0 20px;
  height: fit-content;
  max-height: 166px;
  margin: 10px 0 20px;
  overflow-y: auto;
  h3 {
    margin-bottom: 0;
    margin-top: 0;
    text-align: start;
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 24px;
    color: var(--textColor);
    margin-bottom: 5px;
  }

  ul {
    padding: 0;
    margin-top: 0;
    li {
      display: flex;
      align-items: center;
      padding-right: 14px;
      height: 38px;
      border: 1px solid var(--borderSecond);
      justify-content: space-between;
      list-style-type: none;
      margin-bottom: 9px;

      & > div {
        display: flex;
        align-items: center;
        margin-left: 14px;
        & p {
          color: var(--textColorSecond);
        }
      }

      span {
        display: none;
        cursor: pointer;
      }

      &:hover {
        background-color: var(--sidebarHover);
        p {
          color: var(--textColor);
        }
        span {
          display: block;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin: 0 0 0 10px;
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $dark_grey;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin: auto 0 0 0;
  padding: 0;

  button {
    height: 48px;
    width: 170px;
    padding: 0 30px;
  }
}

@media (max-width: 768px) {
  .geoModal {
    padding: 32px 16px;
    width: calc(100% - 32px);
    max-width: 480px;
    height: fit-content;
    min-height: fit-content;
    &__title {
      margin-bottom: 8px;
      font-size: 16px;
    }
    &__description {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }

  .geoInput {
    margin-top: 5px;
    width: 100%;
    font-size: 10px;

    & label {
      font-size: 12px;
    }
    & > div > div {
      height: 40px;
      font-size: 10px;
    }
  }
  .noLocations {
    p {
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  .geoModal {
    &__title {
      font-size: 20px;
    }
    &__content-notice {
      font-size: 12px;
    }
    .decline,
    .accept {
      font-size: 10px;
    }
  }

  .geoInput {
    & div:nth-child(2) {
      & > :nth-child(1) {
        height: 20px;
      }
    }
    & div {
      max-height: 120px;
    }
    margin-top: 5px;
    width: 100%;
    .menuList {
      height: 10px;
    }
    label {
      text-align: start;
    }
  }
}
