.context_menu {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 210px;
  background: var(--tooltipContentSecond);
  box-shadow: var(--boxShadow);
  box-sizing: border-box;
  z-index: 1025;
  border-radius: 8px;
}

.actionItemPartition {
  border-bottom: 1px solid var(--contextBorder);
}
