@import 'static/assets/styles/global/global-varibles';
.storage {
  &__wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  &__row {
    p {
      margin: 0px;
    }
    display: flex;
    align-items: center;
    padding: 0 23px 0 32px;
    cursor: pointer;
  }

  &__text,
  &__calculationText {
    min-width: 40px;
    text-transform: uppercase;
    font-family: $sf_pro;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: var(--textColor);
  }

  &__space-info {
    &__indicator {
      width: 100%;
      height: 5px;
      margin: 0 9px;
      position: relative;

      &:before {
        content: '';
        background-color: var(--indicatorSpace);
        position: absolute;
        bottom: 0px;
        width: 0px;
        max-width: 100%;
        height: 5px;
      }

      @for $i from 0 through 100 {
        &-#{$i} {
          &:before {
            width: $i * 1%;
          }
        }
      }
    }
  }
}
