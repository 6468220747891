.note-page {
  width: 100%;
  margin: 57px auto 0;
  padding: 0;
  position: relative;
  display: grid;
  grid-template-columns: 292px auto;
  grid-template-rows: 61px auto;
  grid-template-areas:
    'note-menu note-header'
    'note-menu note-content';
  .mobile-arrow-left-wrapper {
    display: none;
  }
  &-file {
    &-name {
      font-family: $sf_pro;
      font-size: 24px;
      line-height: 30px;
      color: $dark_grey;
      border: none;
      outline: none;
      position: absolute;
      top: 60px;
      left: 0;
      z-index: 1;
      width: 100%;

      &::placeholder {
        color: $search_placeholder;
      }
    }
  }

  &-header {
    grid-area: note-header;
    background: $primary_light;
    padding: 11px 35px 14px 35px;
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      display: block;
      width: calc(100% - 70px);
      position: absolute;
      bottom: 0;
    }
    &-fixed {
      //width: 100%;
      //position: fixed;
    }

    &-label {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $fifty_color;
      margin: 0 0 0 9px;
    }

    .file-action {
      margin: 0 20px 0 auto;
      z-index: 10;
    }
  }

  &-menu {
    height: 100vh;
    background-color: $primary_light;
    grid-area: note-menu;

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(80% - 64px);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;
      margin: 0 33px 0 16px;
      border-bottom: 1px solid $preview_container;
    }

    &-title {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: #202124;
      font-weight: 400;
    }

    &-note {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $medium_grey;

      .link {
        display: inline;
        font-family: $sf_pro;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-item {
      &.active {
        background: $primary_light;

        .note-page-menu-item-content-title {
          color: #0f73ef;
        }
      }

      &-list {
        height: calc(100vh - 62px);
        overflow-y: auto;
      }

      &-content {
        display: flex;
        border-bottom: 1px solid $preview_container;
        padding: 12px 12px 12px 7px;
        margin: 0 33px 0 17px;
        cursor: pointer;

        &-info {
          margin: 0 0 0 7px;
        }

        &-title {
          font-family: $sf_pro;
          font-size: 12px;
          line-height: 20px;
          color: $dark_grey;
        }

        &-date {
          font-family: $sf_pro;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $fifty_color;
        }
      }
    }
  }

  .breadcrumb {
    margin: 15px 0 15px 10px;
  }

  .tox-tinymce {
    margin: 0px;
    border: none;
  }

  .tox {
    grid-area: note-content;
    background: $primary_light;
    border-top: 1px solid #e9ebef;
    padding-top: 11px;
    .tox-toolbar {
      &::-webkit-scrollbar {
        height: 3px;
        border-radius: 2px;
      }
    }
    .tox-sidebar-wrap {
      margin: 50px 0 0 0;
    }

    .tox-toolbar__primary {
      background: none;
      //border-top: 1px solid $preview_container;
    }

    .tox-toolbar__group {
      border-right: none !important;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 8px);
        bottom: 50%;
        height: 16px;
        width: 1px; /* or 100px */
        border-right: 1px solid #d3d3d3;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }

    .tox-statusbar {
      display: none;
    }
  }

  .mce {
    &-content {
      &-body {
        width: 210mm;
        height: 297mm;
        background: $primary_light;
        margin: 0 auto;
      }
    }
  }

  .editor {
    &-placeholder {
      grid-area: note-content;
      background: $primary_light;
    }
  }

  &-user {
    width: 38px;
    height: 38px;
  }
}
@media (max-width: 480px) {
  .note-page {
    &-header {
      padding: 11px 0 14px 17px;
    }
  }

  .mobile-note {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    display: block;
    grid-template-columns: 292px auto;
    grid-template-rows: 61px auto;
    grid-template-areas:
      'note-menu note-header'
      'note-menu note-content';
    &-header {
      display: none;
    }
    .tox {
      display: none;
    }
    .note-page-menu {
      &-header {
        margin: 0 0 0 18px;
      }
    }
  }
  .mobile-editor {
    .tox-tinymce {
      margin: 0;
    }
    .tox-toolbar {
      &::-webkit-scrollbar {
        height: 2px;
        border-radius: 2px;
      }
    }
    grid-template-areas:
      'note-menu note-menu'
      'note-header note-header'
      'note-content note-content';
    .note-page-menu {
      width: 100%;
      &-header {
        margin: 0;
      }
      &-title {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    .mobile-arrow-left-wrapper {
      width: 24px;
      height: 24px;
      display: block;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mobile-arrow-left {
      width: 6px;
      height: 12px;
    }
    .note-page-menu-item-list {
      display: none;
    }
  }
}
.editor-wrapper {
  height: calc(100% - 95px);
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  background: white;
  .editor {
    background: white;
  }
}
