.dropdownContainer {
  position: absolute;
  bottom: 90px;
  left: -2px;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.dropdownItem {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #5e5e5e;
  width: 100%;
  color: #fff;
  font-family: 'Nunito';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
}

@media only screen and (max-width: 730px) {
  .dropdownContainer {
    bottom: 70px;
  }

  .dropdownItem {
    font-size: 14px;
  }
}
