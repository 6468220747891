@import 'static/assets/styles/global/global-varibles';

.ringWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.containerClass {
  & > footer {
    z-index: 1;
    position: relative;
  }
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.border {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1261px;
  max-height: 867px;
}

.wrapper {
  width: 70%;
  height: 100%;
  position: relative;
}

.sidebar {
  height: 100%;
  max-width: 386px;
  width: 100%;
  background-color: #000;
  padding: 24px 24px 73px 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  &__title {
    & h2 {
      color: #fff;
      font-family: 'Nunito';
      font-size: 33px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
    & p {
      color: #fff;
      font-family: 'Nunito';
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      padding: 0;
      margin: 0;
    }
  }
}

.ringImageWrapper {
  position: absolute;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 20px;
  mix-blend-mode: lighten;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.38) inset;
}

.ringImage {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.listWrapper {
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  & li {
    & h2 {
      color: #fff;
      font-family: 'Nunito';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
    & p {
      color: #fff;
      font-family: 'Nunito';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }
}

.activation,
.orderRing {
  color: #fff;
  font-family: 'Nunito';
  border-radius: 20px;
  border: 0.8px solid #fff;
  width: 100%;
  height: 41px;
  font-size: 15px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  & svg {
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
  };
}

.orderRing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 20px;

  & span {
    font-weight: 300;
  }
}

.activation {
  border-radius: 20px;
  border: 0.8px dashed #3f3f3f;
  color: #6b6b6b;
}

.buttonsWrapper {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentClass {
  z-index: 1;
  width: 100%;
}

.actionsWrapper {
  display: flex;
  gap: 15px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.NeyraLogo {
  position: absolute !important;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);

  & svg path {
    transition: fill-opacity 200ms ease-in-out;
  }

  &:hover svg path {
    fill-opacity: 0.8;
  }
}

@media screen and (max-width: 100px) {
  .ringImage {
    height: 100%;
    max-width: calc(100% + 50px);
    object-fit: contain;
  }
}

@media screen and (max-width: 1500px) {
  .buttonsWrapper {
    position: initial;
  }

  .sidebar {
    &__title {
      & h2 {
        font-size: 24px;
      }
      & p {
        font-size: 14px;
      }
    }
  }

  .listWrapper {
    gap: 5px;
    & li {
      & h2,
      & p {
        font-size: 10px;
        line-height: 16px;
      }
    }
  }
  .actionsWrapper {
    margin: 35px 0 50px 0;
  }
}

@media screen and (min-width: 1700px) {
  .activation,
  .orderRing {
    height: 61px;
    font-size: 21px;
  }
}

@media screen and (max-width: 860px) {
  .ringWrapper {
    flex-direction: column;
  }
  .buttonsWrapper {
    position: initial;
    display: flex;
    gap: 32px;
    flex-direction: row;
    margin-top: 32px;
    & .orderRing {
      height: 60px;
      z-index: 1100;
    }
  }

  .contentClass {
    height: 754px;
    position: relative;
  }

  .ringImageWrapper {
    width: 100%;
    height: 100%;
    z-index: -1;

    & .ringImage {
      height: 100%;
      width: 100%;
      object-fit: contain;
      transform: scale(1.2);
      z-index: -1;
    }
  }

  .actionsWrapper {
    position: absolute;
    bottom: 18px;
    right: 16px;
    margin: 0;
    z-index: 100;
  }

  .sidebar {
    max-width: 100%;
    padding: 0;
    margin: 32px 0;
    height: fit-content;
  }

  .listWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    row-gap: 20px;
    z-index: 100;
    & li {
      width: 100%;

      & p,
      & h2 {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .containerClass {
    height: 100%;
    max-height: 100%;
    padding-bottom: 0;
  }
  .contentClass {
    height: 559px;

    position: relative;
  }

  .ringImageWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    & .ringImage {
      transform: scale(1.5);
    }
  }

  .actionsWrapper {
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }

  .buttonsWrapper {
    position: initial;
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .ringWrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    height: auto;
    background-color: #000;
    position: relative;
    overflow-y: auto;
  }

  .mobileDescription {
    height: 100%;
    width: 100%;
    margin-top: 22px;
    padding: 0 16px 74px;
    & .title {
      & h2 {
        color: #fff;
        font-size: 33px;
        font-weight: 400;
        margin: 0;
      }
      & p {
        color: #fff;
        font-size: 21px;
        font-weight: 300;
        margin: 0;
      }
    }
  }
  .listWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    gap: 30px;
    & li {
      max-width: 100%;
      & h2 {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        text-transform: capitalize;
      }
      & p {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-transform: capitalize;
      }
    }
  }
}
