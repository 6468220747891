@import 'static/assets/styles/global/global-varibles';

.wrapper {
  font-family: $sf_pro;
  height: 46px;
  font-size: 10px;
  line-height: 24px;
  color: #7e8a9f;
  margin-left: 18px;
  margin-right: 14px;
  margin-bottom: 4px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.bank {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.bankTitle {
  font-family: $sf_pro;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0;
  color: var(--sidebarGrayText);
}

.bankValue {
  font-family: $sf_pro;
  margin-top: 4px;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--textColorThird);
}

.titleBack {
  margin-top: 6px;
  height: 20px;
  cursor: pointer;
}

.titleBack:hover {
  & > svg {
    color: var(--iconColor);
  }
}

.titleBack svg {
  color: #7D7D7D;
}

.titleBackCollapsed {
  display: flex;
  transform: rotate(180deg);
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}

.collapsedTitle {
  margin-left: auto;
  margin-right: auto;
}

.action {
  display: flex;
  flex-direction: column;
}

.unrealize {
  font-family: $sf_pro;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  margin: 9px 0 0 0;
}

.collapsed {
  align-items: center;
  justify-content: center;
  height: 41px;
  margin-bottom: 1px;
}

.collapsed .bank, .collapsed .unrealize {
  display: none;
}

@media screen and (max-width: 480px) {
  .title > div {
    display: block;
    margin-right: 20px;
  }
}
