.file-action-relative {
  position: relative !important;
}

.file-action {
  position: absolute;
  &__modal {
    z-index: 1;
    width: 208px;
    height: auto;
    background: var(--tooltipContent);
    // box-shadow: 0 2px 8px #ccceda;
    position: absolute;
    bottom: -8px;
    right: 0;
    transform: translateY(100%);
    &.open-up {
      bottom: auto;
      top: -150px;
    }
    &.top-sm {
      bottom: auto;
      top: -70px;
    }
  }

  &__list {
    color: $dark_grey;
    font-family: $sf_pro;
    font-size: rem(12px);
    line-height: rem(16px);
    padding: 0;
    margin: 0;
  }

  &__list > button {
    list-style-type: none;
    padding: 12px 20px 12px 51px;
    cursor: pointer;
    color: var(--textColorSecond);
    width: 100%;
    text-align: left;

    &:hover {
      //background: rgba(246, 248, 252, 0.05);
    }
  }

  .file:hover & {
    opacity: 1;
    visibility: visible;
  }

  .file & {
    right: 10px;
    opacity: 0;
    visibility: hidden;
  }
}

.option {
  background: transparent center left 20px no-repeat;

  &-save {
    background: url(/static/assets/img/icons/action/save.svg) center left 20px
      no-repeat;
  }

  &-print {
    background: url(/static/assets/img/icons/action/print.svg) center left 20px
      no-repeat;
  }

  &-delete {
    background: url(/static/assets/img/icons/action/delete.svg) center left 20px
      no-repeat;
  }

  &-safetynet {
    background: url(/static/assets/img/icons/action/safetynet.svg) center left
      20px no-repeat;
  }

  &-download-pdf {
    background: url(/static/assets/img/icons/action/download-pdf.svg) center
      left 20px no-repeat;
  }

  &-download-docx {
    background: url(/static/assets/img/icons/action/download-docx.svg) center
      left 20px no-repeat;
  }

  &-divider {
    border-bottom: 0.5px solid $context_border_color;
  }

  @each $action_option in $action_options {
    &.#{$action_option} {
      background-image: url('/static/assets/img/icons/action/#{$action_option}.svg');
    }
  }
}
