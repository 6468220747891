@import 'static/assets/styles/global/global-varibles';

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalTitle {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
  text-align: center;
  color: #10161a;
}

.modalSubtitle {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #4b576c;
  margin-bottom: 24px;
  margin-top: 0;
}

.metamaskIcon {
  border: 1px solid #d6e2f5;
  width: 168px;
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;
}

.metamask {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #202124;
  margin-top: 24px;
  margin-bottom: 0;
  cursor: pointer;
}
