.ghost-logo {
  #Fill-1 {
    animation: BigGhost 2s ease-in-out infinite;
  }

  #Fill-8,
  #Fill-4 {
    animation: LittleTwoGhostRects 2s ease-in-out infinite 0.1s;
  }

  #Fill-6 {
    animation: LittleTwoGhostRects 2s ease-in-out infinite 0.25s;
  }
}

// Ghost animation

@keyframes BigGhost {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  99% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes LittleTwoGhostRects {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-13px);
  }
  99% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes LittleLastGhostRects {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-13px);
  }
  99% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}
