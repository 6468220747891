@import 'static/assets/styles/global/global-varibles.scss';

.sharedByLink {
  background-color: #f6f8fc;
  min-height: 100vh;

  main {
    padding: 13px 16px;
  }

  &__main {
    &__accessDenied {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__button {
        margin-top: 20px;
      }
      &__lock {
        padding-top: 120px;
      }
      &__boldText {
        padding-top: 4px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #000000;
      }
      &__text {
        padding-top: 8px;
        font-size: 14px;
        line-height: 22px;
        max-width: 329px;
        text-align: center;
        color: #7e8a9f;
      }
    }
  }
}
.authWallets {
  &_wrapper {
    background: #ffffff;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_logo {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    p,
    h2 {
      font-family: $sf_pro;
      text-align: center;
      padding: 0px;
      margin: 0px;
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #4b576c;
      white-space: pre-wrap;
    }
  }
}
