.wrapper {
  text-align: center;
  max-width: 490px;
}

.title {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #10161a;
}

.message {
  font-size: 12px;
  line-height: 16px;
  color: #4b576c;
}
