@import 'static/assets/styles/global/global-varibles';

.container {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $sf_pro;
  padding: 36px 40px 0;
  z-index: 1;
}

.logo {
  color: var(--iconColor, #000);
}

.web3LoginBtn {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}
.otherLoginOptions {
  display: flex;
  gap: 24px;
}

.emailLoginBtn {
  width: 54px;
  height: 54px;
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
  border: 0.6px solid #7D7D7D;
}

.emailLoginBtn svg {
  max-height: 24px;
}

.loginText {
  margin-left: 6px;
}

@media (max-width: 800px) {
  .container {
    padding-top: 20px;
    padding-left: 36px;
  }
}

@media (max-width: 480px) {
  .container {
    padding-top: 20px;
    padding-left: 16px;
  }
}
