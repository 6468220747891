.sharing {
  &-container {
    padding: 0 31px 0 31px;
    position: relative;
    margin-top: 57px;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: var(--scrollbarColor);
    }
  }

  &-tabs {
    &.tabs {
      margin: 0;
      padding: 0 100px 0 0;
      box-shadow: none;
    }

    .tabs {
      &-item {
        padding: 0;
        margin: 0 44px 0 0;

        a {
          font-family: $sf_pro;
          font-size: 16px;
          line-height: 24px;
          color: var(--textColorGray);
          text-transform: uppercase;
        }

        &.active {
          color: $primary_color;
          border: none;

          a {
            color: $primary_color;
          }
        }
      }
    }

    &__guest {
      font-family: $sf_pro;
      color: var(--textColor);
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.site__pages-entity-settings .sharing-container {
  padding-top: 12px;
}

@media screen and (max-width: 480px) {
  .sharing {
    &-tabs {
      &.tabs {
        padding: 0 10px 0 0;
        width: 100%;
      }
      .tabs {
        &-item {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          line-height: 18px;
          padding: 0;
          margin: 0 10px 0 0;

          &:last-child {
            margin-left: auto;
          }
          & > a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
