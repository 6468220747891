@import 'static/assets/styles/global/global-varibles';

.label {
  flex-direction: column;
  width: 100%;
  color: $view_overlay;

  display: flex;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 24px;
  color: $primary_dark;
  padding: 0;
  margin: 24px 0 0 0;

  position: relative;
}

.input {
  background: $row_light_grey;
  border: 1px solid $forty_color;
  box-sizing: border-box;
  padding: 11px 16px;
  margin: 4px 0 0 0;
  font-size: 14px;
  line-height: 24px;

  &__button {
    position: absolute;
    top: 44px;
    right: 14px;
    cursor: pointer;

    svg {
      path {
        fill: $medium_grey;
      }
    }
  }

  &__description {
    font-family: $sf_pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $dark_grey;
    margin: 8px 0;
  }

  &__error {
    color: $red;
  }
}
