@import 'static/assets/styles/global/global-varibles';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 420px;
  max-width: 488px;
  padding: 40px;
  text-align: center;
  background: var(--modalContent);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  &-extended {
    max-width: 650px;
  }

  &-big {
    max-width: 880px;
  }

  &-geo {
    max-width: 575px;
    min-height: 505px;
  }

  &-analytics {
    max-width: 575px;
    min-height: 392px;
    max-height: 455px;
    font-family: $sf_pro;

    .context-menu-item-divider {
      width: 300px;
      margin-left: calc(50% - 150px);
    }

    .analytics {
      &__modal {
        &__header {
          margin-top: 10px;
          font-size: 24px;
          line-height: 30px;
          color: #10161a;
          text-align: center;
          font-weight: 400;
        }

        &__warning {
          color: #7c7e88;
          margin-top: 8px;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          display: flex;
          flex-direction: column;

          .tooltip {
            top: 50px;
            padding: 15px;
            background-color: white;
            color: black;
            border: 0.5px solid #e4e7ee;
            box-shadow: 0 2px 8px #ccceda;
          }

          &:hover .folder-tooltip {
            display: flex;
          }
        }

        &__content {
          display: flex;
          justify-content: center;
          margin-top: 30px;

          &__file {
            display: flex;
            justify-content: center;

            &__text {
              display: flex;
              flex-direction: column;
              padding-left: 10px;
              margin-right: 20px;

              & > span:first-child {
                font-family: $sf_pro;
                font-size: 18px;
                color: #19191d;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
              }

              & > span:last-child {
                font-family: $sf_pro;
                color: #7c7e88;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
              }
            }
          }

          &__folder {
            display: flex;
            justify-content: center;
            margin-left: 20px;

            &__text {
              display: flex;
              flex-direction: column;
              padding-left: 10px;

              & > span:first-child {
                font-family: $sf_pro;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #19191d;
              }

              & > span:last-child {
                font-family: $sf_pro;
                color: #7c7e88;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
              }
            }
          }
        }

        &__info {
          width: 300px;
          display: flex;
          justify-content: space-around;
          margin-left: calc(50% - 150px);
          margin-right: auto;
          margin-top: 31px;
          margin-bottom: 12px;

          &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 80px;

            & > span {
              font-family: $sf_pro;
              color: #7c7e88;
              margin-top: 5px;
            }

            & > span:last-child {
              font-family: $sf_pro;
              color: #202124;
              font-size: 14px;
              font-style: normal;
              line-height: 24px;
              letter-spacing: 0px;
              text-align: center;
            }
          }
        }

        &__users {
          display: flex;
          justify-content: space-between;
          width: 260px;
          margin-left: calc(50% - 140px);
          margin-right: auto;
          margin-top: 12px;

          &__item {
            display: flex;

            & > span {
              font-family: $sf_pro;
              color: #7c7e88;
              padding-left: 5px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px;
              letter-spacing: 0px;
              text-align: left;
            }

            & > span:first-child {
              color: rgb(15, 115, 239);
            }

            & > span:last-child {
              color: #000;
            }
          }
        }

        &__lock {
          margin-top: 42px;
          margin-bottom: 38px;
          font-size: 12px;
          line-height: 24px;
          color: #7e8a9f;
          display: flex;
          justify-content: center;
          align-items: flex-end;

          & > span {
            margin-left: 8px;
          }
        }

        &__button {
          :hover {
            cursor: pointer;
          }

          display: flex;
          justify-content: center;
          margin-top: 35px;
          margin-bottom: 16px;
          height: 48px;

          &__text {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            margin-left: 35px;
            margin-right: 35px;
            text-align: center;
            letter-spacing: 1.4px;
            text-transform: uppercase;
          }

          &__cancel {
            border: 1px solid #ccceda;
            box-sizing: border-box;
            margin-right: 10px;
            color: #0f73ef;
            width: 199px;
          }

          &__continue {
            background: #0f73ef;
            color: #ffffff;
            width: 199px;
          }
        }
      }
    }
  }

  &__contented {
    min-height: auto;
  }

  &__overlay {
    background: rgba(16, 22, 26, 0.6);
  }

  &__delete {
    max-width: 436px;
  }

  &-upload {
    min-height: 280px;
  }

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__marker {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: $primary_color;
  }

  &__title {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 32px;
    color: var(--textColor);
    margin: 0 0 24px 0;
    font-weight: 400;

    &-small {
      margin-bottom: 8px;
    }

    &-medium {
      margin-bottom: 15px;
    }

    &-left {
      text-align: left;
    }

    &-main {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: $colBlackText;
      margin: 36px 0 31px 0;
    }
  }

  &__sub-title {
    font-family: $sf_pro;
    margin-top: 0;
    font-size: 12px;
    line-height: 18px;
    white-space: pre-wrap;
    color: $medium_grey;
  }

  &__centered-btn {
    display: flex;
    justify-content: center;
  }

  &__buttonsAround {
    display: flex;
    justify-content: space-around;
    margin: auto 0 0 0;
    padding: 0;

    .decline {
      height: 48px;
      width: calc(32% - 5px);
      padding: 0;
      border: 1px solid var(--buttonColor);
      text-transform: uppercase;
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      color: var(--textColor);
      font-weight: 500;
      letter-spacing: 0.1em;

      &-half {
        width: calc(100% - 5px);
      }
    }

    .accept {
      height: 48px;
      width: calc(32% - 5px);
      padding: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      background-color: var(--buttonColor);
      color: var(--avatarText);

      &-half {
        width: calc(100% - 5px);
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: auto 0 0 0;
    padding: 0;

    &-centered {
      justify-content: center;
    }

    .decline {
      height: 48px;
      width: calc(32% - 5px);
      padding: 0;
      border: 1px solid var(--buttonColor);
      text-transform: uppercase;
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      color: var(--textColor);
      font-weight: 500;
      letter-spacing: 0.1em;

      &-half {
        width: calc(100% - 5px);
      }
    }

    .accept {
      height: 48px;
      width: calc(32% - 5px);
      padding: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      background-color: var(--buttonColor);
      color: var(--avatarText);

      &-half {
        width: calc(100% - 5px);
      }
    }

    .move {
      height: 48px;
      width: 170px;
      padding: 0 30px;
    }

    .alert {
      background-color: $red;
    }

    .grey {
      color: $dark_grey;
    }
  }

  &__buttons_full {
    display: flex;
    margin: auto 0 0 0;
    padding: 0;

    button {
      height: 48px;
      font-weight: 400;
      padding: 0;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }

  &__content {
    &-notice {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $color_blue;
      margin: 0 0 24px 0;

      &-left {
        text-align: left;
        position: relative;
      }
      &-center {
        text-align: center;
      }
    }

    .modal__input-error {
      .modal__input {
        background: #fff6f7;
        border: 1px solid #ed1d2d;
      }

      .error-message {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
        color: #ed1d2d;
      }
    }

    .storage-title {
      h1 {
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 24px;
      }

      p {
        margin-bottom: 26px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $medium_grey;
      }
    }
  }

  &__error {
    background-color: white;
    z-index: 1;
    width: 500px;
    position: absolute;
    background: #ffffff;
    box-shadow: 2px 8px 24px rgba(26, 49, 139, 0.15);
    border-radius: 8px;
    padding: 30px 46px 25px 46px;

    &-wrapper {
      position: relative;
    }

    &-title {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 8px;
      text-align: center;
      color: #10161a;
    }

    &-description {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #7c7e88;
      margin-top: 0;
      margin-bottom: 22px;
    }

    &-btn {
      width: 199px;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1.4px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .storage-btn {
    display: flex;
    justify-content: center;

    button {
      cursor: pointer;
      font-family: $sf_pro;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 112px;
      border: 0.5px solid #d6e2f5;

      svg {
        margin-bottom: 8px;
      }

      &:not(last-child) {
        margin-right: 8px;
      }

      &:disabled {
        cursor: not-allowed;
        color: #c1c9d9;
      }
    }

    &__active {
      font-size: 12px;
      line-height: 16px;
      color: white;
      background-color: $primary_color;
    }
  }

  .cloud-storage-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      cursor: pointer;
      font-family: $sf_pro;
      display: flex;
      align-items: center;
      border: 0.5px solid #d6e2f5;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      svg {
        margin: 6px 14px 6px 10px;
        width: 16px;
        height: 16px;
      }

      &:not(last-child) {
        margin-right: 8px;
        margin-bottom: 5px;
      }

      &:disabled {
        cursor: not-allowed;
        color: #c1c9d9;
      }
    }

    &__active {
      font-size: 12px;
      line-height: 16px;
      color: white;
      background-color: $primary_color;
    }
  }

  .storage-error-msg {
    position: relative;
    margin-bottom: 20px;
    & > span {
      left: 0;
      right: 0;
      margin-top: 2px;
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 16px;
      color: #ed1d2d;
    }
  }

  &__desc {
    padding: 24px 0;
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $fifty_color;

    .link {
      display: inline;
      text-transform: none;
      font-family: $sf_pro;
      font-size: 14px;
      letter-spacing: normal;
    }
  }

  &__text {
    padding: 0;
    margin-bottom: 24px;
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $fifty_color;

    .link {
      display: inline;
      text-transform: none;
      font-family: $sf_pro;
      font-size: 14px;
      letter-spacing: normal;
    }
  }

  &__image {
    &-container {
      margin: 50px 0;
    }
  }

  .bottom-button {
    margin: 0 auto;
  }

  .tabs {
    padding: 0;

    &-item {
      padding: 32px 0 18px 0;
      width: 50%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__owner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 17px 0 0 0;

    &-name {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: $primary_black;
      text-align: left;
      margin: 0 0 0 13px;
    }

    &-email {
      margin: 2px 0 0 0;
      color: $comment_grey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-status {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #000000;
      margin: 0 0 0 auto;
    }
  }

  &__users {
    position: relative;

    .option-selector {
      position: static;
      border: none;
      padding: 0;
      margin-bottom: 0;

      &-selected {
        margin-right: 10px;
      }

      &-item {
        &-container {
          top: 48px;
          right: 0;
        }
      }
    }
  }

  &__input {
    background: $active_comment;
    border: 1px solid $forty_color;
    box-sizing: border-box;
    text-align: left;
    padding: 13px 11px 4px 17px;
    min-height: 48px;
    width: 100%;

    &.react-tagsinput--focused {
      border-color: $primary_color;
    }

    & > span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &-has-users {
      padding: 7px 118px 0 6px;

      &-comment {
        padding-right: 148px;
      }
    }

    input {
      width: 80px;
      max-width: 268px;
      height: 24px;
      caret-color: $primary_color;
      background: transparent;
      border: 0;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $primary_black;
      margin: 0;
      padding: 0;
      outline: none;
      overflow-wrap: anywhere;
    }

    &-container {
      margin: 0;
      position: relative;
      min-width: 49%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: var(--textColor);

      .rc-time-picker {
        width: 100%;
      }
    }

    .react-tagsinput {
      &-input {
        margin: 0 0 7px 0;
      }

      &-tag {
        display: inline-flex;
        align-items: center;
        background: $primary_light;
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $primary_black;
        border: none;
        padding: 5px 11px 4px 6px;
        margin: 0 7px 7px 0;

        .user-icon {
          width: 25px;
          height: 25px;
          margin: 0 7px 0 0;
        }

        .tag-text {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 208px;
        }
      }

      &-remove {
        display: inline-block;
        width: 13px;
        height: 13px;
        color: $fifty_color;
        background: url(/static/assets/img/cancel-icon--big.svg) no-repeat
          center center;
        background-size: 12px 12px;
        margin: 0 0 0 10px;
        cursor: pointer;

        &:before {
          content: '';
        }
      }
    }
  }

  &__select {
    &-error {
    }

    &-error__text {
      margin-top: 10px;
      font-family: $sf_pro;
      text-align: start;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ed1d2d;
    }
  }

  &__comment {
    margin: 0;

    &-input {
      background: $active_comment;
      border: 1px solid $forty_color;
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      resize: none;
      caret-color: $primary_color;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $primary_black;
      padding: 12px 17px 10px 17px;

      &:focus,
      &:active {
        border-color: $primary_color;
      }
    }
  }

  .tags {
    &-suggest {
      width: 100%;
      background: $primary_light;
      border: 0.5px solid $context_border_color;
      box-shadow: 0 2px 8px $forty_color;
      margin: -4px 0 -84px 0;

      &-item {
        display: flex;
        width: 100%;
        margin: 0;
        padding: 17px 18px 21px 18px;

        &:hover {
          cursor: pointer;
        }

        .user-icon {
          flex: none;
          width: 43px;
          height: 43px;
          margin-right: 13px;
          font-size: 20px;
        }

        &-name {
          text-align: left;
          width: calc(100% - 56px);
          font-family: $sf_pro;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.1px;
          color: $primary_black;
        }

        &-email {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: $sf_pro;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: $comment_grey;
          margin: 4px 0 0 0;
        }

        &-title {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__by-links {
    &-container {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  &__datetime {
    padding: 0;
    position: relative;

    &-container {
      display: flex;
      padding: 24px 0;
      justify-content: space-between;

      & > .react-datepicker-wrapper {
        width: calc(50% - 7px);
      }

      & > .rc-time-picker {
        width: calc(50% - 7px);
      }
    }

    &-title {
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 24px;
      color: $primary_black;
      text-align: left;
    }
  }

  &-input {
    background: var(--baseColor);
    border: 1px solid var(--inputBorder);
    height: 48px;
    padding: 12px 0 12px 21px;
    width: 100%;
    color: var(--textColor);

    &:focus {
      border: 1px solid $primary_color;
    }
  }

  &__workspace {
    &-create {
      &-content {
        padding: 32px 0 0 0;
      }
    }

    .modal {
      min-height: auto;

      &__title {
        margin-top: 56px;
      }

      &__buttons {
        justify-content: flex-end;
        margin-top: 26px;
      }
    }

    .decline {
      color: $dark_grey;
      border: none;
      width: 40px;
    }

    .accept {
      width: 130px;
    }

    .long {
      width: 180px;
      padding: 0 11px;
    }
  }

  &__workspace & {
    &__input {
      &-name {
        padding: 6px 11px 4px 17px;
      }
    }
  }

  &__step {
    &-container {
      display: flex;
      margin: 0 auto;
    }

    &-item {
      width: 12px;
      height: 12px;
      border: 1px solid $primary_color;
      box-sizing: border-box;
      opacity: 0.2;
      margin: 0 9px 0 0;

      &.active {
        opacity: 1;
      }
    }
  }
}

.error-access-denied-modal {
  min-width: 575px;
  padding: 50px 103px;

  & .modal__content {
    svg {
      margin-right: 50px;
    }
  }

  & .modal__title {
    font-family: $sf_pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    color: var(--textColor);
  }

  & .modal__sub-title {
    font-family: $sf_pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 0 20px;
    color: var(--textColorGray);
  }
  svg.warning__image {
    width: 244.79px;
    height: 231px;
    margin-bottom: 8px;
  }
}
.warning {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: $sf_pro;
  }
  &__sub-title {
    margin: 10px 0 25px 0;
    white-space: wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #4b576c;
  }
  &__buttons-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  &__button {
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $sf_pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.1em;
    width: 132px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-cancel {
      color: #0f73ef;
    }
    &-switch {
      color: white;
      background-color: #0f73ef;
    }
  }
}

.file-security-checker {
  .security-2fa {
    max-width: 575px !important;
    min-height: 710px !important;
  }

  &.security-pin {
    max-width: 575px !important;
    min-height: 710px !important;
  }

  &.security-voice {
    max-width: 606px !important;
    min-height: 710px !important;
  }

  &.security-key {
    max-width: 575px !important;
    min-height: 710px !important;

    .key-tab__icon > svg {
      left: calc(50% + 30px) !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .ReactModal__Content {
    width: calc(100vw - 32px);
    margin: auto 16 auto 0;
    left: calc(50% - 16px);
    height: max-content !important;
  }
  .modal {
    &__title {
      font-size: 20px;
    }
    &__content-notice {
      font-size: 12px;
    }
    .decline,
    .accept {
      font-size: 10px;
    }
  }
  .modal__contented {
    width: calc(100vw - 32px);
    padding: 40px 24px !important;
    height: max-content !important;
  }
}
