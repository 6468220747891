.createWidget {
  width: 346px;
  border: 0.5px solid #5E5E5E4D;
  border-radius: 20px;
  padding: 16px;
  background-color: #111111;
}

.createLabel {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0 0 16px 0;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.loaderTextContainer {
  display: flex;
  justify-content: center;
  margin: 24px 0 6px 0;
  width: 100%;
}

.loaderText {
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  width: 0;
  animation: typing;
  animation-duration: 4s;
  animation-timing-function: steps(30, end);
  animation-fill-mode: forwards;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}