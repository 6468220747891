.remove-user {
  padding: 5px 4.5vw;
  height: 80vh;
  justify-content: center;
  width: 44vw;

  p {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $fifty_color;
    text-align: center;

    a {
      color: $primary_color;
      text-decoration: none;
    }
  }

  .right-menu__label {
    margin: 0 auto;
  }

  .bottom-button {
    margin-top: 58px;
  }
}
