.container {
  width: 100%;
  height: 70px;
  background: var(--workspaceMainButtonColor);
  border: 0.5px solid var(--workspaceMainButtonBorderColor);
  padding: 11px;
  border-radius: 3px 20px 20px 20px;
  z-index: 2 !important;
  overflow: hidden;
  position: relative;
}

.container:hover {
  background: var(--wsSettingsHover);
}

.workspaceMenu {
  background-color: var(--settingsMenuBg);
  position: fixed;
  top: 60px;
}

.wcImage {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  object-position: bottom;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.collapsed .titleWrapper {
  align-items: center;
  justify-content: center;
}

.collapsed {
  max-width: 72px;
}
