@import 'static/assets/styles/global/global-varibles';

.wrapper {
  background: #ffffff;
}

.container {
  font-family: $sf_pro;
  position: relative;
}

.subTitle {
  justify-content: center;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #202124;
  margin-top: 0;
  margin-bottom: 4px;
  opacity: 0.8;
}

.title {
  text-align: center;
  margin-top: 0;
  font-weight: 500;
  font-size: 30px;
  line-height: 48px;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  color: #202124;
  mix-blend-mode: normal;
  margin-bottom: 30px;
}

.avatar {
  margin: 20px auto 10px auto;
  width: 100px;
  height: 100px;
}

.avatar > span {
  font-size: 20px;
  line-height: 19px;
}

.loginList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.welcomeTitle {
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
}

.welcomeTitle > span {
  font-weight: 400;
}

.welcomeSubTitle {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

.web2 {
  opacity: 0.4;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Error modal styles START */

.errorOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.63);
  z-index: 1001;
}

.errorModal {
  display: block;

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}

.errorContainer {
  font-family: $sf_pro;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 504px;
  padding: 40px;
  text-align: center;
  background: #fff;
  transform: translate(-50%, -50%);
}

.errorTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #10161a;
  margin: 15px 0;
}

.errorText {
  font-size: 14px;
  line-height: 24px;
  color: #7e8a9f;
  margin-bottom: 24px;
}

.signInBtn {
  margin-left: auto;
  margin-right: auto;
  width: 170px;
  height: 48px;
}
.coinbaseContainer {
  background-color: rgba(126, 216, 255, 0.08);
  width: 250px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0 auto;
  & button {
    cursor: pointer;
    user-select: none;
    width: 250px;
    height: 60px;
    border-radius: 10px;
    background-color: rgba(0, 147, 230, 0.05);
    color: #000000;
    font-weight: bold;
  }
}
/* Error modal styles END */

@media screen and (max-width: 500px) {
  .wrapper {
    height: 100%;
    overflow-y: auto;
  }

  .container {
    margin: 20px 0;
  }

  .loginList {
    gap: 20px;
  }

  .subTitle {
    font-size: 11px;
    line-height: 16px;
  }

  .subTitle {
    font-size: 11px;
    line-height: 16px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
  }
}
