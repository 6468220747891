.editor {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 0;

  &-file {
    &-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
      margin: 25px 0 14px 10px;
    }
  }

  &-header {
    &-fixed {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px 0 30px;
      & .header-right-side {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile-button {
          position: relative;
        }
        .menu {
          position: absolute;
          right: 41px;
          top: 61px;
          z-index: 100;
        }
      }
    }
  }

  .breadcrumb {
    margin: 15px 0 15px 10px;
  }

  .tox-tinymce {
    border: none;
    & .tox-edit-area {
      margin: 30px 0 30px 0;
      & .tox-edit-area__iframe {
        padding: 20px 25px 0 25px;
      }
    }
  }

  .tox {
    .tox-sidebar-wrap {
      width: 210mm;
      margin: 0 auto;
    }

    .tox-editor-header {
      //position: fixed;
      //width: 100%;
    }
    .tox-tbtn--select {
      width: 94px;
      &:nth-child(2) {
        width: 60px;
      }
    }
  }
}
