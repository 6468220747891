@import 'static/assets/styles/global/global-varibles';

.drag-layer {
  background: $selected_background;
  width: 202px;
  height: 48px;
  z-index: 1003;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  //filter: drop-shadow(0px 4px 12px rgba(26, 49, 139, 0.25));
  box-shadow: 0px 4px 12px rgba(26, 49, 139, 0.25);

  &-multi {
    box-shadow: 0px 4px 5px rgba(57, 62, 84, 0.2);
    transform: translate(-6px, -96px);
  }

  &-icon {
    margin: 0 11px 0 0;

    &-folder {
      width: 32px;
      height: 32px;
      margin: 0 12px 0 0;
    }

    &-file {
      width: 32px;
      height: 32px;
    }

    &-picture {
      width: 32px;
      height: 32px;
      margin: 0 12px 0 0;
    }
  }

  &-name {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $primary_dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 134px;
  }

  &-count {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: $primary_color;
    padding: 8px 6px 5px 6px;

    font-family: $sf_pro;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: $primary_light;
  }

  &-a {
    transform: translate(0px, -42px);
    z-index: 1001;
    box-shadow: 0px 4px 5px rgba(57, 62, 84, 0.2);
  }

  &-b {
    transform: translate(6px, 12px);
    z-index: 1002;
    box-shadow: 0px 4px 12px rgba(26, 49, 139, 0.25);
  }
}

.note-drag-layer {
  display: flex;
  padding: 9px 26px 10px 16px;
  background: #e8f0fd;
  box-shadow: 0px 4px 12px rgba(26, 49, 139, 0.25);

  span {
    margin-top: 2px;
    margin-left: 8px;
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 16px;
  }
}
