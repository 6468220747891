.rule {
  border: 0;
  border-top: 1px solid #f4f6f9;
  margin: 0;
}

.rule.dark {
  border-top: 1px solid #E9EBEF;
}

.rule.dashed {
  border-top-style: dashed;
}

.vertical {
  width: 1px;
  height: 100%;
  background: #e9ebef;
}

