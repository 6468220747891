.account-plan-security {
  background: #ffffff;
  border-bottom: 1px solid #ccceda;

  &__chart-wrapper {
    position: relative;
    margin-top: 40px;
    height: 140px;
  }

  &__short-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 50%;

    transform: translate(-50%, -100%);
  }

  &__short-data-top {
    margin-bottom: 10px;
    color: #1b1a1e;
    line-height: 1;
  }

  &__short-data-bottom {
    color: #58595f;
    line-height: 1;
  }

  &__list {
    margin-top: 40px;
    border-top: 1px solid #dcdee9;
  }

  &__security {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 25px;
    min-height: 80px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      width: 84%;
      height: 1px;
      background: $secondary_light;
      transform: translateY(-100%);
    }
  }

  &__security-ico-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    border: 1px solid #e5e7ec;
    border-radius: 50%;

    font-size: 0;

    > * {
      max-width: 100%;
    }
  }

  &__security-name {
    margin-left: 12px;
    color: #10161a;
  }

  &__security-status {
    margin-left: auto;
  }

  &__security-non-activated {
    width: 184px;
    height: 36px;
  }
}
