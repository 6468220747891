.rstcustom {
  &__collapseButton {
    top: 57%;

    &:after {
      border-top-color: $forty_color;
      border-left-width: 5px;
      border-right-width: 5px;
      border-top-width: 5px;
    }

    &:hover:after {
      border-top-color: $forty_color;
    }
  }

  &__expandButton {
    &:after {
      border-top-color: $forty_color;
      border-left-width: 5px;
      border-right-width: 5px;
      border-top-width: 5px;
    }

    &:hover:after {
      border-top-color: $forty_color;
    }
  }

  &__rowLabel {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 24px;
    color: $empty_color;

    .default {
      width: 19px;
      margin: 0 8px 0 0;
    }

    .selected-folder {
      color: $primary_color;
    }
  }

  &__node {
    &:hover {
      cursor: pointer;
    }
  }

  &__rowWrapperDragDisabled {
    cursor: pointer;
    padding: 5px 0 0 0;
  }
}

.tree-folder {
  .rstcustom {
    &__node {
      & > div {
        position: absolute;
        left: 30px;
      }
    }
  }

  &-selected {
    background-color: $primary_light;

    .rstcustom {
      &__expandButton {
        &:after {
          border-top-color: $primary_color;
        }
      }

      &__collapseButton {
        &:after {
          border-top-color: $primary_color;
        }
      }
    }
  }
}

.tree-notes {
  .rstcustom {
    &__node {
      .note {
        border: 1px solid #f2f4f8;
        border-left-color: white;
        border-right-color: white;
        position: relative;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 0 33px 0 17px;

        .collapsedBtn {
          top: 4px;
          left: -12px;
          position: absolute;
        }

        .noteContent {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            margin-left: 8px;
            font-size: 12px;
            line-height: 16px;
          }
        }
        .createSubNote {
          cursor: pointer;
          display: none;
        }
      }

      .rootNote {
        .noteContent {
          width: 100%;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            margin-top: 2px;
            font-weight: 400;
            font-family: $sf_pro;
            font-size: 14px;
            line-height: 20px;
            color: #202124;
          }
        }
      }

      .droppable {
        border: 1px solid #0f73ef;
      }

      &:hover {
        .createSubNote {
          display: block;
        }
        background-color: white;
      }

      .dropDisable {
        .noteContent {
          opacity: 0.5;
        }

        &:hover {
          background-color: #f2f4f8;
          .createSubNote {
            display: none;
          }
        }
      }

      .activeNote {
        .noteContent {
          span {
            color: #0f73ef;
          }
        }
      }
    }

    &__expandButton {
      display: none;
    }

    &__rowLabel {
      margin-left: 8px;

      span {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__rowWrapperDragDisabled {
      padding: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .tree-folder {
    .rstcustom {
      &__node {
        & > div {
          position: absolute;
          left: 0;
        }
      }
    }
  }
}
