.chatWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  margin: 0 16px;
}

.contentFull {
  height: 100%;
  max-height: calc(100% - 150px);
  margin: 0;
}

.contentHeader {
  font-family: Nunito;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  letter-spacing: 0em;
  margin-bottom: 20px;
}

.tagList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tagItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 0.8px dashed #FFFFFF;
  height: 64px;
  color: #FFFFFF;
  padding-right: 23px;
  margin: 2px;
}

.tagItem svg {
  color: #FFFFFF80;
}

.tagItemActive {
  position: relative;
  border-style: solid;
  border-color: #F8D477;
  color: #F8D477;
}

.tagItemActive::before {
  content: '';
  position: absolute;
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: #F8D477;
  transform: rotate(45deg);
}

.tagItemActive svg {
  color: #F8D477;
}

.chatInput {
  display: flex;
  margin-bottom: 36px;
}

.tagName {
  margin: 0 0 0 25px;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.chatInput {
  display: flex;
  gap: 11px;
  padding: 0 16px;
}

.input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input input {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  height: 27px;
  background-color: transparent;
  color: #FFFFFF;
  outline: none;
  border: none;
  margin-bottom: 10px;
}

.input input::placeholder {
  color: #FFFFFF;
}

.inputBorder {
  width: 100%;
  height: 0.7px;
  background-color: #CECECE;
  box-shadow: 0px 8px 4px 0px #0000000D;
}

.inputAction {
  max-height: 44px;
}

.inputAction svg {
  width: 44px;
  height: 44px;
}

.messenger {
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: calc(100% - 60px);
  padding: 0 16px;
  margin: 20px 0;
  overflow-y: auto;
}

.timer {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 10px;
  margin-top: 0;
}