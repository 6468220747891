.error {
  background: rgba(242, 221, 223, 0.5);
  border: 1px solid $red;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 10px 12px 10px 16px;
  margin: 0 0 6px 0;
  width: 100%;

  &-text {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin: 0 0 0 12px;
    text-align: left;
  }

  &-icon {
    display: flex;
    width: 18px;
    height: 18px;
  }
}
