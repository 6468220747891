.access-request {
  &-block {
    position: relative;

    .view-all {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      line-height: 24px;
      color: $primary_color;
      text-decoration: none;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-item {
    display: flex;
    flex-direction: column;
    width: 242px;
    height: 242px;
    margin: 0 16px 16px 0;
    padding: 0 0 0 20px;
    background: $primary_light;
    position: relative;
    cursor: pointer;
    user-select: none;

    transition: box-shadow 0.3s, transform 0.3s;

    &:hover {
      box-shadow: 0 6px 19px -5px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      .file-action {
        opacity: 1;
        visibility: visible;
      }
    }

    .file-action {
      transition: all 0.3s;
      position: absolute;
      top: 10px;
      right: 16px;
      opacity: 0;
      visibility: hidden;
    }

    .icon__img--folder {
      width: 78px;
      height: 62px;
      margin: 0;
    }

    .folder__icon {
      padding: 20px 0 0 0;
    }

    &__file-name {
      font-family: $sf_pro;
      font-size: $main-fz;
      line-height: 24px;
      color: $primary_dark;
      padding: 12px 0 0 0;
    }

    &__file-number {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__give-access-link {
      padding: 20px 0 0 0;
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 16px;
      color: $primary_color;
      text-decoration: none;
    }
  }
}
