.title {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 0;
  margin-top: 24px;
  color: var(--textColorSecond);
}

.sidebarItem > button {
  opacity: 0;
  transition: opacity .6s;
}

.sidebarItem:hover > button {
  opacity: 1;
}
