.audio-player {
  &-icon {
    width: 100%;
    height: 100%;
  }

  .rhap {
    &_container {
      padding: 0 43px 13px 16px;
      background-color: transparent;
      box-shadow: none;
    }

    &_main {
    }

    &_horizontal-reverse {
      .rhap_controls-section {
        margin: 0;
      }
    }

    &_progress-section {
      flex: 20 1 auto;
    }

    &_controls-section {
    }

    &_time {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: $primary_black;
    }

    &_play-pause-button {
      width: 12px;
      height: 16px;
      margin: 0;
      padding: 0;
      font-size: 1rem;

      svg {
        width: 12px;
        height: 16px;
      }
    }

    &_progress-bar {
      height: 2px;
      background-color: $primary_light;
    }

    &_progress-indicator {
      width: 2px;
      height: 2px;
      top: 0;

      box-sizing: border-box;
      position: absolute;
      z-index: 3;
      margin-left: 0;
      border-radius: 50%;
      box-shadow: none;
      background-color: $primary_color;
    }

    &_progress-filled {
      background-color: $primary_black;
    }

    &_download-progress {
      background-color: $primary_light;
    }
  }
}
