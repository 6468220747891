.voice-wrapper {
  &__close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: var(--iconColor);

    &:hover {
      cursor: pointer;
    }
  }
}
