@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
  background: var(--modalOverlay);
}

.wrapper {
  font-family: $sf_pro;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 64px);
  max-width: 700px;
  max-height: 645px;
  background-color: var(--baseColor);
}

.header {
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  cursor: pointer;
  margin: 30px 30px 0 0;
  color: var(--iconSecondColor);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0;
}

.form {
  width: calc(100% - 104px);
}

.formContainer {
  max-height: 280px;
  margin-top: 24px;
  overflow-y: auto;
  overflow-x: visible;
}

.fieldItem {
  margin-bottom: 18px;
}

// Preview styles

.previewBody {
  margin: 10px 36px 40px 70px;
}

.fieldList {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.previewFieldItem {
  display: inline-flex;
  margin-top: 8px;
}

.fieldName {
  font-size: 14px;
  line-height: 22px;
  color: var(--textColorGray);
  min-width: 120px;
  margin: 0;
}

.fieldValue {
  margin: 0;
  font-size: 12px;
  line-height: 22px;
  color: var(--textColor);
}

.fieldUserNameValue {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: var(--textColor);
}

.fieldLinkValue {
  text-decoration-line: underline;
}

.copyButton {
  margin-left: 10px;
  color: var(--textColor);
}

.taglist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  max-height: 92px;
  overflow-y: hidden;
}

.tagItem {
  font-family: $roboto;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #1A1A1A;
  padding: 5px 10px;
  margin-top: 0;
  background: #FAFAFA;
  margin-right: 8px;
}

.filedPreviewValue {
  display: flex;
  align-items: center;
}


@media screen and (max-width: 480px) {
  .wrapper {
    width: 100%;
    left: 50% !important;
  }

  .form {
    width: calc(100% - 32px);
  }

  .closeBtn {
    margin-top: 12px;
    margin-right: 12px;
  }

  .previewFieldItem {
    display: flex;
    flex-direction: column;
  }

  .previewBody {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 30px;
    margin-top: 0;
  }
}
