@import 'static/assets/styles/global/global-varibles.scss';
.space {
  display: flex;

  &__preview {
    width: 78px;
    height: 100%;

    &_icon {
      margin-left: 18px;
    }

    &_no {
      display: none;
    } 
  }

  &__content {
    width: calc(100% - 77px);

    &_full {
      width: 100% !important;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;

  &__content {
    &_title {
      margin: 0;
      font-family: $sf_pro;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      color: var(--textColor);
    }

    &_subtitle {
      font-family: $sf_pro;
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;      
      color: var(--newGrayTextColor);
    }
  }
  &__actions {
    display: flex;
    align-items: center;
  }
}

.row {
  padding: 25px 24px;
  display: flex;

  &__heder {
    &_title {
      font-family: $sf_pro;
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: var(--textColor);
    }

    &_subtitle {
      font-family: $sf_pro;
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0em;
      max-width: 890px;
      color: var(--newGrayTextColor);
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
    &_end {
      justify-content: flex-end;
    }
    &_around {
      justify-content: space-around;
    }
  }

  &_list {
    padding: 16px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .space {
    padding: 16px 16px 16px 10px;
    &__preview {
      display: none;
    }
    &__content {
      width: 100%;
    }
  }

  .header__actions {
    align-items: start;
  }

  .row {
    &_list {
      flex-direction: column;
      margin-top: 20px;
      gap: 10px;
    }
  }

  .header__content_title {
    max-width: calc(100% - 64px);
  }
}
