@import 'static/assets/styles/global/global-varibles';

.wrapper {
  background: transparent;
}

.container {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  font-family: $sf_pro;
}

.logo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 30%;
  height: 60vh;
  background-size: contain;
}

.form {
  margin: 0 30px;
  position: relative;
  max-width: 824px;
  width: 100%;
  background: rgba(245, 245, 245, 0.4);
  backdrop-filter: blur(12px);
  padding: 50px 80px;
}

.topLeftCorner {
  position: absolute;
  top: 0;
  left: 0;
}

.topRigthtCorner {
  position: absolute;
  top: 0;
  right: 0;
}

.topRigthtCorner > path {
  stroke: #1a1a1a;
}

.bopLeftCorner {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bopLeftCorner > path {
  stroke: #1a1a1a;
}

.botRightCorner {
  position: absolute;
  bottom: 0;
  right: 0;
}

.botRightCorner > path {
  fill: #1a1a1a;
}

.logo > svg {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0;
  text-align: left;
  color: #202124;
  text-transform: uppercase;
}

.text {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.4;
  margin-top: 4px;
  margin-bottom: 50px;
  color: #202124;
}

.formTitle {
  margin-top: 40px;
  margin-bottom: 0;
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    padding-bottom: 30px;
    padding: 0 30px;
  }

  .logo {
    width: 70%;
    height: 25vh;
  }

  .form {
    margin-top: 10%;
    padding: 30px 40px;
  }

  .formTitle {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    height: 100%;
    overflow-y: auto;
  }
  .container {
    padding: 0 16px;
    margin: 20px 0;
  }

  .form > header,
  .formTitle {
    display: block;
  }

  .logo {
    display: none;
  }

  .form {
    width: 100%;
    padding: 0;
    backdrop-filter: initial;
    margin: 0;
    background-color: initial;
  }

  .corners {
    display: none;
  }
}
