.check__all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  margin-bottom: 10px;

  & > .file-action {
    margin-left: auto;
  }

  &.with--margin {
    margin: 32px 0 15px;
  }
  &__name {
    color: $primary_dark;
    line-height: rem(24px);
    margin-right: 12px;
  }
  &__btn {
    border: 1px solid $thirty_color;
    border-radius: 100%;
    width: 25px;
    height: 25px;
  }
  &__file {
    display: flex;
    align-items: center;
  }
  &__sort {
    &--text {
      color: $primary_dark;
      font-family: $sf_pro;
      line-height: rem(24px);
      letter-spacing: rem(1.4px);
    }
    &--btn {
      cursor: pointer;
      color: $primary_color;
      font-family: $sf_pro;
      line-height: rem(24px);
      letter-spacing: rem(1.4px);
      &:after {
        content: '';
        background: url('/static/assets/img/icon-arrow.svg');
        width: 7px;
        height: 12px;
        margin: -1px 6px;
        transform: rotate(90deg);
        display: inherit;
      }
    }
  }
}
