.mainWrapper {
  min-height: 50px;
  max-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 8px 2px 8px 16px;
  border-color: var(--terminalBorder);
  border-radius: 0;
  border: 0.8px solid var(--terminalBorder);
  transition: all 0.3s ease;
  border-radius: 8px;
}

.alignItemsCenter {
  height: 38px;
  align-items: center;
  .buttonsWrapper {
    top: 50%;
    transform: translateY(-50%);
  }
}

.wrapper:hover {
  min-height: 50px;
  border: 1px solid var(--primeTextColor);
  border-radius: 8px;
}

.wrapperFocus {
  min-height: 50px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 8px;
}

.container {
  flex: 1;
  display: flex;
  min-height: 18px;
  color: var(--textColor);
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}

.textarea {
  padding: 0;
  padding-right: 90px;
  background-color: transparent;
  border: none;
  width: 100%;
  color: var(--textColor);

  font-family: 'Nunito';
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  min-height: 18px;
  overflow-y: auto;
  resize: none;
  margin-top: 4px;
}

.smallerPadding {
  padding-right: 34px;
}

.input::placeholder {
  font-size: 18px;
}

.buttonsWrapper {
  position: absolute;
  right: 21px;
  top: 12px;
  display: flex;
  color: white;
}

.buttonsList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
}
.button {
  color: var(--terminalBorder);
  cursor: pointer;

  &:hover {
    color: var(--primeTextColor);
  }
}

.buttonSend {
  position: relative;
  width: 24px;
  height: 24px;
}

.activeButton {
  color: #fff;
}

.logoButton {
  padding: 2px 0;
}

.triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
}

.square {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  width: 17px;
  height: 17px;
  border: 1px solid #fff;
}

.triangleToSquare {
  .triangle {
    animation: hideTriangle 1s ease forwards;
  }
  .square {
    animation: showSquare 1s ease forwards;
  }
}

.squareToTriangle {
  .triangle {
    animation: showTriangle 1s ease forwards;
  }
  .square {
    animation: hideSquare 1s ease forwards;
  }
}

@keyframes hideTriangle {
  from {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
  to {
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
  }
}
@keyframes showSquare {
  from {
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
}
@keyframes showTriangle {
  from {
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
}
@keyframes hideSquare {
  from {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
  to {
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
  }
}
