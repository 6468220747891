@import 'static/assets/styles/global/global-varibles';

.title {
  font-family: $sf_pro;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: $view_overlay;
  margin-top: 24px;
  white-space: nowrap;
}

.button {
  height: 48px;
  width: 202px;
  margin: 0 auto;
  &__join {
    margin-top: 24px;
  }
  &__ghost {
    background-color: transparent;
    border: none;
    color: #4b576c;
    &:hover {
      background-color: transparent;
    }
  }
}

.authWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 294px);
}

.authContent {
  width: 488px;
  background: $primary_light;
  box-shadow: 0 2px 12px rgba(15, 115, 239, 0.15);
  border: 2px solid $primary_color;
  margin: 0 auto;
  padding: 56px 40px;
  &__title {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 32px;
    color: $view_overlay;
    text-align: center;
  }
  &__forgot {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: $dark_grey;
    margin: 10px 0 0 0;
    display: block;
  }
  &__login {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $primary_dark;
    color: $primary_light;
    transition: background 0.3s;
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    padding: 6px 24px 4px;
    width: 100%;
    height: 48px;
    margin: 32px 0 0 0;

    &:hover {
      background-color: $primary_color-light;
    }

    &:active {
      background-color: $primary_color-dark;
    }

    &[disabled] {
      background-color: $row_light_grey;
      color: $fifty_color;
      cursor: auto;
    }
  }
}

.authFooter {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 22px;
  color: $dark_grey;
  text-align: center;
  margin: 25px 0 0 0;

  &__link {
    text-transform: uppercase;
  }
}

@media screen and (max-width: 480px) {
  .authContent {
    width: auto;
    margin: 15px;
    &__login {
      width: auto;
    }
  }
}
