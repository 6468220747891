@import 'static/assets/styles/global/global-varibles';

.container {
  display: flex;
  font-family: $sf_pro;
  margin-top: 8px;
}

.label {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: var(--textColorGray);
  min-width: 120px;
}

.password {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--textColor);
}

.copyBtn {
  margin-left: 10px;
  color: var(--iconColor);
}

.showBtn {
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}
.showBtn > svg {
  color: var(--iconColor);
}

.filedPreviewValue {
  display: flex;
}

@media screen and (max-width: 480px) {
  .container {
    flex-direction: column;
  }
}