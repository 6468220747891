@import 'static/assets/styles/global/global-varibles';

.header {
  font-family: $sf_pro;
  display: flex;
  align-items: center;
}

.defaultLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid var(--iconColor);
  border-radius: 50%;
  min-width: 46px;
  height: 46px;
}

.defaultLogo > svg {
  color: var(--iconColor);
}

.name {
  color: var(--textColor);
  margin-left: 20px;
}

.editBtn {
  cursor: pointer;
  margin-left: auto;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 3px 7px 5px 7px;
}

.logoWrapper {
  min-width: 46px;
  height: 46px;
  border-radius: 50%;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}