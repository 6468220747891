.react-datepicker {
  &-wrapper {
    width: 100%;
  }

  &-popper {
    left: 15%;
  }

  &__input-container {
    display: flex;
    justify-content: center;

    .input {
      background: $row_light_grey
        url(/static/assets/img/icons/input/calendar.svg) no-repeat scroll center
        right 15px;
      height: 48px;
      width: 100%;
      padding: 12px 0 12px 14px;
      border: 1px solid $primary_color;
      box-sizing: border-box;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;

      &:focus {
        border: 1px solid $primary_color;
      }

      &::placeholder {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $colGrayMed;
      }
      &-field {
        background: $primary_light;
        border: 1px solid $button-border;
        color: $colBlackText;
      }
    }
  }
}

.time-picker {
  .rc-time-picker {
    &-input {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      background: $row_light_grey url(/static/assets/img/icons/input/clock.svg)
        no-repeat scroll center right 15px;
      border: 1px solid $primary_color;
      border-radius: 0;
      height: 48px;
      padding: 12px 0 12px 14px;
      color: $primary_black;

      &::placeholder {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $thirty_color;
      }
    }

    &-clear {
      display: none;
    }
  }
}

.drive {
  .rc-time-picker {
    &-panel {
      min-width: 224px;

      &-input {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        border-radius: 0;
        height: 36px;
        background-color: $row_light_grey;
        padding: 0 0 0 14px;

        &::placeholder {
          font-family: $sf_pro;
          font-size: 14px;
          line-height: 24px;
          color: $thirty_color;
        }

        &-wrap {
        }
      }

      &-inner {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        border-radius: 0;
        background-color: $row_light_grey;
        width: 100%;
      }
    }
  }
}
