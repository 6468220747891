@import 'static/assets/styles/global/global-varibles';

.svgContainer {
  max-width: 681px;
  max-height: 423px;
  width: 100%;
  height: 100%;
  color: white;
}
.leftCircle,
.rightCircle {
  animation: rotate 35s linear infinite;
}

.leftCircle {
  animation-direction: reverse;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
