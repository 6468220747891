.corners {
  width: 100%;
  height: 100%;
}

.topLeftCorner {
  position: absolute;
  left: 0;
  top: 0;
}

.topRightCorner {
  position: absolute;
  right: 0;
  top: 0;
}

.topRightCorner > path {
  stroke: #000;
}

.botLeftCorner {
  position: absolute;
  left: 0;
  bottom: 0;
}

.botLeftCorner > path, .topLeftCorner > path  {
  stroke: #000;
}

.botRightCorner {
  position: absolute;
  right: 0;
  bottom: 0;
}

.botRightCorner > path {
  fill: #000;
}
