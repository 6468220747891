.checkbox-menu {
  width: 100%;
  &__label {
    width: 100%;
    border: 1px solid #ccceda;
    background-color: #f6f8fc;
    padding: 10px 17px;
    color: #10161a;
    font-family: $sf_pro;
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }
}

.checkbox-menu__input:checked ~ .checkbox-menu__label {
  color: #ffffff;
  background-color: #206acf;
  border: 1px solid #206acf;
}
