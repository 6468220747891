@import 'static/assets/styles/global/global-varibles.scss';

.wrapper {
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  background-color: transparent;
}

.icon {
  color: var(--iconColor);
}

