.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
}

.button {
  position: absolute;
  border-radius: 100%;
  top: .8px;
  left: 1px;
  width: 100%;
  height: 100%;
  max-width: 22px;
  max-height: 22px;
  background-color: #FFFFFF;
}

.darkIcon {
  color: #ffb800;
  margin-right: 2px;
}

.lightIcon {
  color: #202124;
  margin-right: 2px;
}

