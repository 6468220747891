@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  padding-top: 9px;
}

.inputWrapper {
  display: flex;
  position: relative;
  width: auto;
  height: 48px;
  border-radius: 9px;
  border: 1px solid #f0f0f2;
  background-color: #ffffff;
  margin: 0 16px;
}

.input {
  width: 100%;
  font-family: $sf_pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  padding: 12px 16px;
  border: none;
  border-radius: 9px;
}

.input:focus {
  border: none;
}

.recordBtn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.activeRecordBtn {
  background: #ea4335;
  border-radius: 50%;

  & svg {
    fill: #fff;
  }
}

.activeRecordBtn::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  animation: waveAnimation 1.2s infinite;
}

// tabs start

.tabs {
  display: flex;
  justify-content: space-between;
  background-color: #7676800f;
  border-radius: 9px;
  padding: 2px;
  margin: 0 16px;
  gap: 2px;
}

.tab {
  width: 100px;
  display: flex;
  flex-grow: 1;
  height: 21px;
  border-radius: 7px;
  padding: 3px, 10px, 3px, 10px;
  border: 0.5px solid transparent;
  cursor: pointer;
}

.tab > span {
  width: 100%;
  text-align: center;
  align-items: center;
  line-height: 21px;
  font-family: $sf_pro;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #7d7d7d;
}

.tabActive {
  border: 0.5px solid #0000000a;
  background-color: var(--fileBg);

  & > span {
    color: var(--textColor);
  }
}

.seperateRight > div:last-child {
  position: relative;

  &::after {
    position: relative;
    right: calc(100% + 1px);
    content: '';
    height: 12px;
    min-width: 1px;
    top: 50%;
    border-radius: 1px;
    transform: translateY(-50%);
    background-color: #8e8e93;
  }
}

.seperateLeft > div:first-child {
  position: relative;

  &::before {
    position: relative;
    left: calc(100% + 1px);
    content: '';
    height: 12px;
    min-width: 1px;
    top: 50%;
    border-radius: 1px;
    transform: translateY(-50%);
    background-color: #8e8e93;
  }
}

/// tab end

.messenger {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0 16px;
}

.messageNeiro {
  position: relative;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px 16px 20px 27px;
  box-shadow: 0px 3px 6px 0px #05295626;
  color: #1a1d26;
}

.messageNeiro::after {
  content: '';
  transform: rotate(90deg);
  position: absolute;
  right: 6px;
  bottom: -4px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #ffffff;
  clear: both;
  transform: rotate(90deg);
}

.message {
  position: relative;
  border-radius: 8px;
  background-color: #303030;
  padding: 24px 16px 16px 27px;
  box-shadow: 0px 3px 6px 0px #05295626;
  color: #ffffff;
}

.message::after {
  content: '';
  transform: rotate(90deg);
  position: absolute;
  left: 6px;
  bottom: -4px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid #303030;
  clear: both;
  transform: rotate(-90deg);
}

.message > p,
.messageNeiro > p {
  margin: 0;
  font-family: $sf_pro;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.message > .timeStamp {
  font-family: $sf_pro;
  font-size: 7px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0px;
  position: absolute;
  bottom: -14px;
  left: 20px;
  color: var(--textColor);
}

.messageNeiro > .timeStamp {
  font-family: $sf_pro;
  font-size: 7px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0px;
  position: absolute;
  bottom: -14px;
  right: 20px;
  color: var(--textColor);
}

.neyroActions {
  display: flex;
  bottom: 0px;
  left: 30px;
  gap: 4px;
  margin-top: 10px;
}

.neyroActionBtn {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.neyroActions > button > svg {
  color: var(--neyra_text_color);
}

.transformedText {
  position: relative;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px 16px 16px 27px;
  box-shadow: 0px 3px 6px 0px #05295626;
  color: #1a1d26;
  margin-top: 10px;
}

// voice message

.voicePlayer {
  display: flex;
  flex-direction: column;
}

.voiceBtn {
  cursor: pointer;
  margin-right: 8px;
}

.voice {
  display: flex;
}

.voice > button > svg {
  color: #fff;
}

.voiceDuration {
  font-family: $sf_pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 0;
  margin-top: 6px;
  padding-left: 40px;
}

// voice message

.voicePlayer {
  display: flex;
  flex-direction: column;
}

.voiceBtn {
  cursor: pointer;
  margin-right: 8px;
}

.voice {
  display: flex;
}

.voice > button > svg {
  color: #fff;
}

.voiceBar {
  height: 27px;
  width: 100%;
}

.voiceDuration {
  font-family: $sf_pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 0;
  margin-top: 6px;
  padding-left: 40px;
}

// voice message

.voicePlayer {
  display: flex;
  flex-direction: column;
}

.voiceBtn {
  cursor: pointer;
  margin-right: 8px;
}

.voice {
  display: flex;
}

.voice > button > svg {
  color: #fff;
}

.voiceBar {
  height: 27px;
  width: 100%;
}

.voiceDuration {
  font-family: $sf_pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 0;
  margin-top: 6px;
  padding-left: 40px;
}

@keyframes waveAnimation {
  0% {
    transform: scale(1);
    background-color: rgba(234, 67, 53, 1);
  }
  50% {
    transform: scale(1.4);
    background-color: rgba(234, 67, 53, 0.7);
  }
  100% {
    transform: scale(1);
    background-color: rgba(234, 67, 53, 1);
  }
}
