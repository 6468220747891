.account-settings {
  width: 100%;
  height: 100%;
  background: #ffffff;
  &__content {
    width: 100%;
    height: 100vh;
    position: absolute;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__tools {
    flex: 1 0 auto;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 16px 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
    .tools-item {
      width: 100%;
      &:not(:first-child) {
        margin-top: 24px;
      }
      &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      &__title {
        color: $primary_dark;
        line-height: rem(24px);
        margin-bottom: 8px;
      }
      &__content {
        width: 100%;
      }
      &__label {
        margin-right: 8px;
      }
      &__input {
        width: 100%;
        height: 48px;
        border: 1px solid #ccceda;
        background-color: #f6f8fc;
        color: #abadb9;
        font-family: $sf_pro;
        font-size: rem(14px);
        line-height: rem(24px);
        padding: 0 16px;
        margin-bottom: 12px;
        &.invalid {
          color: #ed1d2d;
        }
        &_date {
          height: 48px;
          width: 100%;
          max-width: 408px;
          border: 1px solid #ccceda;
          background-color: #f6f8fc;
          color: $primary_dark;
          font-family: $sf_pro;
          font-size: rem(14px);
          line-height: rem(24px);
          padding: 0 16px;
          margin: 0 0 12px 0;
        }
        &_timezone {
          & > input {
            height: 48px;
            width: 100%;
            max-width: 207px;
            border: 1px solid #ccceda;
            background-color: #f6f8fc;
            color: $primary_dark;
            font-family: $sf_pro;
            font-size: rem(14px);
            line-height: rem(24px);
            padding: 0 16px;
            margin: 6px 25px 6px 0;
          }
          ul {
            background-color: #f6f8fc;
            max-width: 207px;
          }
        }
      }
      &__image {
        width: 72px;
        border: 1px solid #ccceda;
        background-color: #f6f8fc;
        border-radius: 0;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;

        & .arrow {
          display: none;
        }
      }
      &__text {
        color: #58595f;
        line-height: rem(21px);
      }
    }
    &__checkboxes {
      width: 100%;
      border-top: 1px solid #ccceda;
      margin-top: 20px;
      padding: rem(12px) 0;
      &-title {
        color: #10161a;
        line-height: 1.5rem;
        margin-bottom: 8px;
        font-family: $sf_pro;
        font-size: 0.875rem;
      }
      &__small {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__item {
          width: 45%;
          display: flex;
          flex-direction: column;
        }
      }
      &__box {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    &-unlimeted {
      min-height: max-content;
      border-top: 1px solid #ccceda;
      padding: 0;
      padding-top: 16px;
      margin-top: 16px;
      .country-phone-number {
        margin-bottom: 0;
      }
      .headline {
        color: #10161a;
        line-height: 1.5rem;
        margin-bottom: 8px;
        font-family: $sf_pro;
        font-size: 0.875rem;
        margin: 0;
        margin-bottom: 10px;
        &-tip {
          margin: 0;
        }
      }
      .react-numeric-input {
        width: 100% !important;
        input {
          width: 100%;
          height: 40px;
          border: 1px solid #ccceda;
          background: #f6f8fc;
          color: #abadb9;
          font-family: $sf_pro;
          padding: 0 16px !important;
          font-size: 0.875rem;
          line-height: 1.5rem;
        }
      }
      .tools-item {
        margin-top: 0 !important;
      }
    }
    &__btn {
      width: 45%;
      border: none;
      outline: none;
      line-height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      min-width: 164px;
      height: 36px;
      margin: 40px 0 50px auto;
      border-radius: 28px;
      background-color: #206acf;
      cursor: pointer;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 1.4px;
    }
  }
  &__footer {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 64px;
    box-shadow: inset 0 1px 0 0 #ccceda;
    .delete-account {
      color: #ed1d2d;
      font-family: $sf_pro;
      font-size: rem(12px);
      letter-spacing: rem(1.4px);
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  &__popup {
    position: absolute;
    top: 12px;
    right: 0;

    width: 452px;
    height: auto;
    padding: 20px;

    background-color: white;
    border: 1px solid #ccceda;
    transform: translate(0, 0);
    z-index: 100;
    user-select: none;
  }
}
.noborder {
  border: none;
  margin-top: 0;
}
.country-phone-number {
  margin-bottom: 12px;

  & .react-phone-number-input__input {
    @extend .tools-item__input;
  }

  & .react-phone-number-input__country--native {
    width: 60px;
    border: 1px solid #ccceda;
    background-color: #f6f8fc;
    border-radius: 0;
    border-right: none;
    margin-right: 0;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  & .react-phone-number-input__country-select-arrow {
    display: none;
  }
}
svg {
  vertical-align: middle;
}
