.cdx-block,
.ce-header {
  color: var(--textColor);
}

.ce-block--selected .ce-block__content {
  background-color: var(--sidebarHover);
}

.cdx-input {
  background-color: var(--inputBg);
  border-color: var(--inputBorder);
  color: var(--textColor);
}

.cdx-warning::before {
  background-color: var(--editorIconBg);
  border-radius: 4px;
}

.ce-toolbar__plus {
  color: var(--iconColor);
}

.ce-toolbar__plus:hover {
  background-color: var(--hover);
}

.ce-toolbar__settings-btn {
  color: var(--iconColor);
}

.ce-toolbar__settings-btn:hover {
  background-color: var(--hover);
}

.ce-popover,
.tc-popover {
  background-color: var(--tooltipContentSecond);
  border: none;
  color: var(--textColor);
}

.ce-popover__item-icon,
.tc-popover__item-icon {
  color: #202124;
  background-color: var(--comingSoonLabelBackground);
}

.tc-popover__item-icon {
  border: 1px solid rgba(226, 226, 229, 0.2);
}

.tc-cell--selected,
.tc-row--selected,
.tc-add-column:hover,
.tc-add-row:hover,
.tc-add-row:hover:before,
.tc-popover__item:hover {
  background-color: var(--sidebarHover);
}

.ce-popover__item--active {
  color: var(--textColor);
  background-color: var(--sidebarHover);
}

.ce-popover__item:hover:not(.ce-popover__item--no-visible-hover) {
  background-color: var(--sidebarHover);
}

.cdx-search-field {
  background-color: var(--comingSoonLabelBackground);
}

.cdx-search-field__icon svg,
.cdx-search-field__input::placeholder {
  color: var(--textColorGraySecond);
}
