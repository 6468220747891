.popups-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 15px 16px;

  max-width: 100%;
  min-height: 65px;

  border-bottom: 1px solid #ccceda;

  &__title {
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: var(--textColor);

    &__before {
      color: #206acf !important;
    }
  }

  &__cancel {
    &-icon {
      color: var(--iconSecondColor);
      cursor: pointer;
    }
  }
}
