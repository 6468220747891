.modalContainer {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: auto;
  width: 630px;
  padding: 40px;
  text-align: center;
  background-color: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  &__exit {
    color: #202124;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin: auto 0 0 0;
    padding: 0;
  }
}

.title {
  margin-top: 64px !important;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  margin: 0 0 8px 0;
}

.description {
  margin-bottom: 48px !important;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  color: #7e8a9f;
  margin: 0 0 12px 0;
}

.button {
  background-color: #202124;
  border: none;
  &:hover {
    background-color: #202124;
  }
}
