@import 'static/assets/styles/global/global-varibles';

.keyIcon {
  top: 12px;
  left: 16px;
  position: absolute;
  z-index: 1;
  color: #7e8a9f;
}

.wrapper {
  position: relative;

  &__button {
    font-family: $sf_pro;
    position: absolute;
    min-width: 91px;
    min-height: 32px;
    padding: 11px 16px 9px 16px;
    top: 7px;
    right: 7px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.inputWrapper {
  & > div {
    margin-top: 10px;
  }
  & > input {
    padding-left: 50px;
    padding-right: 135px;
    margin-bottom: 0;
    overflow: hidden;
    background-color: #f6f8fc;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;

    &:focus {
      border: 1px solid #0f73ef;
    }
  }
}

.uploadBtn {
  font-family: $sf_pro;
  position: absolute;
  min-width: 91px;
  min-height: 32px;
  padding: 11px 16px 9px 16px;
  top: 7px;
  right: 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}