.wrapper {
  margin: 10px 0;
  padding: 20px 0 40px;
  width: 100%;
  height: calc(100% - 20px);
  overflow: auto;
  scrollbar-width: 1px;
  scrollbar-color: #e0d6d6;
  &::-webkit-scrollbar,
  &::-moz-scrollbar {
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.conversationContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
  width: 75%;
}

.shadowBlock {
  position: absolute;
  top: 0;
  left: 5px;
  right: 5px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.shadowBlockBottom {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
  width: calc(100% - 10px);
  height: 60px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}
