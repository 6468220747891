@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  position: relative;
  margin-top: 32px;
  font-family: $sf_pro;
}

.action {
  cursor: pointer;
  color: #0F73EF;
  font-size: 12px;
  line-height: 16px;
}

.popup {
  width: 176px;
  flex-direction: column;
  bottom: 100%;
  background: #FFFFFF;
  border-bottom: 0.5px solid #E4E7EE;
  box-shadow: 0px 2px 8px #CCCEDA;
  position: absolute;
  display: none;
  padding: 6px 16px;
}

.popupOpen {
  display: flex;
}

.popupItem {
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
}

.popupItem > span {
  margin-left: 8px;
}

@media screen and (max-width: 480px) {
 .wrapper {
   margin-top: 21px;
 }
}