@import 'static/assets/styles/global/global-varibles';

.right-menu {
  grid-area: right;
  position: relative;
  z-index: 1002;

  &__white {
    background: $primary_light;
    .right-menu__content-wrapper {
      background: $primary_light;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    color: $primary_dark;
    padding: 25px 0 0 0;
  }

  &__wrapper {
    &-fixed {
      width: 345px;
      background-color: transparent;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 60px 65px;
    max-width: 100%;
    height: 125px;
    border-bottom: 1px solid $forty_color;
    background: $primary_light;
    position: relative;

    &__title {
      font-family: $sf_pro;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 1.4px;
      color: $primary_dark;
      &__before {
        color: $primary_color;
      }
    }

    &__cancel-icon {
      position: absolute;
      top: 27px;
      right: 16px;
    }
  }

  &__content-wrapper {
    background: $row_light_grey;
    box-shadow: inset 0 1px 0 $shadow_grey_light;
    height: 100vh;

    &-headless {
      padding-top: 125px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 5px 4.5vw;

    button {
      margin: 0 auto;
    }
  }

  .checkbox {
    margin: 0 12px 0 0;

    &__container {
      display: flex;
      margin: 12px 0 0 0;
    }

    &__label {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $title_section;
      margin: 0 48px 0 0;
    }
  }

  &__input {
    &-container {
      display: flex;
      align-items: center;
      padding: 12px 0 0 0;

      input {
        margin: 0;
        width: 100%;
      }

      .add-button {
        margin: 0;
      }
    }

    &-radio {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &-styled {
        position: absolute;
        top: 0;
        left: 0;
        height: 23px;
        width: 23px;
        background-color: $row_light_grey;
        border: 1px solid $radio_unchecked;
        border-radius: 50%;

        &:after {
          content: '';
          position: absolute;
          display: none;
          top: 8px;
          left: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $primary_light;
        }
      }

      &-label {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        color: $title_section;

        display: block;
        position: relative;
        padding: 0 0 0 35px;
        margin: 0 36px 0 0;
        cursor: pointer;
        user-select: none;

        &:hover input ~ .right-menu__input-radio-styled {
          background-color: $primary_color-light;
          height: 24px;
          width: 24px;
          border: none;
        }

        input:checked ~ .right-menu__input-radio-styled {
          background-color: $primary_color;
          height: 24px;
          width: 24px;
          border: none;
        }

        input:checked ~ .right-menu__input-radio-styled:after {
          display: block;
        }
      }
    }
  }

  .team-user {
    &__list {
      margin: 32px 0 45px 0;

      .user-roles-item {
        background: none;
        position: relative;
        cursor: pointer;
        user-select: none;
        margin: 16px 0 0 0;

        .file-action {
          transition: all 0.3s;
          position: absolute;
          top: 12px;
          right: 25px;
          opacity: 0;
          visibility: hidden;
        }

        &:hover {
          box-shadow: 0 6px 19px -5px rgba(0, 0, 0, 0.2);

          .file-action {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .right-menu {
    z-index: 1003;
    position: fixed;
    &__wrapper-fixed {
      width: 100%;
    }
  }
}
