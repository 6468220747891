.smart-contracts {
  &-block {
    position: relative;
    width: 50%;
    padding: 0 8px 0 0;

    .view-all {
      position: absolute;
      right: 9px;
      top: 18px;
      font-size: 12px;
      line-height: 24px;
      color: $primary_color;
      text-decoration: none;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background: $primary_light;
    font-size: 12px;
    line-height: 24px;
    color: $light_grey;
    height: 68px;
    padding: 10px 24px;

    &:nth-child(2n) {
      background: $row_light_grey;
    }

    &__name {
      width: 65%;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $title_section;
    }

    &__file {
      width: 35%;
      text-align: right;
      a {
        color: $more_icon_color;
        text-decoration: none;
      }
    }

    &__date {
      width: 65%;
    }

    &__timer {
      width: 35%;
      text-align: right;
    }
  }
}
