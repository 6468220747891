@import 'static/assets/styles/global/global-varibles';
.sidebarWrapper {
  top: 0;
  right: 0;
  width: 345px;
  min-width: 345px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 15px 18px 0;
  background-color: transparent;
  z-index: 1001;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;

    & button {
      flex-grow: 1;
      cursor: pointer;
      padding: 3px 3px;
      color: var(--aiGeneratorHeader);
      font-size: 12px;
      font-family: $sf_pro;
      font-weight: 500;
      text-transform: uppercase;
      transition: color 100ms ease-in-out;
      &:hover {
        color: var(--aiGeneratorHeaderActive);
      }
    }
  }
  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    bottom: 84px;
    left: 0;
    width: 100%;
    height: 60px;
    background: var(--studioFiltersShadow);
    opacity: 1;
  }
}

.shadowHidden::after {
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}

.buttonDisable:first-of-type {
  background-color: transparent;
  & > svg {
    color: #b9b9b9;
  }
}

.backOption {
  padding: 11px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--textColor);
  margin-bottom: 10px;
  border-radius: 20px;
  border: 0.7px solid #5e5e5e;
  min-height: 62px;
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    font-family: 'Nunito';
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  & div {
    cursor: pointer;
    color: var(--textColor);
    padding: 5px;
  }
}

.imageOptionsList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  &__item {
    border-radius: 16px;
    border: 0.5px solid var(--grayTabBorder);
    padding: 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & p {
      color: var(--textColor);
      font-family: 'Nunito';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }

    svg {
      width: 40px;
      height: 40px;
      color: var(--iconColor);
      &:hover {
        color: var(--iconColor);
      }
      & > rect {
        transition: stroke 100ms ease-in-out;
        color: var(--iconColor);
      }
      &:hover > rect {
        transition: stroke 100ms ease-in-out;
        stroke: var(--iconColor);
      }
    }
    & button {
      cursor: pointer;
      &:disabled svg rect {
        stroke: #5e5e5e;
      }
      &:disabled svg path {
        fill: #5e5e5e;
      }
    }
  }
}

.creativityWrapper {
  flex-basis: 1;
  width: 100%;
  margin-right: 20px;
  padding-bottom: 14px;
  & h3 {
    color: var(--textColor);
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 0 5px;
  }
  & input[type='range'] {
    cursor: pointer;
    width: 100%;
    padding: 1px 0;
    border-radius: 8px;
    height: 3px;
    outline: none;
    border: none;
    transition: background 450ms ease-in;
    accent-color: #fff;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
    }
  }
  &__value {
    width: 60px;
    border: 0.5px solid var(--studioPanelBorder);
    padding: 10px 10px;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    color: var(--textColor);
  }
}

.searchWrapper {
  width: 100%;
  margin-bottom: 24px;
  & input {
    width: 100%;
    padding: 11px 16px;
    border-radius: 16px;
    border: 0.5px dashed var(--searchInput);
    background-color: transparent;
    color: var(--textColor);
    font-family: 'Nunito';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    &::placeholder {
      color: var(--convertModalBtnColor);
    }
  }
}

.tabsWrapper,
.modelsWrapper {
  display: flex;
  padding: 6px;
  gap: 12px;
  border-radius: 12px;
  border: 0.5px dashed var(--grayTabBorder);
  margin-bottom: 10px;
  transition: border 200ms ease-in-out;
  &:hover {
    border: 0.5px solid var(--grayTabBorder);
  }
  & div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    color: var(--textColor);
    font-family: 'Nunito';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    border-radius: 8px;
    background: transparent;
    transition: background 200ms ease-in-out;
    &.activeTab,
    &:hover {
      background: var(--lightGrayTab);
    }
  }
}

.modelsWrapper {
  flex-direction: column;
  gap: 6px;
  & > div {
    gap: 12px;
    &:hover {
      background: transparent;
    }
  }
}

.solidBorder {
  border: 0.5px solid var(--grayTabBorder);
}
.dashedBorder {
  border: 0.5px dashed var(--grayTabBorder);
}

.filtersTitle,
.modelTitle {
  color: var(--textColor);
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  margin: 0 auto 12px;
  padding: 0;
  text-align: center;
}
.modelTitle {
  margin: 0;
}

.filterWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 20px;
  overflow: auto;
  height: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.filterCard {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 9px 16px;
  box-sizing: border-box;

  color: #fff;
  background: transparent;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 8px;
  height: fit-content;

  & img {
    border-radius: 5px;
    width: 80px;
    height: 80px;
  }

  &__label {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    color: var(--textColor);
    text-align: center;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  &__checked {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.095px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1.523809552192688px);
    & svg {
      color: #ffb800;
      width: 20px;
      height: 20px;
    }
  }

  &_selected.filterCard::before,
  &:hover::before {
    background: linear-gradient(180deg, #ffb800, var(--filterCardBorder2Studio))
      border-box;
  }

  &_selected .filterCard__label,
  &:hover .filterCard__label {
    color: #ffb800;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    border: 1px solid transparent;
    background: linear-gradient(
        180deg,
        var(--filterCardBorder1Studio),
        var(--filterCardBorder2Studio)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.historyContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}

.historyWrapper {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  color: #fff;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.historyItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px 12px;
  border-radius: 12px;
  border: 0.5px dashed var(--searchInput);
  transition: all 1000ms ease-in-out;
  &:hover {
    border: 0.5px solid var(--searchInput);
  }

  & p {
    margin: 0;
    color: var(--historyItemText);
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__button {
    cursor: pointer;
    color: #656565;
    font-family: 'Nunito';
    transition: color 100ms ease-in-out;
    &:hover {
      color: var(--textColor);
    }
    &:disabled {
      cursor: pointer;
      color: #656565;
    }
    & svg {
      height: 14px;
      width: 14px;
    }
  }
}

@media (max-width: 480px) {
  .sidebarWrapper {
    padding: 0 16px 80px;
    margin-bottom: 50px;
    position: initial;
    width: 100%;
    overflow: hidden;
    &__header {
      display: none;
    }
  }
}
