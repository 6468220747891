@import 'static/assets/styles/global/global-varibles';

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img {
  height: 50px;
  color: var(--iconColor);
}

.title {
  font-family: $sf_pro;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--textColor);
  margin: 16px 0 8px 0;
}

.description {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--textColorGray);
  margin: 0 0 16px 0;
  max-width: 100%;
}

.saveButton {
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
}

.accessDenyTitle {
  font-family: $sf_pro;
  margin: 0 0 8px 0;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--textColor);
}
