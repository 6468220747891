.orderRingWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
}

.contentClass {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  border: none;
}

.titleBlock {
  color: #535353;
  font-family: Nunito;
  font-size: 27px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  text-transform: uppercase;
}

.activeTitle {
  color: #fff;
}

.block {
  padding: 31px 37px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sizeBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
}

.typeBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 61px 0 110px;
}

.featureBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 100px 0;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  color: #565151;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  transition: color 0.5s ease;

}

.featureItemActive {
  color: #fff;
}