.file-history {
  padding: 35px 0 0 2.5vw;

  &__info {
    display: flex;

    .file-icon {
    }

    &-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(95%);
    }

    &-size {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $primary_color;
    }
  }

  &__list {
    margin: 0 0 24px 3vw;
    padding: 0 0 0 2vw;
    border-left: 1px solid $forty_color;

    &-item {
      display: flex;
      padding: 30px 0 0 0;

      &-user {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 16px;
        color: $primary_dark;
      }

      &-date {
        font-family: $sf_pro;
        font-size: 11px;
        line-height: 16px;
        color: $fifty_color;
      }

      &-action {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 16px;
        color: $light_grey;
        text-transform: capitalize;
      }

      &-button {
        &.right-menu__container {
          margin: 0 0 0 auto;
        }
      }
    }
  }

  &__trial {
    font-family: $sf_pro;
    font-size: 16px;
    line-height: 24px;
    color: $primary_dark;
    margin: 0 0 24px 3vw;
  }
}
