.logo {
  color: white;
  cursor: pointer;
  position: fixed;
  z-index: 100;

  &:disabled:hover {
    border: none;
    cursor: initial;
    color: white;
  }
}
