.popover {
  position: absolute;
  display: none;
  z-index: 9999;
  font-family: $sf_pro;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: $primary_black;
  background: $primary_light;
  border-radius: 2px;
  padding: 10px 16px 8px;
  white-space: nowrap;
  filter: drop-shadow(0px 2px 12px rgba(26, 49, 139, 0.15));

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    right: calc(50% - 6px);
    display: block;
    z-index: 1;
    width: 0;
    height: 0;
    border-bottom: 4px solid $primary_light;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  &-right {
    left: calc(100% - 3px);

    &:before {
      top: calc(50% - 4px);
      right: 100%;
      border-right: 4px solid $primary_light;
      border-bottom: 4px solid transparent;
      border-top: 4px solid transparent;
    }
  }

  &-parent {
    position: relative;
  }

  &-parent:hover & {
    display: inline-block;
  }
}
