.overlay {
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1005;
}
.modal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  background-color: var(--grayBg);
  border: 0.5px solid hsla(0, 0%, 100%, 0.5490196078431373);
  border-radius: 10px;
  border: 1px solid var(--textColor);
}

.content {
  color: #fff;
  padding: 30px 36px;
}

.textarea {
  width: 100%;
  background: var(--chatInput);
  border: 0.5px solid var(--historyItemText);
  border-radius: 10px;
  padding: 10px;
  color: var(--textColor);
  resize: none;
  &:focus {
    border-color: var(--textColor);
  }
  &::placeholder {
    color: var(--convertModalBtnColor);
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}

.button {
  cursor: pointer;
  padding: 12px 20px;
  color: var(--historyItemText);
  border-radius: 12px;
  border: 0.7px solid var(--historyItemText);
  background: var(--actionButtonsColor);
  backdrop-filter: blur(5.800000190734863px);
  transition: all 200ms ease-in-out;

  &:hover {
    border: 0.7px solid #f5e12d;
    color: #f5e12d;
  }
}

.optionsWrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.option {
  border: 0.8px dashed var(--neyraBorderNavigationColor);
  padding: 8px 14px;
  color: var(--neyraBorderNavigationColor);
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  height: 33px;
  cursor: pointer;
  font-family: 'Nunito';
  transition: all 200ms ease-in-out;
  &:hover {
    border: 0.8px solid var(--neyraLogoColor);
    color: var(--neyraLogoColor);
  }
}

.activeOption {
  border: 0.8px dashed #fff;
  color: #fff;
}
