@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  align-items: inherit;
  position: relative;
  margin-right: 26px;
  height: 53px;
}

.deleteBtn {
  position: absolute;
  right: -20px;
  top: 16px;
  cursor: pointer;
  color: var(--iconColor);
}

.textInput {
  width: 70%;
  height: 53px;
}

.textInput > input {
  font-family: $sf_pro;
  height: 100%;
  background-color: var(--inputBg) !important;
  border: 1px solid var(--inputBorder);
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  color: var(--textColor);
  border: none;
}

.labelClass {
  width: 30%;
  border: none;
  font-family: $sf_pro;
  color: var(--textColor);
  font-size: 14px;
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  margin-right: 10px;
  background: var(--inputBg);
  backdrop-filter: blur(2px);
}

.editableLabel {
  margin-right: 10px;
  width: 30%;
}

.editableLabel > input {
  background-color: var(--inputBg);
  color: var(--textColor);
}

.editableInpu {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 14px 0 16px 16px;
  font-family: $sf_pro;
  color: #10161A;
  font-size: 14px;
  align-self: center;
  border: none;
  background: #FAFAFA;
  backdrop-filter: blur(2px);
}

@media screen and (max-width: 480px) {
  .wrapper {
    flex-direction: column;
    gap: 6px;
    height: 110px;
  }

  .labelClass, .editableLabel {
    width: 100%;
  }

  .editableLabel > input {
    padding-right: 16px;
  }

  .labelClass {
    padding-right: 16px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .textInput {
    width: 100%;
  }

  .deleteBtn {
    height: 20px;
    top: initial;
    bottom: 16px;
  }
}