@import 'static/assets/styles/global/global-varibles';

.wrapper {
  background: #fff;
}

.container {
  position: relative;
  width: calc(100% - 60px);
  margin: 0 30px;
  padding: 64px 80px 50px 80px;
  max-width: 824px;
  background: rgba(245, 245, 245, 0.4);
  backdrop-filter: blur(12px)
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #202124;
  margin: 0 0 4px 0;
}

.subTitle {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #7D7D7D;
  margin: 0;
}

.form {
  font-family: $sf_pro;
}

.transitions {
  margin-top: 30px;
}

.resend {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 44px;
  margin-top: 24px;
}

.resendTitle {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  color: #7D7D7D;
  margin-top: 14px;
  margin-bottom: 4px;
}

.inputLabel {
  font-size: 16px;
  line-height: 18px;
  color: #7D7D7D;
  margin-bottom: 16px;
}

.resendBtn {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #202124;
  cursor: pointer;
}

.nextBtn {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  width: 296px;
}

.input > input {
  border: none;
  padding: 0;
  border-bottom: 1px solid #202124;
  background-color: transparent;
  letter-spacing: -0.4px;
  color: #202124;
  font-weight: 500;
  font-size: 26px;
  line-height: 48px;
  letter-spacing: -0.4px;
}

.inputError > input {
  background-color: transparent;
  color: #FFB800;
}

.inputError > div {
  position: absolute;
  display: flex;
  color: #FFB800;
}

.inputError > div > svg {
  margin-right: 12px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.emailStep {
  margin-bottom: 90px;
}

.backBtn {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  width: 124px;
  min-width: initial;
  color: #202124 !important;
  margin-right: 24px;
  height: 36px;
}

.strong {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #202124;
}

.verification {
  display: flex;
  flex-direction: column;
}

.verificationTitle {
  font-size: 16px;
  line-height: 18px;
  color: #202124;
  margin: 0 0 10px 0;
}

.verificationDescription {
  font-size: 12px;
  line-height: 150%;
  color: #7D7D7D;
  margin: 0;
}

.verificationInput {
  display: flex !important;
  justify-content: space-between;
  margin-top: 36px;
}

.verificationInput > input {
  font-family: 'Gotham Pro';
  font-style: normal;
  width: 12%;
  height: 60px !important;
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  background-color: transparent;
  line-height: 48px;
  letter-spacing: -0.4px;
  color: #202124;
  height: 26px;
  border: none;
  padding: 0 20px 4px 20px;
  border-bottom: 1px solid #202124;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
.verificationInput > input::-webkit-outer-spin-button,
.verificationInput > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.verificationInput > input[type=number] {
  -moz-appearance: textfield;
}

.verificationInput > input::-webkit-input-placeholder { /* Edge */
  font-weight: 400;
}

.verificationInput > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-weight: 400;
}

.verificationInput > input::placeholder {
  font-weight: 400;
}

.verificationInput > input:nth-child(4)  {
  margin-left: 10%;
}

.verificationInputWrapper {
  position: relative;
}

.verificationInputError {
  display: flex;
  align-items: center;
  color: #FFB800;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  position: absolute;
  bottom: -28px;
}

.verificationInputError > svg {
  margin-right: 10px;
}

.inputPassword {
  margin-bottom: 24px;
}

.inputPassword:first-child {
  margin-bottom: 24px;
}

.inputPassword > button {
  right: 4px;
  top: 30px;
}

.inputLabelPassword {
  margin-bottom: 0;
}

.passwordHintsList {
  padding: 0 0 0 10px;
  display: grid;
  grid-template-columns: 140px 1fr;
}

.passwordHint {
  font-weight: 400;
  font-size: 9px;
  line-height: 16px;  
  color: #202124;
}

.passwordHintActive {
  font-weight: 600;
}

.workspace {
  margin-bottom: 42px;
}

@media screen and (max-width: 500px) {
  .wrapper {
    height: 100%;
    overflow-y: auto;
  }

  .container {
    margin: 20px 0;
    padding: 48px 16px 20px 16px;
    backdrop-filter: initial;
    width: 100%;
  }

  .corners {
    display: none;
  }

  .actions {
    flex-direction: column-reverse;
    align-items: center;
  }

  .nextBtn {
    width: 100%;
  }

  .backBtn {
    margin-top: 14px;
    margin-right: 0;
    width: 93px;
    height: 34px;
  }

  .inputLabel {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 8px;
  }

  .input {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
  }

  .inputError > div {
    font-size: 10px;
    line-height: 150%;
  }

  .inputError > input {
    border-bottom: 1px solid #FFB800;
  }

  .verificationInput > input  {
    width: 42px;
    height: 40px !important;
    padding: 0 0 4px 0;
  }

  .verificationInput > input:nth-child(4)  {
    margin-left: 40px;
  }

  .verificationTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  .verificationDescription {
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
  }

  .verificationInputError {
    font-size: 10px;
    line-height: 150%;
  }

  .verificationInputError  > svg {
    width: 14px;
    height: 12px;
    margin-right: 8px;
  }

  .inputPassword > input {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }

  .inputPassword > button {
    top: 50px;
    width: 24px;
    height: 24px;
  }

  .passwordHintsTitle {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 15px;
  }

  .passwordHintsList {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .passwordHint {
    font-size: 10px;
    line-height: 15px;
  }
}