.sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.mainItem {
  display: flex;
  justify-content: space-between;
  border-radius: 16px 3px 16px 16px;
  border: 1px solid var(--textColor);
  height: 62px;
  padding: 16px 20px;
  cursor: pointer;
}

.preview {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.preview span, .preview svg, .back  {
  color: var(--iconColor);
}

.preview span {
  margin-left: 25px;
  color: var(--textColor);
}

.back {
  margin-top: 2px;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.secondItem {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  border: 0.7px solid #5e5e5e80;
  height: 62px;
  padding: 16px 20px;
  cursor: pointer;
}

.secondItem:hover {
  border-color: #999999;
  border-width: 1px;
}

.secondItem:hover .deleteBtn {
  opacity: 1;
}

.deleteBtn {
  margin-top: 5px;
  cursor: pointer;
  opacity: 0;
  color: var(--textColor);
  transition: opacity .6s;
}