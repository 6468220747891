@import 'static/assets/styles/global/global-varibles.scss';

.sharedByLink {
  min-height: 100vh;

  main {
    padding: 13px 16px;
  }

  &__main {
    &__accessDenied {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__button {
        margin-top: 20px;
      }
      &__lock {
        padding-top: 120px;
      }
      &__boldText {
        padding-top: 4px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: var(--textColor);
      }
      &__text {
        padding-top: 8px;
        font-size: 14px;
        line-height: 22px;
        max-width: 329px;
        text-align: center;
        color: #7e8a9f;
      }
    }
  }
}
