.wrapper {
  position: relative;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid white;
    border-radius: 50%;
    border-style: dashed;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }
}

.medium {
  width: 102px;
  height: 102px;
}

.large {
  width: 147px;
  height: 147px;
}

.rhombus {
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 7px;
  height: 7px;
  background-color: #fff;
}

.disabledButton {
  opacity: 0.5;
}

.title {
  color: #fff;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}

.text {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-top: 4px;
}