@import 'static/assets/styles/global/global-varibles';

.wrapper {
  max-width: 402px;
  width: 100%;
}

.requestContent {
  width: 100%;
  height: 293px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__input {
    width: 100%;
    height: 45px;
    font-family: $sf_pro;
    font-size: 14px;
    padding-left: 15px;
    border: 1px solid var(--inputBorder, #D6E2F5);

    &__error{
      border-color: #ED1D2D;
      border-width: 1px;
    }
  }

  &__textfield {
    padding: 8px 15px;
    resize: none;
    width: 100%;
    height: 85px;
    font-family: $sf_pro;
    font-size: 14px;
    border: 1px solid var(--inputBorder, #D6E2F5);
  }
}

.requestTitle {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--textColorGray, #7D7D7D);
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    margin-top: 2px;
    width: 16px;
    height: 16px;
  }
}

.button {
  font-family: $sf_pro;
  cursor: pointer;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;

  &__request {
    height: 48px;
    font-size: 12px;
    background: var(--buttonColor, #202124);
    color: var(--baseColor, #ffffff);
  }

  &__donate {
    height: 60px;
    background: #1c82eb;
    display: flex;
    gap: 11px;

    svg {
      max-width: 30px;
      height: 24.38px;
    }

  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
}

.modal {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    letter-spacing: 0.8px;
  }

  &__address {
    font-weight: 800;
    letter-spacing: 0.8px;
  }
  &__button{
    margin-top: 16px;
  }
}

.error {
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 16px;
  color: #ED1D2D;
  margin: 0;
}

@media screen and (max-width: 720px) {
  .wrapper {
    margin-right: 0;
    margin-bottom: 35px;
  }
}

.successfullMessage{
  width: 488px;
  height: 263px;
  & section{
    padding: 40px 100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    & [class='modal__title modal__title-medium']{
      font-weight: 700;
    }
    & button {
      width: 199px;
    }
  }
}

.error{
  width: 488px;
  height: 230px;
  & section{
    height: 100%;
    display: flex;
    justify-content: space-between;
    & [class='modal__title modal__title-medium']{
      font-weight: 700;
    }
    & button {
      min-width: 199px;
    }
  }
}