.itemSidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 3px 20px 20px;
  width: 62px;
  height: 62px;
  border: 0.7px solid #5E5E5E;
  cursor: pointer;
  color: #fff;
  background-color: #000000;
  transition: all 0.3s ease-in-out;
}

.itemSidebar:hover {
  border-color: #999999;
  border-width: 1px;
}

.itemSidebarActive {
  background-color: var(--settingsSidebarHiver);
  border-radius: 3px 20px 20px 20px;

}

.itemSidebar:first-child {
  border-color: #fff;
}