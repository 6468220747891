@import 'static/assets/styles/global/global-varibles';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.wrapper:last-child {
  margin-bottom: 0;
}

.link {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
}

.directLink {
  width: 100%;
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: var(--textColor);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.defaultLogo {
  position: relative;
  min-width: 19px;
  min-height: 19px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #1A1A1A;
  border: 1.2px solid #FFFFFF;
  margin-right: 10px;
}

.defaultLogo > svg {
  color: #FFFFFF;
  width: 11px;
  height: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logoImage {
  height: 100%;
  width: 100%;
}

.logo {
  width: 19px;
  margin-right: 10px;
}

.linkCopy svg {
  color: var(--iconColor);
}