@import 'static/assets/styles/global/global-varibles';

.smallDocIconWrapper {
  box-sizing: border-box;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 30px;
    height: 40px;
    max-width: 30px;
    max-height: 40px;
  }

  & span {
    position: absolute;
    color: white;

    font-family: $sf_pro;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 0.78;
    text-align: center;

    top: 3px;
    text-transform: uppercase;
  }

  & img {
    position: absolute;
    width: 26px;
    height: 25px;
    bottom: 4px;
    left: 1.5px;
  }
}

.smallMediaIconWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg:nth-child(1) {
    fill: var(--fileBg);
    stroke: var(--fileBorder);
    width: 30px;
    max-width: 30px;
    height: 40px;
    max-height: 40px;
  }

  & img {
    position: absolute;

    & + svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      fill: #ffffff;
      width: 13px;
      height: 13px;
      max-width: 13px;
      max-height: 13px;
      transition: fill 200ms linear, transform 0.7s ease;

      &:hover {
        fill: #0f73ef;
      }
    }
  }

  & .audioImage {
    width: 22px;
    height: 22px;
  }
  & .videoImage {
    width: 25px;
    height: 23px;
  }
}

.videoBigImage {
  width: 51px;
  height: 51px;
  margin-left: 2px;
  margin-bottom: 4px;
}

.audioBigImage {
  width: 44px;
  height: 44px;
}

.mediaBigIconContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & span {
    color: var(--textColor);
    font-family: $sf_pro;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    text-align: center;
    &:nth-of-type(2) {
      font-size: 8px;
      line-height: 200%;
    }
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    fill: #ffffff;
    width: 15px;
    height: 15px;
    max-width: 15px;
    max-height: 15px;
    transition: fill 200ms linear, transform 0.7s ease;
    &:hover {
      fill: #0f73ef;
    }
  }
}
.specialBigIconContent {
  position: relative;
  width: 100%;
  height: 100%;
}

.iconText {
  font-family: $sf_pro;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  margin-top: 11px;
  color: var(--textColor);
}

.iconPreview {
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
}

.mediaBigIconContent svg.videoPlayIcon {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: fill 200ms linear, transform 0.7s ease;
}

.bigIconWrapper {
  position: relative;
  width: 61px;
  height: 80px;
}

.bigIconBackground {
  position: absolute;
  top: 0;
}

.docBigIconContent {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4px;

  & img {
    margin-top: 5px;
    width: 54px;
    height: 49px;
  }

  & .docCreationDate {
    display: block;
    font-family: $sf_pro;
    font-style: normal;
    font-weight: 200;
    font-size: 6px;
    line-height: 1.17;
    text-transform: uppercase;
    color: var(--textColor);
  }

  & .docType {
    position: absolute;
    bottom: 5px;
    left: -9px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 42px;
    height: 18px;
    border-radius: 5px;

    font-family: $sf_pro;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.33;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.folderWrapper {
  position: relative;

  & svg:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  & svg:nth-child(4) {
    position: absolute;
    top: 2px;
    left: 2px;
  }
}

div.folderWrapper.smallIcon {
  & svg:nth-child(1) {
    max-width: 59px;
    max-height: 40px;
  }
  & svg:nth-child(3) {
    position: absolute;
    bottom: 0px;
    left: -1px;
    max-width: 59px;
    max-height: 34px;
  }

  & svg:nth-child(4) {
    position: absolute;
    top: -2px;
    left: 5px;
    width: 18px;
    height: 9px;
  }
}

.folderContent {
  position: absolute;
  top: 14px;
  left: 7px;

  & img {
    width: 97px;
    height: 63px;
    border-radius: 2px;
  }
  &.smallContent {
    top: 8px;
    left: 7px;
    & img {
      width: 42px;
      height: 25px;
    }
  }
}

.folderContentGroup {
  position: absolute;
  top: 14px;
  left: 5.5px;
  & img {
    width: 33px;
    height: 63px;
    margin-right: 1px;
    border-radius: 2px;
  }
  & img:last-of-type {
    margin-right: 0;
  }
  &.smallContent {
    top: 8px;
    left: 7px;
    & img {
      width: 14px;
      height: 25px;
      margin-right: 0;
      border-radius: 0;
    }
  }
}

div.folderWrapper.contextMenuIcon {
  & svg:nth-child(1) {
    max-width: 32px;
    max-height: 22px;
  }
  & svg:nth-child(3) {
    position: absolute;
    bottom: -2px;
    left: 0px;
    max-width: 32px;
    max-height: 20px;
  }

  & svg:nth-child(4) {
    position: absolute;
    top: -0.5px;
    left: 2.5px;
    width: 10px;
    height: 4px;
  }
}
.smallInternalIconWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg:nth-child(1) {
    fill: var(--fileBg);
    stroke: var(--fileBorder);
    width: 30px;
    max-width: 30px;
    height: 40px;
    max-height: 40px;
  }
  & svg:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 20px;
    max-height: 23px;

    fill: var(--iconColor);
  }
}

.hintWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 20px;
  font-size: 12px;

  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  top: 70%;
  left: 70%;
}

.highPosition {
  top: 45%;
  left: 50%;
}

@media screen and (max-width: 768px) {
  .folderWrapper {
    position: relative;

    & svg:nth-child(1) {
      width: 82px;
      height: 60px;
    }

    & svg:nth-child(3) {
      width: 88px;
      height: 50px;
      left: -3px;
    }

    & svg:nth-child(4) {
      width: 28px;
      height: 8px;
      top: 0px;
      left: 4px;
    }
  }

  div.folderWrapper.smallIcon {
    & svg:nth-child(1) {
      max-width: 32px;
      max-height: 22px;
    }
    & svg:nth-child(3) {
      position: absolute;
      bottom: -1px;
      left: 0px;
      max-width: 32px;
      max-height: 20px;
    }

    & svg:nth-child(4) {
      position: absolute;
      top: -0.5px;
      left: 2.5px;
      width: 10px;
      height: 4px;
    }
  }

  .folderContent {
    position: absolute;
    top: 11px;
    left: 9px;

    & img {
      width: 100%;
      height: 100%;
      width: 60px;
      height: 40px;
    }
    &.smallContent {
      top: 5px;
      left: 3.5px;
      & img {
        width: 23px;
        height: 15px;
        border-radius: 1px;
      }
    }
  }

  .folderContentGroup {
    position: absolute;
    top: 10px;
    left: 8px;
    & img {
      width: 20px;
      height: 41px;
      margin-right: 1.5px;
      border-radius: 1px;
    }
    & img:last-of-type {
      margin-right: 0;
    }
    &.smallContent {
      top: 5px;
      left: 4px;
      & img {
        width: 7.5px;
        height: 15px;
        margin-right: 0;
        border-radius: 0.5px;
      }
    }
  }

  .bigIconWrapper {
    width: 46px;
    height: 60px;
  }
  .bigIconBackground {
    width: 46px;
    height: 60px;
  }

  .iconText {
    font-size: 8px;
    margin-top: 8px;
  }

  .iconPreview {
    width: 23px;
    height: 23px;
    margin-left: auto;
    margin-right: auto;
  }

  .mediaBigIconContent {
    & span:first-child {
      font-size: 8px;
    }

    & .audioBigImage {
      width: 25px;
      height: 25px;
    }

    & .videoBigImage {
      width: 35px;
      height: 35px;
      margin-left: 0;
    }

    & svg {
      &.audioPlayIcon {
        top: 46%;
        width: 11px;
        height: 11px;
      }
      &.videoPlayIcon {
        width: 11px;
        height: 11px;
      }
    }
  }
  .docBigIconContent {
    & .docCreationDate {
      font-size: 4.5px;
      font-weight: 200;
    }

    & img {
      width: 39px;
      height: 37px;
      margin-top: 2px;
    }

    & .docType {
      height: 13px;
      width: 30px;
      font-size: 7px;
      left: -6px;
    }
  }
}
