@import 'static/assets/styles/global/global-varibles.scss';

.container {
  background-color: var(--tooltipContentSecond);
  box-shadow: 2px 8px 24px rgba(26, 49, 139, 0.15);
  width: 230px;
  border-radius: 4px;
  padding: 14px 0;
}
.isClosed {
  display: none;
}
.container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container li {
  height: 38px;
}

.container li:hover {
  background-color: var(--sidebarHover);
  cursor: pointer;
}

.planMenuItem {
  display: flex;
  align-items: center;
}

.planMenuItemText {
  width: 100%;
}

.upgradeButtonLink {
  margin-left: auto;
  margin-right: 16px;
}

.planMenuItem .upgradeButton {
  border-radius: 2px;
  padding: 0;
  letter-spacing: 0.1em;
  min-width: 100px;
  min-height: 32px;
  width: 100px;
  height: 32px;
  background-image: linear-gradient(86.92deg, #1a67bb 0%, #2f39c1 99.55%);
  border: none;
}

.workspacesMenuItem {
  display: flex;
  align-items: center;
  padding: 3px 16px;
}

.workspaceIcons {
  margin-left: auto;
}

.rule {
  margin: 8px 0;
  border-top: 1px solid var(--contextBorder);
}

.logOutMenuItem {
  display: flex;
  align-items: center;
}

.logoutIcon {
  margin-left: auto;
  margin-right: 16px;
  color: $colGrayMed;
}

.themeLink {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  font-family: $sf_pro;
  color: var(--textColor);
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;
  height: 100%;
}

.themeLinkText {
  margin: 0;
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}

.themeLink:hover {
  background-color: var(--sidebarHover);
}

@media screen and (max-width: 480px) {
  .wrapper {
    font-size: 12px;
  }
}
