@import 'static/assets/styles/global/global-varibles';

.containerStandart {
  font-family: $sf_pro;
  width: 168px;
  height: 168px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #d6e2f5;
}

.selected {
  border-color: #0f73ef;
  background: rgba(255, 255, 255, 0.2);
}

.containerStandart:hover {
  border-color: #0f73ef;
  background: rgba(255, 255, 255, 0.2);
}

.containerDark {
  font-family: $sf_pro;
  width: 172px;
  height: 172px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(29, 29, 29, 0.4);
  border-color: #ffffff;
  border-style: solid;
  border-width: 0.1rem;
  backdrop-filter: blur(12px);
  cursor: pointer;
}

.containerDark .text {
  color: #ffffff;
}

.containerDark .web3 {
  color: #ffffff;
  opacity: 0.8;
}

.text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  color: #4b576c;
  margin-top: 20px;
}

.web3 {
  opacity: 0.8;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.unstoppable {
  margin-top: 10px;
}

// whiteThem
.corners {
  display: none;
}
.containerWhite:hover {
  .darkWallet path {
    fill: #0f73ef;
  }

  & > span {
    color: #0f73ef !important;
  }
  .corners {
    display: block;
  }

  .botRightCorner > path {
    fill: #0f73ef;
  }
  .botLeftCorner > path,
  .topRightCorner > path,
  .topLeftCorner > path {
    stroke: #0f73ef;
  }
}

.containerWhite {
  cursor: pointer;
  background-color: white;
  position: relative;
  font-family: $sf_pro;
  width: 172px;
  height: 172px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.containerWhite .web3 {
  color: #202124;
}

.corners {
  width: 100%;
  height: 100%;
  position: absolute;
}

.darkWallet path {
  fill: black;
}

.topLeftCorner {
  position: absolute;
  left: 0;
  top: 0;
}

.topRightCorner {
  position: absolute;
  right: 0;
  top: 0;
}

.topRightCorner > path {
  stroke: #000;
}

.botLeftCorner {
  position: absolute;
  left: 0;
  bottom: 0;
}

.botLeftCorner > path {
  stroke: #000;
}

.botRightCorner {
  position: absolute;
  right: 0;
  bottom: 0;
}

.botRightCorner > path {
  fill: #000;
}
//

@media screen and (max-width: 500px) {
  .containerDark {
    width: 150px;
    height: 150px;
  }

  .containerWhite {
    width: 160px;
    height: 160px;
  }
}

button:disabled {
  cursor: not-allowed;
}
