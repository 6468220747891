.optionButton {
  width: 100%;
  padding: 7px 122px;
  border-radius: 12px;
  border: 0.7px dashed rgba(255, 255, 255, 0.8);
  cursor: pointer;

  &:hover {
    border-color: #fff;
    svg path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
}
.optionsContainer {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
