@import 'static/assets/styles/global/global-varibles';

.header {
  height: 80px;
  width: 100%;
}

.icon {
  margin: 40px 0 0 80px;
}



@media (max-width:480px) {
  .header {
    height: 40px;
  }
  
  .icon {
    margin: 10px 0 0 16px;
  }
  
}