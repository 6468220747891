.wrapper {
  position: relative;
}

.popover {
  position: absolute;
  z-index: 100;
  background-color: var(--tooltipContentSecond);
  color: var(--textColor);
  right: 0;
  top: calc(100% + 5px);
  display: none;
  border-radius: 8px;
}

.visible {
  display: block;
}

.popoverArrow {
  position: absolute;
  top: -10px;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}
