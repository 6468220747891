.asside-popup {
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;

  height: 100vh;

  z-index: 9999;
  opacity: 0;

  animation: showPopup 0.3s forwards;

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;

    background-color: #020917;
    transform: translateX(-100%);
    opacity: 0.2;
  }
}

@keyframes showPopup {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
