.header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
}

.headerBtn {
  width: 45px;
  height: 30px;
  color: #FFFFFF8C;
}

.headerBtn > svg {
  width: 40px;
  height: 27px;
}