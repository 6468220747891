.inputContainer {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 12px;
  background-color: transparent;
  border: 0.7px solid #f5e12d;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  &_error {
    border-color: #ff1717;
  }
}

.error {
  position: absolute;
  bottom: -20px;
  color: #ff1717;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.eyeButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  cursor: pointer;
  opacity: 0.6;
}

@media only screen and (max-width: 730px) {
  .input {
    font-size: 14px;
    height: 51px;
  }
}
