.billing-history-item {
  list-style: none;
  padding: 0;
  margin: 0;

  padding-top: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e7ec;

  &:first-child {
    padding-top: 3px;
  }

  &__date {
    color: #838688;
  }

  &__add-info {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  &__desc {
    color: $primary_dark;
  }
}
