@import 'static/assets/styles/global/global-varibles';

.welcomeNeyra {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  display: flex;
  gap: 30px;
  align-items: center;
}

.dron {
  width: 3px;
  height: 3px;
  background-color: #fff;
  transform: rotate(45deg);
}

.text {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: 'Nunito';
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
