.btn {
  min-width: 180px;
  height: 40px;
  box-shadow: 0 2px 4px rgba(16, 22, 26, 0.12);
  border-radius: 24px;
  cursor: pointer;

  &__select {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: relative;
    background: transparent;
    box-shadow: none;
    border: 1px solid $thirty_color;
    cursor: pointer;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      margin: auto;
      background: url('/static/assets/img/button_plus.svg') center center / 9px
        9px no-repeat;
    }

    &:after {
      transform: rotate(-90deg);
    }
  }
}

.upload-btn {
  min-width: 180px;
  height: 40px;
  cursor: pointer;

  &_white {
    border: none;
    outline: none;
    background: #ffffff;
    padding: 0 24px;
    box-shadow: 0 2px 4px rgba(16, 22, 26, 0.12);
    border-radius: 24px;
    color: $secondary_color;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 1.4px;

    transition: color 0.3s, background 0.3s;

    &:hover {
      background: $primary_color-light;
      color: #fefefe;
    }

    &:active {
      background: $primary_color-dark;
      color: #fefefe;
    }
  }
}

.button {
  width: 196px;
  height: 48px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &_small {
    height: 36px;
    width: 100%;
    max-width: 124px;
  }

  &__loading {
    width: 24px;
    height: 24px;
  }

  &--back {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #d6e2f5;
    cursor: pointer;
  }

  &--primary {
    background-color: $primary_color;
    color: #ffffff;
    transition: background 0.3s;

    &:hover {
      background: $primary_color-light;
    }

    &:active {
      background: $primary_color-dark;
    }

    &_outline {
      background: none;
      border: 1px solid #d6dadc;
      color: $primary_color;

      &:hover {
        color: #ffffff;
      }

      &:active {
        color: #ffffff;
      }
    }
  }

  &_pending {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }

  &--long {
    width: auto;
    padding: 11px 30px;
  }
}

.auth__link {
  width: 180px;
  height: 44px;
  border-radius: 28px;
  border: 1px solid $primary_light;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease;

  &__text {
    color: $primary_light;
    font-family: $sf_pro;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.auth__square {
  width: 196px;
  height: 48px;
  //background-color: $primary_color;
  //box-shadow: 0 12px 25px -6px rgba(38, 135, 255, 0.59);
  border: 2px solid $primary_light;
  outline: none;
  cursor: pointer;
  color: $primary_light;
  font-family: $sf_pro;
  font-size: rem(14px);
  line-height: rem(24px);
  text-transform: uppercase;
  letter-spacing: rem(1.4px);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.1);
  }

  &[disabled] {
    background-color: $thirty_color;
    box-shadow: none;
  }
}

.auth-crypto__square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 168px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 20px rgba(26, 49, 139, 0.08);
  cursor: pointer;
  color: $primary_light;
  font-family: $sf_pro;
  font-size: rem(14px);
  line-height: rem(24px);
  text-transform: uppercase;
  letter-spacing: rem(1.4px);
  transition: all 0.3s ease;

  &:hover {
    border: 1px solid #ffffff;
  }

  &[disabled] {
    background-color: $thirty_color;
    box-shadow: none;
  }

  & > svg {
    width: 59px;
    height: 59px;
    margin-bottom: 15px;
  }
}

.buttons--hover {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

.dots-action {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.3s ease;
  background: transparent;

  &__button {
    background: url('/static/assets/img/action-dots.svg') center center / 23px
      5px no-repeat;
    width: 23px;
    height: 5px;
    border: none;
    outline: none;
    cursor: pointer;

    &.open {
      background-image: url('/static/assets/img/action-dots-active.svg');
    }

    &:hover {
      background-image: url('/static/assets/img/action-dots-active.svg');
    }
  }

  &:active {
    background: $secondary_light;
  }

  &.vertical {
    transform: rotate(90deg);
  }
}

.download-action {
  width: 56px;
  height: 56px;
  border: 1px solid rgba(204, 206, 218, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__button {
    background: url('/static/assets/img/action--download.svg') center center /
      21px 22px no-repeat;
    width: 21px;
    height: 22px;
    cursor: pointer;
    border: none;
    outline: none;
  }
}

.content-image__btn {
  border: none;
  outline: none;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  min-width: 164px;
  height: 36px;
  border-radius: 28px;
  background-color: $primary_color;
  cursor: pointer;
  transition: background 0.3s ease;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1.4px;

  &:hover {
    background: #197fff;
  }

  &:active {
    background: #106de0;
  }

  &.active {
    border: 1px solid #a5acb2;
    background: #ffffff;
  }

  &_light {
    border: 1px solid #a5acb2;
    outline: none;
    line-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    min-width: 164px;
    height: 36px;
    border-radius: 28px;
    background-color: #ffffff;
    transition: background 0.3s ease;
    color: $primary_color;
    text-transform: uppercase;
    letter-spacing: 1.4px;
  }
}

.link {
  width: 196px;
  height: 48px;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 18px;
  color: $primary_color;
  text-align: center;

  &:hover {
    color: $primary_color-light;
  }

  &:active {
    color: $primary_color-dark;
  }

  &--shrink {
    width: auto;
    height: auto;
  }

  &--grey {
    color: $dark_grey;
  }
}

.add-button {
  width: 48px;
  height: 48px;
  background: $primary_color url('/static/assets/img/icon--pluse.svg') no-repeat
    center;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: $primary_color-light;
  }
}

.remove-button {
  margin: 0 8px 8px 0;
  padding: 8px 36px 8px 16px;
  height: 40px;
  width: auto;
  background: url(/static/assets/img/cancel-icon--big.svg) no-repeat center
    right 16px;
  background-size: 10px 10px;
  text-transform: none;

  &:hover {
    background-color: $primary_color-light;
  }
}

.tag-button {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 11px 10px 0;
  padding: 5px 10px;
  height: 34px;
  background: $secondary_light;
  border: none;
  text-transform: none;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: $fifty_color;
  cursor: pointer;

  &:hover {
    background-color: $shadow_grey_light;
  }
}

.button-raw {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: $primary_dark;
  color: $primary_light;
  transition: background 0.3s;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding: 6px 24px 4px;
  height: 34px;

  &:hover {
    background-color: $primary_color-light;
  }

  &:active {
    background-color: $primary_color-dark;
  }

  &[disabled] {
    background-color: $row_light_grey;
    color: $fifty_color;
    cursor: auto;
  }
  &-disabled {
    background-color: $row_light_grey;
    color: $fifty_color;
    cursor: auto;
    cursor: pointer;
    &:hover {
      background-color: $row_light_grey;
      color: $primary_color-dark;
    }
  }

  &.delete {
    background-color: #ed1d2d;
  }
}

.button-light {
  border: none;
  outline: 1px solid $primary_color;
  cursor: pointer;
  background-color: #ffffff;
  color: $primary_color;
  transition: background 0.3s;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding: 6px 24px 4px;
  height: 34px;

  &:hover {
    background: $primary_color-light;
    color: #ffffff;
  }

  &:active {
    background: $primary_color-dark;
    color: #ffffff;
  }

  &.effect {
    transition: color 1.5s, background-color 1.5s, transform 1.5s;
    background: #ffffff;
    color: $primary_color-dark;
  }
}

.button-gradient {
  border: none;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  transition: background 0.3s;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  padding: 6px 34px 4px;
  height: 34px;
  background: linear-gradient(79.11deg, #00a5ff 27.88%, #0047ff 84.06%);

  &:hover {
    background: linear-gradient(261.49deg, #00a5ff 16.82%, #0047ff 60.87%);
  }
}

.button-ghost {
  border: none;
  outline: none;
  cursor: pointer;
  color: #0f73ef;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  width: auto;
  height: 34px;
}

.button-action {
  cursor: pointer;
}

.circle-hover {
  position: relative;

  &:before {
    display: none;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--hover);
  }

  &:hover {
    &:before {
      display: block;
    }
  }
}
.circle-rotate {
  transform: rotate(45deg);
}
.gear {
  background: url(/static/assets/img/icons/left-menu/gear.svg) center center
    no-repeat;
  background-size: 16px;
  width: 24px;
  height: 24px;
  position: relative;

  &:before {
    display: none;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(15, 115, 239, 0.07);
  }

  &:hover {
    background-image: url(/static/assets/img/icons/left-menu/gear-active.svg);

    &:before {
      display: block;
    }
  }
}
