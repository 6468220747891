.analyzeBtn {
  font-family: 'IBM Plex Mono';
  position: relative;
  display: flex;
  width: 100%;
  height: 57px;
  padding: 8px 16px 8px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: .5px solid #a5a5a5;
  background: var(--modalBG);
  color: var(--textColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.analyzeBtnAnimate {
  background-color: transparent;
}

.analyzeBtnAnimate  .analyzeBtnFilter {
  width: 0;
  height: 100%;
  background-color: var(--modalBG);
  border-radius: 16px;
  position: absolute;
  border: 1px solid transparent;
  top: 0;
  left: 0;
  animation: fillFromLeft 3s linear forwards;
  z-index: 1;
}

.analyzeBtnAnimate .analyzeBtnText {
  position: absolute;
  color: var(--textColor);
  font-size: 1.5em;
  z-index: 2;
}

.analyzeBtnAnimate .analyzeBtnText {
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@keyframes fillFromLeft {
  0% {
      width: 0;
  }
  100% {
      width: 100%;
  }
}
