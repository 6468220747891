.sidebarItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border: 0.7px solid #5e5e5e80;
  border-radius: 20px 3px 20px 20px;
  cursor: pointer;
  min-height: 62px;
  max-height: 62px;
  transition: all 0.3s ease-in-out;
}

.sidebarItemMain {
  border-width: 1px;
  border-color: var(--textColor);
  border-radius: 3px 16px 16px 16px;
}

.sidebarItem:hover {
  border-color: var(--primeTextColor);
  border-width: 1px;
}

.sidebarItemActive {
  border-radius: 3px 16px 16px 16px;
  background-color: #0687E326;
}

.sidebarInfo {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.iconContainer {
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.iconContainer > svg {
  color: var(--textColor);
}

.name {
  font-family: 'Nunito';
  color: var(--textColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.count {
  color: #7d7d7d;
  font-family: 'Nunito';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sidebarItemCollapsed .count {
  display: none;
}

.sidebarItemCollapsed .name {
  display: none;
}

.sidebarItemCollapsed {
  padding: 11px;
  width: 72px;
}

.backBtn {
  color: var(--textColor);
  cursor: pointer;
}