.fields input {
  border: none;
  font-weight: 500;
  font-size: 26px;
  line-height: 48px;
  letter-spacing: -0.4px;
  color: #202124;
  border-bottom: 1px solid #202124;
  background-color: transparent !important;
  padding-left: 0;
}

.passwordInput {
  margin-top: 24px;
}

.passwordInput > button {
  top: 48px;
  right: 10px;
}

.inputError {
  border: none;
}

.inputError > input {
  border-bottom: 1px solid #FFB800;
  color: #FFB800;
}

.inputError > div {
  color: #FFB800;
}

.inputError > div > svg {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.inputLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #7D7D7D;
}

.fields input:active, .fields input:hover, .fields input:focus {
  border-bottom: 1px solid #202124 !important;
}

.inputError > input:active, .inputError > input:hover, .inputError > input:focus  {
  border-bottom: 1px solid #FFB800;
}

.fields .input::placeholder {
  color: #B9B9B9;
  opacity: 1;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover, 
.input:-webkit-autofill:focus, 
.input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-text-fill-color: var(--textColor) !important;
}

.fields input::placeholder {
  font-size: 26px;
  line-height: 48px;
}

.scrollDown {
  max-height: 500px;
  overflow-y: hidden;
}

.formAppear {
  opacity: 0.01;
}

.formAppearActive {
  opacity: 1;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-animation: animShowNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) both 0.15s;
  animation: animShowNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) both 0.15s;
}

.actions {
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  margin-top: 60px;
}

.back {
  border: none;
  font-weight: 400;
  width: 196px;
  background-color: transparent;
}

.back:hover {
  background-color: transparent;
}

.login {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  width: 280px;
}

.backButton {
  height: 34px;
  width: 200px;
  text-transform: uppercase;
  color: #202124 !important;
}

.forgot {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
}

@-webkit-keyframes animShowNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 500px, 0);
  }
}

@keyframes animShowNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0);
  }
}

@media only screen and (max-width: 820px) {
  .fields input {
    font-size: 30px;
    line-height: 48px;
    height: 58px;
  }
}

@media only screen and (max-width: 500px) {
  .fields input {
    height: 46px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.4px;
    padding-bottom: 16px;
    padding-top: 0;
  }

  .fields button {
    top: 28px;
  }

  .fields label {
    padding: 15px 0;
  }

  .login {
    width: 220px;
  }

  .passwordInput > button {
    width: 24px;
    height: 24px;
    top: 40px;
    right: 10px;
  }

  .inputLabel {
    padding: 0 !important;
  }

  .actions {
    flex-direction: column-reverse;
    align-items: center;
  }

  .backButton {
    margin-top: 14px;
    width: 93px;
    height: 34px;
  }

  .login {
    width: 100%;
  }
}
