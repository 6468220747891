@import 'static/assets/styles/global/global-varibles.scss';

.container {
  display: block;
  width: 100%;
  height: 100%;
  padding: 24px 24px 0 24px;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  position: relative;
  height: calc(100% - 240px);
  border-radius: 20px;
  overflow: hidden;
  margin: 0.5px;
  border: 0.5px solid var(--terminalBorder);
}

.content:hover {
  border-color: var(--primeTextColor);
}

.containerProducts {
  overflow: visible;
}

.containerProducts .content {
  border: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 18px 0;
  position: relative;
}

.smallerFooter {
  padding: 14px 0 16px 0;
}

.footerNavigate {
  display: flex;
  gap: 9px;
}

.loginPageButton {
  position: relative;
  left: -30px;
}

.footerNavigateBtn {
  width: 60px;
  height: 38px;
  border: 0.8px solid var(--neyraBorderNavigationColor);
  color: var(--neyraBorderNavigationColor);
  border-radius: 8px;
  cursor: pointer;
}

.footerNavigateBtn:hover,
.footerActionsBtn:hover,
.footerActionsTokens:hover {
  border: 0.8px solid var(--neyraBorderButtonsHover);
  color: var(--neyraBorderButtonsHover);
  & svg {
    fill: var(--neyraBorderButtonsHover);
  }
}

.footerActions {
  display: flex;
  gap: 14px;
}

.footerActionsBtn {
  width: 48px;
  height: 38px;
  border: 0.8px solid var(--neyraBorderButtonsColor);
  color: var(--neyraBorderButtonsColor);
  border-radius: 8px;
  cursor: pointer;
  & svg {
    fill: var(--neyraBorderButtonsColor);
  }
}

.footerActionsTokens {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 77px;
  height: 38px;
  border: 0.8px solid var(--neyraBorderButtonsColor);
  color: var(--neyraBorderButtonsColor);
  border-radius: 8px;
  cursor: pointer;
}

.footerActionsTokens:hover .footerActionsTokensСircle {
  background-color: var(--neyraBorderButtonsHover);
}

.activeButtonToken {
  color: #ebf0b9fc;
  border-color: #ebf0b9fc;

  .footerActionsTokensСircle {
    background-color: #ebf0b9fc;
  }
}

.warningButtonToken {
  color: #b52f12;
  border-color: #b52f12;

  .footerActionsTokensСircle {
    background-color: #b52f12;
  }
}

.footerActionsTokensСircle {
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 50%;
  background-color: var(--neyraBorderButtonsColor);
}

.footerNavigateBtnActive {
  background-color: transparent;
  border-color: var(--neyraBorderButtonsActive);
  color: var(--neyraBorderButtonsActive);
}

.footerNavigateBtnActive:hover {
  color: var(--neyraBorderButtonsActive);
  cursor: pointer;
}

.footerActionsTokensText {
  font-family: $sf_pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}

.cube {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.circle {
  position: absolute;
  width: 1.5px;
  height: 1.5px;
  background-color: #fff;
  transform: rotate(45deg);
}

.sdebarFooter {
  margin-left: 30px;
}

.settingsBtn {
  width: 48px;
  display: none;
}
.footerNavigate,
.footerActions {
  button:disabled {
    opacity: 0.5;
  }
  button:disabled {
    &:hover {
      border: 0.8px solid var(--neyraBorderButtonsColor);
      color: var(--neyraBorderButtonsColor);

      .footerActionsTokensСircle {
        background-color: var(--neyraBorderButtonsColor);
      }
    }
  }
}

@media only screen and (min-width: 860px) {
  .loginPageButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 730px) {
  .footerNavigate {
    width: 100%;
    justify-content: space-between;
    gap: 11px;
  }

  .loginPageButton {
    position: initial;
  }

  .settingsBtn {
    display: block;
    width: 60px;
  }

  .footerActions {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 11px;
  }
}

.circle1 {
  animation: moveCircle1 40s linear infinite;
}
.circle2 {
  animation: moveCircle2 40s linear infinite;
}
.circle3 {
  animation: moveCircle3 40s linear infinite;
}
.circle4 {
  animation: moveCircle4 40s linear infinite;
}

@keyframes moveCircle1 {
  0% {
    top: 20px;
    left: -2px;
  }
  24% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: 20px;
  }
  49% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  50% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  74% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  75% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  99% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  100% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
}
@keyframes moveCircle2 {
  0% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: 20px;
  }
  24% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  25% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  49% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  50% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  74% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  75% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
  99% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(-360deg);
  }
  100% {
    top: calc(100% - 48px);
    left: 20px;
    transform: rotateZ(-450deg);
  }
}
@keyframes moveCircle3 {
  0% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  24% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  25% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  49% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  50% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
  74% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(-360deg);
  }
  75% {
    top: calc(100% - 48px);
    left: 20px;
    transform: rotateZ(-450deg);
  }
  99% {
    transform: rotateZ(-450deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  100% {
    transform: rotateZ(-540deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
}
@keyframes moveCircle4 {
  0% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  24% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  25% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
  49% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(-360deg);
  }
  50% {
    top: calc(100% - 48px);
    left: 20px;
    transform: rotateZ(-450deg);
  }
  74% {
    transform: rotateZ(-450deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  75% {
    transform: rotateZ(-540deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  99% {
    transform: rotateZ(-540deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  100% {
    transform: rotateZ(-630deg);
    top: -2px;
    left: calc(100% - 73px);
  }
}
