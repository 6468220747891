@import 'static/assets/styles/global/global-varibles';

.metaWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 0 17px;
  gap: 12px;
}

.value {
  display: flex;
  height: 4px;
  margin-top: 6px;
}

.itemLabels {
  display: flex;
  justify-content: space-between;
}

.itemLabelContent {
  display: flex;
}

.itemLabelContent svg {
  width: 18px;
  height: 18px;
  color: var(--iconColor);
}

.label {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: var(--textColor);
  margin: 0 0 0 6px;
}

.labelValue {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: var(--textColor);
  margin: 0;
}