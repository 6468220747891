@keyframes fillAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.progressBar {
  position: relative;
  height: 51px;
  width: 100%;
  background-color: transparent;
  border-radius: 12px;
  border: 0.7px solid #fff;
}

.fill {
  border-radius: 12px;
  height: 100%;
  background-color: white;
  animation-name: fillAnimation;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.percent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  color: #fff;
  font-family: 'Digital Numbers';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}
