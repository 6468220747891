.line1,
.line2 {
  transform-origin: 50% 50%;
}

.button {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s;


  &:hover {
    opacity: 1;
  }

  &.loading {
    svg {
      animation: PlusActionLoading 5s linear infinite;
    }
    .line1 {
      transform: rotate(0deg) scaleY(1) translateY(0) translateX(0);
    }
    .line2 {
      transform: rotate(0deg) scaleX(1) translateY(0) translateX(0);
    }
  }

  &.active-no-animation {
    .line1 {
      transform: rotate(-45deg) scaleY(0.5) translateY(-15%) translateX(-25%);
    }
    .line2 {
      transform: rotate(-45deg) scaleX(0.9) translateY(11%) translateX(19%);
    }
  }

  &.active {
    animation: PlusActionActivation 0.5s ease-in-out forwards;
    .line1 {
      animation: PlusActionLine1ActivationAnimation 0.5s ease-in-out forwards;
    }
    .line2 {
      animation: PlusActionLine2ActivationAnimation 0.5s ease-in-out forwards;
    }
  }

  &.inactive {
    animation: PlusActionDeactivation 0.5s ease-in-out forwards;
    .line1 {
      animation: PlusActionLine1DeactivationAnimation 0.5s ease-in-out forwards;
    }
    .line2 {
      animation: PlusActionLine2DeactivationAnimation 0.5s ease-in-out forwards;
    }
  }
}

@keyframes PlusActionLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes PlusActionActivation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes PlusActionDeactivation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes PlusActionLine1ActivationAnimation {
  0% {
    transform: rotate(0deg) scaleY(1) translateY(0) translateX(0);
    transform-origin: 50% 50%;
  }
  40% {
    transform: rotate(0deg) scaleY(0) translateY(0) translateX(0);
  }
  55% {
    transform: rotate(0deg) scaleY(0) translateY(0) translateX(0);
    transform-origin: 50% 70%;
  }
  100% {
    transform: rotate(-45deg) scaleY(0.5) translateY(-15%) translateX(-25%);
  }
}

@keyframes PlusActionLine2ActivationAnimation {
  0% {
    transform: rotate(0deg) scaleX(1) translateY(0) translateX(0);
  }
  100% {
    transform: rotate(-45deg) scaleX(0.9) translateY(11%) translateX(19%);
  }
}

@keyframes PlusActionLine1DeactivationAnimation {
  0% {
    transform: rotate(-45deg) scaleY(0.5) translateY(-15%) translateX(-25%);
  }
  50% {
    transform: rotate(0deg) scaleY(0) translateY(0) translateX(0);
    transform-origin: 50% 70%;
  }
  60% {
    transform: rotate(0deg) scaleY(0) translateY(0) translateX(0);
  }
  100% {
    transform: rotate(0deg) scaleY(1) translateY(0) translateX(0);
    transform-origin: 50% 50%;
  }
}

@keyframes PlusActionLine2DeactivationAnimation {
  0% {
    transform: rotate(-45deg) scaleX(0.9) translateY(11%) translateX(19%);
  }
  100% {
    transform: rotate(0deg) scaleX(1) translateY(0) translateX(0);
  }
}
