.connectWrapper {
  font-family: 'Nunito';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.content {
  position: relative;
  width: 76%;
  margin: 16px auto;
}

.content svg {
  width: 100%;
  height: initial;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.products {
 display: flex;
 justify-content: center;
 gap: 24px;
}

.productsItem {
  width: 50px;
  height: 50px;
  border: 0.5px dashed #EDEDED;
  border-radius: 50%;
}

.productsItem svg {
  color: #fff;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  margin-bottom: 30px;
}

.infoItem {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  border: 0.8px dashed #FFFFFF;
  padding: 8px 18px 8px 18px;
}

.infoDetail {
  display: flex;
  flex-direction: column;
}

.infoItemLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;  
  margin: 0;
}

.infoItemValue {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;  
}

.fileInput {
  display: none;
}

.logo {
  width: 66%;
  height: 69%;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.logoText {
  font-size: 30px;
  font-weight: 200;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
}

.infoItem:not(.infoItemActive) {
  opacity: 0.55;
}

///

.homeContainer {
  width: 100%;
  height: calc(100% - 57px);
}

.homeContainer > div:first-child {
  top: 70px !important;
}