@-webkit-keyframes animFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animMove {
  from {
    top: 100px;
  }
}

@keyframes animMove {
  from {
    top: 100px;
  }
}

@-webkit-keyframes animMoveUpFromDown {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
  }
}

@keyframes animMoveUpFromDown {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes animMoveUp {
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes animMoveUp {
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-webkit-keyframes animMoveDownFromUp {
  from {
    -webkit-transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
  }
}

@keyframes animMoveDownFromUp {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes animMoveDown {
  to {
    -webkit-transform: translateY(100%);
  }
}

@keyframes animMoveDown {
  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@-webkit-keyframes animHideNext {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -500px, 0);
  }
}

@keyframes animHideNext {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -500px, 0);
    transform: translate3d(0, -500px, 0);
  }
}

@-webkit-keyframes animShowNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 500px, 0);
  }
}

@keyframes animShowNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0);
  }
}

@-webkit-keyframes animHidePrev {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 500px, 0);
  }
}

@keyframes animHidePrev {
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0);
  }
}

@-webkit-keyframes animShowPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -500px, 0);
  }
}

@keyframes animShowPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -500px, 0);
    transform: translate3d(0, -500px, 0);
  }
}

@-webkit-keyframes animShowPhraseNext {
  from {
    -webkit-transform: translate3d(0, 34px, 0);
  }
}

@keyframes animShowPhraseNext {
  from {
    -webkit-transform: translate3d(0, 34px, 0);
    transform: translate3d(0, 34px, 0);
  }
}
