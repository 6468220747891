.leftMenuToggleable {
  z-index: 3;
}

.splitContainer .splitContainer:first-child {
  z-index: 1002;
  overflow: visible;
}

.splitContainer .splitContainer:first-child > div:last-child {
  position: fixed;
  max-width: 0;
  background-color: var(--secondBaseColor);
}

.splitContainer .splitContainer:last-child > div:last-child {
  width: 100%;
  position: relative;
  height: 100%;
  background-color: var(--secondBaseColor);
}

.splitContainer .splitContainer:last-child {
  height: 100%;
}

.splitContainer .splitContainer + div:not(.splitContainer) > div {
  position: fixed;
  width: 0 !important;
}

/* Selector for the sidebar button */
.splitContainer div[role='presentation'] > div > div > div {
  background: white;
}

.splitContainer div[role='presentation'] > div > div > div:hover svg {
  fill: #0f73ef;
}

.splitContainer div[role='presentation'] svg {
  fill: #c1c9d9;
}

.splitContainer div[role='presentation'] > div > div:nth-child(2) {
  right: -6px;
  display: none;
}

.pageContent {
  position: relative;
  height: 100%;
}

.splitContainerTec:last-child,
.splitContainerTec:first-child > div:last-child {
  top: 20px;
}

.fullHeightContainer {
  height: 100% !important;
}

@media (max-width: 1340px) {
  .splitContainerTec:last-child,
  .splitContainerTec:first-child > div:last-child {
    top: 31px;
  }
}

@media (max-width: 680px) {
  .splitContainerTec:last-child,
  .splitContainerTec:first-child > div:last-child {
    top: 45px;
  }
}

@media (max-width: 460px) {
  .splitContainerTec:last-child,
  .splitContainerTec:first-child > div:last-child {
    top: 57px;
  }
}

@media screen and (max-width: 480px) {
  .splitContainer .splitContainer:first-child {
    display: none;
  }
}
