.wrapper {
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 2px 2px 16px rgba(26, 49, 139, 0.16);
  position: relative;
  min-width: 150px;
}
.wrapper::after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid white;
  border-right: 9px solid transparent;
  position: absolute;
  bottom: -6px;
  left: 8px;
}
