.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.myAiTab {
  width: 100%;
  border: 0.5px solid #5E5E5E4D;
  border-radius: 20px;
  padding: 16px;
  background-color: #111111;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
}

.copyBtn > svg {
  height: 18px;
  color: #fff;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
  margin-bottom: 16px;
}

.text {
  color: #fff;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;  
  margin: 0;
}

.textSecond {
  font-family: 'Nunito';
  font-size: 12px;
  font-weight: 200;
  line-height: 16px;
  color: #FFFFFF60;
  margin: 0;
}

.infoRow {
  display: flex;
  justify-content: space-between;
}

.name {
  font-family: 'Nunito';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #FFFFFF70;
  margin: 0;
}

.value {
  font-family: 'Nunito';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
}

.infoRow:last-child {
  margin-top: 5px;
}

.createBtn {
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  color: white;
  width: 314px;
  height: 38px;
  gap: 8px;
  border-radius: 8px;
  border: 0.8px solid #FFFFFF;
}

.createBtn:hover {
  border-color: #2d7ee1;
}

.createBtn:disabled {
  border-color: rgb(132, 132, 132);
}

.statusBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.statusText {
  align-self: center;
  color: #fff;
}

.loader > div::after {
  background-color: #111111;
}

.statusPlus {
  margin-left: 8px;
}

.icon {
  margin-right: 8px;
}

.publishOptions {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  width: 100%;
}

.option {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.option span {
  color: #FFFFFF;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-left: 10px;
}

.option label span {
  margin-left: 0;
  background-color: #111111 !important;
}


.option label input:checked ~ span {
  background-color: white !important;
}