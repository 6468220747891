@import 'static/assets/styles/global/global-varibles';
//Refactoring №3
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $sf_pro;
  background-color: var(--baseColor, #FFFFFF);
  position: relative;
  height: 100%;
}

.additionalTopText {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 400;
  text-align: center;
  opacity: 0.4;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--textColorSecond, #7E8A9F);
}

.title {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
  letter-spacing: -0.4px;
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
}

.avatar {
  margin: 20px auto 10px auto;
  width: 100px;
  height: 100px;
}

.avatar > span {
  font-size: 20px;
  line-height: 19px;
}


.loginToCustomWorkSpaceList {
  display: flex;
}

.welcomeTitle {
  margin: 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 42px;
  text-transform: uppercase;
  text-align: center;
}

.welcomeTitle > span {
  font-weight: 400;
}

.loginList {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 720px) {
  .loginList{
    flex-direction: column;
    margin: 0 10px;
  }
}
