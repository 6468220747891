.auth-crypto {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(90deg, #00c6fb -0.78%, #005bea 55.99%);
  background-size: cover;
  overflow-y: hidden;

  .head__content {
    z-index: 10;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    top: 38px;
    left: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .auth-crypto-beta-version-wrapper {
      display: flex;
      align-items: center;
    }

    &__logo {
      display: block;
      text-decoration: none;
      cursor: pointer;

      & > img {
        width: 42px;
        height: 50px;
      }
    }
  }

  &-beta-version-text {
    font-family: $sf_pro ;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    color: #ffffff;
  }

  .login-form-wrapper {
    width: 100%;
    height: calc(100vh - 330px);
    display: flex;
    margin-top: 230px;
    justify-content: space-around;
  }

  &-left {
    background: url('/static/assets/img/auth.svg') center center no-repeat;
    background-size: contain;

    margin: 0 20px;

    &-light {
      background-image: url('/static/assets/img/gd-logo-big.svg');
      height: 50vh;
      width: 30vw;
    }

    .loader {
      position: absolute;
      width: 1600px;
      height: 800px;
      top: 50px;
      left: -400px;
      z-index: 1;
    }
  }

  &-right {
    width: 654px;
    display: flex;
    margin: 0 20px;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    color: $primary_light;
    font-family: $sf_pro ;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0px;
  }

  &__text {
    color: $primary_light;
    font-family: $sf_pro ;
    font-size: 11px;
    line-height: 16px;

    display: flex;
    align-items: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    opacity: 0.4;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
  }

  &__container {
    font-family: $sf_pro ;
    color: $primary_light;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    margin-top: 44px;
  }

  &__square:first-child {
    margin-right: 14px;
  }
  &-footer-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 56px;
  }

  &-header {
    margin-top: 50px;
  }

  @media screen and (max-width: 1049px) {
    .login-form-wrapper {
      flex-direction: column;
      align-items: flex-end;
    }

    &-footer {
      display: none;
    }
    &-left {
      align-self: center;
      &-light {
        height: 30vh;
      }
    }
    &-right {
      margin-right: 0;
      width: 80%;
    }
  }
}
