.speakerBtn {
  width: 48px;
  height: 38px;
  border: 0.8px solid var(--neyraBorderButtonsColor);
  color: var(--neyraBorderButtonsColor);
  border-radius: 8px;
  cursor: pointer;

  &:not(.activeSpeakerBtn):hover {
    border: 0.8px solid var(--primeTextColor);
    color: var(--primeTextColor);
  }
}
.speakerBtn:disabled:hover {
  border: 0.8px solid var(--neyraBorderButtonsColor);
  color: var(--neyraBorderButtonsColor);
}

.activeSpeakerBtn {
  background-color: #fff;
  color: #000;
}
