@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(16, 22, 26, 0.95);
  z-index: 1005;
}

.modal {
  box-sizing: border-box;
  width: 100vw !important;
  min-height: 100%;

  padding: 70px 20px;
  text-align: center;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  outline: none;

  @media screen and (min-width: 555px) {
    position: absolute;
    max-width: 555px;
    min-height: 568px;
    max-height: 568px;
    padding: 60px 55px;
    overflow: hidden;

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.skipBtn {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 24px;

  background-color: transparent;
  border: none;

  font-family: $sf_pro;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: #1a1a1a;
}

.rectangleContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 20px;

  position: absolute;
  bottom: 56px;
  left: 50%;
  transform: translateX(-50%);
}

.activeRectangle {
  cursor: pointer;
  stroke-opacity: 1;
  animation: switch 500ms ease-in;
}
.fadeRectangle {
  cursor: pointer;
  stroke-opacity: 0.2;
}

.slideContainer {
  animation: showSlide 1000ms linear;
}

.figureWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 270px;

  width: 100%;

  &__firstLine {
    position: absolute;
    top: 0;
    left: 10px;
    transform: rotate(270deg);
    width: 10px;
    height: 10px;
    z-index: 1;
  }

  &__secondtLine {
    position: absolute;
    top: 0;
    right: 10px;
    transform: rotate(360deg);
    width: 10px;
    height: 10px;
    z-index: 1;
  }

  &__thirdLine {
    position: absolute;
    bottom: 0;
    left: 10px;
    transform: rotate(180deg);
    width: 10px;
    height: 10px;
    z-index: 1;
  }

  &__triangle {
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 6px;
    height: 6px;
    z-index: 1;
  }

  &__card {
    position: absolute;
    z-index: 1;
    width: 160px;
    height: 250px;

    @media screen and (min-width: 555px) {
      width: 195px;
      height: 296px;
    }
  }

  &__diamondBackground {
    position: absolute;
    width: 310px;
    height: 300px;

    @media screen and (min-width: 555px) {
      width: 367px;
      height: 328px;
    }
  }

  &__squareBackground {
    width: 100%;
    height: auto;

    @media screen and (min-width: 555px) {
      width: 400px;
      height: 252px;
    }
  }
  &__illustration {
    position: absolute;
    top: 30px;
    left: -63px;
    width: 115vw;
    max-width: 560px;
    height: auto;

    @media screen and (max-width: 554.99px) {
      top: 55%;
      left: 48%;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: 555px) {
      width: 560px;
      height: 252px;
    }
  }

  &__sharingImage,
  &__tokenizationImage {
    width: 100%;
    height: auto;

    @media screen and (min-width: 555px) {
      width: 100%;
      height: 100%;
    }
  }
}

.modal_header {
  margin: 24px 0 10px 0;
  padding: 0;

  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  text-transform: capitalize;
  font-family: 'Gotham Pro';

  color: #000000;
}

.modal_description {
  padding: 0;
  margin: 0;
  width: 100%;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;

  text-align: center;
  letter-spacing: 0.1px;
  text-justify: inter-character;
  font-family: 'Gotham Pro';

  color: #4c516d;
}

@keyframes showSlide {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes switch {
  0% {
    stroke-opacity: 0.2;
  }

  100% {
    stroke-opacity: 1;
  }
}
