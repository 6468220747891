@import 'static/assets/styles/global/global-varibles.scss';

.wrapper {
  padding: 6px 16px;
  display: flex;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  font-family: $sf_pro;
  color: var(--textColor);
  font-size: 14px;
  line-height: 24px;
  height: 30px;
  font-weight: 400;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
}

.wrapper:hover {
  border: var(--sidebarActiveBorderItem);
  background-color: transparent;
  color: var(--textColor);
}

.showOnlyIcon.wrapper:hover :global(.popover) {
  display: block;
}
.showOnlyIcon.wrapper:hover :global(.popover-right) {
  left: calc(100% + 2px);
}

.wrapper.showOnlyIcon {
  padding: 0;
  width: 40px;
  height: 40px;
  justify-content: center;
  border-radius: 4px;
}

.active {
  border: var(--sidebarActiveBorderItem);
}

.collapseWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 30px;
  margin: 0 auto;
}

.icon {
  margin-right: 8px;

  &.collapsed {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
}

.showOnlyIcon .icon {
  margin-right: 0;
}

.name {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.menuLogout {
  display: flex;
  align-items: center;
}

.menuLogoutColapsed {
  display: flex;
  justify-content: center;
}