.uploader {
  display: flex;
  gap: 18px;
  font-family: Nunito;
  margin-left: 10px;
}

.progress {
  font-size: 21px;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffff;
  align-self: center;
}

.countInfo {
  font-size: 21px;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0em;
  color: #949494;
  align-self: center;
}

.action {
  cursor: pointer;
}

.action svg {
  transition: all 0.5s ease
}

