@import 'static/assets/styles/global/global-varibles';

.wrapper {
  padding: 0 16px;
  min-height: 48px;
  max-height: 88px;
  overflow-y: auto;
  width: 100%;
  margin-top: 18px;
  display: flex;
  justify-content: center;
  color: var(--textColor);
  background-color: var(--inputBg);
  border: 1px solid var(--inputBorder);
}

.inputWrapper {
  width: 100%;
}

.activeInput {
  border: none;
}

.activeInputTagExist {
  margin-left: 16px;
}

.tagItem {
  font-family: $roboto;
  display: inline-flex;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 24px;
  background: var(--modalContent);
  color: var(--textColor);
  margin-top: 8px;
}

.tagItem:not(:last-child) {
  margin-right: 8px;
}

.tagItemDelete  {
  margin-left: 6px;
  cursor: pointer;
}

.tagItemDelete > svg {
  width: 7px;
  height: 7px;
}

.activeInput > input {
  border: none;
  padding: 4px 8px 4px 0;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 24px;
  background-color: transparent;
  color: var(--textColor);
}