@import 'static/assets/styles/global/global-varibles';
.colors {
  height: fit-content;
  max-height: 100%;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    height: fit-content;
  }
}

.colorItem {
  &__wrapper {
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    cursor: pointer;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    border-radius: 3px 9px 9px 9px;
    &.emptyColor {
      background: url(/static/assets/img/icons/colors/empty.svg) no-repeat
        center center;
      border: 1px solid $fifty_color;
      width: 22px;
      height: 22px;
      border-radius: 4px;
    }
    &:hover {
      width: 26px;
      height: 26px;
      padding: 0;
    }
  }
}

.activeColor > div {
  width: 26px;
  height: 26px;
  padding: 0;
}

.colorEditor {
  cursor: pointer;
}
