@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
  background: var(--modalOverlay);
}

.wrapper {
  font-family: $sf_pro;
  width: calc(100% - 32px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 546px;
  background-color: white;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin: 16px 16px 0 0;
}

.content {
  margin: 40px 30px 40px 30px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #1A1A1A;
  text-align: center;
  margin-bottom: 24px;
}

.actions {
  display: flex;
  justify-content: center;
  letter-spacing: 0.1em;
}

.cancelBtn {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 24px;
  color: #1A1A1A !important;
  letter-spacing: 0.1em;
}

.submitBtn {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 480px) {
  .wrapper {
    left: 50% !important;
  }
}