@import 'static/assets/styles/global/global-varibles';

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalTitle {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #10161a;
  margin-bottom: 5px;
}

.modalText {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #4b576c;
  margin: 0;
}

.modalTextSeconst {
  margin-top: 26px;
}

.modalSubtitle {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #4b576c;
  margin: 0;
}

.smartContractsAddress {
  font-family: $sf_pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin: 4px 0;
  text-transform: uppercase;
  display: flex;
  align-items: baseline;
}

.smartContractsAddress > span {
  font-weight: 100;
}

.smartContractsAddresses {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.buttonsContainer > button {
  cursor: pointer;
}

.networkMarker {
  text-transform: uppercase;
  margin-right: 8px;
  color: #000;
}

.modal {
  width: 650px !important;
  max-width: 650px !important;
}
