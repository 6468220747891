.actions {
  &-block {
    width: 100%;
    margin: 0 0 30px 0;

    &-date {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: var(--textColorGray);
      margin: 0 0 12px 0;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    max-height: 35px;
    padding: 6px 28px 5px 24px;
    cursor: pointer;

    &:nth-child(2n-1) {
      background: var(--sidebarHover);
    }

    &.active {
      background: $selected_background;
      filter: drop-shadow(0px 4px 12px rgba(26, 49, 139, 0.25));
    }

    &:hover {
      box-shadow: var(--boxShadow);
    }

    &-icon {
      width: 10px;
      height: 10px;

      &-inline-block {
        display: inline-block;
      }

      &.done {
        background: url(/static/assets/img/icons/check.svg) no-repeat center
          center;
      }

      &.in-progress {
        background: $color_blue;
        border-radius: 50%;
      }

      &.current {
        background: linear-gradient(
          92.58deg,
          $active_color 5.59%,
          $active_light_color 96.51%
        );
        border-radius: 50%;
      }
    }

    &-status {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: $medium_grey;
      width: 12%;
      margin: 0 0 0 17px;
    }

    &-action {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: var(--textColor);
      width: 12%;

      &-buttons {
        display: flex;
        margin: 0 0 0 auto;
        .mobile-button-action {
          display: none;
        }
        .button-action {
          margin: 0 0 0 4px;

          & > svg {
            color: var(--iconColor);
          }
        }
      }
    }

    &-entity {
      &-name {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: var(--textColor);
        width: 25%;
        position: relative;

        &-trimmed {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 10px;
          max-width: 100%;
        }
      }

      &-type {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: var(--textColorGray);
        text-transform: capitalize;
        width: 13%;
      }

      &-size {
        font-family: $sf_pro;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: $light_grey;
        width: 8%;
      }
    }

    &-datetime {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: var(--textColorGray);
      width: 21.5%;
    }

    .header {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    &-first {
      background-color: transparent !important;
    }
  }
}
@media (max-width: 480px) {
  .is-new {
    color: #0f73ef;
  }
  .actions {
    &-block {
      &-date {
        font-size: 10px;
        margin: 0 0 10px 0;
      }
    }
    &-item {
      justify-content: space-between;
      &-status {
        width: 59px;
        margin: 0 0 0 17px;
      }
      &-action {
        margin: 0 20px;
        max-width: 45px;
        &-buttons {
          .button-action {
            display: none;
          }
          .mobile-button-action {
            display: block;
            cursor: pointer;
            position: relative;
          }
          .mobile-popup {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            background: #ffffff;
            box-shadow: 0px 1px 8px rgba(26, 49, 139, 0.25);
            padding: 12px 13px 13px 14px;
            position: absolute;
            right: 0;
            width: 171px;
            height: 221px;
            &-title {
              font-size: 10px;
              color: #7c7e88;
              line-height: 16px;
            }
            &-text {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 12px;
              line-height: 24px;
            }
          }
          margin: 0 0 0 20px;
        }
      }
      &-first {
        display: none;
      }
      &-datetime {
        display: none;
      }
      &-entity {
        &-name {
          &-trimmed {
            padding: 0px;
          }
        }
        &-type {
          display: none;
        }
      }
    }
  }
}
