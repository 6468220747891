@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  display: block;
  &__wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;

    background: var(--baseColor);
    box-shadow: var(--boxShadow);
    transform: translate(-50%, -50%);
  }
  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    svg {
      color: var(--iconColor);
      height: 18px;
      width: 18px;
    }
  }
  &__upscalerContainer {
    padding: 58px 68px 58px 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > div:nth-child(3) {
      width: 440px;
    }
  }
  &__title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-family: $sf_pro;
    font-size: 12px;
    color: var(--textColor);
    font-weight: 400;
    label {
      margin-right: 6px;
    }
    &__icon {
      &__label {
        font-size: 9px;
      }
    }
  }

  &__imageContent {
    & > svg {
      width: 12px;
      height: 12px;
      position: absolute;
      path {
        stroke: var(--iconColor);
      }
    }

    & > svg:nth-child(1) {
      transform: rotate(0deg);
      top: -1px;
      left: -1px;
    }
    & > svg:nth-child(2) {
      bottom: -1px;
      left: -1px;
      transform: rotate(180deg);
    }
    & > svg:nth-child(3) {
      top: -1px;
      right: -1px;
      transform: rotate(0deg);
    }
    & > svg:nth-child(4) {
      fill: var(--iconColor);
      bottom: 0;
      right: 0;
      transform: rotate(90deg);
    }
    margin-top: 42px;
    padding: 26px 23px 20px 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    width: 494px;
    height: 494px;
    img {
      box-sizing: border-box;
      width: 440px;
      height: 440px;
    }
    &__textLoader {
      font-family: $sf_pro;
      position: absolute;
      top: -10px;
      left: 30px;
      font-size: 12px;
      color: var(--textColor);
    }
    &__bottomInformation {
      font-family: $sf_pro;
      width: 100%;
      position: absolute;
      bottom: 6px;
      &__credits {
        position: absolute;
        display: flex;
        text-transform: uppercase;
        align-items: center;
        gap: 7px;
        left: 26px;
        font-size: 8px;
        color: var(--textColor);
        font-weight: 700;
        div {
          background: rgba(217, 217, 217, 0.44);
          height: 5px;
          width: 92px;
        }
      }
      &__size {
        position: absolute;
        left: 50%;
        right: auto;
        font-size: 11px;
        color: var(--textColor);
        font-weight: 300;
        line-height: 12px;
      }
    }
  }
  &__selectSizeButtonsWrapper {
    margin-top: 40px;
  }
  &__buttons {
    width: 440px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    font-size: 12px;
    color: var(--textColor);
    button {
      min-height: 27px;
      height: 27px;
      min-width: auto;
      font-weight: 500;
      &:nth-child(1) {
        width: 256px;
        svg > path {
          stroke: var(--iconColor);
        }
      }
      &:nth-child(2) {
        width: 125px;
        background-color: #0f73ef;
        svg > path {
          stroke: #0f73ef;
        }
      }
    }
  }
  &__button__disabled {
    background-color: #7e8a9f !important;
  }
}

@media (max-width: 1024px) {
  .modal {
    &__wrapper {
      width: 100%;
      height: 100%;
    }
    &__upscalerContainer {
      justify-content: flex-start;
      padding: 50px 16px;
    }
  }
}
@media (max-width: 500px) {
  .modal {
    &__imageContent {
      width: 358px;
      height: 370px;
      margin: 30px 0 0 0;
      img {
        box-sizing: border-box;
        width: 315px;
        height: 316px;
      }
    }
    &__upscalerContainer {
      justify-content: flex-start;
      padding: 50px 16px;
      & > div:nth-child(3) {
        width: 315px;
      }
    }
    &__buttons {
      width: 315px;
      margin: 30px 21px 0 32px;
      column-gap: 0;
      button {
        &:nth-child(1) {
          width: 139px;
        }
        &:nth-child(2) {
          width: 139px;
        }
      }
    }
  }
}
