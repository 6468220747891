.table {
  display: grid;
  grid-template-columns: 30% repeat(4, 1fr);
  width: 100%;

  &--contented {
    grid-template-columns: 1fr 100px 1fr 150px 100px 70px;
  }

  &-head {
    font-family: $sf_pro;
    font-size: 11px;
    line-height: 16px;
    font-weight: normal;
    color: $dark_grey;
    padding: 21px 0 12px 0;

    &:first-child {
      padding-left: 24px;
    }
  }

  &-row {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 100px 1fr 150px 100px 70px;
    margin-bottom: 4px;

    &:hover {
      box-shadow: 0px 1px 8px rgba(26, 49, 139, 0.25);
    }
  }

  &-cell {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 22px;
    color: $dark_grey;
    padding: 15px 0 14px 0;
    display: flex;
    align-items: center;
    position: relative;

    &.first {
      padding-left: 24px;
    }

    &-noted {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &-note {
      font-size: 11px;
      line-height: 16px;
      color: $medium_grey;
    }

    &-actions {
      display: flex;
      align-items: center;

      .actions-item-icon {
        margin: 0 6px 0 0;
      }

      .logout-icon {
        display: none;
      }

      &:hover {
        .logout-icon {
          display: initial;
        }
      }
    }

    &-actions & {
      &-status {
        margin: 0 6px 0 0;
      }
    }

    &.light {
      background: $primary_light;
    }
    &.hover:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      box-shadow: 0px 1px 8px rgba(26, 49, 139, 0.25);
      background: #fff;
    }
  }
}
@media (max-width: 480px) {
  .table {
    &-cell {
      &-status {
        font-size: 12px;
      }
      width: 100px;
      &-noted {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 39vw;
      }
      &-note {
        white-space: pre-line;
        max-height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;
      }
    }
    &-head {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      font-weight: normal;
      color: $dark_grey;
      padding: 21px 0 12px 0;
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
