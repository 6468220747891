.manage-link {
  padding: 5px 4.5vw;

  &__action-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 25px 0;

    .splitter {
      color: $forty_color;
    }
  }

  .bottom-button {
    margin-top: 44px;
  }
}
