// define themes
html[app-theme='dark'] {
  --baseColor: #000;
  --secondColor: rgba(255, 255, 255, 0.03);
  --primeColor: #0f73ef;
  --bluredPrimeColor: #0f74ef50;
  --primeTextColor: #ffffff;
  --primeSecond: #252729;
  --textColor: #ffffff;
  --textColorSetting: #ffffff;
  --textColorSecond: #7c7e88;
  --textColorThird: #ffffff;
  --textColorFour: #e5e5e5;
  --textColorGray: #7d7d7d;
  --textColorGraySecond: #2e1d1d;
  --iconColor: #ffffff;
  --iconColorPrime: #7d7d7d;
  --iconSecondColor: #7d7d7d;
  --iconDisabledColor: rgba(125, 125, 125, 0.49);
  --contextIcon: #ffffff;
  --indicatorSpace: #303030;
  --indicatorActive: #ffffff;
  --grayBg: #1c1a1b;
  --grayBgSecond: #7d7d7d;
  --modalOverlay: #000;
  --modalBG: #242424;
  --secondModalOverlay: #404040;
  --membersPriceModalText: #b9b9b9;
  --membersInfoIconColor: #b9b9b9;
  --darkBlue: #b9b9b9;
  --filterBackground: rgba(255, 255, 255, 0.1);
  --filterSortIconColor: rgba(255, 255, 255, 0.06);
  --mainContainerColor: #000;
  --workspaceMainButtonColor: rgba(255, 255, 255, 0.15);
  --workspaceMainButtonBorderColor: #fff;
  --actionButtonsColor: #323232;
  --actionButtonsHover: #ffffff;
  --squareFileBorder: #3a3a3a;
  --neyraBorderColor: #5e5e5e;
  --neyraBorderButtonsColor: rgba(255, 255, 255, 0.5);
  --neyraBorderNavigationColor: rgba(255, 255, 255, 0.5);
  --neyraBorderButtonsHover: #fff;
  --neyraBorderButtonsActive: #ebf0b9fc;
  --neyraLogoColor: #fff;
  --chatInput: #131313cc;
  --searchInput: rgba(255, 255, 255, 0.6);
  --studioTabHover: #141414;
  --filterCardStudio: #000;
  --filterCardBorderStudio: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.73) 0.19%,
    rgba(255, 255, 255, 0) 103.8%
  );
  --historyItemText: rgba(255, 255, 255, 0.8);
  --chatMessage: #151515;
  --sidebarTabsBackground: #111111;
  --datePickerBorder: rgba(255, 255, 255, 0.4);
  --scrollbarColor: #7e8a9f;
  --studioFiltersShadow: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000 97.9%
  );
  --studioLoaderShadow: #0002;
  --lightGrayTab: #141414;
  --grayTabBorder: rgba(255, 255, 255, 0.6);
  --studioPanelBorder: rgba(255, 255, 255, 0.5);
  --rangeInputBg: rgba(255, 255, 255, 0.2);
  --swapButton: #000;
  --pagePreview: #00000099;
  --previewBoxShadow: linear-gradient(360deg, rgba(0, 0, 0, 0) 4.9%, #000000 77.76%);
  --previewBoxShadowBot: linear-gradient(180deg, rgba(0, 0, 0, 0) 4.9%, #000000 95.51%);

  --filterCardBorder1Studio: rgba(255, 255, 255, 0.73) 0.19%;
  --filterCardBorder2Studio: rgba(255, 255, 255, 0) 103.8%;

  // folder
  --folderIndicator: #363636;
  --folderIndicatorIcon: #ffffff;
  --folderBoxShadow: none;
  --folderActive: #242424;

  //
  --fileBg: #303030;
  --fileBorder: #ffffff;
  --fileBorderUp: #cacaca;
  --fileBorderDown: #cacaca40;
  --fileBorderSeparate: transparent;

  --avatarText: #202124;
  --profileCard: #242424;
  --tokensCard: #242424;
  --buttonColor: #ffffff;
  --border: #ffffff;
  --borderSecond: #303030;
  --borderNone: none;
  --borderTransparent: #ffffff;
  --inputDisabled: #242424;
  --inputBorder: #303030;
  --inputBg: #242424;
  --inputErrorBg: #1d0b05;
  --ruleColor: #0e0e0e;
  --tooltipBackground: #141414;
  --tooltipContent: #242424;
  --tooltipContentSecond: #0e0e0e;
  --modalContent: #000;
  --inputEye: #7d7d7d;
  --sidebarHover: #303030;
  --dashedDividerBorder: #303030;
  --sidebarItemBorder: #303030;
  --sidebarItemBorderActive: #7d7d7d;
  --datePickerHeader: #242424;
  --hover: #303030;
  --contextBorder: rgb(125, 125, 125, 0.5);
  --aiAutocompleteButtons: #323436;
  --aiAutocompleteButtonsText: #a79b9b;
  --aiGeneratorHeaderActive: #fdfdfd;
  --aiGeneratorHeader: rgba(255, 255, 255, 0.3);
  --aiGeneratorButtons: #3c3a3a;
  --aiGeneratorButtonsHover: #efefef;
  --comingSoonLabelText: #fff;
  --comingSoonLabelBackground: #7d7d7d;
  --evenRow: #242424;
  --notificationHover: #303030;
  --convertModalBtnColor: #e8e9ed;
  --convertModalBtnTextColor: #202124;
  --dirButton: rgba(0, 122, 255, 0.3);
  --editorIconBg: #e8e9ed;
  --lightBorderOnly: #1b1919;
  --buttonBGDarkOnly: #242424;
  --switchToggleDarkOnly: #c1c9d9;
  --disabledBtn: #ffffff;

  // swith
  --switchBgActive: #ffffff;
  --switchBgInactive: #7d7d7d;
  --boxShadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

  //skeleto
  --skeletonItem: #131313;

  //setting
  --sticksForRender: #303030;
  --progress: #b9b9b9;

  //tokensPage
  --textTokens: #fff;
  --tokenInput: #fff;
  --tokenInputBuy: #fff;
  --tokenHistory: none;
  --tokenHistoryItem: #fff;
  --tokenIcon: none;
  --expirationDate: #7d7d7d;
  --error: #fff;
  --bcgError: #2c0a0a;
  --inputBorderError: #ed1d2d;
  --featureIcon: none;
  --tokenIconBorder: 0.5;
  --tokenIconBorderColor: #4b576c;

  //downloadComponent
  --downloadTitle: #fff;
  --downloadSubTitle: rgba(255, 255, 255, 0.6);
  --downloadIcon: #fbfcff;
  --downloadLoaderBg: #313131;

  // sedebar new styles
  --secondBaseColor: #000;
  --dividingLine: #212127;
  --sidebarMarker: #343434;
  --sidebarMarkerText: #b9b9b9;

  --sidebarGrayText: #7c7e88;
  --sidebarIcon: #9a9ba0;
  --sidebarNewBg: #000;
  --sidebarActiveItem: #262424;
  --sidebarActiveBorderItem: 0.6px solid #23232c;
  --sidebarNewBoxShadow: 0px 1px 1.6px 0px #e1e1e720;
  --sidebarActiveIcon: #e1e0e4;
  --sidebarGroupActive: #0d0d0d;
  --sidebarGroup: #0d0d0d;
  --sidebarGroupBorder: 0.6px solid #212327;
  --sidebarGroupBorderActive: 0.6px solid #ffffff;
  --sidebarGroupColor: #494951;
  --sidebarGroupColorActive: #fff;
  --sidebarSelectWS: #fff;

  --hrColor: #212127;
  --addBtn: #1a1a1a;
  --settingsBtn: #0d0d0d;
  --settingsBtnHover: #ffffff;
  --settingSort: #1d1f24;
  --settingsBorder: #23232c;
  --settingsHeadBorder: #303030;
  --settingsHeadBg: #303030;
  --settingsBorderColor: #323232;

  --folderColor: #131313;
  --folderHeader: #0e0e0e;

  --buttonBg: #323232;
  --buttonIconColor: #ffffff;
  --buttonBorder: 0.6px solid #202124;
  --buttonBgSecond: #323232;
  --buttonBgGray: #323232;

  --checkBoxBorder: #7c7e88;
  --checkBoxBorderActive: #d9d9d9;

  --newDividingLine: #232323;
  --borderCard: #303030;
  --newGrayTextColor: #b9b9b9;
  --newInputBorderColor: #ffffff80;
  --borderCardColor: #272727;
  --badgBgColor: #30303030;
  --settingsHrColor: #303030;
  --settingsMenuBg: #000;
  --headerDirectionBg: #7d7d7d;
  --unreadBadgeBg: #ededef;
  --wsSettingsHover: #232323;
  --newInputBg: #ffffff;

  // neyra
  --neyra_msg_bg: #ffffff;
  --neyra_text_color: #1a1a1a;

  --settingsSidebarColor: #ffb800;

  --settingsSidebarHover: #0687e326;

  --terminalBorder: rgba(255, 255, 255, 0.44);
  --terminalPlaceholder: #808080;
  --productBorderColor: #ffb800;

  //welcome
  --welcome_background: #000;
  --welcome_text_color: #fff;
  --welcome_button_background: rgba(255, 255, 255, 0);
  --welcome_button_text_color: #ffe24a;
  --welcome_yellow: #ffe24a;
}
