.reset-button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: auto;
  color: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
