@import 'static/assets/styles/global/global-varibles';

.container {
  position: relative;
  font-family: 'Nunito';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.infoText {
  position: absolute;
  top: 80px;
  left: 17px;
  color: #fff;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;

  &::before {
    position: absolute;
    left: -19px;
    top: 9px;
    content: '';
    width: 3px;
    height: 3px;
    transform: rotate(45deg);
    background-color: #fff;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  width: 100%;
  height: 100%;
}

.title {
  color: #a0a0a0;
  text-align: center;
  font-family: 'Nunito';
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}
