@import 'static/assets/styles/global/global-varibles';

.container {
  font-family: 'Nunito';
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputSidebar {
  display: flex;
  justify-content: space-between;
  max-width: 330px;
  width: 100%;
  height: 70px;
  padding: 11px 16px;
  border-radius: 20px;
  border: 1px;
  gap: 8px;
  border: 0.5px solid #fff;
  opacity: 0.4;
  color: #fff;
}

.inputSidebarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 0.5px solid #FFFFFF;
  border-radius: 14px;
}

.inputSidebarInput {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
}

.inputSidebarInputText {
  width: 100%;
  margin: 0;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.inputSidebarCurrent {
  position: relative;
  border-width: 1px;
  opacity: 1;
  cursor: pointer;
}

.inputSidebarFinish {
  border-width: 1px;
  opacity: 1;
}

.publishBtn:disabled {
  opacity: 0.4;
  cursor: disabled;
}

.publishBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  border: 0.5px solid #FFFFFF;
  border-radius: 20px;
  padding: 11px 16px;
}

.publishBtnComponent {
  display: flex;
  align-items: center;
}

.publishBtnCircle {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 2px solid #FFFFFF;
  margin-right: 15px;
}

.publishBtnText {
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  margin: 0;
  color: #fff;
}

.inputSidebarCurrent::after {
  position: absolute;
  content: '';
  right: -4px;
  top: 30px;
  width: 7px;
  height: 7px;
  background: #fff;
  transform: rotate(45deg);
}

.inputSidebarCurrent .inputSidebarIcon {
  border-width: 1px;
}

.inputSidebarMyInput {
  font-family: 'Nunito';
  width: 100%;
  outline: none;
  padding: 0;
  border: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  
  background-color: transparent;
  color: #FFFFFF;
  caret-color: #F5E12D;
}

.inputSidebarMyInput::-webkit-outer-spin-button,
.inputSidebarMyInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputSidebarInputActive > .inputSidebarMyInput {
  width: calc(100% - 50px);
  display: block;
}

.inputSidebarInputActive > .inputSidebarInputText {
  display: none;
}

.inputSidebarActivet {
  border-color: #F5E12D !important;
  opacity: 1;
}

.inputSidebarActivet > .inputSidebarIcon {
  border-color: #F5E12D;
}


.skills {
  position: absolute;
  right: 26px;
  color: #fff;
}

//// Bios Input

.biosInputItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

.biosIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 14px;
  border: 0.7px;
  border: 0.7px solid #FFFFFF;
}

.biosInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 330px;
  padding: 14px 16px;
  border-radius: 20px;
  border: 1px;
  gap: 8px;
  border: 0.5px solid #fff;
  opacity: 0.4;
  color: #fff;
}

.dateInput {
  width: calc(100% - 50px) !important;
  background-color: transparent;
  border: none;
  height: 21px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #fff;
}

.dateInput::placeholder {
  color: #fff;
}

.biosInputCurrent {
  border-width: 1px;
  opacity: 1;
  border-color: #F5E12D;
}

.biosInputCurrent .biosIcon {
  border-width: 1px;
}

.biosInputItemActive .biosIcon {
  border-color: #F5E12D;
}

.biosInputFinish {
  opacity: 1;
  border-width: 1px;
}

///  

.setupBtnInfo {
  color: #fff;
  display: flex;
  gap: 13px;
}

.setupBtnInfoText {
  font-size: 17px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  align-self: center;
}

.setupBtnPrice {
  color: #fff;
  display: flex;
  flex-direction: column;
}

.setupBtnPriceTotal {
  font-size: 17px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.setupBtnPriceDetail {
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
}

///

.inputSidebarProgress {
  display: flex;
  justify-content: space-between;
  width: 330px;
  height: 70px;
  padding: 11px 16px;
  border-radius: 20px;
  border: 1px;
  gap: 8px;
  border: 0.5px solid #fff;
  color: #fff;
}


/// INPUT BUTTON

.inputBtnText {
  width: calc(100% - 48px);
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  background-color: transparent;
  color: #FFFFFF;
}

.inputButtonCurrent {
  position: relative;
  border-width: 1px;
  opacity: 1;
  cursor: pointer;
}

/// 

.inputSidebarProgressInput {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
}

.inputSidebarProgressInputActive > input {
  width: calc(100% - 50px);
  display: block;
}

.inputSidebarProgressInputActive > p {
  display: none;
}

.inputSidebarProgressMyInput {
  display: none;
  font-family: 'Nunito';
  width: 100%;
  outline: none;
  padding: 0;
  border: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  background-color: transparent;
  color: #FFFFFF;
  caret-color: #F5E12D;
}
