.wrapper {
  display: none;
  bottom: 25px;
  right: 0px;
  width: 413px;
  flex-direction: column;
  align-items: start;
  z-index: 1006;
  position: fixed;
  background-color: var(--downloadLoaderBg);
  box-shadow: 0px 2px 8px 0px rgba(204, 206, 218, 0.25);
}

.sharedContainer {
  bottom: 0;
}

.header {
  width: 100%;
  padding: 13px 14px 15px 14px;
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
}

.btnDownload {
  cursor: pointer;
  svg {
    path {
      fill: var(--downloadIcon);
    }
  }
}

.btnDownload:first-child {
  margin-right: 10px;
}

.rotateBtn {
  transform: rotate(180deg);
}

.visible {
  display: flex;
}

.icon {
  position: relative;
}

.container {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 19px 9px 14px;
}

.visibleContainer {
  display: flex;
  gap: 16px;
}

.title {
  font-family: 'SF Pro Text';
  color: var(--downloadTitle);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.text {
  width: 100%;
  display: flex;
  flex-direction: column;

  color: var(--downloadTitle);
  font-size: 14px;
  font-family: 'SF Pro Text';
  font-weight: 500;
  line-height: 24px;
}

.subTitleSize {
  color: var(--downloadSubTitle);
  font-size: 12px;
  line-height: 12px;
  font-family: 'Roboto';
  letter-spacing: 0.1px;
  font-weight: 400;
}

.preload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preload svg {
  animation: lds-dual-ring 1.1s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .wrapper {
    max-width: calc(328px - 22px);
    left: 50%;
    bottom: 17px;
    transform: translate(-50%, 0);
  }
  .header {
    padding: 8px 11px 0px 11px;
  }
  .container {
    padding: 6px 11px 8px 11px;
  }
}

