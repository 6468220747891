.checkbox {
  margin: 0 20px 0 0;
  padding-top: 14px;
  padding-bottom: 12px;
  &__input {
    position: absolute;
    opacity: 0;
    color: $primary_dark;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      line-height: 0;
      font-size: 0;
      display: block;
    }
    & + label:before {
      content: '';
      width: rem(24px);
      height: rem(24px);
      border: 1px solid $forty_color;
      display: inline-block;
      background: $primary_light;
    }
    &:disabled + label {
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: $primary_light;
    }
    &:checked + label {
      background: $primary_color;
    }
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: $primary_color url('/static/assets/img/icon--checked.svg') center center /
        14px 11px no-repeat;
      width: 100%;
      height: 100%;
    }
  }
}

.folder_selected label.select-item-checkbox {
  display: block;
}
