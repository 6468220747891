@import 'static/assets/styles/global/global-varibles';

.menu {
  position: absolute;
  right: 41px;
  top: 61px;
  z-index: 100;
}

div.popupNotification {
  right: 10px;
  top: 0px;
}

.errorMessage {
  position: absolute;
  left: 0;
  bottom: -22px;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 16px;
  color: #ed1d2d;
  &.invalidKey {
    left: 45px;
    font-size: 10px;
  }
}

.errorInput input {
  border: 1px solid #ed1d2d;
  background: var(--inputErrorBg);
}
.errorInput input:focus {
  border: 1px solid #ed1d2d;
}

.sidebar {
  width: 190px !important;
}

.Modal {
  height: 0;
}

.Overlay {
  display: none;
}
.OverlayHard {
  display: none;
}

.signInBtn {
  width: 116px;
  min-width: 80px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  min-height: 33px;
  height: 33px;
  background-color: var(--textColor, #202124);
  color: var(--buttonBg, #FFFFFF);
  border-radius: 4px;
  margin-left: 10px;
}

.notAuthHeader {
  display: flex;
  flex-direction: column;
  border-bottom: none;
}

.notAuthHeaderActions {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 12px 16px;
  border-bottom: 0.5px dashed #e8e9ed;
}

.notAuthHeaderInfo {
  display: flex;
}

.titleEncrypt {
  margin: 20px 0 8px 0;
  font-family: 'SF Pro Text';
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--textColor);
}
.subtitleEncrypt {
  margin: 0 0 24px 0;
  font-family: 'SF Pro Text';
  font-size: 14px;
  line-height: 150%;
  color: var(--grayBgSecond);
  & span {
    display: block;
  }
}
.cancelDecrypt {
  cursor: pointer;
  user-select: none;
  width: 118px;
  height: 40px;
  font-family: 'SF Pro Text';
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--textColorSetting);
  border: 1px solid var(--borderTransparent);
}
.decrypt {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  user-select: none;
  width: 157px;
  height: 40px;
  background: #b9b9b9;
  font-family: 'SF Pro Text';
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  &.active {
    cursor: pointer;
    background: var(--textColorSetting);
    color: var(--baseColor);
  }
}

.decryptInput {
  display: block;
  width: 431px;
  height: 48px;
  padding: 0 23px;
  margin: 0 auto;
  background: #f5f5f5;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 24px;
  color: #7e8a9f;
  border: none;
  &::placeholder {
    background: #f5f5f5;
    font-family: $sf_pro;
  }
  &.error {
    color: #ed1d2d;
  }
}
.decryptButtons {
  margin: 0 auto 50px auto;
  width: 431px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.message {
  font-family: $sf_pro;
  color: var(--grayBgSecond);
  font-size: 10px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  text-wrap: nowrap;
}

.nonAuth {
  &__header {
    &__logo {
      cursor: pointer;
    }
    &__logoGg {
      color: var(--iconColor);
    }
    &__buttons {
      display: flex;
      gap: 8px;
    }
    &__btn {
      width: 33px;
      height: 33px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__light {
        background-color: transparent;
        border: .6px solid var(--iconColor, #000000);
        color: var(--iconColor);

        &:hover {
          background-color: var(--hover);
        }
      }

      &__dark {
        background-color: var(--iconColor, #000000);
        border: .6px solid var(--iconColor, #000000);
        & > svg {
          color: var(--baseColor, #FFF);
        }
        &hover {
          background-color: var(--grayBgSecond);
          border: .6px solid var(--grayBgSecond);
        }
      }

      &.active {
        &:hover {
          cursor: pointer;
        }
      }
      &.circleHover {
        position: relative;
      }
    }
    &__settings {
      display: flex;
      align-items: center;
      &__accountButton {
        position: relative;
      }
      button {
        max-height: 40px;
      }
      &__menu {
        position: absolute;
        right: 41px;
        top: 61px;
        z-index: 100;
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      padding: 12px 16px;
      justify-content: space-between;
      &:first-child {
        background-color: #ffffff;
      }

      & > svg {
        min-height: 36px;
        min-width: 36px;
      }
    }
    &__breadcrumb {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.view {
  &__buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 auto;
  }
  &__btn {
    cursor: pointer;
    transition: all 0.3s;
  }
}

// create modal 

.createWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: auto;
  max-width: 408px;
  padding: 40px;
  text-align: center;
  background: var(--modalContent);
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.createExit {
  color: var(--iconColor);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px;
}

.createTitle {
  font-family: $sf_pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--textColor);
  margin: 0 0 24px;
}

.createInputWrapper {
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  position: relative;
  color: #0f73ef;
  margin: 0 24px 24px 24px;
}

.createInput {
  background: var(--baseColor);
  border: 1px solid var(--newInputBorderColor);
  border-radius: 4px;
  height: 38px;
  padding: 8px 16px;
  width: 100%;
  color: var(--textColor);
}

.createButtons {
  display: flex;
  font-family: $sf_pro;
  justify-content: center;
  gap: 20px;
  padding: 0;
}

.createDeclineBtn {
  height: 40px;
  width: 130px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  cursor: pointer;
  color: var(--textColor);
  text-transform: uppercase;
}

.createDeclineBtn:hover {
  background-color: var(--settingsBtnHover);
  color: var(--baseColor);
}

.createAcceptBtn {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  width: 130px;
  height: 40px;
  border-radius: 3px;
}

//

@media screen and (max-width: 480px) {
  .Overlay {
    display: block;
  }
  .OverlayHard {
    display: block;
  }
  .sidebar {
    width: 60vw !important;
    left: 0;
  }
}
