.account-plan-storage {
  position: relative;
  padding: 40px 0;
  background: #ffffff;

  &__chart-wrapper {
    position: relative;
  }

  &__short-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__short-data-top {
    margin-bottom: 10px;
    color: #1b1a1e;
    line-height: 1;
  }

  &__short-data-bottom {
    color: #58595f;
    line-height: 1;
  }

  &__chart-wrapper {
    margin: 0 auto;
    height: 276px;
    width: 276px;
  }

  &__chart {
    position: static;
  }

  &__usage-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 35px auto 0;
    width: 100%;
    max-width: 348px;
  }

  &__space-editing {
    width: 100%;
    max-width: 270px;
    margin: 38px auto 0;

    user-select: none;
  }

  &__space-editing-items {
    display: flex;
    justify-content: space-between;
    margin: 26px auto 0;
    width: 100%;
    max-width: 348px;
  }
}
