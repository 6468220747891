.workspace {
  width: 100%;
  padding: 0;
  display: flex;

  h3 {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 24px;
    color: $primary_dark;
  }

  &-container {
    width: 100%;

    &__empty {
      padding: 260px 0 0 0;

      h3 {
        font-size: 32px;
        line-height: 48px;
        text-align: center;
      }

      p {
        font-family: $sf_pro;
        font-size: 20px;
        line-height: 30px;
        color: $light_grey;
        margin: 0 auto;
        width: 495px;
        text-align: center;
      }
    }

    .manage-button {
      margin: 84px auto 0 auto;
    }
  }

  &-member {
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;

    &-header {
      padding: 18px 31px 19px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-plus {
        font-family: $sf_pro;
        cursor: pointer;
        text-transform: uppercase;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        margin-left: 20px;
        min-width: 32px;
        min-height: 32px;
        border-radius: 50%;
        padding: 0;
        & > svg {
          margin: auto;
          color: var(--baseColor);
        }
      }

      &-back {
        padding: 0 16px;
        font-family: $sf_pro;
        font-size: 18px;
        line-height: 24px;
        color: $primary_dark;
        margin-right: auto;
        margin-bottom: 14px;
        font-weight: 400;
      }

      &-actions {
        padding: 0 16px;
        border-top: 1px solid $preview_container;
        display: flex;
        padding-top: 31px;
      }

      & > & {
        padding-right: 0;
      }

      .search {
        &__field {
          border: 1px solid $button-border;
          margin: 0;
          height: 42px;

          &__input {
            padding-left: 35px;
            margin-left: 17px;
            height: 40px;
            width: 309px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            background: transparent;
            border-bottom: 1px solid #c1c9d9;
          }

          &__icon {
            width: 24px;
            height: 24px;
            right: auto;
            left: 45px;
            top: 8px;
            background-size: contain;
            position: relative;
          }

          &__button {
            min-width: 132px;
            height: 40px;
            background-color: $primary_color;
            color: white;
            font-family: $sf_pro;
            & > svg {
              margin-right: 8px;
            }

            &:disabled {
              background-color: $search_placeholder;
            }
          }
        }
      }
    }

    &-email {
      user-select: text;
      text-overflow: ellipsis;

      & svg {
        margin-right: 12px;
      }

      &:hover .tooltip {
        display: flex;
        bottom: 30px !important;
        background-color: white;
        color: black;
        box-shadow: 2px 8px 24px rgba(26, 49, 139, 0.15);
      }
    }

    &-header & {
      &-invite {
        margin: 0 0 0 24px;
        display: flex;
        align-items: center;
        position: relative;

        &-input {
          display: flex;

          &-error {
            background: $rose;
            border: 1px solid $red;
            box-sizing: border-box;
          }
        }
        &__error {
          display: none;
          font-size: 12px;
          line-height: 16px;
          color: $red;
          margin: 0;
          position: absolute;
          bottom: -20px;

          &-show {
            display: block;
          }
        }
      }
    }

    &-container {
      padding: 0 31px 0 16px;
      .table {
        &-head {
          font-family: $sf_pro;
          color: $text_grey;

          &-info {
            padding-left: 24px;
          }
        }

        &-cell {
          display: flex;
          align-items: center;

          &.light {
            background: $primary_light;
          }

          &-actions {
            justify-content: flex-end;
            padding-right: 25px;
          }
        }

        &-row:not(.blocked) {
          border-bottom: 1px solid #e8f0fd;
          &:hover {
            background-color: #fff;
          }
        }

        &-row.blocked {
          background-color: #f1f1f5;

          & div {
            color: #c1c9d9;
          }
        }
      }

      .option {
        &-selector {
          position: relative;
          top: auto;
          right: auto;
          border: none;
          margin: 0;
          padding: 0;

          &-item {
            &-container {
              top: 20px;
            }
          }
        }
      }
    }

    &-info {
      display: flex;
      align-items: center;
      padding: 16px 0 16px 24px;

      .user-icon {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

      &-name {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 22px;
        color: $dark_grey;
        margin: 0 8px 0 0;
        width: calc(100% - 52px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &-finded {
          color: $primary_color;
        }
      }

      &-status {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 22px;
        color: $status_orange;
      }

      &-blocked {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 22px;
        color: #c1c9d9;
      }
    }

    &-panel {
      display: flex;
      flex-wrap: nowrap;
      margin-top: 11px;
      margin-left: 36px;

      &-select {
        display: none;
        width: calc(100vw - 90px);
        padding-top: 8px;
      }

      button {
        font-family: $sf_pro;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        color: #7e8a9f;
        margin-right: 25px;
        cursor: pointer;
        & span {
          margin-left: 5px;
        }
      }

      .selected {
        font-size: 16px;
        font-weight: 400;
        color: var(--textColor);
      }
    }
  }

  &-invite {
    position: relative;
    &-member {
      display: flex;
      flex-wrap: wrap;
      max-height: 90px;
      overflow-y: auto;

      &-item {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 14px;
        color: $primary_light;
        padding: 0 0 0 36px;
        margin: 7px 0 0 0;
        display: flex;
        align-items: center;

        &-remove {
          display: inline-block;
          width: 10px;
          height: 10px;
          color: $fifty_color;
          background: url(/static/assets/img/icons/closes/close-invite.svg)
            no-repeat center center;
          background-size: 10px 10px;
          margin: 0 0 0 10px;
          cursor: pointer;

          &:before {
            content: '';
          }
        }
      }
    }

    &__error {
      position: absolute;
    }
  }
}

.create-workspace {
  height: 70vh;

  .create-button {
    margin-top: auto;
    width: 100%;
  }

  .cancel-button {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 35px;
  }
}

@media screen and (max-width: 480px) {
  .workspace {
    &-member {
      &-header {
        .search {
          &__field {
            border: 1px solid $button-border;
            margin: 0;
            height: 42px;

            &__input {
              display: none;
            }

            &__icon {
              display: none;
            }
          }
        }
        &-plus {
          &-mini {
            display: flex;
          }
        }
      }
      &-panel {
        margin-left: 16px;
        margin-top: 0;

        &-select {
          display: flex;
          padding-top: 0;
        }

        & > button {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-device-width: 480px) and (max-device-width: 1024px) {
  .workspace {
    &-member {
      &-header {
        .search {
          &__field {
            border: 1px solid $button-border;
            margin: 0;
            height: 42px;

            &__input {
              display: none;
            }

            &__icon {
              display: none;
            }
          }
        }
      }
    }
  }
}
