@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modalWrapper {
  display: block;
}

.modalHeader {
  font-family: $sf_pro;
  font-size: 24px;
  line-height: 32px;
}

.modalBody {
  margin-top: 10px;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 18px;
  color: #7e8a9f;
}

.modalFooter {
  margin-top: 20px;
}

.modal {
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: auto;
    max-width: 488px;
    padding: 40px;
    text-align: center;
    background: var(--modalContent);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__title {
    font-family: $sf_pro;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    color: var(--textColor);
    margin: 0 0 8px 0;
  }

  &__subTitle {
    font-family: $sf_pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 0 20px;
    color: var(--textColorGray);
    margin-top: 0;
    white-space: wrap;
  }
}

.warning {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-family: $sf_pro;
  }
  &__subTitle {
    margin: 10px 0 25px 0;
    white-space: wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #4b576c;
  }
  &__buttonsWrapper {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  &__button {
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: $sf_pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.1em;
    width: 132px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__cancel {
      color: #0f73ef;
    }
    &__switch {
      color: white;
      background-color: #0f73ef;
    }
  }
}

.accessDenied {
  min-width: 575px;
  padding: 50px 103px;

  & .modal__contentWrapper {
    svg {
      margin-right: 50px;
    }
  }
}

svg.warning__image {
  width: 244.79px;
  height: 231px;
  margin-bottom: 8px;
}

@media screen and (max-width: 480px) {
  .modal {
    &__content {
      width: calc(100vw - 32px);
      padding: 40px 24px !important;
      height: max-content !important;
    }
  }

  .accessDenied {
    min-width: auto;
    & .modal__contentWrapper {
      svg {
        margin-right: auto;
        margin-left: auto;
        width: 260px;
        height: 120px;
      }
    }
  }
}
