.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.videoContainer video {
  height: initial !important;
  max-height: 100%;
  width: 100%;
}

.pdfPreview {
  margin: -54px 0 0 0 !important;
  height: calc(100vh - 108px) !important;
  scroll-margin: 20px;
  width: 100%;
}

.pdfPreview::--webkit-scrollbar-track { 
  height: 100px;
  max-height: 100px;
}

.pdfPreview > div > div:first-child {
  margin-top: 70px;
}

.pdfPreview p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 130px;
  padding: 0;
  background-color: var(--pagePreview);
  font-family: 'SF Pro Text';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  color: var(--textColor);
}

.pdfPreview > div > div > canvas {
  width: 100% !important;
}

.pdfPreviewRoot {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .pdfPreview > div > div > canvas {
    width: 90% !important;
  }
}



@media screen and (max-width: 900px) {
  .pdfPreview > div > div > canvas {
    width: 80% !important;
  }
}
