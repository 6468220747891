.content {
  position: relative;
  width: 100%;
  border-radius: 20px;
  margin: 0.5px;
  border: 0.5px dashed #5e5e5e;
}

.opacity {
  border-color: rgba(94, 94, 94, 0.5);
}

.cube {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.circle {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  transform: rotate(45deg);
}

.circle1 {
  animation: moveCircle1 40s linear infinite;
}
.circle2 {
  animation: moveCircle2 40s linear infinite;
}
.circle3 {
  animation: moveCircle3 40s linear infinite;
}
.circle4 {
  animation: moveCircle4 40s linear infinite;
}

@keyframes moveCircle1 {
  0% {
    top: 20px;
    left: -2px;
  }
  24% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: 20px;
  }
  49% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  50% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  74% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  75% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  99% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  100% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
}
@keyframes moveCircle2 {
  0% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: 20px;
  }
  24% {
    transform: rotateZ(-90deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  25% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  49% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  50% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  74% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  75% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
  99% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(-360deg);
  }
  100% {
    top: calc(100% - 48px);
    left: 20px;
    transform: rotateZ(-450deg);
  }
}
@keyframes moveCircle3 {
  0% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  24% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  25% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  49% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  50% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
  74% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(-360deg);
  }
  75% {
    top: calc(100% - 48px);
    left: 20px;
    transform: rotateZ(-450deg);
  }
  99% {
    transform: rotateZ(-450deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  100% {
    transform: rotateZ(-540deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
}
@keyframes moveCircle4 {
  0% {
    top: -2px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  24% {
    transform: rotateZ(-270deg);
    top: -2px;
    left: -30px;
  }
  25% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -2px;
  }
  49% {
    top: calc(100% - 20px);
    left: -2px;
    transform: rotateZ(-360deg);
  }
  50% {
    top: calc(100% - 48px);
    left: 20px;
    transform: rotateZ(-450deg);
  }
  74% {
    transform: rotateZ(-450deg);
    top: calc(100% - 48px);
    left: calc(100% - 20px);
  }
  75% {
    transform: rotateZ(-540deg);
    top: calc(100% - 73px);
    left: calc(100% - 48px);
  }
  99% {
    transform: rotateZ(-540deg);
    top: -30px;
    left: calc(100% - 48px);
  }
  100% {
    transform: rotateZ(-630deg);
    top: -2px;
    left: calc(100% - 73px);
  }
}
