.account-notifications {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  &__none {
    padding: 30px 0;

    width: 100%;
    //height: 100%;

    margin-top: auto;
    margin-bottom: auto;

    text-align: center;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  &__none-desc-wrapper {
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__none-desc {
    margin-top: 12px;

    color: #58595f;
  }

  &__none-btn {
    width: 210px;
    height: 48px;

    margin-top: 27px;

    border-radius: 28px;
    border: 1px solid #d6dadc;

    color: $primary_color;

    background: none;
    transition: 0.3s;

    &:hover {
      background: $primary_color-light;
      border-color: transparent;
      color: #ffffff;
    }

    &:active {
      background: $primary_color-dark;
      border-color: transparent;
      color: #ffffff;
    }
  }

  &__preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    user-select: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-x: hidden;
  }

  &__content-unread {
    background: #ffffff;
  }

  &__content-read {
    flex-grow: 1;
    background: #f6f8fc;

    border-top: 1px solid #e5e7ec;
  }

  &__content-read & {
    &__item-time {
      color: $fifty_color;
    }
  }

  &__item {
    position: relative;

    padding: 21px 0 19px 17px;

    cursor: pointer;
    transition: background 0.3s;

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        width: 96%;
        height: 1px;
        background: #e5e7ec;
        transform: translateY(-100%);
      }
    }

    &:hover {
      background: #f9f9f9;
    }
  }

  &__item-time {
    color: $primary_color;
    letter-spacing: 0.8px;
  }

  &__item-title {
    margin: 9px 0 8px 0;
  }

  &__item-desc {
  }
}
