.wrapper {
  display: inline-block;
  position: relative;
}
.icon {
  cursor: pointer;
}

.callout {
  position: absolute;
  top: -9px; 
  right: calc(50% + 6px);
  transform: translate(100%, -100%);
  display: none;
}

.icon:hover ~ .callout {
  display: block;
}
