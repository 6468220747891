@import 'static/assets/styles/global/global-varibles';

.container {
  display: flex;
  justify-content: space-between;
}

.info {
  color: var(--textColorSecond);
}

.info > svg {
  margin-right: 6px;
}

.label {
  font-size: 12px;
  line-height: 24px;
}

.inputWrapper {
  font-family: $sf_pro;
  display: flex;
  align-items: center;
  position: relative;
}

.input > input {
  margin-top: 0;
  text-align: end;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  border: none;
  background: transparent;
  color: var(--textColor);
  min-width: 20px;
  width: 60px;
  margin: 0;
  padding: 0 5px;
  height: 24px;
  -moz-appearance: textfield;
}

.input > input:focus {
  border: 1px solid #0f73ef;
}

.input > input::-webkit-outer-spin-button,
.input > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.prefix input {
  padding-left: 25px;
}
.prefix span {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  left: 10px;
  color: -var(--textColor);
}

.postfix input {
  padding-right: 25px;
}
.postfix span {
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  position: absolute;
  right: 3px;
  color: var(--textColor);
}

.selectInput {
  width: 120px;
  height: 24px;
}
