.container {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

.content {
  height: 100%;
  overflow-y: auto;
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.joinContainer {
  background: #f6f8fc;
  height: 100vh;
}

.background {
  position: absolute;
  height: 100%;
  animation: animatedX 5s ease-in-out infinite alternate-reverse forwards;
  opacity: 1;
}
.background path {
  animation: animatedXColor 1s ease-in-out infinite alternate-reverse forwards;
}

.whiteBlockBackground {
  position: absolute;
  height: 100%;
}

.darkFooter {
  color: black !important;
}

.darkFooter div,
.darkFooter p,
.darkFooter a,
.darkFooter span {
  color: black !important;
}

@keyframes animatedX {
  0% {
    transform: rotate(-5deg) scale(1);
    opacity: 0.3;
  }
  50% {
    transform: rotate(-0deg) scale(0.8);
    opacity: 1;
  }
  100% {
    transform: rotate(5deg) scale(1);
    opacity: 0.3;
  }
}

@keyframes animatedXColor {
  0% {
    fill: #016bed4a;
    stroke: #016ced;
    stroke-width: 18;
  }

  100% {
    fill: #016ced;
    stroke: #016bed4a;
    stroke-width: 68;
  }
}

.footer {
  width: 75%;
  align-self: flex-end;
  margin-bottom: 28px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  z-index: 1;
}

.scene {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  animation-duration: 0.5s;
  animation-name: showSetup;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 1.9s;
}

@media (max-width: 768px) {
  .footer {
    width: 82%;
  }
}

@media (max-width: 420px) {
  .footer {
    width: 100%;
    padding-left: 17px !important;
    padding-right: 17px !important;
    margin-bottom: 15px !important;
  }
  .joinContainer {
    height: 100%;
  }
}
