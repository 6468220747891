.wrapper {
  display: flex;
  height: 100%;
  padding: 20px;
  overflow: clip;
  background: var(--welcome_background);
}

.border {
  position: relative;
  width: 100%;
  border-radius: 20px;
  border: 0.8px solid rgba(255, 255, 255, 0.5);
}

.cube {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
  animation: moveCircle 40s linear infinite;
}

.circle {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #fff;
  transform: rotate(45deg);
}

.setupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
  padding: 32px;
}

.setupBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 100%;
  width: 100%;
}

.setupLogo {
  max-width: 490px;
  height: auto;
  color: var(--neyraLogoColor);
}

.setupText {
  color: var(--welcome_text_color);
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  letter-spacing: 0.24px;
}

.setupProgress {
  position: relative;
  width: 100%;
  max-width: 82%;

  border-radius: 8px;
  border: 1px solid #f5e12d;

  color: #141313;
  text-align: center;
  font-family: 'Digital Numbers';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.24px;

  span {
    position: inherit;
    z-index: 1;
  }
}

.whiteText {
  color: #fff;
}

.fill {
  position: absolute;
  height: 100%;
  border-radius: 8px;
  background-color: white;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 16px;
  padding-bottom: calc(16px + 3%);
  gap: 16px;
}

.messageBlock {
  max-width: 820px;
  min-height: 330px;
  width: 100%;
  padding-top: calc(16px + 8%);
  overflow-y: auto;

  color: var(--welcome_text_color);
  font-family: 'Nunito';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  white-space: pre-line;

  ul {
    margin: 0;
    padding-left: 30px;
  }

  span {
    font-weight: 700;
  }
}
.messageBlock::-webkit-scrollbar {
  display: none;
}

.activateButton {
  max-width: 398px;
  width: 100%;
  padding: 12px 16px;

  border-radius: 14px;
  border: 0.7px solid var(--welcome_yellow);
  background-color: var(--welcome_button_background);

  color: var(--welcome_button_text_color);
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  transition: all 0.3s ease;
  cursor: pointer;
}

.hideButton {
  opacity: 0;
  cursor: auto;
}

@keyframes moveCircle {
  0% {
    top: 20px;
    left: -4px;
  }
  24% {
    top: calc(100% - 20px);
    left: -4px;
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(-90deg);
    top: calc(100% - 46px);
    left: 20px;
  }
  49% {
    transform: rotateZ(-90deg);
    top: calc(100% - 46px);
    left: calc(100% - 20px);
  }
  50% {
    transform: rotateZ(-180deg);
    top: calc(100% - 73px);
    left: calc(100% - 46px);
  }
  74% {
    transform: rotateZ(-180deg);
    top: -30px;
    left: calc(100% - 46px);
  }
  75% {
    top: -4px;
    left: calc(100% - 73px);
    transform: rotateZ(-270deg);
  }
  99% {
    transform: rotateZ(-270deg);
    top: -4px;
    left: -30px;
  }
  100% {
    transform: rotateZ(-360deg);
    top: 20px;
    left: -4px;
  }
}
