@import 'static/assets/styles/global/global-varibles';

.corners {
  position: absolute;
  width: 34px;
  height: 34px;
  background: transparent;
  &.top {
    top: 10px;
    border-top: 2px solid #fff;
  }

  &.bottom {
    bottom: 10px;
    border-bottom: 2px solid #fff;
  }

  &.left {
    left: 10px;
    border-left: 2px solid #fff;
  }

  &.right {
    right: 10px;
    border-right: 2px solid #fff;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  display: block;
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: auto;
    max-width: 488px;
    padding: 40px;
    text-align: center;
    background: var(--modalContent);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    height: 540px;
    margin: auto;
    border-radius: 4px;
    align-items: center;
    gap: 40px;
    justify-content: center;
  }
  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    color: #7d7d7d;
  }
  &__title {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 32px;
    color: var(--textColor);
    margin: 0 0 24px 0;
    font-weight: 400;
  }
  &__container {
    position: relative;
    background: #2f2f2f;
    margin-bottom: 12px;
    height: 290px;

    &__rec {
      top: 23px;
      right: 21px;
      position: absolute;
      color: #ed1d2d;
      font-family: $sf_pro;
      font-size: 10px;
      line-height: 12px;
      & > svg {
        width: 9px;
        height: 9px;
      }
    }
    &__camera {
      top: calc(50% - 12.5px);
      left: calc(50% - 12.5px);
      position: absolute;
    }
    &__date {
      top: 23px;
      left: 21px;
      position: absolute;
      font-family: $sf_pro;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
    }
    &__timer {
      position: relative;
      bottom: 30px;
      font-family: $sf_pro;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0.1em;
      color: #fff;
      height: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 484px;

    &__stop {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: #c1c9d9;
      color: #ffffff;
      padding: 11px 28px;
      border: 1px solid #c1c9d9;
      min-height: 48px;
      & > svg {
        margin-right: 10px;
      }
    }

    &__play {
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .modal {
    &__content {
      padding: 0;
      width: calc(100vw - 32px);
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      height: 100%;
      max-height: 446px;
    }
  }
}

@media screen and (max-width: 480px) {
  .modal {
    &__content {
      padding: 0;
      max-height: 360px;
      width: calc(100vw - 32px);
      padding: 40px 24px !important;
      height: max-content !important;
    }
    &__title {
      font-size: 20px;
    }
  }
}
