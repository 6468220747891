@import 'static/assets/styles/global/global-varibles';

.entry-list {
  height: 100%;
  padding: 0 31px;
  position: relative;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
  &-active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 2px);
      background: rgba(232, 240, 253, 0.4);
      border: 1px solid $primary_color;
      z-index: 0;
    }
  }

  &::-webkit-scrollbar {
    width: 1px;
    padding: 5px;
  }

  &::-webkit-scrollbar-track {
    margin: 0 0 30px 0;
  }

  &-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.entry-upload {
  width: 356px;
  height: 90px;
  background: $primary_color;
  box-shadow: 0 2px 12px rgba(26, 49, 139, 0.15);
  border-radius: 8px;
  padding: 16px 50px 14px 50px;
  position: fixed;
  bottom: 32px;
  left: calc(50% + 120px);
  margin-left: -178px;
  z-index: 1003;

  &-title {
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $primary_light;
    margin: 0 0 4px 0;
  }

  &-folder {
    font-family: $sf_pro;
    font-size: 18px;
    line-height: 24px;
    color: $primary_light;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;

    &-icon {
      width: 32px;
      height: 32px;
      margin: 0 4px 0 0;
      padding: 4px 7px 9px 7px;

      & > svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.site__pages-entity-settings {
  .entry-list {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .entry-list {
    overflow-y: auto;
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    height: -webkit-fill-available;
    padding: 0 20px 9px 20px;
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: var(--scrollbarColor);
    }
  }

  .entry-list > div {
    overflow-y: initial;
    min-height: initial;
    max-height: initial;
    margin-bottom: 60px;
  }
}
