@import '../../static/assets/styles/global/global-varibles.scss';

.input {
  display: block;
  width: 100%;
  border: 1px solid var(--inputBorder);
  padding: 12px 16px;
  font-family: $sf_pro;
  font-size: 12px;
  line-height: 24px;
  color: var(--textColor);
  margin-bottom: 5px;
  height: 48px;
  background-color: var(--profileCard);

  &:disabled {
    background-color: var(--inputDisabled);
    cursor: not-allowed;
  }
}

.textarea {
  min-height: 96px;
  resize: none;
}

.themeGeneral {
  position: relative;
}

.themeGeneral input {
  background-color: var(--inputBg);
  border: 1px solid var(--inputBorder);
  color: var(--textColor);
}

.themeSecond input {
  background-color: var(--newInputBg);
  border: 1px solid var(--inputBorder);
  color: var(--textColor);
}

.themeUnderlined input {
  color: $colBlackText;
  border: none;
  border-bottom: 1px solid #d6e2f5;
  font-family: $sf_pro;
  font-size: 18px;
  line-height: 24px;
}

.themeUnderlined input::placeholder {
  color: $colGrayMed;
}

.wrapper.isError .input {
  background-color: rgba(237, 29, 45, 0.05);
  border: 1px solid #ed1d2d;
}

.isError .input {
  background-color: rgba(237, 29, 45, 0.05);
  border: 1px solid #ed1d2d;
}

.label {
  display: inline-block;
  font-family: $sf_pro;
  color: var(--textColor, #7D7D7D);
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 5px;
  margin-right: 8px;
}

.themeGeneral .label {
  color: var(--textColor, #7D7D7D);
  font-family: $sf_pro;
}

.disabled .label {
  color: var(--textColor);
}

.optional {
  color: #7e8a9f;
  margin-left: 5px;
}

.errorMessage {
  text-align: initial;
  font-size: 12px;
  line-height: 18px;
  color: #ed1d2d;
}

.required {
  color: #ed1d2d;
  margin-left: 2px;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 30px;
  color: var(--inputEye, #202124);
}

.input::-ms-reveal,
.input::-ms-clear {
  display: none;
}

.customIcon {
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 15px;
}

.mint_text_container {
  background-color: #ffffff;
  border: 2px solid #f5f5f5;
  padding: 8px 16px;
  margin-bottom: 16px;
  height: 40px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  color: #202124;
}

.mint_label {
  display: inline-block;
  font-family: $sf_pro;
  margin-bottom: 5px;
  margin-right: 8px;

  width: 72px;
  height: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #b1b5c4;
}

.mint_container {
  text-align: left;
}

.mint_text {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 4px 0;
  color: #202124;
}

.scrollable {
  height: 60px;
  max-height: 120px;
  overflow-y: scroll;
}
