@import 'static/assets/styles/global/global-varibles';

.createForm {
}

.input {
  margin-bottom: 14px;
}

.optionCreate {
  display: flex;
  align-items: center;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionName {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.optionNetwork {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & svg {
    max-width: 20px;
    min-height: 20px;
  }
}

.optionNetworkMarker {
  font-family: 'SF Pro Text', sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin-left: 6px;
  color: #fff;
}

.plusBtn,
.optionColor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #eaedf4;
  border-radius: 60px;
  margin-right: 10px;

  & > svg {
    width: 12px;
    height: 12px;
  }
}
