@import 'static/assets/styles/global/global-varibles';

.password {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progressWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 12px;
}

.progressLabel {
  font-family: $sf_pro;
  font-weight: 500;
  margin: 0 0 0 20px;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.progressBar {
  width: 100%;
  height: 6px;
  background: #F5F5F5;
}

.activeBar {
  height: 6px;
}

.activeBar-weak {
  width: 30%;
  background-color: #ED1D2D;
}

.activeBar-middle {
  width: 60%;
  background-color: #F2BF65;
}

.activeBar-strong {
  width: 100%;
  background-color: #5BD230;
}

.activeLabel-weak {
  color: #ED1D2D;
}

.activeLabel-middle {
  color: #F2BF65;
}

.activeLabel-strong {
  color: #5BD230;
}