.mobile-apps-popup {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 516px;
  padding: 54px 0 57px 0;

  text-align: center;

  background: #ffffff;

  &__exit {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  &__description {
    padding: 0 15px;
    box-sizing: border-box;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 10px;

    letter-spacing: -0.35px;
    font-size: 28px;
    line-height: 40px;
    color: #10161a;
  }

  &__subtitle {
    color: #58595f;
  }

  &__buttons {
    display: flex;

    margin-top: 30px;
  }

  &__app-btn {
    background: none;
    outline: none;
    border: none;
    box-shadow: none;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 195px;
    height: 56px;

    border: 1px solid #dddee3;
    border-radius: 31px;

    text-align: left;

    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__app-btn-text {
    margin-left: 10px;
  }

  &__app-btn-txt {
    color: #7b7b7b;
  }

  &__continue-btn {
    margin-top: 30px;
  }

  &__skip-text {
    margin-top: 24px;

    color: #d1d3d3;
    line-height: 1;
    cursor: pointer;
  }
}

@media screen and (max-width: 540px) {
  .mobile-apps-popup {
    width: 95%;
  }
}

@media screen and (max-width: 450px) {
  .mobile-apps-popup {
    &__title {
      font-size: 24px;
      line-height: 1.2;
    }

    &__buttons {
      flex-direction: column;
    }

    &__app-btn {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
