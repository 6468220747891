.apiForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.apiForm input {
  text-transform: initial;
}

.apiForm button {
  justify-content: center;
}

.apiItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px;
  border: 0.7px solid #5e5e5e80;
  border-radius: 20px 3px 20px 20px;
  cursor: pointer;
  min-height: 62px;
  max-height: 62px;
  transition: all 0.3s ease-in-out;
}
.apiItem p {
  color: var(--textColor);
}

.info {
  display: flex;
  flex-direction: column;
}

.copyBtn {
  display: none;
  color: var(--iconColor);
  cursor: pointer;

  & > svg {
    height: 14px;
  }
}

.deleteBtn {
  display: none;
  color: var(--iconColor);
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 18px;
}

.apiItem:hover {
  .deleteBtn, .copyBtn {
    display: block;
  }
}