@import 'static/assets/styles/global/global-varibles';

.container {
  font-family: $sf_pro;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  padding-bottom: 46px;
}

.textLine {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 0;
}

.signLink {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #202124;
  margin-left: 8px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #202124;
  opacity: 0.6;
}

.policyText {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
}

.policyLink {
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  
  &:last-child {
    margin-right: 0;
  }
}

.mobileBreak {
  display: none;
}

@media (max-width: 800px) {
  .container {
    padding-right: 40px;
    padding-bottom: 34px;
  }

  .textLine {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 24px;
    gap: 8px;
  }

  .text {
    font-size: 12px;
    line-height: 150%;
  }

  .signLink {
    font-size: 14px;
    line-height: 18px;
  }

  .policyText, .policyLink {
    font-size: 10px;
    line-height: 15px;
  }
}