@import 'static/assets/styles/global/global-varibles';

.button {
  width: calc( 50% - 5px);
  height: 48px !important;
  padding: 0;
  text-transform: uppercase;
  font-family: $sf_pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: center;
}

.container {
  margin-bottom: 22px;
  font-family: $sf_pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #7C7E88;
}

.name {
  color: #4B576C;
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
}

.workspace {
  color: #0F73EF;
}

.title {
  font-family: $sf_pro;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;

}

.buttonWrapper{
  display: flex;
  justify-content: center;
}