.sidebarList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
  height: calc(100vh - 106px);
}

.sidebarContent {
  height: calc(100vh - 170px);
}

.title {
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 0;
  margin-top: 24px;
  color: var(--textColorSecond);
}

.sidebarItem > button {
  opacity: 0;
  transition: opacity .6s;
}

.sidebarItem:hover > button {
  opacity: 1;
}

.chatList {
  height: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 5px;
  margin-top: 10px;
}

.connectInput {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

.input {
  display: none;
  width: 100%;
  height: 42px;
  font-size: 20px;
  border: none;
  border-radius: 6px;
  background-color: var(--inputBg);
  color: var(--textColor);
  padding: 3px 8px;
}

.connectBtn {
  display: none;
  width: 160px;
  padding: 4px 10px;
  border-radius: 8px;
  background-color: var(--baseColor);
  color: var(--textColor);
  cursor: pointer;
  text-transform: uppercase;
}

.connectBtnShow {
  display: block;
}

/////////////////////////

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 10051;
}

.modal {
  display: block;
}

.connectModal {
  position: absolute;
  top: 50%;
  left: calc(50% - 170px);
  width: 100%;
  max-width: 520px;
  min-height: 170px;
  border-radius: 8px;
  padding: 19px 50px 30px;
  text-align: center;
  background: var(--textColor);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: var(--baseColor);
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
