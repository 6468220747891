@import 'static/assets/styles/global/global-varibles';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 346px;
  max-width: 450px;
  text-align: center;
  background: #ffffff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  font-family: $sf_pro;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close {
  cursor: pointer;
}

.content {
  padding: 0 50px 34px 50px;
  display: flex;
  flex-flow: column nowrap;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  margin: auto;
  width: 80px;
  height: 80px;
  background-color: #0f73ef;
  border-radius: 50%;
}

.titile {
  font-weight: 400;
  font-size: 24px;
  line-height: 23px;
  color: #1a1a1c;
  margin-top: 20px;
  margin-bottom: 5px;
}

.description {
  font-size: 16px;
  line-height: 24px;
  color: #4b576c;
  margin: 0;
}

.form {
  margin-top: 20px;
}

.formButtons {
  display: flex;
  justify-content: center;
}

.formButtons > button {
  font-weight: 400;
}

.passField > button {
  top: 16px;
}

.modal {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}
