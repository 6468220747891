.text {
  font-family: $sf_pro;

  &--8 {
    font-size: rem(8px);
  }

  &--10 {
    font-size: rem(10px);
  }

  &--11 {
    font-size: rem(11px);
  }
  &--12 {
    font-size: rem(12px);
  }

  &--14 {
    font-size: rem(14px);
  }

  &--16 {
    font-size: rem(16px);
  }

  &--18 {
    font-size: rem(18px);
  }

  &--24 {
    font-size: rem(24px);
  }
  &--27 {
    font-size: rem(27);
  }
  &--32 {
    font-size: rem(32px);
  }
  &--40 {
    font-size: rem(40px);
  }
  &--64 {
    font-size: rem(64px);
  }

  &--bold {
    font-family: $sf_pro;
    font-weight: 400;
  }

  &--medium {
    font-family: $sf_pro;
    font-weight: 400;
  }

  &--light {
    font-family: $sf_pro;
    font-weight: 300;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--red {
    color: $red;
  }

  &--light-red {
    color: $light_red;
  }
}
