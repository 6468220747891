.wallet-container {
  display: flex;
  flex-direction: column;
  background: #f6f8fc;

  .popups-head {
    background: #ffffff;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
