@import 'static/assets/styles/global/global-varibles';

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: calc(100% - 46px);
}

.infoIcon {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 11px;
}

.menuBtn {
  width: 12px;
  height: 18px;
  margin-right: 8px;
  color: var(--sidebarSelectWS);
  cursor: pointer;
}

.noTitle .avatarWrapper:hover {
  background-color: #f6f8fc;
}

.infoContent {
  display: flex;
  width: 100%;
}

.infoWrapper {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  position: relative;
  margin-left: 10px;
  width: 100%;
}

.wsImgWrapper {
  height: 50px;
  overflow: hidden;
  width: 100%;
}

.img {
  width: 100%;
  height: 100%;
}

.title {
  color: var(--textColor);
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  font-family: 'Nunito';
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: var(--sidebarGrayText);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titleWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noTitle .title {
  display: none;
}

.collapsed {
  height: 49px;
  width: 49px;
}

.collapsed .title {
  text-align: center;
  line-height: 14px;
  margin-bottom: 0;
}

.collapsed .subtitle {
  display: none;
}

.collapsed .infoWrapper {
  margin-left: 18px;
  display: none;
}

.collapsed .menu {
  display: none;
}

.collapsed .menuBtn {
  display: none;
}

.collapsed.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}
.collapsed .avatarWrapper {
  margin-left: 13.5px;
}

.collapsed .infoIcon {
  margin: 0;
}

.collapsed .infoContent {
  justify-content: center;
}