html[app-theme='light'] {
  --baseColor: #ffffff;
  --secondColor: #f5f5f5;
  --primeColor: #0f73ef;
  --bluredPrimeColor: #0f74ef50;
  --primeTextColor: #007aff;
  --primeSecond: #e7f1fd;
  --textColor: #000000;
  --textColorSetting: #202124;
  --textColorSecond: #7c7e88;
  --textColorThird: #1a1a1a;
  --textColorFour: #000000;
  --textColorGray: #afafaf;
  --textColorGraySecond: #4b576c;
  --iconColor: #202124;
  --iconColorPrime: #0f73ef;
  --iconSecondColor: #000000;
  --iconDisabledColor: rgba(125, 125, 125, 0.49);
  --contextIcon: #4b576c;
  --indicatorSpace: #e4e7ec;
  --indicatorActive: #202124;
  --grayBg: #f6f8fc;
  --grayBgSecond: #7e8a9f;
  --modalOverlay: rgba(16, 22, 26, 0.95);
  --modalBG: #f5f5f5;
  --secondModalOverlay: #ffffff;
  --membersPriceModalText: #7d7d7d;
  --membersInfoIconColor: #202124;
  --darkBlue: #1a318b;
  --filterBackground: #f5f8fc;
  --filterSortIconColor: #e8e9ed;
  --mainContainerColor: radial-gradient(
    55.86% 55.86% at 50% 44.14%,
    #fff 0%,
    #dedede 84.5%,
    #d5d5d5 100%
  );
  --workspaceMainButtonColor: rgba(255, 255, 255, 0.6);
  --workspaceMainButtonBorderColor: rgba(0, 0, 0, 0.5);
  --actionButtonsColor: transparent;
  --actionButtonsHover: transparent;
  --squareFileBorder: #fff;
  --neyraBorderColor: #fff;
  --neyraBorderButtonsColor: #000000;
  --neyraBorderNavigationColor: rgba(0, 0, 0, 0.3);
  --neyraBorderButtonsHover: #000;
  --neyraBorderButtonsActive: #000;
  --neyraLogoColor: #000;
  --chatInput: rgba(0, 0, 0, 0.2);
  --searchInput: #000;
  --studioTabHover: rgba(0, 0, 0, 0.2);
  --filterCardStudio: #eaeaea;
  --filterCardBorderStudio: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.73) 0.19%,
    rgba(255, 255, 255, 0) 103.8%
  );
  --historyItemText: #000;
  --chatMessage: #ffffff80;
  --sidebarTabsBackground: #fff;
  --datePickerBorder: rgba(0, 0, 0, 0.4);
  --scrollbarColor: #7e8a9f;
  --studioFiltersShadow: linear-gradient(
    180deg,
    rgba(214, 213, 213, 0) 0%,
    #d6d5d5 97.9%
  );
  --studioLoaderShadow: #fff2;
  --lightGrayTab: rgba(255, 255, 255, 0.5);
  --grayTabBorder: rgba(94, 94, 94, 0.6);
  --studioPanelBorder: #fff;
  --rangeInputBg: rgba(0, 0, 0, 0.2);
  --swapButton: #f7f7f7;
  --pagePreview: #FFFFFFB2;
  --previewBoxShadow: linear-gradient(360deg, rgba(217, 217, 217, 0) 0%, #D9D9D9 71.91%);
  --previewBoxShadowBot: linear-gradient(180deg, rgba(217, 217, 217, 0) 28.09%, #D9D9D9 100%);

  // folder
  --folderIndicator: #ffffff;
  --folderIndicatorIcon: #202124;
  --folderBoxShadow: inset 0 0 0 4px #e8f0fd;
  --folderActive: #e8f0fd;

  --filterCardBorder1Studio: rgba(0, 0, 0, 0.73) 0.19%;
  --filterCardBorder2Studio: rgba(255, 255, 255, 0) 103.8%;

  //file
  --fileBg: #ffffff;
  --fileBorder: #000000;
  --fileBorderUp: #ffffff;
  --fileBorderDown: #ffffffe5;
  --fileBorderSeparate: #ffffffe5;

  --avatarText: #ffffff;
  --profileCard: #fafafa;
  --tokensCard: #fcfcfc;
  --buttonColor: #202124;
  --border: #000000;
  --borderSecond: #efefef;
  --borderNone: #000000;
  --borderTransparent: transparent;
  --inputDisabled: #f2f2f2;
  --inputBorder: #d6e2f5;
  --inputBg: #fafafa;
  --inputErrorBg: #fff2f3;
  --ruleColor: #e4e7ec;
  --tooltipBackground: #ffffff;
  --tooltipContent: #ffffff;
  --tooltipContentSecond: #ffffff;
  --modalContent: #ffffff;
  --inputEye: #7d7d7d;
  --sidebarHover: #f6f8fc;
  --dashedDividerBorder: #e8e9ed;
  --sidebarItemBorder: #e8e9ed;
  --sidebarItemBorderActive: #c1c9d9;
  --datePickerHeader: #f0f0f0;
  --hover: rgba(15, 115, 239, 0.07);
  --contextBorder: rgb(228, 231, 236);
  --aiAutocompleteButtons: #323436;
  --aiAutocompleteButtonsText: #ffffff;
  --aiGeneratorHeaderActive: #000000;
  --aiGeneratorHeader: #716d6d;
  --aiGeneratorButtons: #e9ecf0;
  --aiGeneratorButtonsHover: rgba(27, 26, 26, 0.337);
  --comingSoonLabelText: #0f73ef;
  --comingSoonLabelBackground: #e7f1fd;
  --evenRow: #fafafa;
  --notificationHover: #f2f4f8;
  --convertModalBtnColor: #3b3b3b;
  --convertModalBtnTextColor: #b9b9b9;
  --dirButton: #e8effb;
  --editorIconBg: none;
  --lightBorderOnly: #e8e9ed;
  --buttonBGDarkOnly: #ffffff;
  --switchToggleDarkOnly: #ffffff;
  --disabledBtn: #f4f6f9;

  // swith
  --switchBgActive: #1a1a1a;
  --switchBgInactive: #b9b9b9;
  --boxShadow: 0px 2px 8px #ccceda;

  //skeleto
  --skeletonItem: #e9ecf0;

  //setting
  --sticksForRender: #f1f1f1;
  --progress: #4b576c;

  //tokensPage
  --textTokens: #2b2c31;
  --tokenInput: #1a1a1a;
  --tokenInputBuy: #070707;
  --tokenHistory: #fafafa;
  --tokenHistoryItem: #4b576c;
  --tokenIcon: #fff;
  --expirationDate: #7c7e88;
  --error: #ed1d2d;
  --bcgError: #fff;
  --inputBorderError: #c1c9d9;
  --featureIcon: #202124;
  --tokenIconBorder: 1;
  --tokenIconBorderColor: #f4f6f9;

  //downloadComponent
  --downloadTitle: #10161a;
  --downloadSubTitle: #7e8a9f;
  --downloadIcon: #4b576c;
  --downloadLoaderBg: #ffffff;

  // sedebar new styles
  --secondBaseColor: #f8f8f9;
  --dividingLine: #e6e6ea;
  --sidebarMarker: #ededef;
  --sidebarMarkerText: #b9b9b9;

  --sidebarGrayText: #82858b;
  --sidebarIcon: #494951;
  --sidebarNewBg: #f8f8f9;
  --sidebarActiveItem: #ffffff;
  --sidebarActiveBorderItem: 0.6px solid #e1e1e7;
  --sidebarNewBoxShadow: 0px 1px 1.6px 0px #00000020;
  --sidebarNewHover: 0px 1px 1px 0px #e1e1e799;
  --sidebarActiveIcon: #1c1b1f;
  --sidebarGroupActive: #e3ebff;
  --sidebarGroup: #ededef;
  --sidebarGroupBorder: 0.6px solid #ffffff;
  --sidebarGroupBorderActive: 0.6px solid #ffffff;
  --sidebarGroupColor: #494951;
  --sidebarGroupColorActive: #007aff;
  --sidebarSelectWS: #494951;

  --hrColor: #e6e6ea;
  --addBtn: #ffffff;
  --settingsBtn: #ffffff;
  --settingsBtnHover: #e1e1e7;
  --settingSort: #ededef;
  --settingsBorder: #e1e1e7;
  --settingsHeadBorder: #e1e1e7;
  --settingsHeadBg: #ffffff;
  --settingsBorderColor: #ffffff;

  --folderColor: #ffffff;
  --folderHeader: #ededef;

  --buttonBg: #ffffff;
  --buttonIconColor: #1c1b1f;
  --buttonBorder: 0.6px solid #e1e1e7;
  --buttonBgSecond: #202124;
  --buttonBgGray: #d9d9d9;

  --checkBoxBorder: #4b576c;
  --newDividingLine: #e6e6ea;
  --borderCard: #f1f1f1;
  --newGrayTextColor: #494951;
  --newInputBorderColor: #f1f1f1;
  --borderCardColor: #f1f1f1;
  --badgBgColor: #e8e9ed;
  --settingsHrColor: #e6e6ea;
  --settingsMenuBg: #ffffff;
  --headerDirectionBg: #e7e9ed;
  --unreadBadgeBg: #202124;
  --wsSettingsHover: #e1e1e7;
  --newInputBg: #fafafa;

  // neyra
  --neyra_msg_bg: #272727;
  --neyra_text_color: #ffffff;

  --settingsSidebarColor: #202124;
  --settingsSidebarHiver: #0687e399;

  --terminalBorder: #000000;
  --terminalPlaceholder: #5e5e5e;
  --productBorderColor: #007aff;

  //welcome
  --welcome_background: radial-gradient(
    55.86% 55.86% at 50% 44.14%,
    #fff 0%,
    #dedede 84.5%,
    #d5d5d5 100%
  );
  --welcome_text_color: #0f0f0f;
  --welcome_button_background: rgba(255, 255, 255, 0.4);
  --welcome_button_text_color: #0f0f0f;
  --welcome_yellow: #f5e12d;
}
