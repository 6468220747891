.sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 62px;
  padding: 11px 20px 11px 20px;
  gap: 12px;
  border-radius: 20px;
  border: 0.7px solid #5e5e5e80;
  cursor: pointer;
}

.sidebar:hover {
  border-color: var(--primeTextColor);
  border-width: 1px;
}

.sidebar:hover .archiveBtn {
  display: block;
}

.chatName {
  font-family: 'Nunito';
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin: 0;  
  color: var(--textColor);
}

.sidebarBody {
  width: calc(100% - 34px);
  display: flex;
  align-items: center;
  gap: 12px;
}

.archiveBtn {
  display: none;
  cursor: pointer;
}

.archiveBtn svg {
  height: 17px;
  width: 17px;
  color: var(--textColor);
}

.loadingBtn {
  
}