.entity-settings {
  max-width: 345px;
  width: 100%;

  &__popup {
    &__cancel {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #206acf;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      margin: 10px auto auto auto;
    }
    &-img {
      margin-top: 5%;
    }
    &-title {
      font-family: $sf_pro;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #10161a;
    }
    &-content {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin: 0 auto;
      color: #58595f;
      width: 75%;
      margin-bottom: 20px;
    }
    &-subtitle {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: #10161a;
      width: 70%;
      margin: 0 auto 10px auto;
    }
  }
  &__btn {
    border: none;
    outline: none;
    line-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    min-width: 164px;
    height: 46px;
    margin: 60px auto 0 auto;
    border-radius: 28px;
    background-color: #206acf;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1.4px;
  }

  &__content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 345px;
    height: calc(100vh - 85px);
  }

  &__content {
    &-scrollable {
      height: calc(100vh - 450px);
      overflow-y: scroll;
    }
  }

  &__file-info-wrapper {
    display: flex;
    align-items: center;
    padding: 22px 16px;
    box-shadow: inset 0 -1px 0 #ccceda;
    background: $primary_light;
  }

  &__file-info-details {
    margin-left: 14px;
  }

  &__file-info-data {
    font-family: $sf_pro;
    line-height: 16px;
    color: $fifty_color;
  }

  &__file-info-dot {
    width: 3px;
    height: 3px;
    margin-right: 5px;

    border-radius: 50%;

    background-color: rgba(171, 173, 185, 0.6);
  }

  input {
    &:checked {
      & + .slider {
        background-color: #2196f3;
        border: 1px solid #2196f3;
      }
      & + .slider:before {
        transform: translateX(26px);
        background-color: $primary_light;
      }
    }

    &:focus {
      box-shadow: 0 0 1px #2196f3;
    }
  }

  &__dwnld {
    color: #7c7e88;
    font-family: $sf_pro;
    line-height: rem(24px);
    padding: 16px 0 60px;
  }

  &__link {
    margin: 0 3px;
  }

  &__footer {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-top: auto;
    padding: 12px 16px;
    box-shadow: inset 0 1px 0 #ccceda;

    background: #ffffff;
  }

  &__footer-options {
    position: relative;
    margin-right: 20px;

    cursor: pointer;

    .file-action {
      bottom: 0;
      right: 100%;
      transform: translateX(-11px);
    }
  }

  &__footer-download {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 40px;
    text-decoration: none;

    span {
      margin-left: 10px;
    }
  }

  // Preloader
  .ghost-logo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f1f4f8;
    z-index: 2;
  }

  .popups-head {
    padding: 0 24px 0 0;
    border: none;
    min-height: auto;

    &__full {
      padding: 0;
      min-height: 58px;

      .tabs {
        width: 100%;
      }
    }

    .tabs {
      margin: 0;
      padding: 0;
      &-hint {
        position: relative;
      }
      &-item {
        padding: 13px 31px 13px 31px;
        flex: 1 1 0;
        text-align: center;

        &.active {
          padding-bottom: 17px;
        }
        &:hover:not(:disabled) {
          cursor: pointer;
        }
        &-disabled {
          svg path {
            fill: var(--iconDisabledColor);
          }
        }
        &.neyra {
          padding: 13px 27px 13px 27px;
        }
      }
    }
    &__titled {
      padding-left: 22px;
      height: 56.5px;
    }

    &__title {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: var(--textColor);
      text-transform: capitalize;
      width: 266px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;

    &-container {
      width: 345px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-default {
        width: 345px;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg.default {
          width: 136px;
          height: 93px;
        }

        &-column {
          flex-direction: column;
          justify-content: space-between;

          svg {
            width: 100%;
          }
        }
      }
    }
  }

  .info-history {
    &.tabs {
      margin: 0;
      padding: 0;
    }

    .tabs-item {
      padding: 15px 0 13px 0;
      width: 33%;
      text-align: center;
      color: $tab_item;

      &.active {
        color: $primary_dark;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__info {
    padding: 21.5px 20px 9px 22px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
      margin: 0 0 4px 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-size {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
      margin: 0 0 15px 0;
    }

    &-item {
      display: flex;
      justify-content: space-between;

      &-title {
        font-family: $sf_pro;
        font-size: 13px;
        line-height: 24px;
        color: var(--textColorSecond);

        & > svg {
          margin-right: 6px;
        }
      }

      &-fileName {
        font-family: $sf_pro, 'sans-serif';
        font-size: 13px;
        line-height: 24px;
        color: var(--textColor);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: end;
        width: 80%;
      }

      &-value {
        font-family: $sf_pro, 'sans-serif';
        font-size: 13px;
        line-height: 24px;
        color: var(--textColor);
        &-owner {
          font-family: $sf_pro;
          font-size: 12px;
          line-height: 24px;
          color: var(--textColor);
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__hash {
        &-copy {
          cursor: pointer;
          & > svg {
            width: 10.5px;
            height: 12.25px;
            color: var(--iconColor);
            &:hover {
              path {
                fill: #0f73ef;
              }
            }
          }
        }
        &-value {
          display: flex;
          align-items: center;
          gap: 4px;
          font-family: $sf_pro;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: var(--textColor);
        }
      }
      &__users {
        &-edit {
          position: relative;
          cursor: pointer;
          margin-top: 5px;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border: 1px solid #e4e7ec;
          border-radius: 40px;
          background-color: #ffffff;
          &::before {
            cursor: default;
            content: '';
            position: absolute;
            width: 0;
            height: 20.39px;
            border-left: 1px solid var(--inputBorder);
            left: -21px;
          }

          & > svg {
            color: #202124;
          }
        }
      }
    }
  }

  &__tags {
    padding: 11px 22px 13px 22px;

    &.adding {
      padding: 11px 20px 15px 20px;
      margin: 0 11px;
    }

    &-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: var(--textColor);
      margin: 0 0 4px 0;
    }

    &-item {
      max-width: 300px;
    }

    .react-tagsinput-input {
      input {
        width: 80px;
        max-width: 272px;
        height: 26px;
        caret-color: var(--primeColor);
        background: transparent;
        border: 0;
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: var(--textColor);
        margin: 0;
        padding: 0;
        outline: none;
        overflow-wrap: anywhere;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }

    &-input {
      background: var(--inputBg);
      border: 1px solid var(--inputBorder);
      padding: 0 0 4px 4px;
      position: relative;

      &:focus-within {
        border: 1px solid var(--primeColor);
      }
    }

    .react-tagsinput {
      &-tag {
        display: inline-flex;
        align-items: center;
        background: var(--tooltipContentSecond);
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: var(--textColorGray);
        border: none;
        padding: 5px 12px 5px 10px;
        margin: 4px 4px 0 0;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
      }

      &-remove {
        display: inline-block;
        width: 12px;
        height: 12px;
        color: $fifty_color;
        background: url(/static/assets/img/cancel-icon--big.svg) no-repeat
          center center;
        background-size: 12px 12px;
        margin: 0 0 0 10px;
        cursor: pointer;

        &:before {
          content: '';
        }
      }
    }

    .tags-suggest {
      &-container {
        .link {
          margin: 14px 0 0 0;
          overflow-wrap: anywhere;
        }
      }

      &-item {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $fifty_color;
        margin: 8px 0 0 0;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;

        &-error {
          font-family: $sf_pro, 'sans-serif';
          font-size: 12px;
          line-height: 24px;
          color: #9b0032;
        }
      }
    }

    .link--shrink {
      margin: 0;
    }
  }

  &__colors {
    &-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: var(--textColor);
      margin: 0 0 4px 0;
    }

    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 28px;
    }

    &-item {
      $item-color: &;
      width: 22px;
      height: 22px;
      border: 1px solid $fifty_color;
      box-sizing: border-box;
      background: url(/static/assets/img/icons/colors/empty.svg) no-repeat
        center center;

      &-box {
        margin: 0 7.5px 0 7.5px;

        &:first-child {
          margin-left: 0;
        }

        &.active {
          border: 1px solid $fifty_color;
          padding: 3px;
        }

        &.edit {
          border-color: #d9dbdd;

          #{$item-color} {
            border-color: #d9dbdd;
            background: url('/static/assets/img/icons/colors/edit.svg')
              no-repeat center center !important;
          }
        }

        &:hover:not(.active) {
          margin: 0 4.5px 0 4.5px;

          &:first-child {
            margin: 0 1.5px 0 0;
          }

          #{$item-color} {
            cursor: pointer;
            width: 28px;
            height: 28px;
          }
        }

        &.purple {
          border-color: $color_purple;

          #{$item-color} {
            border-color: $color_purple;
            background: $color_purple;
          }
        }

        &.orange {
          border-color: $color_orange;

          #{$item-color} {
            border-color: $color_orange;
            background: $color_orange;
          }
        }

        &.blue {
          border-color: $color_blue;

          #{$item-color} {
            border-color: $color_blue;
            background: $color_blue;
          }
        }

        &.green {
          border-color: $color_green;

          #{$item-color} {
            border-color: $color_green;
            background: $color_green;
          }
        }

        &.red {
          border-color: $color_red;

          #{$item-color} {
            border-color: $color_red;
            background: $color_red;
          }
        }

        &.skyblue {
          border-color: $skyblue;

          #{$item-color} {
            border-color: $skyblue;
            background: $skyblue;
          }
        }
        &.darkblue {
          border-color: $darkblue;

          #{$item-color} {
            border-color: $darkblue;
            background: $darkblue;
          }
        }

        &-disabled {
          cursor: default;

          &:hover {
            cursor: default;

            &:not(.active) {
              margin: 0 7.5px 0 7.5px;

              #{$item-color} {
                cursor: default;
                width: 22px;
                height: 22px;
              }
            }
          }
        }
      }
    }
  }

  &__history {
    &-block {
      &-date {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: var(--textColor);
        background: var();
        width: 100%;
        height: 24px;
        padding: 6px 24px 4px;
      }
    }

    &-owerflove {
      position: relative;
      width: 345px !important;
      height: calc(100vh - 420px) !important;
    }

    &-container {
      margin: 23px 0 0 0;
    }

    &-item {
      border-top: 1px solid var(--borderSecond);
      padding: 16px 24px 17px;
      overflow-x: hidden;

      &-header {
        display: flex;
      }

      &-info {
        background: $row_light_grey;
        border-radius: 4px;
        margin: 4px 0 0 37px;
        padding: 7px 16px;
        display: flex;
        align-items: baseline;

        &-icon {
          width: 12px;
          height: 12px;
        }
      }

      &-name {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 18px;
        color: $fifty_color;
        margin: 0 0 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-time {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: var(--textColorGray);
      margin: 0 0 0 auto;
    }

    &-user {
      &-info {
        width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        padding: 0 0 8px 8px;
      }

      &-name {
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 19px;
        color: var(--textColor);
        width: 182px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-email {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 14px;
        color: var(--textColorGray);
      }
    }

    &-action {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: var(--textColorSecond);
      padding: 0 0 0 37px;
    }
  }

  .react-datepicker {
    &-wrapper {
      width: 100%;
      padding: 15px 24px 23px;
    }

    &-popper {
      left: 15%;
    }

    &__input-container {
      display: flex;
      justify-content: center;

      input {
        height: 46px;
        background: var(--inputBg);
        color: var(--textColor);
        padding: 2px 0 2px 7px;
        border: 1px solid var(--inputBorder);
        box-sizing: border-box;
        width: 100%;
        margin-top: 0;

        &:focus {
          border: 1px solid $primary_color;
        }
      }
    }
  }

  &__message {
    padding: 22px 11px 13px 11px;

    &.active {
      .entity-settings__message-input-wrapper {
        background: var(--tooltipContentSecond);
        padding: 16px 20px 15px 20px;
      }

      .entity-settings__message-comments-wrapper {
        height: calc(100vh - 410px);
      }
    }

    &-name {
      font-family: $sf_pro;
      font-size: 18px;
      line-height: 24px;
      color: $primary_dark;
      margin: 0 0 4px 0;
      padding: 0 20px 0 20px;
      display: flex;

      &-item {
        color: var(--textColor);
        cursor: pointer;
      }

      &-active {
        color: #0f73ef;
      }
      &-deliver {
        margin: 0 8px;
      }
    }

    &-size {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
      margin: 0 0 15px 0;
    }

    input#{&}-input {
      background: var(--inputBg);
      border: var(--inputBorder);
      height: 48px;
      padding: 12px 0 12px 21px;
      width: 100%;
      color: var(--textColor);

      &:focus {
        border: 1px solid $primary_color;
      }
    }

    &-input {
      &-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 20px 0 20px;

        & > input {
          background: $row_light_grey;
          border: 1px solid $forty_color;
          box-sizing: border-box;
          padding: 11px 16px;
          margin: 12px 0 0 0;
          font-size: 14px;
          line-height: 24px;
          &::placeholder {
            color: $thirty_color;
          }
        }
      }

      &-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 15px 0 0 0;

        .cancel {
          color: var(--primeTextColor);
          margin: 0 20px 0 0;
        }
      }

      &__reply {
        &-block {
          margin: 15px 0 0 -50px;
          width: 281px;
        }
      }
    }

    &-item {
      &:not(:last-child) {
        margin: 0 0 28px 0;
      }

      &-content {
        width: calc(100% - 52px);
      }
    }

    &-user {
      &-icon {
        .user-icon {
          width: 42px;
          height: 42px;
          margin: 0 10px 0 0;
        }
      }

      &-name {
        width: 100%;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: var(--textColor);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-date {
      font-size: 10px;
      line-height: 24px;
      color: $light_grey;
    }

    &-comment {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $comment_grey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .link {
        padding: 0;
        margin: 7px 0 0 0;
      }

      &-replies {
        margin: 28px 0 0 0;
        padding: 0 0 0 50px;

        .entity-settings__message-item-content {
          width: 181px;
          overflow-wrap: break-word;
        }
      }

      &-block {
        display: flex;
        justify-content: space-between;

        &.active {
          background: var(--sidebarHover);
          margin: 0 -20px -17px -20px;
          padding: 21px 20px 17px 20px;
        }
      }
    }

    &-comments {
      &-wrapper {
        margin: 38px 0 0 0;
        padding: 0 20px 0 20px;
        overflow-y: auto;
        height: calc(100vh - 330px);
      }
      &-wrapper-short {
        margin: 20px 0 0 0;
        padding: 0 10px 0 10px;
        overflow-y: auto;
        height: calc(100vh - 372px);
      }
    }
  }

  &__action {
    &-header {
      padding: 18px 24px 16px;
      text-align: center;

      button {
        margin: 0 auto;
        width: 297px;
        height: 40px;
      }
    }

    &-container {
      margin: 23px 0 0 0;
    }

    &-block {
      &-date {
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        color: $dark_grey;
        background: $row_light_grey;
        width: 100%;
        height: 24px;
        padding: 6px 24px 4px;
      }
    }

    &-item {
      border-top: 1px solid var(--borderSecond);
      border-bottom: 1px solid var(--borderSecond);
      padding: 16px 24px 17px;
      display: flex;
      align-items: center;

      &:hover {
        .entity-settings__action-buttons {
          display: flex;
        }
      }
    }

    &-icon {
      width: 10px;
      height: 10px;

      &.done {
        background: url(/static/assets/img/icons/check.svg) no-repeat center
          center;
      }

      &.in-progress {
        background: $color_blue;
        border-radius: 50%;
      }
    }

    &-info {
      margin: 0 0 0 9px;
    }

    &-name {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 18px;
      color: var(--textColor);
    }

    &-datetime {
      display: flex;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $fifty_color;

      &-splitter {
        margin: 0 1px;
        font-size: 24px;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;

      .button-action {
        margin: 0 0 0 8px;
      }
    }

    &-overflow {
      width: 100% !important;
      height: calc(100vh - 450px) !important;
    }
  }

  &__web3 {
    &-header {
      font-family: $sf_pro;
      font-size: 18px;
      line-height: 24px;
      color: var(--textColor);
      padding: 30px 18px 16px 20px;
    }
  }

  &_mob_background {
    display: none;
  }

  &__scrollbars-custom {
    position: relative;
    width: 345px;
    height: calc(100vh - 375px) !important;
  }
}

@media screen and (max-width: 500px) {
  .entity-settings {
    max-width: 100%;

    &_mob_background {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #10161a;
      opacity: 0.85;
    }

    &__image-container {
      width: 270px;
    }

    &__image-container-default {
      width: 100%;
    }

    &__content-wrapper {
      width: 100%;
      height: auto;
    }

    .popups-head {
      .tabs-item {
        padding: 6vw 11vw 6vw 10vw;

        & > svg {
          width: 16px;
        }
      }
    }

    &__info-item__hash-value > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: --var(--textColorSecond);
    }

    &__info,
    &__tags,
    &__colors {
      padding: 17px;
    }

    &__colors {
      &-container {
        margin-bottom: 20px;
        justify-content: space-between;
      }

      &-item {
        width: 18px;
        height: 18px;

        &-box {
          margin: 0;
        }
      }
    }
    &__history {
      &-owerflove {
        width: 100% !important;
        height: calc(100vh - 420px) !important;
        margin: 0;
      }

      &-item {
        width: 100%;
        border-top: none;
        padding: 0 0 10px 10px;
        margin-bottom: 20px;

        &-info {
          margin-right: 10px;
        }

        &-name {
          font-size: 11px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .react-datepicker-wrapper {
        padding: 10px;
      }

      &-block-date {
        font-size: 10px;
        background: white;
        padding: 0 0 0 10px;
      }

      &-user {
        &-info {
          width: 216px;
          position: relative;
        }
        &-icon {
          min-width: 32px;
          .user-icon {
            min-width: 32px;
          }
        }
        &-name {
          max-width: 150px;
        }
        &-email {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &-time {
        position: absolute;
        right: 0;
      }
    }

    &__action-header {
      padding: 18px 10px 0 10px;

      & > button {
        width: 100%;
      }
    }

    &__share {
      &-header {
        padding: 0 17px;
      }
      &-name {
        font-size: 14px;
        line-height: 13px;
      }
      &-label {
        font-size: 8px;
        line-height: 14px;
        border-radius: none;
      }

      &-shares {
        &-wrapper {
          padding: 0 17px;
        }
        &-wrapper {
          overflow-y: auto;
        }
      }
    }

    &__message {
      padding: 17px;
      &-user-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-name {
        padding: 0;
      }
      &-input {
        &-buttons > button:first-child {
          margin: 0 auto;
        }

        &-wrapper {
          padding: 0;
        }
        &__reply-block {
          position: absolute;
          left: -50px;
          bottom: -115px;
          width: 230px;
          margin: 0;
        }
      }
      &-item-content {
        position: relative;
        width: 150px;
      }
      &-comments-wrapper {
        padding: 0;
      }
      &-comment {
        margin-top: 12px;
        font-size: 10px;
        line-height: 15px;

        &-block.active {
          width: 100%;
          padding: 0 0 110px 0;
          padding-bottom: 120px;
          margin: 0;
        }

        & > button {
          font-size: 12px;
          line-height: 24px;
        }

        &-replies .entity-settings__message-item-content {
          width: 130px;
        }
      }
    }

    &__message-input {
      border: none;
    }

    &__action {
      &-overflow {
        width: 100% !important;
        height: calc(100vh - 450px) !important;
      }
    }
  }

  .entity-settings-isiOS > div:first-child > div:first-child > div:first-child {
    margin-bottom: 40px !important;
  }
}

.entity-color-picker {
  &_container {
    position: absolute;
    right: 10px;
    padding: 8px;
    bottom: 0;
    background: var(--tooltipContentSecond);
    border: 0.8px solid var(--inputBorder);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.12);
    max-height: 402px;
  }

  &_container_with_colors {
    position: absolute;
    right: 10px;
    padding: 8px;
    bottom: 0;
    background: var(--tooltipContentSecond);
    border: 0.8px solid var(--inputBorder);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.12);

    max-height: 402px;
  }

  &_input_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;
    height: 30px;
  }

  &_hex-input {
    width: 59px;
    height: 28px;
    border: 0.8px solid #d6e2f5;
  }

  &_input-text {
    font-family: $sf_pro;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.004em;
    background-color: var(--inputBg);
    border: 1px solid var(--grayBgSecond);
    color: var(--textColor);
    text-align: center;
  }

  &_rga_input_container {
    display: flex;
    flex-direction: row;
  }
  &_rgba-block {
    height: 28px;
    width: 35px;
    padding: 4px 8px 4px 8px;
    border-radius: 0;
    border: 0.8px solid var(--grayBgSecond);

    &_label {
      width: 35px;
      height: 8px;

      font-family: $sf_pro;
      font-style: normal;
      font-weight: 600;
      font-size: 8px;
      line-height: 8px;
      color: var(--textColor);
      margin-bottom: 4px;
    }
  }

  &_custom-pointers .react-colorful {
    width: 174px;
  }

  &_custom-pointers .react-colorful__saturation-pointer {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: 1px solid #ffffff;
  }
  &_custom-pointers .react-colorful__hue-pointer {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: 1px solid #ffffff;
  }

  &_custom-pointers .react-colorful__saturation {
    border: none;
    border-radius: 0;
    margin-bottom: 8px;
    height: 174px;
  }

  &_custom-pointers .react-colorful__hue {
    height: 10px;
    border-radius: 5px;
  }

  &_your-colors {
    &_label {
      font-family: $sf_pro;
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 16px;

      letter-spacing: 0.02em;
      color: var(--textColor);
      padding-bottom: 4px;
      margin-bottom: 4px;
      margin-top: 8px;
    }

    &_block {
      width: 100%;
      height: 100%;
      &_wrapper {
        height: 16px;
        width: 16px;
        margin: 7px;
        &:hover {
          border: 1px solid var(--hover-color);
          padding: 1px;
        }
      }
    }

    &_container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      max-height: 60px;
      overflow-y: scroll;
      width: 184px;
      margin: -7px;
    }
  }
  &_button-container {
    display: flex;
    flex-direction: row;
    margin: 16px 8px 8px 8px;
    align-items: flex-end;
  }

  &_add-button {
    max-width: 70px;
    min-width: 70px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_remove-button {
    max-width: 70px;
    min-width: 70px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_context-menu {
    position: absolute;
    bottom: 12px;
    right: 4px;
    z-index: 2;

    background: #ffffff;
    box-shadow: 0 2px 8px #ccceda;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 140px;
    height: 41px;
  }
}
