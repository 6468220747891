.registrationStepContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  height: 100%;
  width: 100%;
  max-width: 870px;
}

.shadowBlock {
  position: absolute;
  left: 5px;
  right: 5px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.shadowBlockBottom {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 160px;
  width: calc(100% - 10px);
  height: 75px;
  z-index: 10;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  display: none;
}

.lowerShadow {
  bottom: 90px;
}

.visible {
  display: block;
}

.scrollContent {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.question {
  color: #fff;
  font-family: Nunito;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.status {
  display: inline;
  margin-left: 8px;
}

.answer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  &::after {
    content: '';
    display: inline-block;
    margin-left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
  }
}

.footerBlock {
  flex-shrink: 0;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.scrollContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scrollContent::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.continueButtonWrapper {
  position: absolute;
  width: 100%;
  background-color: #000;
}

.continueButton {
  margin: 0 auto;
  width: 100%;
  max-width: 398px;
  padding: 12px 16px;
  border-radius: 14px;
  border: 0.7px solid #fff;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  &:hover {
    color: #ffe24a;
    border-color: #ffe24a;
  }
}

.hideButton {
  button {
    animation: stretchWidth 1s forwards;
  }
  animation: hideBlock 1s forwards;
}

.activeButton {
  color: #ffe24a;
  border-color: #ffe24a;
}

@keyframes stretchWidth {
  100% {
    max-width: 100%;
    color: rgba(255, 226, 74, 0);
  }
}
@keyframes hideBlock {
  100% {
    display: none;
    visibility: hidden;
  }
}
