.notification {
  &-block {
    width: 100%;
    margin: 0 0 30px 0;
  }

  &-item {
    display: flex;
    align-items: center;
    height: 88px;
    padding: 24px 80px 24px 24px;
    margin: 0 0 8px 0;
    cursor: pointer;
    background: $primary_light;
    position: relative;

    &.active {
      background: $selected_background;
    }

    &.active & {
      &-user {
        &-icon {
          &.entity-icon {
            background-color: $primary_light;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 2px 12px rgba(26, 49, 139, 0.15);
    }

    &:hover & {
      &-delete {
        display: initial;
      }
    }

    &-user {
      &-name {
        font-family: $sf_pro;
        font-size: 16px;
        line-height: 24px;
        color: $view_overlay;
        margin: 0 6px 0 0;
      }

      &-icon {
        &.entity-icon {
          width: 40px;
          height: 40px;
          margin: 0 8px 0 0;
          padding: 8px 7px 8px 9px;
          background: $grey-5;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .default {
            width: 24px;
            height: 24px;
          }

          .default-image {
            width: 24px;
            height: 24px;
          }
        }

        &.user-icon {
          width: 40px;
          height: 40px;
          margin: 0 8px 0 0;
        }
      }
    }

    &-time {
      font-family: $sf_pro;
      font-size: 12px;
      line-height: 24px;
      color: $medium_grey;
    }

    &-entity {
      &-info {
        display: flex;
        align-items: baseline;
        padding: 4px 0 0 0;
        margin: 0 0 -6px 0;
      }

      &-icon {
        &.user-icon {
          width: 32px;
          height: 32px;
          margin: 0 6px 0 0;
        }
      }

      .entity-icon {
        margin: 0 8px 0 0;

        .default {
          width: 19px;
          height: 13px;
        }

        .default-image {
          width: 14px;
          height: 18px;
        }
      }

      &-name {
        font-family: $sf_pro;
        font-size: 16px;
        line-height: 24px;
        color: $view_overlay;
        margin: 0 6px 0 0;
      }
    }

    &-actions {
      display: flex;
      margin: 0 0 0 auto;

      .done {
        background: url(/static/assets/img/icons/done.svg) no-repeat center left;
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 22px;
        color: $done;
        margin: 0 72px 0 0;
        padding: 0 0 0 18px;
      }

      .button-light {
        padding: 6px 14px 4px;
      }
    }

    &-delete {
      display: none;
      position: absolute;
      top: 32px;
      right: 28px;
      width: 24px;
      height: 24px;
      background: url(/static/assets/img/icons/delete.svg) no-repeat center
        center;
      cursor: pointer;
    }

    &-message {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
      margin: 0 6px 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 55vw;
    }
  }

  &-header {
    &-actions {
      .file {
        &-action {
          &__modal {
            width: 224px;
          }
        }
      }
      .option {
        width: 100%;
        padding: 13px 19px 12px 51px;
        text-align: left;

        &:hover {
          background-color: $row_light_grey;
        }
      }
    }
  }
}
