.dashboard-container {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: auto;
  grid-template-areas: 'main right';

  &__main {
    grid-area: main;
    padding: 30px 60px;
  }

  &__right {
    grid-area: right;
    padding: 30px 0;
  }

  &__two-blocks {
    display: flex;
  }
}
