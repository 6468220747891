.tfa__file {
  background-color: var(--baseColor);
}

.file_security_modal {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: var(--baseColor);
}

@media screen and (max-width: 480px) {
  .file_security_modal {
    height: 100vh;
    width: 100vw;
  }
}
