.wallet-history-empty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 400px;

  padding: 20px 0;
  border-bottom: 1px solid #ccceda;
  text-align: center;

  background: #ffffff;

  &__title {
    margin-bottom: 20px;

    color: #10161a;
  }

  &__desc {
    max-width: 297px;
    margin: 0 auto 20px;
    color: #7c7e88;
  }

  &__update {
    width: 172px;
    height: 36px;
    margin: 0 auto;

    color: #206acf;
    letter-spacing: 1.4px;
    transition: all 0.3s;
    user-select: none;

    &:active {
      color: #206acf;
    }

    &:hover {
      background: #197fff;

      &:active {
        background: #197fff;
        color: #ffffff;
      }
    }
  }
}
