.wrapper {
  display: block;
  position: relative;
  width: 46px;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.button {
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  top: 3px;
  left: 3px;
  background-color: var(--baseColor);
  border-radius: 32px;
}

.small {
  width: 47px;
  height: 24px;

  .themePrimary, .themeDark {
    width: 47px;
    height: 24px;
    .button {
      top: 3px;
      width: 18px;
      height: 18px;
    }
  }
}

.big {
  width: 60px;
  height: 30px;

  .themePrimary, .themeDark {
    width: 60px;
    height: 30px;
    .button {
      top: 3px;
      width: 24px;
      height: 24px;
    }
  }
}

.disabled {
  cursor: not-allowed;

  .themePrimary .themeDark {
    background: rgba(193, 201, 217, 0.41);
    mix-blend-mode: normal;
    opacity: 0.8;

    .button {
      background: #FFFFFF;
      opacity: 0.45;
      box-shadow: 0px 1px 3px rgba(16, 22, 26, 0.297186);
    }
  }
}

.wrapper input:checked ~ .themePrimary > .button {
  left: auto;
  right: 2px;
}

.wrapper input:checked ~ .themeDark > .button {
  left: auto;
  right: 2px;
}

.wrapper input:checked ~ .themePrimary {
  background-color: #0F73EF;
}

.wrapper input:checked ~ .themeDark {
  background-color: var(--switchBgActive);
}

.themePrimary, .themeDark {
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 24px;
  border-radius: 32px;
  background-color: var(--switchBgInactive);
}
