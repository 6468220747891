.main__menu {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 100vh;
  z-index: 4;

  &.open--menu {
    z-index: 60;

    & + .site__pages {
      margin: 0 0 0 170px;
    }
  }

  .main__nav {
    width: 100%;
    display: flex;
    flex-direction: column;

    .nav__link {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.link__ghostdrive {
        //background: url("/static/assets/img/gost_logo_files.svg") center center / 23px 33px no-repeat;
        cursor: pointer;
      }

      &.account {
        cursor: pointer;
      }

      &.link__ghostnotes {
        background: url('/static/assets/img/ghost_notes_logo.svg') center center /
          30px 31px no-repeat;
      }

      &.active--link {
        background-color: $primary_color;
      }

      &.link__create-workspace {
        width: 28px;
        height: 28px;
        border: 1px solid $forty_color;
        transform: rotate(90deg);
        cursor: pointer;
        margin: 16px auto;

        &:hover {
          background-color: $shadow_table;
        }
      }

      &.link__workspace {
        cursor: pointer;
        padding: 16px;
        color: $primary_light;
        font-family: $sf_pro;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1px;
        width: 60px;
        height: 60px;

        &:hover {
          background-color: $shadow_table;
        }

        &.active {
          width: 60px;
          height: 60px;
          padding: 14px 14px;
          margin: 0 0;
          font-size: 14px;
          line-height: 16px;
          background-color: $primary_light;
          color: $primary_light;

          .link__holder {
            width: 32px;
            height: 32px;
          }
        }

        .link__holder {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $primary_light;
          color: $primary_light;
        }
      }
    }
  }

  .menu__profile {
    width: 22px;
    height: 23px;
    background: url('/static/assets/img/profile_flapper.svg') center center /
      22px 23px no-repeat;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
.main__menu.leftMenuIsClosed {
  background-color: white;
}
