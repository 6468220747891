.more-apps-item {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: white;
    padding-top: 40px;
    padding-bottom: 20px;
    margin: 0 auto;
    width: 242px;
    height: 242px;
    box-shadow: 0 1px 0 #ccceda;
    transition: box-shadow 0.3s, filter 0.3s, -webkit-filter 0.3s;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 6px 19px -5px rgba(0, 0, 0, 0.2);
    }
  }
  &__name {
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }
  &__button {
    width: 108px;
    height: 28px;
    margin: 0 auto;
  }
}
