.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--mainContainerColor);
  &_margin {
    margin-top: 130px;
  }
}

.borderContent {
  border: none;
}

.smallImagesBlock {
  background: #131313cc;
  position: absolute;
  left: 30px;
  top: 70px;
  border-radius: 20px;
  padding: 12px 14px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  & .smallPictureWrapper {
    position: relative;
    cursor: pointer;

    & .smallPicture {
      position: relative;
      width: 64px;
      height: 64px;
      border-radius: 10.37px;
    }

    & div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      border-radius: 6px;
      background: rgba(19, 19, 19, 0.6);
      backdrop-filter: blur(1.523809552192688px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.imageWrapper {
  width: 100%;
  height: 100%;
}
.borderContainer {
  padding-right: 17px;
}
.recordActionStart {
  background-color: transparent;
  border-color: #e31010;
  color: #e31010;
}

.recordActionStart:hover {
  border-color: #e31010;
  color: #e31010;
}
.clearAction {
  color: #cab35e;
  border-color: #cab35e;
}
.generatedImageWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 16px 18px;

  & .mainPicture {
    width: 100%;
    height: 100%;
    border-radius: 13px;
  }
  .aiSidebarTogleButton {
    position: absolute;
    display: flex;
    align-items: center;
    top: 40%;
    right: -13px;
    transform: translate(-50%, -50%);
    color: white;
    height: 100vh;
    cursor: pointer;
    z-index: 1003;
    &_open {
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      path {
        fill: var(--iconColor);
      }
    }
    &:hover {
      background-color: var(--bluredPrimeColor);
    }
  }
  & .groupImages {
    padding: 0 31px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 300px));
    justify-content: center;

    width: 100%;
    gap: 6px;
    overflow-y: auto;
    overflow-x: hidden;
    & .corner {
      position: absolute;
      bottom: -13px;
      right: -13px;
      width: 12px;
      height: 11px;
    }
    & ~ div {
      width: 100%;
    }

    & ~ .generatedImageWrapper__info {
      margin-left: 30px;
    }
    & ~ .generatedImageWrapper__generatedImages {
      margin-top: auto;
    }
    & .imageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.5px dashed #b9b9b9;
      position: relative;

      & .defaultImage {
        width: 42px;
        height: 42px;
      }
      & .aiImage {
        cursor: pointer;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__generatedImages {
    display: flex;
    padding: 0;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;

    list-style: none;
    width: 100%;
    height: 100px;
    overflow-y: hidden;
    overflow-x: scroll;
    gap: 40px;
    & li {
      min-width: 88px;
      min-height: 88px;
      cursor: pointer;
      & img {
        width: 88px;
        height: 88px;
      }
    }
  }
}

.welcomeWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  height: auto;

  &__title {
    color: var(--textColor);
    text-align: center;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 32px 0 8px;
  }
  &__desc,
  &__price {
    color: var(--textColor);
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: 160%;
    margin: 0;
    padding: 0;
  }
  &__price {
    font-weight: 400;
  }
}

.neyraLogo,
.NeyraLogo {
  color: var(--neyraLogoColor);
}

.promptWrapper {
  position: absolute;
  bottom: 57px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 50px);
  max-width: 867px;
  &__prompt {
    width: 100%;
    padding: 13px 16px 13px 94px;
    background-color: transparent;
    border-radius: 12px;
    border: 0.7px solid rgba(255, 255, 255, 0.6);
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5.800000190734863px);

    color: #fff;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__dropdown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    &__btn {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      color: #fff;
      font-family: Nunito;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      & .corner {
        width: 20px;
        height: 20px;
        display: flex;
        margin-top: auto;
        transition: all 300ms ease-in-out;
        transform: rotate(0);
      }

      & .cornerUp {
        transition: all 300ms ease-in-out;
        transform: rotate(135deg);
      }
    }
    &__options {
      position: absolute;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
      padding: 8px;
      border-radius: 2px;
      background: #141414;
      display: flex;
      flex-direction: column;
      gap: 12px;
      & button {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding-right: 4px;
        gap: 2px;
        &.active,
        &:hover {
          border-radius: 2px;
          background: #282828;
        }
      }
    }
  }
}

.inputWrapper {
  position: relative;
}

.loaderWrapper {
  position: absolute;
  bottom: 23px;
  left: 0;
  width: 100%;
  height: auto;
}

.NeyraLogo {
  position: absolute !important;
  top: 22px;
  left: 47px;

  & svg path {
    transition: fill-opacity 200ms ease-in-out;
  }

  &:hover svg path {
    fill-opacity: 0.8;
  }
}

.imageOptions {
  position: absolute;
  top: 49px;
  right: 42px;
  display: flex;
  gap: 17px;
  &__option {
    cursor: pointer;
    padding: 2px 14px;
    border-radius: 6.095px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1.523809552192688px);
    min-width: 60px;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      opacity: 0.5;
      transition: opacity 200ms ease-in-out;
    }

    &:hover svg {
      opacity: 1;
    }
  }
}

.downloadSkybox {
  top: 20px;
  &:hover::after {
    content: 'Upload to Drive';
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: var(--tooltipBackground);
    width: 100px;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: var(--textColor);
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.7;
  }
}

.imageCount {
  position: absolute;
  top: 25px;
  right: -120px;
  width: fit-content;
  & div {
    display: flex;
    gap: 6px;
  }
  & p {
    color: var(--textColor);
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  svg {
    width: 40px;
    height: 40px;
    color: var(--neyraBorderNavigationColor);
    transition: stroke 100ms ease-in-out;

    &:hover {
      color: var(--neyraBorderButtonsHover);
    }
  }
  & button {
    cursor: pointer;
    &:disabled svg rect {
      stroke: #5e5e5e;
    }
    &:disabled svg path {
      fill: #5e5e5e;
    }
  }
}

.studioTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5rem;
  padding: 2px;
  z-index: 99999999;
  background-color: transparent;
  border: 0.5px solid var(--neyraBorderNavigationColor);

  & button {
    padding: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    font-family: 'Nunito';
    font-weight: 300;
    font-size: 14px;
    color: #5e5e5e;
    min-width: 50px;
    &.active {
      color: var(--textColor);
      background: var(--studioTabHover);
    }
    &:hover {
      color: var(--textColor);
      background: var(--studioTabHover);
    }
  }
}

.loader {
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@media (max-width: 1350px) {
  .generatedImageWrapper .groupImages {
    grid-template-columns: repeat(3, minmax(0, 400px));
  }
  .welcomeWrapper {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
  .promptWrapper {
    bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .generatedImageWrapper .groupImages {
    grid-template-columns: repeat(2, minmax(0, 400px));
  }
}
@media (max-width: 780px) {
  .generatedImageWrapper .groupImages {
    grid-template-columns: repeat(1, minmax(0, 400px));
  }
}
@media (max-width: 480px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
    height: 100%;
  }
  .generatedImageWrapper .groupImages {
    padding: 0 16px;
    grid-template-columns: repeat(2, minmax(0, 400px));
  }
  .generatedImageWrapper .groupImages .imageContainer .defaultImage {
    width: 26px;
    height: 26px;
  }
}

@keyframes l5 {
  0% {
    box-shadow: 10px 0 var(--textColor), -10px 0 var(--studioLoaderShadow);
    background: var(--textColor);
  }
  33% {
    box-shadow: 10px 0 var(--textColor), -10px 0 var(--studioLoaderShadow);
    background: var(--studioLoaderShadow);
  }
  66% {
    box-shadow: 10px 0 var(--studioLoaderShadow), -10px 0 var(--textColor);
    background: var(--studioLoaderShadow);
  }
  100% {
    box-shadow: 10px 0 var(--studioLoaderShadow), -10px 0 var(--textColor);
    background: var(--textColor);
  }
}
