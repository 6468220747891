@import 'static/assets/styles/global/global-varibles.scss';

.wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #0f73ef;
  border-radius: 100%;
  font-family: $sf_pro;
  font-size: 27px;
  line-height: 27px;
  font-weight: 400;
  color: var(--avatarText);
  overflow: hidden;
  box-shadow: 2px 8px 24px 0px #1a318b0d;
}
.image {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.initials {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
}

.bordered {
  position: relative;
  color: #0f73ef;
}

.bordered > svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.bordered > span {
  z-index: 1;
}
