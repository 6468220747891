@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}
.modal {
  display: block;
}

.analytics {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 575px;
  min-height: 392px;
  max-height: 455px;
  font-family: $sf_pro;
  padding: 40px;
  text-align: center;
  background: var(--modalContent);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__header {
    margin-top: 10px;
    font-size: 24px;
    line-height: 30px;
    color: #10161a;
    text-align: center;
    font-weight: 400;
  }

  &__warning {
    color: #7c7e88;
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .tooltip {
      top: 50px;
      padding: 15px;
      background-color: white;
      color: black;
      border: 0.5px solid #e4e7ee;
      box-shadow: 0 2px 8px #ccceda;
    }

    &:hover .folder__tooltip {
      display: flex;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    &__file {
      display: flex;
      justify-content: center;

      &__text {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        margin-right: 20px;

        & > span:first-child {
          font-family: $sf_pro;
          font-size: 18px;
          color: #19191d;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
        }

        & > span:last-child {
          font-family: $sf_pro;
          color: #7c7e88;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }

    &__folder {
      display: flex;
      justify-content: center;
      margin-left: 20px;

      &__text {
        display: flex;
        flex-direction: column;
        padding-left: 10px;

        & > span:first-child {
          font-family: $sf_pro;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #19191d;
        }

        & > span:last-child {
          font-family: $sf_pro;
          color: #7c7e88;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    }
  }

  &__info {
    width: 300px;
    display: flex;
    justify-content: space-around;
    margin-left: calc(50% - 150px);
    margin-right: auto;
    margin-top: 31px;
    margin-bottom: 12px;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 80px;

      & > span {
        font-family: $sf_pro;
        color: #7c7e88;
        margin-top: 5px;
      }

      & > span:last-child {
        font-family: $sf_pro;
        color: #202124;
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }

  &__users {
    display: flex;
    justify-content: space-between;
    width: 260px;
    margin-left: calc(50% - 140px);
    margin-right: auto;
    margin-top: 12px;

    &__item {
      display: flex;

      & > span {
        font-family: $sf_pro;
        color: #7c7e88;
        padding-left: 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;
      }

      & > span:first-child {
        color: rgb(15, 115, 239);
      }

      & > span:last-child {
        color: #000;
      }
    }
  }
}

.divider {
  border-bottom: 0.5px solid var(--contextBorder);
}

.verticalDivider {
  position: absolute;
  top: 34%;
  bottom: 66.75%;
  border: 0.5px solid #e4e7ee;
  transform: rotate(-90deg);
  width: 34px;
}
