.file-monitoring {
  &-block {
    position: relative;

    .view-all {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      line-height: 24px;
      color: $primary_color;
      text-decoration: none;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: $primary_light;
    font-size: 12px;
    line-height: 24px;
    color: $light_grey;
    height: 52px;

    &__header {
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
      height: 38px;

      .file-monitoring-item__name:before {
        background: none;
      }
    }

    &:nth-child(2n) {
      background: $row_light_grey;
    }

    &__favorite {
      width: 10%;

      .favorite {
        background: url(/static/assets/img/favorite.svg) 0 0 no-repeat;
        width: 10px;
        height: 10px;
        margin: 0 auto;
      }
    }

    &__name {
      width: 35%;
      padding: 0 0 0 35px;

      &:before {
        position: absolute;
        content: '';
        background: url(/static/assets/img/icons/file/type.svg) no-repeat;
        width: 23px;
        height: 28px;
        margin: 0 0 0 -35px;
      }
    }

    &__date {
      width: 20%;
    }
    &__user {
      width: 15%;
    }
    &__action {
      width: 10%;
    }

    &__action-icon {
      width: 10%;

      .action {
        width: 12px;
        height: 12px;
        margin: 0 auto;

        &__edited {
          background: url(/static/assets/img/icons/action/edit.svg) 0 0 no-repeat;
        }

        &__printed {
          background: url(/static/assets/img/icons/action/print.svg) 0 0 no-repeat;
        }

        &__shared {
          background: url(/static/assets/img/icons/action/share.svg) 0 0 no-repeat;
        }

        &__copied {
          background: url(/static/assets/img/icons/action/copy.svg) 0 0 no-repeat;
        }
      }
    }
  }
}
