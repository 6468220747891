.small-upload {
  margin: 16px 0;
  &__container {
    width: 100%;
    height: 160px;
    border: 1px dashed $forty_color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  &__text {
    color: $fifty_color;
    line-height: 24px;
    text-align: center;
  }

  &__button {
    margin-top: 11px;
  }
}
