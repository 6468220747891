@import 'static/assets/styles/global/global-varibles';

div.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 620px;
  padding: 42px 40px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: var(--modalContent);
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.modalTitle {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--textColor);
  margin: 0;
}

.modalSubtitle {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--textColorGray);
  margin-bottom: 0;
  margin-top: 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.selectContainer {
  display: flex;
  width: 65%;
  position: relative;
  margin: 4px 0;
}

.selector {
  width: 100%;
  padding: 12px 20px;
  max-width: 408px;
}

.selectOption {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;

  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--textColorGray);
}

.selectOption > svg {
  margin-right: 10px;
  width: 20px;
}

.selectOption > span {
  margin-right: 2px;
  margin-top: 2px;
}

@media (max-width: 768px) {
  div.modal {
    padding: 20px;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
    & > section > button {
      margin: 0;
      margin-left: auto;
      margin-bottom: 20px;
    }
  }
  .selector {
    padding-left: 0;
    padding-right: 0;
  }
  .modalTitle {
    font-size: 18px;
    line-height: 20px;
  }
  .modalSubtitle {
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;
  }
  .selectOption {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .buttonsContainer {
    flex-direction: column;
    & button {
      width: 100%;
      height: 40px;
      &:first-of-type {
        order: 2;
      }
    }
  }
}
