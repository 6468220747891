@import 'static/assets/styles/global/global-varibles.scss';

.notificationPopup {
  position: absolute;
  width: 408px;
  height: 481px;
  background: var(--tooltipContent);
  box-shadow: var(--boxShadow);
  cursor: default;
  left: 100%;
  bottom: -190%;
  z-index: 100;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0 24px;
    position: relative;

    &_closeBtn {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: var(--iconColor);
    }

    h2 {
      color: $colBlackText;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
    }
  }

  &_hide {
    display: none;
  }

  &_loader {
    text-align: center;
  }
}

.title {
  display: flex;

  h2 {
    padding: 0;
    color: var(--textColor);
    font-size: 16px;
    line-height: 24px;
  }

  &__unread {
    display: flex;
    margin-left: 6px;
    height: 27px;
    width: 27px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 14px;
    align-self: center;
    border-radius: 100%;
    background-color: $red;
    color: #fff;
  }
}

.notificationPopupBottom {
  bottom: initial;
  left: -240px;
}

.wrapper {
  font-family: $sf_pro;
  position: relative;
  &_tabs {
    display: flex;
    border-bottom: 1px solid $preview_container;
  }

  .tabItem {
    padding: 12px 24px;
    font-size: 15px;
    line-height: 16px;
    opacity: 0.45;
    cursor: pointer;
    color: var(--textColorGray);

    &__active {
      position: relative;
      opacity: 1;
      color: var(--textColor);

      &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        content: '';
        height: 1px;
        background-color: #206acf;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    height: 387px;
    background-color: var(--tooltipContent);
  
    .notificationItem:hover {
      background-color: var(--notificationHover);
    }

    &_unread {
      background-color: var(--skeletonItem);
   
      .notificationItem:hover {
        background-color: var(--tooltipContent);
      }
    }

    &_scrolled {
      height: 100%;
      overflow: auto;
    }
  }
}

.action {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  cursor: pointer;

  &_unread {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: $red;
  }
}

.notificationItem {
  font-family: $sf_pro;
  display: flex;
  padding: 24px;
  border-bottom: .5px solid var(--sidebarItemBorderActive);
 
  & p {
    color: var(--textColorSetting) !important;
  }
  & span:first-child {
    color: var(--textColor) !important;
  }

  &__avatar {
    &_icon {
      width: 40px;
      height: 40px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &_initials {
      font-size: 17px;
      line-height: 17px;
    }
  }
  &__body {
    margin-left: 10px;
    margin-top: 5px;

    &_text {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      color: var(--textColor);
      word-break: break-word;
    }

    &_detail {
      margin: 10px 0 0 0;
      font-size: 12px;
      line-height: 16px;
      color: var(--textColorGray);
    }

    &_read {
      margin: 10px 0 0 0;
      font-size: 12px;
      line-height: 16px;
      color: var(--textColor);
      cursor: pointer;
    }
  }

  &_hover {
    background-color: var(sidebarItemBorderActive);
  }
}

.multysigAction {
  height: 40px;
  margin-top: 10px;
  display: flex;
  gap: 8px;
}

.multysigAction > button {
  min-width: 130px;
  width: 130px;
  height: 40px;
}

.tooltip {
  font-family: $sf_pro !important;
  border-radius: 8px !important;
  box-shadow: var(--boxShadow) !important;
  background-color: var(--tooltipContentSecond) !important;
  color: var(--textColor) !important;
  width: 190px;
  white-space: pre-line !important;
  font-size: 13px !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-transform: initial !important;
  margin-bottom: 4px !important;
}

.multysigResult {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-right: 40px;
}

.multysigAcceptBtn {
  min-height: 40px;
  max-height: 40px;
  height: 40px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
  border: none;
  background-color: rgb(84, 187, 142);
}

.multysigDeclineBtn {
  min-height: 40px;
  max-height: 40px;
  height: 40px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0em;
  border: none;
  background-color: rgb(237, 29, 45);  
}


.notificationItem:hover {
  @media screen and (min-width: 1025px) {
    .notificationItem__body_detail:not(.viewed) {
      display: none;
    }
  }
}

.notificationItem:not(:hover) {
  @media screen and (min-width: 1025px) {
    .notificationItem__body_read {
      display: none;
    }
  }
}

.emptyNotification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--baseColor);
  height: 100%;

  svg {
    color: var(--iconColor);
  }

  h1 {
    color: var(--textColor);
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin: 20px 0 5px 0;
  }

  p {
    margin: 0;
    color: var(--textColor);
    text-align: center;
    font-size: 14px;
    line-height: 22px;
  }
}

.notification_join {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
  height: 40px;
  width: 132px;
  background: $color_blue;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.notificationPopupBottom {

}

@media screen and (max-width: 480px) {
  .notificationPopup {
    width: calc(100vw - 40px);
    right: -50px;
  }
}