.auth__shared {
  .form__input {
    width: 448px;
    height: 56px;
    padding: 0 24px;
    border: 1px solid #cbcdd5;
    background-color: #fafafb;
    color: $primary_dark;
    font-family: $sf_pro ;
    font-size: rem(16px);
    line-height: rem(24px);
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

  .input__wrapper {
    margin: 8px 0 52px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    @media screen and (max-width: 540px) {
      width: 100%;
    }

    .password__eye {
      position: absolute;
      top: 24px;
      right: 16px;
      width: 17px;
      height: 9px;
      background: url('/static/assets/img/password_eye.svg') center center /
        17px 9px no-repeat;
      cursor: pointer;
    }
  }

  .input__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

  .input__error__msg {
    color: #58595f;
    font-family: $sf_pro ;
    font-size: rem(12px);
    line-height: rem(24px);
  }

  .input__error {
    background: $primary_light;
    border: 1px solid #ce4a50;
  }
}

.auth {
  &__label {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 20px 0;
    position: relative;

    &-column {
      flex-direction: column;
    }

    &-error {
      //background: linear-gradient(90.12deg, rgba(252, 168, 69, 0.23) 0.1%, rgba(252, 168, 69, 0) 98.92%);
    }
  }

  &__input {
    font-family: $sf_pro ;
    font-size: rem(40px);
    line-height: rem(48px);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px 12px 8px 0;
    width: 100%;
    background: none;
    outline: none;
    caret-color: $primary_light;
    color: $primary_light;

    &::placeholder {
      font-size: rem(40px);
      line-height: rem(48px);
      letter-spacing: -0.4px;
      color: rgba(255, 255, 255, 0.15);
    }

    &:active,
    &:focus {
      border-color: $primary_light;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: $primary_light;

      &:hover,
      &:focus {
        -webkit-text-fill-color: $primary_light;
      }
    }

    &-error {
      color: $sign_error;
      border: none;
      border-bottom: 1px dashed $sign_error;
      background: none;
    }

    &-errors {
      position: absolute;
      font-family: $sf_pro ;
      font-size: rem(14px);
      line-height: rem(14px);
      color: $sign_error;
      margin: 10px 0 0 0;
      bottom: -8px;

      svg {
        margin: 0 12px 0 0;
      }
    }

    &-button {
      position: absolute;
      top: 43px;
      right: 5px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      opacity: 0.2;

      &.active {
        opacity: 1;
      }
    }

    &-help {
      position: absolute;
      right: 0;
      top: 180px;
      font-family: $sf_pro ;
      font-size: rem(14px);
      line-height: rem(16px);
      letter-spacing: 0.1px;
      color: $primary_light;

      &.disabled {
        opacity: 0.25;
      }
    }

    &-container {
      position: relative;
    }
  }

  &__code {
    display: flex;

    label {
      &:nth-child(4) {
        padding-left: 80px;
      }

      input {
        font-family: $sf_pro ;
        font-size: rem(40px);
        line-height: rem(48px);
        border: none;
        border-bottom: 1px solid $primary_light;
        padding: 0;
        width: 100%;
        max-width: 82px;
        background: none;
        outline: none;
        caret-color: $primary_light;
        color: $primary_light;
        text-align: center;
        opacity: 0.2;

        &:active,
        &:focus,
        &.active {
          opacity: 1;
        }

        &::placeholder {
          font-family: $sf_pro ;
          font-size: rem(40px);
          line-height: rem(48px);
          color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.scroll {
  &-up {
    max-height: 500px;
    overflow-y: hidden;

    &.step-2 {
      .step {
        &-enter {
          &-active {
            margin-bottom: -270px;
          }
        }
      }
    }

    .step {
      opacity: 0;

      &-appear {
        opacity: 0.01;

        &-active {
          opacity: 1;
          -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
          -webkit-animation: animShowPrev 0.5s cubic-bezier(0.7, 0, 0.3, 1) both
            0.15s;
          animation: animShowPrev 0.5s cubic-bezier(0.7, 0, 0.3, 1) both 0.15s;
        }
      }

      &-enter {
        opacity: 0.01;

        &-active {
          opacity: 1;
          margin-bottom: -220px;
          -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
          -webkit-animation: animShowPrev 0.5s cubic-bezier(0.7, 0, 0.3, 1) both
            0.15s;
          animation: animShowPrev 0.5s cubic-bezier(0.7, 0, 0.3, 1) both 0.15s;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
          -webkit-animation: animHidePrev 0.5s cubic-bezier(0.7, 0, 0.3, 1)
            forwards;
          animation: animHidePrev 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
        }
      }
    }
  }

  &-down {
    max-height: 510px;
    overflow-y: hidden;
    padding-bottom: 10px;

    .step {
      opacity: 0;

      &-appear {
        opacity: 0.01;

        &-active {
          opacity: 1;
          -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
          -webkit-animation: animShowNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) both
            0.15s;
          animation: animShowNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) both 0.15s;
        }
      }

      &-enter {
        opacity: 0.01;

        &-active {
          opacity: 1;
          //margin-bottom: -180px;
          -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
          -webkit-animation: animShowNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) both
            0.15s;
          animation: animShowNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) both 0.15s;
        }
      }

      &-exit {
        opacity: 1;
        height: 0;

        &-active {
          -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
          -webkit-animation: animHideNext 0.5s cubic-bezier(0.7, 0, 0.3, 1)
            forwards;
          animation: animHideNext 0.5s cubic-bezier(0.7, 0, 0.3, 1) forwards;
        }
      }
    }
  }
}

.step {
  &-1 {
    .form {
      &__title {
        margin: 94px 0 20px 0;
      }
    }

    .auth {
      &__label {
        padding: 0;
      }
    }
  }
}

.storage_type {
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 0;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    padding: 18px 33px 17px 33px;
    background: rgba(255, 255, 255, 0.09);
    border: 1px solid rgba(255, 255, 255, 0.35);
    filter: drop-shadow(4px 4px 20px rgba(26, 49, 139, 0.08));
    min-width: 260px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: 20px;
    }
  }

  & > div:first-child {
    margin-right: 17px;
  }

  .active {
    margin-bottom: 0;
    border: 2px solid #ffffff;
    box-shadow: 4px 4px 20px rgba(26, 49, 139, 0.08);
  }
}

@media (max-width: 1441px) {
  .auth__code {
    justify-content: space-between;

    & label:nth-child(4) {
      padding-left: 0;
      margin-left: 40px;
    }
    .auth__label {
      width: 72px;
    }
  }
}

@media (max-width: 1024px) {
  .auth__code {
    .auth__label {
      width: 44px;
    }
  }
}

@media (max-width: 786px) {
  .auth__code {
    justify-content: space-between;

    & label:nth-child(4) {
      padding-left: 0;
      margin-left: 30px;
    }
    .auth__label {
      width: 36px;
    }
  }
}

@media (max-width: 480px) {
  .auth__code {
    justify-content: space-between;

    & label:nth-child(4) {
      padding-left: 0;
      margin-left: 20px;
    }
    .auth__label {
      width: 42px;
    }
  }

  .auth__label input {
    font-size: 25px !important;
    line-height: 48px !important;
  }
}
