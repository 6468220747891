@import 'static/assets/styles/global/global-varibles';

.wrapper {
  display: flex;
  align-items: inherit;
  position: relative;
  margin-right: 26px;
}

.deleteBtn {
  position: absolute;
  right: -20px;
  top: 16px;
  cursor: pointer;
}

.deleteBtn > svg {
  color: var(--iconSecondColor);
}

.textInput {
  height: 53px;
  width: 100%;
}

.textInput > input {
  font-family: $sf_pro;
  height: 100%;
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
}

.linkInput {
  display: block;
  font-family: $sf_pro;
  padding: 12px 16px;
  width: 100%;
  height: 53px;
  background-color: #fafafa;
  margin: 0;
  border: 1px solid #e8f0fd;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
}

.passwordWrapper {
  display: flex;
  width: 100%;
}

.passwordInput {
  height: 53px;
  width: 100%;
}

.passwordInput > input {
  height: 100%;
  margin: 0px;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 20px;
  padding-right: 44px;
}

.passwordInput > button {
  top: 18px;
  right: 20px;
}

.datePickerWrapper {
  position: relative;
  width: 100%;
  height: 53px;
}

.datePickerInput {
  height: 100%;
  width: 100%;
  font-family: $sf_pro;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  border: none;
  color: var(--textColor);
}

.datePicker {
  background-color: var(--profileCard);
  border: 1px solid var(--inputBorder);
  position: relative;
  height: 100%;
  padding: 12px 16px;
}

.datePicker > div:first-child {
  height: 100%;
}

.datePickerPopup {
  margin-top: 10px !important;
}

.areaInput {
  min-height: 100px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.areaInput > textarea {
  font-family: $sf_pro;
  margin-bottom: 0;
  width: 100%;
}

.labelClass {
  font-family: $sf_pro;
  font-size: 14px;
  min-width: 104px;
  padding-top: 13px;
  color: var((--textColor));
}

.editableInput {
  position: relative;
  background-color: transparent;
  top: 12px;
  padding: 0 10px 0 0;
  width: 104px;
  font-family: $sf_pro;
  font-size: 14px;
  line-height: 150%;
  align-self: center;
  border: none;
  color: var(--textColor);
}

@media screen and (max-width: 480px) {
  .wrapper {
    flex-direction: column;
  }

  .labelClass {
    padding-top: 0;
    height: 28px;
  }

  .editableInput {
    top: 0;
    padding: 0;
    height: 30px;
    width: 100%;
  }

  .deleteBtn {
    top: 48px;
  }

  .textInput, .passwordInput {
    height: 40px;
  }

  .passwordInput > button {
    top: 8px;
    right: 10px;
  }
}