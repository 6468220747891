@import 'static/assets/styles/global/global-varibles';

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
  max-height: calc(100% - 170px);
}

.avatarContainer:before {
  content: '';
  position: absolute;
  background-color: #000;
  height: 2px;
  top: -2px;
  width: 70.4%;
}

.neyraPass {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  height: 90%;
}

.neyraPassImg {
  height: 90%;
  object-fit: contain;
}

.info {
  width: 60%;
  align-items: center;
  color: #fff;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.subTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-height: 900px) {
  .avatarContainer {
    width: initial;
    height: calc(100% - 160px);
  }
}