@import 'static/assets/styles/global/global-varibles';

.wrapper {
  font-family: $sf_pro;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}

.header {
  height: 50px;
}

.title {
  font-size: 21px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: -0.4px;
  text-align: left;
}

.otherLoginOptions {
  display: flex;
  gap: 24px;
  margin-top: 50px;
}

.loginOption {
  width: 54px;
  height: 54px;
  border: 0.6px solid #7D7D7D;
  border-radius: 27px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.authSelector {
  position: relative;
  display: flex;
}

.aiFrame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
}

.logoWrapper {
  z-index: 1;
}

.active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.leftSelector,
.rightSelector {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px 24px 16px;
}

.detail {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 0;
}

.anotherLink {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 0;
}

.logo {
  cursor: pointer;
  text-align: center;
}

.electron {
  display: flex;
  gap: 30px;
}

.walletContainer {
  background-color: rgba(126, 216, 255, 0.08);
  width: 250px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & button {
    cursor: pointer;
    user-select: none;
    width: 250px;
    height: 60px;
    border-radius: 10px;
    background-color: rgba(0, 147, 230, 0.05);
    color: #000000;
    font-weight: bold;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  display: block;
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: auto;
    max-width: 488px;
    padding: 40px;
    text-align: center;
    background: #ffffff;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__title {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 32px;
    color: var(--textColor);
    margin: 0 0 24px 0;
    font-weight: 400;
  }

  &__subTitle {
    font-family: $sf_pro;
    margin-top: 0;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
    color: $medium_grey;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .content {
    padding: 20px 0;
  }
  .wrapper {
    height: 100%;
    overflow-y: auto;
  }
  .header {
    padding-top: 20px;
    margin: 0 16px;
  }

  .tree {
    display: none;
  }

  .detail {
    text-align: center;
  }

  .footer {
    position: relative;
    bottom: 24px;
    padding: 0 16px 0 16px;
  }

  .modal {
    &__content {
      width: calc(100vw - 32px);
      padding: 40px 24px !important;
      height: max-content !important;
    }
  }
}
