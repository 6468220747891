.neyraButton {
  max-width: 358px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 8px 16px;
  border-radius: 14px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  cursor: pointer;

  &:hover {
    border-color: #fff;
  }
}

.buttonText {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
