@import 'static/assets/styles/global/global-varibles';
.search__option__image {
  width: 30px;
  height: 40px;
}

.actionSearch {
  width: initial;
  position: relative;
  height: 33px;
  min-width: 33px;
}

.actionSearchActive {
  width: 100%;
  max-width: 250px;
}

.actionSearchContent {
  width: 100%;
  display: flex;
  border: var(--buttonBorder);
  border-radius: 5px;
  background-color: var(--actionButtonsColor);
  border: 0.6px solid rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.actionSearchContent:hover {
  background-color: var(--actionButtonsHover);
  border: 0.6px solid #fff;

  & svg {
    color: var(--baseColor);
  }
}

.actionSearchActive .searchBtn {
  background-color: var(--actionButtonsColor);
  border-right: none;

  & svg {
    color: var(--buttonIconColor);
  }
}

.searchBtn {
  height: 32px;
  width: 32px;
  cursor: pointer;
  color: var(--buttonIconColor);
}

.field {
  width: 0px;
  padding: 0;
  border-width: 0;
  background-color: var(--actionButtonsColor);
  color: var(--buttonIconColor);
}

.fieldActive {
  width: 100%;
  max-width: 350px;
}

.search {
  &__options {
    position: absolute;
    top: 100%;
    min-width: 500px;
    max-height: 350px;
    width: 100%;
    padding: 19px 6px 26px 6px;
    background-color: var(--tooltipContentSecond);
    overflow-y: auto;
    z-index: 1;

    &__option {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 9px 24px 8px 20px;
      height: 55px;
      width: 100%;

      &:hover {
        background: var(--tooltipContent);
      }
    }
  }

  &__option {
    &__preview {
      display: flex;

      &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        max-width: 59px;
        height: 40px;
        max-height: 40px;
        margin-right: 15px;
      }
      p {
        color: var(--iconColor);
        align-self: center;
        margin-left: 10px;
      }
    }
    &__create {
      cursor: pointer;
      font-size: 10px;
      line-height: 24px;
      color: var(--textColorGray);
      align-self: center;
    }
  }

  &__field {
    &__content {
      position: relative;
      display: flex;
      align-items: center;

      &_icon {
        min-width: 17px;
        min-height: 17px;

        cursor: pointer;
        position: relative;

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          content: '';
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--hover);
        }

        &:hover {
          &:before {
            display: block;
          }
        }

        & > svg {
          position: relative;
          & > path {
            fill: var(--iconColor);
          }
        }
      }

      &_input {
        position: relative;
        background-color: transparent;
        width: 100%;
        height: 39px;
        border: none;
        color: var(--textColor);
        font-family: $sf_pro;
        font-size: 18px;
        line-height: 24px;
      }
    }

    @media screen and (max-width: 640px) {
      max-width: 100%;
    }

    &-action {
      display: flex;
      align-items: center;

      &__close_icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 5px;
      }

      &__filter_icon {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    &__icon {
      position: absolute;
      left: 0;
      top: 7px;
      background: url('/static/assets/img/icons/search/search_icon.svg')
        no-repeat center center;
      width: 22px;
      height: 26px;
      margin: 0;
    }
    &__input {
      position: relative;
      width: 100%;
      height: 39px;
      border: none;
      background: url('/static/assets/img/icons/search/search_icon.svg')
        no-repeat left center;
      margin: 0;
      padding-left: 32px;
      color: $secondary_dark;
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $dark_grey;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $dark_grey;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $dark_grey;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $dark_grey;
      }
      &:focus {
        background: none;
        outline: inherit;
        padding-left: 0;
      }
    }

    .empty--editable-label {
      border-bottom: 1px solid $button-border;
      color: $thirty_color !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .search {
    &__options {
      left: -40px;
      top: 47.5px;
      min-width: 100vw;
    }
  }
}
