.multiple-file-selection {
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 72px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 -4px 12px rgba(16, 22, 26, 0.09);
  transform: translateY(100%);
  transition: transform 0.3s;
  z-index: 300;

  &.active {
    transform: translateY(0);
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 72px;
    width: 100%;
    min-height: inherit;
  }

  &__number-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__number {
    margin-right: 8px;
    color: $primary_color;
  }

  &__help {
    color: #2b2c31;
  }

  &__options-wrapper {
    .file-action-icons__item {
      &:last-child {
        .tooltip {
          left: initial;
          right: -15px;
          transform: none;
        }
      }
      &:nth-last-child(2) {
        .tooltip {
          margin-left: -10px;
        }
      }
    }
  }
}
