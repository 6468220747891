@import 'static/assets/styles/global/global-varibles';

.checker input:checked ~ span {
  background: black url(../../../../../static/assets/svg/checkmark.svg)
    no-repeat center !important;
  border: 1px solid black !important;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: var(--modalOverlay);
  z-index: 10;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 515px;
  width: 100%;
  height: 325px;
  background: var(--baseColor);

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 40px;

  border: 1px solid var(--borderNone);
}

.title {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
  color: var(--textColor);
}

.description {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: var(--textColorGray);

  margin: 15px 0;
}

.checker {
  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: var(--textColor);
  margin-right: 8px;
  &_container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}

.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 16px;
  & > div {
    width: 100px;
    display: flex;
    justify-content: flex-end;

    & > svg {
      color: var(--iconColor, #ffffff);
    }

    & > button {
      background: var(--buttonColor);
      color: var(--baseColor, #ffffff);
    }
  }
}

.close {
  position: absolute;
  top: 22px;
  right: 22px;
  color: var(--iconColor);
  cursor: pointer;
}

.notificationBar {
  position: absolute;
  bottom: -20px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 4px 10px;

  font-family: $sf_pro;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #202124;

  &.green {
    background-color: #54bb8e;
  }
  &.yellow {
    background-color: #f2a928;
  }

  &__link {
    font-family: inherit;
    color: inherit;
    text-decoration: underline;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;

    transition: font-weight 100ms ease-in-out;
    &:hover {
      color: inherit;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 480px) {
  .button_container {
    justify-content: space-evenly;
    div {
      width: calc(50% - 50px);
    }
  }

  .checker {
    margin-right: 0;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }

  .title {
    margin-top: 0;
  }

  .container {
    padding: 60px 15px 32px 15px;
    width: calc(100% - 32px) !important;
    margin: auto 16px;
    left: initial !important;
  }
}
