.icon {
  &__img {
    margin-right: 16px;
    &--folder {
      width: 56px;
      height: 56px;
    }
    &_file {
      min-width: 36px;
      margin-right: 16px;
    }
    &--text {
      font-family: $sf_pro;
      font-size: rem(14px);
      line-height: rem(16px);
      text-transform: uppercase;
    }
  }
}

.arrow-next-icon {
  width: 9px;
  height: 16px;

  &--small {
    width: 7px;
    height: 12px;
  }

  &--grey {
    background: url('/static/assets/img/arrow-next-icon--grey.svg') no-repeat
      center center;
  }

  &--black {
    background: url('/static/assets/img/arrow-next-icon--black.svg') no-repeat
      center center;
  }

  &--blue#{&}--small {
    background: url('/static/assets/img/arrow-next-blue--small.svg') no-repeat
      center center;
  }
}

.cancel-icon {
  &:hover {
    cursor: pointer;
  }

  &--big {
    width: 17px;
    height: 16px;
    background: url('/static/assets/img/cancel-icon--big.svg') no-repeat center
      center;
  }
}

.logout-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &--grey {
    background: url('/static/assets/img/logout-icon--grey.svg') no-repeat center
      center;
  }
}

.checker-tick-icon {
  width: 14px;
  height: 11px;

  &--white {
    background: url('/static/assets/img/checker-tick-icon-white.svg') no-repeat
      center center;
  }
}

.dropdown-icon {
  width: 12px;
  height: 8px;
  background: url('/static/assets/img/dropdown-icon.svg') no-repeat center right;

  &--centered {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
}

.tick-icon {
  &--white {
    width: 14px;
    height: 11px;
    background: url('/static/assets/img/tick-icon-white.svg') no-repeat center
      center;
  }
}

.protection-icon {
  &--blue {
    background: url('/static/assets/img/protection-icon-blue.svg') no-repeat
      center center;
  }
}

.locked-icon {
  &--blue {
    background: url('/static/assets/img/locked-icon-blue.svg') no-repeat center
      center;
  }
}

.key-icon {
  &--blue {
    background: url('/static/assets/img/key-icon-blue.svg') no-repeat center
      center;
  }
}

.password-icon {
  &--blue {
    background: url('/static/assets/img/password-icon-blue.svg') no-repeat
      center center;
  }
}

.music-icon {
  &--blue {
    background: url('/static/assets/img/music-icon-blue.svg') no-repeat center
      center;
  }
}

.left-menu__actions-item.chat {
  color: #4b576c;
  background-image: url('/static/assets/img/chat.svg');
}
.left-menu__actions-item.chat.active,
.left-menu__actions-item:hover {
  background-image: url('/static/assets/img/chat-active.svg');
  color: #0f73ef;
}
