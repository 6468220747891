@import 'static/assets/styles/global/global-varibles';

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0;
  padding: 0;
}

.tab {
  position: relative;
  padding: 15px 0 13px 0;
  width: 33%;
  text-align: center;
  font-family: $sf_pro;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--textColorSecond);
  text-decoration: none;
  cursor: pointer;
}

.tabActive {
  font-weight: 600;
  position: relative;
  color: var(--primeColor);
}

.tabActive::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 16px;
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--primeColor);
  transform: rotate(135deg);
}

.errorMessage {
  position: absolute;
  bottom: -6px;
  font-size: 12px;
  color: #ed1d2d;
}

.errorInput > input {
  background-color: #fff6f7 !important;
  border: 1px solid #ed1d2d !important;
}

.action {
  position: relative;
}
