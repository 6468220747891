@import 'static/assets/styles/global/global-varibles';

.footer {
  font-family: $sf_pro;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
}

.signUp {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--textColorGray, #797A7C);
}

.links {
  text-align: center;
  margin-top: 8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--textColor, #202124);
}

.link {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--textColor, #202124);
}

.internalLink {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--textColor, #000000);
  text-transform: uppercase;
}