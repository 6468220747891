.launchMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-top: 65px;
  padding-bottom: 30px;
}

.fullHeight {
  height: 100%;
}

.launchMessage {
  width: 100%;
  color: #fff;
  font-family: 'Nunito';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  white-space: pre-line;

  span {
    font-weight: 700;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: grid;

    li {
      display: block;
      list-style-type: none;
      position: relative;
      padding-left: 20px;

      &::before {
        content: '\2022';
        position: absolute;
        left: 0;
      }
    }
  }
}

.continueButton {
  width: 100%;
  max-width: 398px;
  padding: 12px 16px;
  border-radius: 14px;
  border: 0.7px solid #fff;
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  &:hover {
    color: #ffe24a;
    border-color: #ffe24a;
  }
}

.activeButton {
  color: #ffe24a;
  border-color: #ffe24a;
}

@media only screen and (max-width: 730px) {
  .launchMessage,
  .continueButton {
    font-size: 14px;
  }
}
