.sharing-nav-panel {
  margin-bottom: 20px;
  box-shadow: inset 0 -1px 0 #dcdee9;
  background-color: #f2f4f8;

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
  }

  &__list {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }

  &__item {
    @extend .reset-button;

    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #818589;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      background: #206acf;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      transform: translateY(-100%);
    }

    &.active {
      color: $primary_dark;
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
