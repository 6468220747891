.teams {
  width: 100%;
  padding: 32px 16px 75px 16px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 13px 0;
  }

  .search {
    &__field {
      max-width: 207px;

      &__input {
        background: $row_light_grey;
        border: 1px solid $forty_color;
        box-sizing: border-box;
        border-radius: 0;
        padding-left: 16px;
      }

      &__icon {
        right: 14px;
        left: auto;
      }
    }
  }

  h3 {
    font-family: $sf_pro;
    font-size: 24px;
    line-height: 24px;
    color: $primary_dark;
  }

  &-container {
    display: flex;
    flex-direction: column;
    background: $primary_light;
    box-shadow: 0 1px 0 $forty_color;
    padding: 0 0 40px 0;
    height: 684px;
  }

  &-item {
    &-row {
      display: flex;
      align-items: center;
      height: 52px;
      position: relative;
      cursor: pointer;
      user-select: none;

      &:nth-child(even) {
        background: $row_light_grey;
      }

      &:first-child {
        &:hover {
          background: $primary_light;
          box-shadow: none;
        }
      }

      &-head {
        align-items: flex-end;
        padding: 0 0 7px 0;

        .teams-item-name,
        .teams-item-email,
        .teams-item-users {
          font-size: 11px;
          line-height: 16px;
          color: $fifty_color;
        }
      }

      .file-action {
        transition: all 0.3s;
        position: absolute;
        top: 10px;
        right: 25px;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        //background: $thirty_color;
        box-shadow: 0 6px 19px -5px rgba(0, 0, 0, 0.2);

        .file-action {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-action {
      width: 24px;
      height: 24px;
      margin: 0 24px;
    }

    &-name {
      margin: 0 auto 0 0;
      width: 25%;
      font-size: 14px;
      line-height: 24px;
      color: $title_section;
    }

    &-email {
      margin: 0 auto 0 0;
      width: 25%;
      font-size: 14px;
      line-height: 24px;
      color: $light_grey;
    }

    &-users {
      margin: 0 auto 0 0;
    }

    &-users-icon {
      &.user-icon-container {
        padding: 0;
      }
    }
  }
}
