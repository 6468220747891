.add-user {
  padding: 10px 40px;
  height: 84vh;

  .link {
    width: 70px;
    height: 24px;
    text-transform: none;
    font-family: $sf_pro;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
    padding: 12px 0 0 0;
  }

  &__user {
    &-container {
      display: flex;
      height: 66px;
      background: $primary_light;
      box-shadow: inset 0 -1px 0 $shadow_grey_light;
      padding: 13px 17px 35px;
      margin: 20px 0 0 0;

      .checkbox {
        margin: 8px 23px 0 0;
      }
    }

    &-name {
      font-family: $sf_pro;
      font-size: 16px;
      line-height: 24px;
      color: $primary_dark;
    }

    &-email {
      font-family: $sf_pro;
      font-size: 11px;
      line-height: 16px;
      color: $fifty_color;
    }

    &-permissions {
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      padding: 20px 0 0 0;
      margin: 0 0 0 auto;
    }
  }

  &__user-button {
    display: flex;
    flex-wrap: wrap;
    width: calc(40vw - 40px);
    margin: 19px 0 0 0;

    .remove-button {
      font-size: 12px;
      line-height: 24px;
      color: $primary_dark;
      margin: 0 8px 8px 0;
    }
  }

  .bottom-button {
    margin-top: auto;
  }
}
