.more-apps {
  &__headline {
    margin: 0;
    margin-left: 24px;
    margin-bottom: 40px;
  }
  &__container {
    padding: 60px 17px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(242px, 242px));
    justify-content: space-between;
    grid-gap: 20px;
  }
}
