.security-item-error {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  &__ico {
    margin-right: 6px;
  }

  &__text {
    color: #58595f;
  }
}
