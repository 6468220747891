div.breadcrumbContextMenu {
  height: 44px;
  padding: 5px 10px 5px 16px;
  &:first-child{
    padding-top: 8px;
  }
  &:last-child {
    padding: 5px 10px 8px 16px;
  }
}
