@import 'static/assets/styles/global/global-varibles';
.file_security_modal {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: var(--baseColor);
}

.tfaFile {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
  }
  &__subtitle {
    color: #206acf;
    font-family: $sf_pro;
    font-weight: 400;
    font-size: rem(12px);
    line-height: rem(24px);
    text-transform: uppercase;
    letter-spacing: 1.4px;
    margin-bottom: 2px;
    text-align: center;
  }
  &__title {
    font-family: $sf_pro;
    font-weight: 400;
    color: var(--textColor);
    line-height: rem(40px);
    font-size: rem(32px);
    margin-bottom: 3px;
    text-align: center;
    max-width: 300px;
  }

  &__desc {
    font-family: $sf_pro;
    color: var(--textColorGray);
    line-height: rem(24px);
    text-align: center;
    margin-bottom: 24px;
    font-size: rem(14px);
    & > span {
      color: $primary_dark;
      line-height: rem(24px);
      text-transform: uppercase;
    }
  }

  &__btn {
    width: 196px;
    height: 48px;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    background-color: $primary_color;
    color: #ffffff;
    font-size: rem(12px);
    text-transform: uppercase;
    font-family: $sf_pro;
    font-weight: 400;
    transition: background-color 0.3s;

    &:hover {
      background-color: $primary_color-light;
    }

    &:active {
      background-color: $primary_color-dark;
    }
  }
}

.activateError {
  font-family: $sf_pro;
  font-size: rem(12px);
  color: $red;
  margin: 10px 0 -22px 28px;
  align-self: flex-start;
}

.password_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  &__inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px 0 16px 0;
  }
  &__number,
  & > input {
    width: 64px;
    height: 64px;
    border: 1px solid var(--inputBorder);
    background: var(--inputBg);
    color: var(--textColor);
    line-height: rem(24px);
    margin: 4px;
    text-align: center;
    transition: background 0.3s ease;
    &:focus {
      border: 1px solid #206acf;
      color: #206acf;
    }
  }
  & > input {
    font-family: $sf_pro;
    font-size: rem(24px);
  }
}

@media screen and (max-width: 480px) {
  .file_security_modal {
    height: 100vh;
    width: 100vw;
  }
}
