.wallet-billing-item {
  display: flex;
  align-items: center;
  min-height: 80px;
  padding-right: 16px;
  box-shadow: 0 1px 0 #dcdee9;
  box-sizing: border-box;

  &__direction {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    font-size: 0;

    &_spend {
      background-color: #4c516d;
    }
    &_charge {
      background-color: #3ab36c;
      svg {
        transform: rotate(190deg);
      }
    }
  }

  &__content {
    margin-left: 12px;
  }

  &__date {
    color: #787b7e;
  }

  &__title {
    margin-top: 10px;
    color: #10161a;
  }

  &__price {
    margin-left: auto;
  }
}
