.wrapper {
  display: flex;
}

.dotSpinner {
  width: 48px;
}

.dotSpinner > div {
  margin-top: 6px;
  height: 14px;
}

.userMessage {
  justify-content: flex-end;
}

.messageContainer {
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 24px;
  border-radius: 20px;
  background-color: var(--chatMessage);
}

.text {
  margin: 0;
  color: var(--textColor);
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  white-space: pre-line;
  word-break: break-word;
  transition: font-size 0.8s ease;
  user-select: text;
}

.text::selection {
  background: var(--textColorGray);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.blockButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: var(--iconColor);
}

.data {
  color: #878787;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.wafeSurfer {
  gap: 0;
  width: 270px;
}

.wafeSurferProgress > p {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #ffffffb2;
  padding-left: 32px;
  margin-top: 2px;
}

.copyIcon {
  color: var(--iconColor);
  transition: all 0.5s ease-in;
}

.copyIconActive {
  color: #25a82e;
}
