@import 'static/assets/styles/global/global-varibles';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modalOverlay, rgba(16, 22, 26, 0.95));
  z-index: 1005;
}

.modal {
  display: block;
  &__wrapper {
    position: absolute;
    width: calc(100% - 48px);
    max-width: 564px;
    top: 50%;
    left: 50%;
    background: var(--modalContent);
    transform: translate(-50%, -50%);
  }

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
  }
  &__title {
    text-align: center;
    font-family: $sf_pro;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px 0;
    text-transform: uppercase;
    color: var(--textColor);

    &_success {
      margin: 0 0 8px 0;
    }
  }
  &__description {
    white-space: pre-wrap;
    text-align: center;
    font-family: $sf_pro;
    color: #4b576c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  &__input {
    width: 100%;
    input {
      margin: 0px;
    }
    div {
      line-height: 16px;
    }
    button {
      right: 18px;
    }
  }
  &__addEmailEye {
    button {
      top: 42px;
    }
  }
  &__field {
    font-family: $sf_pro;
    display: flex;
    align-items: center;
    margin-top: 8px;

    &_label {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: var(--textColor);
      max-width: 118px;
      width: 100%;
      white-space: nowrap;
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      margin-right: 24px;
    }
  }
  &__changeEmailContent {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__successSendedContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 233px;
    padding: 40px;
  }
  &__addEmailContent {
    height: 480px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__changeWalletAddressContent {
    padding: 40px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $sf_pro;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #4b576c;
      margin: 0;
    }
    &__smartContractsAddress {
      white-space: nowrap;
      margin: 10px 0;
      font-weight: 100;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase;
      display: flex;
      align-items: baseline;
    }
    &__smartContractsAddresses {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      overflow-y: auto;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 17px;
    margin-top: 26px;

    button {
      width: 150px;
      height: 34px;
    }
  }
}
.inputChangeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_change_btn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    color: #0f73ef;
  }
}

div .tooltip {
  &__wrapper {
    margin: 0;
    position: absolute;
    display: flex;
    transform: translate(104%, -50%);
    top: 55%;
    right: 0;
    left: auto;
    width: 232px;
    height: 200px;
    padding: 16px;
    background-color: var(--baseColor);
    color: var(--textColor);
    border-radius: 8px;
    box-shadow: 2px 2px 16px rgba(26, 49, 139, 0.264);
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      left: -9px;
      display: block;
      z-index: 1;
      width: 0;
      height: 0;
      background: transparent;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 9px solid white;
      box-shadow: 2px 2px 16px rgba(26, 49, 139, 0.264);
    }
    section {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      gap: 10px;
    }
  }
  &__content {
    font-family: $sf_pro;
    color: var(--textColor);
    &__title {
      font-weight: 400;
      color: var(--textColor);
      margin-bottom: 10px;
    }
    & .green {
      color: $active_color;
    }
  }
}

.preload {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    animation: lds-dual-ring 1.1s linear infinite;
  }
}

.walletModal {
  display: block;

  &__exit {
    color: var(--iconColor);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 16px;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 520px) {
  .modal {
    &__title {
      font-size: 20px;
      line-height: 19.14px;
      margin: 0 0 40px 0;
      &_success {
        margin: 0 0 15px 0;
      }
    }
    &__input {
      label {
        font-size: 10px;
        line-height: 15px;
        margin: 0px 0 7px 0;
      }
    }
    &__description {
      white-space: normal;
    }
    &__successSendedContent {
      height: 273px;
      padding: 41px 12px 44px 12px;
    }
    &__addEmailContent {
      height: 425px;
      padding: 50px 15px 20px 15px;
    }

    &__changeEmailContent {
      padding: 64px 16px 64px 20px;
    }

    &__fields {
      display: flex;
      gap: 24px;
    }

    &__field {
      margin-top: 0;
      display: flex;
      flex-direction: column;

      &_label {
        max-width: initial;
        margin-right: 0;
        margin-bottom: 4px;
      }
    }

    &__buttons {
      flex-direction: column-reverse;
      margin-top: 30px;
      button {
        width: 100%;
      }
      gap: 10px;
    }
  }
  div > .tooltip {
    &__wrapper {
      display: none;
      transform: translate(104%, -50%);
      right: 40%;
      top: -40%;
      width: 126px;
      height: 116px;
      padding: 5px;
      border-radius: 8px;
      &:before {
        border-right: 9px solid transparent;
      }
      section {
        font-size: 8px;
        gap: 2px;
      }
    }
  }
  .modal__input:hover {
    .tooltip {
      &__wrapper {
        display: block;
      }
    }
  }
}
