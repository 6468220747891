.file {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 10px 24px;
  cursor: pointer;
  transition: box-shadow 0.3s, filter 0.3s;
  .file__date {
    color: var(--textColorSecond);
  }
  &.active {
    background: var(--folderActive);
  }

  &.selected-entity {
    box-shadow: var(--folderBoxShadow);
    
    &::before {
      background: linear-gradient(180deg, #2d7ee1, #0f73ef45) border-box;
    }

    // border: 1px solid #206acf;

    &.active {
      border: none;
      box-shadow: 0 6px 19px -5px rgba(0, 0, 0, 0.2);
    }
  }

  .select-item-checkbox {
    display: none;
  }

  &:hover {
    .select-item-checkbox {
      display: block;
    }
  }

  &.selected-entity {
    .select-item-checkbox {
      display: block;
    }
  }

  &.active {
    .select-item-checkbox {
      display: block;
    }
  }

  &.file_row {
    .select-item-checkbox {
      margin-right: auto;
    }

    .file-action {
      right: -8px;
    }
  }

  &.file_square {
    .select-item-checkbox {
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }

  &.file_small_row {
    margin-bottom: 1px;

    .select-item-checkbox {
      width: 16px;
      height: 16px;
    }
  }

  &__checkbox {
    position: relative;
    cursor: pointer;
    padding: 0;
    line-height: 0;
    font-size: 0;
    display: none;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid $primary_color;
    background: #ffffff;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s;
    }

    &.active {
      border: none;
      background: #206acf url(/static/assets/img/icon--checked.svg) center
        center/14px 11px no-repeat;
      &::after {
        opacity: 1;
      }
    }
  }

  &.active &__checkbox {
    display: block;
    border: none;
    background: #206acf url(/static/assets/img/icon--checked.svg) center
      center/14px 11px no-repeat;
    &::after {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.6;
  }

  &:hover {
    box-shadow: 0 6px 19px -5px rgba(0, 0, 0, 0.2);
    & > div > div > div {
      svg:nth-child(3) {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    .file__checkbox {
      display: block;
    }
  }

  &_scaled {
    opacity: 0.45;
  }

  &.large-index {
    z-index: 2;
  }

  &_row {
    min-height: 88px;

    .file__container-preview {
      flex-wrap: nowrap;
    }

    .file__image {
      width: 56px;
      height: 56px;
      object-fit: cover;
    }

    .icon__img_file {
      max-height: 43px;
    }

    & .default-image {
      width: 36px;
      height: 43px;
      margin-right: 16px;
    }
  }

  &_row & {
    &__checkbox {
      margin-right: 28px;
    }

    &__date {
      text-align: left;
    }
  }

  &_square {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 242px;
    min-height: 242px;
    padding: 0;
    cursor: pointer;
    flex: 1 1 212px;
    min-width: 212px;
    max-width: 300px;
    flex-wrap: nowrap;
    border-radius: 8px;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      border: 1px solid transparent;
      background: linear-gradient(180deg, var(--fileBorderUp), var(--fileBorderDown)) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }

    &-preview {
      align-items: flex-start;
    }

    .file-action-avatar {
      position: absolute;
      opacity: 0;
      top: 10px;
      right: 10px;
      display: flex;
      color: var(--iconColor);
      cursor: pointer;
    }

    .file-action-avatar-show {
      opacity: 1;
    }

    .file-action-avatar-active {
      color: #2d7ee1;
    }

    .file-action {
      top: 10px;
      display: flex;
    }

    .checkbox {
      position: absolute;
      top: 15px;
      left: 15px;
      margin: 0;
      padding: 0;

      label {
        visibility: hidden;
        opacity: 0;
      }
    }

    .icon__img_file {
      width: 85px;
      height: 100px;
      margin-right: 0;
    }
  }

  .file__icon-image {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  &_square & {
    &__desc {
      display: none;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 24px 17px 11px 17px;
      width: 100%;
      margin: 0 auto;
      height: 159px;

      &-preview {
        padding: 5px 5px 0 5px;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    &__text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      margin: 0 auto;
      border-top: 1px solid var(--fileBorderSeparate);
      text-align: center;
      background-color: transparent;
    }

    &__title {
      font-size: 14px;
      line-height: 21px;
      margin: 16px 0 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--textColorFour);
    }

    &__actions {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__edit {
      top: 235px;
      right: 40px;

      &:before {
        right: 75px;
      }
    }

    &__secured-shared {
      display: flex;
      background: var(--folderIndicator);
      height: 27px;
      width: 27px;
      border: 1px solid var(--folderIndicator);
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      &-container {
        top: 146px;
        position: absolute;
        display: flex;
        gap: 6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__secured-shared svg path {
    fill: var(--folderIndicatorIcon);
  }

  &_small_row {
    flex-wrap: nowrap;
    padding: 10px 40px 10px 19px;
    margin: 0;
    border: none;
    height: 40px;
    min-height: 35px;
    background-color: var(--folderHeader);

    &:hover {
      box-shadow: 0 1px 8px rgba(26, 49, 139, 0.25);
      z-index: 1000;
    }

    &:nth-child(2n) {
      background: transparent;
    }

    .icon__img--file {
      display: flex;
      justify-content: center;
      width: 59px;
      height: 40px;
      margin: 0 0 0 auto;

      svg {
        width: 30px;
        height: 40px;
      }
    }

    .file__checkbox {
      width: rem(18px);
      height: rem(18px);
      margin: 0 18px 0 0;
    }

    &.active {
      background: var(--sidebarHover);
      box-shadow: var(--boxShadow);
    }
  }

  &_small_row & {
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 0;
      min-width: 90px;
      max-width: 90px;
    }

    &__text {
      font-family: $sf_pro;
      font-style: normal;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      line-height: 16px;
      color: var(--textColor);
      padding: 0 0 0 9px;
      width: 29%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__shared {
      width: 15%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $primary_color;
    }

    &__secured {
      width: 16%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__created {
      width: 20%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__size {
      display: flex;
      width: 16%;
      font-family: $sf_pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $fifty_color;
    }

    &__actions {
      margin: 0 0 0 auto;
      position: absolute;
    }

    &-action-icons__item {
      max-width: 14px;

      & > img {
        width: 14px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &-action-icons__item-ico-edit {
      max-width: 14px;
      & > img {
        width: 14px;
      }
    }

    &-action-icons__item-ico-timing {
      max-width: 14px;
      & > img {
        width: 14px;
      }
    }

    &-action-icons__item-ico-remove {
      max-width: 14px;
      & > img {
        width: 14px;
      }
    }

    &-action-icons__item-ico-download {
      max-width: 14px;
      max-height: 17px;
      border: none;
      & > img {
        width: 14px;
      }
    }

    &__edit {
      top: 32px;
      right: calc(50% - 121px);

      &:before {
        right: calc(50% - 6px);
      }
    }
  }

  .checkbox {
    label {
      transition: all 0.3s;
    }
    input:checked + label {
      visibility: visible;
      opacity: 1;
    }
  }

  .action-btn {
    margin: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }

  &__actions {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    margin: 0 0 0 auto;
  }

  &:hover {
    .checkbox {
      label {
        visibility: visible;
        opacity: 1;
      }
    }
    .file__actions {
      opacity: 1;
      visibility: visible;
    }

    .file-action-avatar {
      opacity: 1;
    }
  }

  &__edit {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 82px;
    right: calc(50% - 121px);
    width: 284px;
    height: 120px;
    background: $edit_background;
    padding: 18px 20px 16px 20px;
    box-sizing: content-box;
    z-index: 121;
    outline: 0.5px solid #e4e7ee;

    &:before {
      content: '';
      position: absolute;
      top: -12px;
      right: calc(50% - 6px);
      display: block;
      z-index: 1;
      width: 0;
      height: 0;
      border-bottom: 12px solid $edit_background;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
    }

    &-title {
      font-family: $sf_pro;
      font-size: 14px;
      line-height: 24px;
      color: $primary_black;
    }

    &-input {
      background: $primary_light;
      height: 48px;
      padding: 8px 14px;
      width: 100%;
      border: 1px solid #ccceda;
      box-sizing: border-box;
      margin: 8px 0 0 0;
      font-size: 14px;
      line-height: 24px;

      &:focus {
        border: 1px solid $primary_color;
      }

      &::placeholder {
        color: #abadb9;
        font-family: $sf_pro;
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-buttons {
      display: flex;
      margin: 11px 0 0 0;
      justify-content: flex-end;

      .button-raw {
        width: 113px;
      }

      .cancel {
        margin: 0 10px 0 0;
      }

      .save {
      }
    }
  }

  &__inup-wrapper {
    width: 100%;
    margin-right: 40px;
  }

  &__progress-bar {
    transition: width 0.5s ease;
    position: absolute;
    top: 1px;
    left: 1px;
    height: calc(100% - 2px);
    max-width: calc(100% - 2px);
    background-color: #ebf7ff;
    z-index: -1;
  }
  &__title {
    color: var(--textColor);
    line-height: rem(24px);
    word-break: break-all;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .tooltip {
    display: none;
  }
  .file__checker {
    &__title {
      padding-left: 15px;
    }
    &__title:nth-child(n) {
      width: 21%;
    }
    &__title:nth-child(5) {
      width: 9%;
    }
  }
  .file {
    .select-item-checkbox {
      display: block;
    }
    &_square {
      min-width: 0;
      width: 231px;
      min-height: 160px;
      margin: 0;
      & .file {
        &__text {
          height: 77px;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0;
        }
        &__title {
          margin: 16px 0 0 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
        }
        &__date {
          font-size: 10px;
          line-height: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
        }
        &__image {
          width: 100%;
        }
        &__container {
          height: 165px;
        }
        &-action {
          visibility: visible !important;
          opacity: 1 !important;
        }
        &__secured-shared {
          &-container {
            top: 151px;
          }
        }
      }
    }
    &_small_row {
      .file {
        &__text {
          margin: 0;
          padding-left: 9px;
        }
        &__created {
          padding-left: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &__shared {
          padding-left: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 700;
          display: flex;
          gap: 7px;
          align-items: center;
          svg {
            width: 8.13px;
            height: 8.94px;
          }
        }
        &__secured {
          padding-left: 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &__size {
          padding-left: 0;
          padding-right: 20px;
        }
        &-action {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
    }
  }
  .file-non-display-option {
    div {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        display: none;
      }
      &:nth-child(2) {
        width: 70%;
      }
    }
  }
}

@media (max-width: 768px) {
  .file_small_row .file__container > div {
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .zaebalo {
    //todo: need to be fixed
    font-size: rem(12px);

    // .tooltip:hover {
    //   display: flex;
    // }
    // &:hover .tooltip {
    //   display: flex;
    // }
    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      padding: 0 16px 0 0;
      width: 120px;
    }
    &__desc {
      color: $fifty_color;
      line-height: rem(16px);
    }

    &__secured-shared {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: $primary_light;
      margin: 0 6px 0 0;
      border: 1px solid $divider_border_dark;

      &-container {
        display: flex;
        align-items: center;
        position: absolute;
        right: 82px;

        .users-container {
          padding: 0;
          min-width: 40px;
        }
      }

      &-crypto {
        width: 15px;
        height: 15px;
      }
    }

    &__date {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      color: #7c7e88;
    }

    @keyframes setOpacity {
      from {
        opacity: 1;
      }
      to {
        opacity: 0.4;
      }
    }
    .showed:nth-child(n) {
      background: #397fe947;
      transition: background-color 0.5s;
    }
  }
}
@media screen and (max-width: 480px) {
  .file {
    &_square {
      width: 150px !important;
      height: 160px;
      min-height: 0;
      margin: 0;
      & .file {
        &__text {
          height: 77px;
          height: 51px;
        }
        &__title {
          margin: 10px 0 0 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
        &__date {
          font-size: 8px;
          line-height: 11px;
        }
        &__image {
          width: 100%;
        }
        &__container {
          height: 109px;
          padding: 0;

          &-preview {
            padding: 10px 10px 0 10px;
          }
        }
        &-action {
          top: 6px !important;
          visibility: visible !important;
          opacity: 1 !important;
        }
        &__secured-shared {
          &-container {
            top: 95px;
          }
        }
      }
    }
    &_small_row {
      padding: 10px 18px 10px 10px;
      .file {
        &__created {
          display: none;
        }
        &__shared {
          display: none;
        }
        &__secured {
          display: none;
        }
        &__size {
          display: none;
        }
        &__text {
          width: 65%;
        }
        &__container {
          min-width: 71px;
        }
      }
      & > button {
        width: 22px;
      }
    }
  }
}
@media (max-width: 380px) {
  .file {
    &_small_row {
      .file {
        &__text {
          width: 55%;
        }
      }
    }
  }
}
