@import 'static/assets/styles/global/global-varibles';

.inputs {
  &__container {
    display: flex;
    gap: 16px;
  }
  &__input {
    label {
      font-family: 'Nunito';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-right: 0;
    }
    input {
      height: 36px;
      background-color: transparent;
      border-radius: 8px;
    }
  }
}
.imageContainer {
  margin: 15px 0;

  input {
    display: none;
  }
  span {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;
    text-align: left;
    color: var(--textColor);
  }

  &__image {
    margin-top: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 170px;
    border: 0.5px dashed var(--dashedDividerBorder);
    position: relative;
    border-radius: 8px;
    
    & svg {
      color: var(--iconColor);
    }

    & img {
      width: 100%;
    }

    & span {
      margin-left: 17px;
    }

    & .fileName {
      margin-left: 10px;
      font-family: $sf_pro;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  &__previewImage {
    object-fit: cover;
    flex: 1;
    max-width: 100%;
    height: 100%;
  }
}
.textareaContainer {
  span {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--textColor);
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  button {
    width: 143.94px;
    max-height: 38px;
    white-space: nowrap;
    font-size: 12px;
    padding: 0;
    letter-spacing: 0;
    background-color: transparent !important;
    color: #FFFFFF !important;
    border-radius: 8px;
    border: 0.8px solid #FFFFFF;
  }

  button:disabled {
    color: #FFFFFF33 !important;
    border: 0.8px solid #FFFFFF80 !important;
  }
}

.error {
  & > input {
    border: 1px solid #ed1d2d;
  }
  &_message {
    color: #ed1d2d;
    font-family: $sf_pro;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.reUpload {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-left: 0;
}

.uploadIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  margin-left: 0;
}

.description > textarea {
  box-sizing: border-box;
  width: 100%;
  height: 96px;
  margin: 4px 0 20.67px 0;
  resize: none;

  font-family: $sf_pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  background-color: transparent;
  border-radius: 8px;
}

.button_loader {
  & > div > div {
    background-color: var(--baseColor, #FFFFFF) !important;
  }
}

.hintText {
  font-family: $sf_pro;
  display: block;
  height: 34px;
  height: 100%;
  padding: 8px 6px;
}