@import 'static/assets/styles/global/global-varibles';

.wrapper {
  font-family: $sf_pro;
  border-top: 0.5px dashed var(--dashedDividerBorder);
  position: fixed;
  display: flow-root;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  z-index: 1004;
  overflow-x: hidden;
  z-index: 1;
  left: 0;
  text-align: center;
  background-color: #0F73EF;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #fff;
}

.icon {
  margin-right: 6px;
}
