.fileNameTooltip {
  left: 0 !important;
  transform: none !important;
  z-index: 1000 !important;
}

section.rowContainer {
  height: 58px;
}

.videoPlayIcon {
  fill: #ffffff;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  transition: fill 200ms linear, transform 0.7s ease;
}
.videoPlayIcon:hover {
  fill: #0f73ef;
}

.smallRowText {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

.iconsWrapper {
  min-width: 10px;
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right: 56px;
  margin-left: auto;
}

.colorWrapper {
  width: 9px;
  height: 9px;
  min-height: 9px;
  min-width: 9px;
  margin-left: auto;
  border-radius: 50%;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-top: 14px;
}

.label {
  font-family: 'SF Pro Text', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 8px 20px 0 20px;
}

.progressBar {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.progress {
  width: 100%;
  display: flex;
  padding: 0 20px;
}

.progressBarBg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressBarActive {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  /* border: 1px dashed #ffffff; */
}

.progressBarDot {
  width: 2px;
  height: 1px;
}

.progressBarDotBg {
  background-color: #ffffff53;
}

.progressBarDotActive {
  background-color: #fff;
}

.progressBtn {
  font-family: 'SF Pro Text', sans-serif;
  font-size: 11px;
  font-weight: 300;
  line-height: 16.5px;
  text-align: center;
  margin: 0 0 0 10px;
}
.fileContainerPreview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.panoramaPreview {
  transition: scale 300ms ease-in-out;
  scale: 1.2;
}
.panoramaFloatedPreview {
  transition: all 150ms linear;
  scale: 1.3;
}

.checkbox {
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .iconsWrapper {
    margin-right: 0;
    width: 10%;
  }
}
