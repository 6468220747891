.ghost-mode-timer {
  margin-bottom: 10px;

  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: transform 0.3s;

  &_up {
    transform: translateY(-75px);
  }

  &__title {
    margin-bottom: 8px;
    line-height: 1;
    color: #8b8e90;
  }

  &__numbers {
    color: #ffffff;
    margin-bottom: 8px;
    line-height: 1;
  }

  &__img {
    font-size: 0;
  }
}
