.wrapper {
  background-color: white;
  padding: 20px;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    79.11deg,
    #00a5ff 27.88%,
    #0047ff 84.06%
  );
}

@media screen and (min-width: 560px) {
  .wrapper {
    padding: 40px;
  }
}
