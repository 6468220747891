@import 'static/assets/styles/global/global-varibles';

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  background: var(--baseColor);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    font-family: $sf_pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #4b576c;
    margin-top: 20px;
    width: 600px;
    text-align: center;
  }
}

.loader {
  display: block;
}

.loadeLight {
  color: #000000;
}

.loadeDark {
  color: #ffffff;
}