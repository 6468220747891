@import 'static/assets/styles/global/global-varibles';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 16px;
}

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
  max-height: calc(100% - 71px);
}

.avatarContainer::before {
  content: '';
  position: absolute;
  background-color: #000;
  height: 2px;
  top: -2px;
  width: 70.4%;
}

.progressRing {
  position: absolute;
  width: 70%;
  max-height: calc(100% - 20px);
  height: initial;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.bigCircle {
  width: 100%;
  height: initial;
}

.neyraLogo {
  position: absolute;
  width: 45%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}

.typer p {
  font-family: Nunito;
  font-size: 30px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.typer {
  padding-bottom: 30px;
}

.addLogoTP {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 84.34%;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

.addLogoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.addLogoContent > button {
  border: 0.8px solid #FFFFFF80;
  width: 37px;
  height: 37px;
  color: #fff;
  border-radius: 100%;
}

.addLogoContent > p {
  font-family: Nunito;
  font-size: 21px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin: 13px 0 0 0;
}

.addLogoInput {
  display: none;
}

.logoPreview {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.cityList {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 70%;
}

.cityItem {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  cursor: pointer;
  border-bottom: 0.5px solid #FFFFFF80;
}

.addLogoTP:hover .editLogo {
  display: flex;
}

.editLogo {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.editLogo button {
  border: 0.8px solid #FFFFFF80;
  width: 37px;
  height: 37px;
  border-radius: 30px;
}

.editLogo svg {
  color: #fff;
}

.editLogo p {
  font-family: 'Nunito';
  font-size: 21px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin: 13px 0 0 0;
}

@media screen and (max-height: 880px) {
  .addLogoTP {
    width: initial;
    height: calc(100% - 140px);
  }
}