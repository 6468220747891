.filterWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 20px;
  overflow-y: scroll;
  height: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--scrollbarColor);
  }
}

.filterCard {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 9px 16px;
  box-sizing: border-box;

  color: #fff;
  background: transparent;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 8px;
  height: fit-content;

  & img {
    border-radius: 5px;
    width: 80px;
    height: 80px;
  }

  &__label {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    color: var(--textColor);
    text-align: center;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  &__checked {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.095px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1.523809552192688px);
    & svg {
      color: #ffb800;
      width: 20px;
      height: 20px;
    }
  }

  &_selected.filterCard::before,
  &:hover::before {
    background: linear-gradient(180deg, #ffb800, var(--filterCardBorder2Studio))
      border-box;
  }

  &_selected .filterCard__label,
  &:hover .filterCard__label {
    color: #ffb800;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    border: 1px solid transparent;
    background: linear-gradient(
        180deg,
        var(--filterCardBorder1Studio),
        var(--filterCardBorder2Studio)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.filtersTitle {
  color: var(--textColor);
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  margin: 0 auto 12px;
  padding: 0;
  text-align: center;
}

.filterHint {
  position: fixed;
  background: var(--tooltipBackground);
  color: var(--textColor);
  padding: 5px 5px 10px;
  border-radius: 8px;
  z-index: 999999900000000;
  & img {
    width: 250px;
    height: auto;
  }
  & p {
    max-width: 250px;
    margin: 0;
  }
  & h3 {
    margin: 5px 0;
    font-size: 18px;
    font-weight: 500;
  }
}
