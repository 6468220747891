@import 'static/assets/styles/global/global-varibles';

.languageSpace {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 0.5px solid var(--borderCardColor);
  display: flex;
  align-items: center;
}

.languageContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.languageHeader {
  & div div h3 {
    max-width: 100%;
    text-transform: none;
  }
}

.languageSelect {
  height: 48px;
  width: 220px;
}

@media (max-width: 480px) {
  .languageSpace {
    padding-left: 0;
    padding-right: 0;
  }

  .languageContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .languageSelect {
    width: 100%;
  }
}