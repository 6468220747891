.imageContent {
  position: absolute;
  display: contents;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.myImage {
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}